import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';


declare var $: any;
declare var dgl_share: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {

  private regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/i;
  private regexGST: any = "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$";
  private regexPIN: any = /^[0-9]{6}?$/;
  private regexPAN: any = /[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
  private regexIEC: any = /^\s*([0-9a-zA-Z]+)\s*$/;
  private regexNumber: any = /^\s*([0-9]+)\s*$/;

  public thirdPartyVerifyObject: any = {};
  public isexporterPlusDomestic: Boolean = false;
  public isDomesticOnly: Boolean = false;
  public isGSTIN: Boolean = false;

  // accept terms and conditions 
  public t_c: Boolean = false;

  public stateList = [
    "ANDAMAN & NICOBAR ISLANDS",
    "ANDHRA PRADESH",
    "ARUNACHAL PRADESH",
    "ASSAM",
    "BIHAR",
    "CHANDIGARH",
    "CHATTISGARH",
    "DADRA & NAGAR HAVELI",
    "DAMAN & DIU",
    "DELHI",
    "GOA",
    "GUJARAT",
    "HARYANA",
    "HIMACHAL PRADESH",
    "JAMMU & KASHMIR",
    "JHARKHAND",
    "KARNATAKA",
    "KERALA",
    "LAKSHADWEEP",
    "MADHYA PRADESH",
    "MAHARASHTRA",
    "MANIPUR",
    "MEGHALAYA",
    "NAGALAND",
    "ODISHA",
    "MIZORAM",
    "PONDICHERRY",
    "PUNJAB",
    "RAJASTHAN",
    "SIKKIM",
    "TAMIL NADU",
    "TELANGANA",
    "TRIPURA",
    "UTTAR PRADESH",
    "UTTARAKHAND",
    "WEST BENGAL"
  ]

  public refferals: any = [{
    id: "direct",
    img: "req.body.img",
    isImg: true,
    name: "Direct"
  }];

  private iecStatusArray = [0, 4, 7, 8, 9];

  /********
   * third party iec verify 
   * vars
   */
  public loading = false;
  public verfn = 0;
  public thirdPartyObject: any = { verified: false };

  /**********
   * 1st part 
   * form for email, mobile, Contact Person name
   * get otp and verify otp
   * proceed for next part
   */
  public userForm: FormGroup;
  public form: FormGroup;
  public validUser: Boolean = false;
  // for send otp
  public isOtp: Boolean = false;

  public step: number = 0
  // otp verified
  public verified: Boolean = false;
  public timeout: number = 0;
  public isTimeout: Boolean = false

  /**
   * 2nd part
   * set password and proceed for next part
   */
  public isUserSave: Boolean = false;
  public userDetails: any = {};
  public refBy = 'direct';
  public detailsHeading: any = 'Exporter';
  public userData: any = {};

  /***** digi vars */
  checkLocalVar = false;
  ld = false;
  iecDigi = '';
  isSharing = false;
  isFileShared = false;
  idProof = '';
  docName = '';
  dgData: any;

  /***** docs upload */
  imageData_copy_route;
  copy_route_filesToUpload: Array<File> = [];
  isFileUploading = true;
  progressbar = false
  progressbar1 = false;
  progresValue = 0;
  progresValue1 = 0;

  /****
   * 3rd part 
   * bussiness details
   * 
   * For export  and domestic + exporter
   * Title = Exporter details
   * 1. IE code with verify button.
   * 2. name of exporter
   * 3. address
   * 4. city 
   * 5. state 
   * 6. pincode , pre filled if verified.
   * 7. iec certificate doc
   * 8. GSTIN if available
   * 9. If gstin not available => doc type and doc number
   * 10. type of docs => adhar , passport, pan, tan
   * 11. Ad code , name and address
   * 12. Bussiness details (optional) => bussiness name and url (if any)
   */

  /****
   * 3rd part 
   * for Domestic only
   * 1. gstin
   * Title => User details
   * 2. Name of user
   * 3. Address
   * 4. city
   * 5. State
   * 6. Pincode
   * 7. gstin certificate doc
   */

  /****
   * 3rd part 
   * Service Provider
   * 1. Type => CHA , FPO , Franchise, Logistics service
   * 
   * For CHA =>
   * 2. CHA license number
   * Title => CHA details
   * 3. Company name
   * 4. city
   * 5. state
   * 6. address
   * 7. pincode
   * 8. cha license doc 
   * 
   */

  /***
   * For FPO =>
   * 2. PAN number
   * Title => FPO Agent details
   * 3. name of FPO Agent
   * 4. city
   * 5. state
   * 6. address
   * 7. pincode
   * 8. pan doc 
   * 9. gstin number (optional) 
   * 10. Adhar number
   */

  /***
   * For Franchise =>
   * 2. PAN number
   * Title => Franchise Details
   * 3. Name of Franchise 
   * 4. city
   * 5. state
   * 6. address
   * 7. pincode
   * 8. pan doc 
   */

  /***
  * For Logistics services =>
  * 2. PAN number
  * Title => Company Details
  * 3. Company name
  * 4. city
  * 5. state
  * 6. address
  * 7. pincode
  * 8. pan doc 
  */

  constructor(public FormBuilder: FormBuilder, private renderer: Renderer2, private cdr: ChangeDetectorRef,
    public authService: AuthService, private router: Router, public activatedRoute: ActivatedRoute, private nfs: NotificationService) {
    this.getreferrals();
    localStorage.removeItem('attachment_poi');
    this.createForm();
    this.createFormBasedOnUsertype(1);
    this.getAllStates('state', '')
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      // // ("params >>> ", params);
      if (params && params['id']) {
        if (params['id'].length >= 15) {
          this.userData = { _id: params['id'] };
          this.step = 2;
        }
      }
    });
  }

  ngOnDestroy() {
    try {
      document.getElementById('dlshare').remove();
    } catch (error) {

    }
    this.removejscssfile('dl.js', '.js');
  }

  ngAfterViewInit() {
    // ('all done loading :)');
    this.cdr.detectChanges();
    this.digiLocekr();
  }

  digiLocekr() {
    this.checkLocal();
    this.authService.digiLocker().subscribe(response => {
      if (response.success) {
        this.dgData = response.data;
        this.ld = false
        this.loadScript(response.data)
        // var t=document.getElementsByTagName('head');
        // t[0].append(doc);
        setTimeout(() => {
          this.digiLocekr()
        }, 1740000);

      }
      else {
        this.nfs.showInfo('Oops!', response.message, 'error');
      }
    })
  }

  checkLocal() {
    let { userType, ieCode } = this.userForm.value;
    let { mobile, } = this.form.value;

    $('#attachment_poi>span').remove();
    $('#attachment_poi>label').remove();
    if (!this.ld) {
      let e = document.getElementById('attachment_poi');
      if (e != undefined && e != null) {
        if (e.children.length > 0) {
          $('#attachment_poi>a').removeAttr('href');
          let ed = e.children[0]
          // ed.se=' https://pikmybox.com/assets/images/digi.jpg';
          if (ed.children.length > 0) {
            let i = ed.children[0]
            i.setAttribute('src', 'https://pikmybox.com/assets/images/digi.jpg')
            i.setAttribute('style', 'height:60px;cursor:pointer')
            // ed.se.css('height','60px')
            this.ld = true
          }
        }
      }
    }

    var t = localStorage.getItem('attachment_poi')
    if (t !== null && t != "" && t != undefined) {
      var js = JSON.parse(t)
      if (this.iecDigi != js.filename) {
        this.isSharing = true
        this.iecDigi = js.filename
        var data = null;
        if (userType == 2) {
          data =
          {
            ieCode: Date.now(),
            docType: '',
            data: js
          }
        }
        else {
          if (userType == 3) {
            data =
            {
              ieCode: mobile,
              docType: '',
              data: js
            }
          }
          else {
            data =
            {
              ieCode: ieCode,
              docType: 'iec',
              data: js
            }

          }
        }
        this.authService.sendDoc(data).subscribe(res => {
          if (res.success) {
            this.isSharing = false
            this.isFileShared = true;
            localStorage.removeItem('attachment_poi');
            if (data.docType == 'idProof') {
              this.idProof = res.uploadedFile;
            }
            else {
              this.docName = res.uploadedFile;
            }
            // swal('Success',res.message,'success')
          }
          else {
            this.nfs.showInfo('Oops!', res.message, 'error')
          }
        })

      }
      return true
    }
    else {
      return false
    }
  }

  loadScript(data) {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      // this.checkOutUrl = data.
      var dynamicScripts = data.script;
      // for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts;
      node.id = "dlshare";
      node.type = 'text/javascript';
      node.async = false;
      // node.charset = 'utf-8';
      // node.setAttribute("bolt-color","ff0066")
      // node.setAttribute("bolt-logo","https://pikmybox.com/assets/images/digi.jpg")
      document.getElementsByTagName('head')[0].appendChild(node);

      // var doc=document.getElementById('dlshare');
      node.setAttribute("data-app-hash", data["data-app-hash"]);
      node.setAttribute("data-app-id", data['data-app-id'])
      node.setAttribute("data-callback", "receiveMessagewa")
      node.setAttribute("time-stamp", data["time-stamp"]);
      node.onload = function () {
        dgl_share(data['data-app-id'], data["data-app-hash"], data["time-stamp"]);
        $('#attachment_poi>a').removeAttr('href');
        $('#attachment_poi>a').css('color', '#636363')
      };
    }
  }

  createForm() {
    this.form = this.FormBuilder.group(
      {
        email: new FormControl('', { validators: [Validators.required, Validators.email] }), // , updateOn: 'blur'
        name: new FormControl('', { validators: [Validators.required, Validators.minLength(3)] }),
        mobile: new FormControl('', {
          validators: [Validators.required, Validators.pattern(/^\d{10}(,\d{10})*$/),
          Validators.minLength(10), Validators.maxLength(10)]
        }),

        otp: new FormControl('', { validators: [Validators.required, Validators.maxLength(6), Validators.pattern(/^[0-9]{6}?$/)] }),

        password: new FormControl('', {
          validators: [Validators.required,
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/)],
        }),
        cpassword: new FormControl('', {
          validators: [Validators.required,
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/)],
        }),
      });
  }

  createFormBasedOnUsertype(userType) {
    this.isGSTIN = false;
    switch (userType) {
      case 1:
        this.exporterPlusDomesticForm(1);
        break;
      case 40:
        this.exporterPlusDomesticForm(40);
        break;
      case 2:
        this.serviceProviderForm(2, 2);
        break;
      case 30:
        this.domesticForm();
        break;
    }
  }

  exporterPlusDomesticForm(usertype) {
    // * 1. IE code with verify button.
    // * 2. name of exporter
    // * 3. address
    // * 4. city 
    // * 5. state 
    // * 6. pincode , pre filled if verified.
    // * 7. iec certificate doc
    // * 8. GSTIN if available
    // * 9. If gstin not available => doc type and doc number
    // * 10. type of docs => adhar , passport, pan, tan
    // * 11. Ad code , name and address
    // * 12. Bussiness details (optional) => bussiness name and url (if any)
    // * 13. referredBy

    /** contact person name */
    //  check in backend for key matching
    //  fname: new FormControl('', [Validators.required]),

    this.userForm = this.FormBuilder.group({
      referredBy: new FormControl(this.refBy, { validators: [Validators.required], updateOn: 'blur' }),
      ieCode: new FormControl('', {
        validators: [Validators.required, Validators.minLength(10),
        Validators.maxLength(10), Validators.pattern(this.regexIEC)]
      }),

      /** name of exporter */
      name: new FormControl('', { validators: [Validators.required, Validators.minLength(3)] }),
      address: new FormControl('', { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200)] }),
      city: new FormControl('', { validators: [Validators.required, Validators.minLength(3)] }), // , updateOn: 'blur' // after click outside input value assign hogi
      state: new FormControl('', { validators: [Validators.required, Validators.minLength(3)] }), // , updateOn: 'blur' 
      pin: new FormControl('', { validators: [Validators.required, Validators.pattern(this.regexPIN)] }),

      /** doc type => GSN , ADH, PSP, PAN, TAN */
      gstinType: new FormControl('GSN', { validators: [Validators.required], }),
      gstin: new FormControl('', { validators: [Validators.required, Validators.pattern(this.regexGST)] }),
      adh: new FormControl('', { validators: [Validators.minLength(12), Validators.maxLength(12)] }),
      psp: new FormControl(''),
      pan: new FormControl('', { validators: [Validators.pattern(this.regexPAN)] }),
      tan: new FormControl(''),

      adCode: new FormControl('', { validators: [Validators.minLength(10)] }),
      adName: new FormControl('', { validators: [Validators.minLength(3)] }),
      adAddress: new FormControl('', { validators: [Validators.minLength(3)] }),

      businessName: new FormControl('', { validators: [Validators.minLength(3)] }),
      urlForm: this.FormBuilder.array([this.FormBuilder.group({ url: new FormControl('') })]),

      /*** for all usertype */
      userType: new FormControl(usertype, [Validators.required]),
    });
  }

  domesticForm() {

    // * for Domestic only
    // * 1. gstin
    // * Title => User details
    // * 2. Name of user
    // * 3. Address
    // * 4. city
    // * 5. State
    // * 6. Pincode
    // * 7. gstin certificate doc

    this.userForm = this.FormBuilder.group({
      referredBy: new FormControl(this.refBy, { validators: [Validators.required], updateOn: 'blur' }),

      /** name of user */
      name: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
      address: new FormControl('', { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200)], updateOn: 'blur' }),
      city: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
      state: new FormControl('DELHI', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
      pin: new FormControl('', { validators: [Validators.required, Validators.pattern(this.regexPIN)], updateOn: 'blur' }),

      /** doc type => GSN , ADH, PSP, PAN, TAN */
      gstinType: new FormControl('GSN', { validators: [Validators.required] }),
      gstin: new FormControl('', { validators: [Validators.required, Validators.pattern(this.regexGST)] }),
      adh: new FormControl('', { validators: [Validators.minLength(12), Validators.maxLength(12)] }),
      pan: new FormControl('', { validators: [Validators.pattern(this.regexPAN)] }),

      /*** for all usertype */
      userType: new FormControl(30, [Validators.required]),
    });
  }

  serviceProviderForm(userType, selectedType) {
    /****
    * 3rd part 
    * Service Provider
    * 1. Type => CHA , FPO , Franchise, Logistics service
    */
    // ("userType , selectedType >>> ", userType, selectedType);


    if (selectedType == 2) {
      this.userForm = this.FormBuilder.group({
        referredBy: new FormControl(this.refBy, [Validators.required]),

        /** name of Company */
        name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        address: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        city: new FormControl('', [Validators.required, Validators.minLength(3)]),
        state: new FormControl('DELHI', [Validators.required, Validators.minLength(3)]),
        pin: new FormControl('', [Validators.required, Validators.pattern(this.regexPIN)]),

        /*** for user type = 2 only  */
        selectedType: new FormControl(selectedType, [Validators.required]),

        chaLicenseNo: new FormControl('', [Validators.required, Validators.minLength(3)]),
        chaAddress: new FormControl('', [Validators.required, Validators.minLength(3)]),

        /*** for all usertype */
        userType: new FormControl(userType, [Validators.required]),
      });
    }
    else if (selectedType == 4) {
      /***
              * For Franchise =>
              * 2. PAN number
              * Title => Franchise Details
              * 3. Name of Franchise 
              * 4. city
              * 5. state
              * 6. address
              * 7. pincode
              * 8. pan doc 
              */

      this.userForm = this.FormBuilder.group({
        referredBy: new FormControl(this.refBy, [Validators.required]),

        /** name of Franchise */
        name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        address: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        city: new FormControl('', [Validators.required, Validators.minLength(3)]),
        state: new FormControl('DELHI', [Validators.required, Validators.minLength(3)]),
        pin: new FormControl('', [Validators.required, Validators.pattern(this.regexPIN)]),

        /** doc type => GSN , ADH, PSP, PAN, TAN */
        gstinType: new FormControl('PAN', [Validators.required]),
        pan: new FormControl('', [Validators.pattern(this.regexPAN)]),

        /*** for all usertype */
        userType: new FormControl(userType, [Validators.required]),

        /*** for user type = 2 only  */
        selectedType: new FormControl(selectedType, [Validators.required]),
      });
    }
    else if (selectedType == 11) {
      /***
           * For Logistics services =>
           * 2. PAN number
           * Title => Company Details
           * 3. Company name
           * 4. city
           * 5. state
           * 6. address
           * 7. pincode
           * 8. pan doc 
           */

      this.userForm = this.FormBuilder.group({
        referredBy: new FormControl(this.refBy, [Validators.required]),

        /** name of company */
        name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        address: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        city: new FormControl('', [Validators.required, Validators.minLength(3)]),
        state: new FormControl('DELHI', [Validators.required, Validators.minLength(3)]),
        pin: new FormControl('', [Validators.required, Validators.pattern(this.regexPIN)]),

        /** doc type => GSN , ADH, PSP, PAN, TAN */
        gstinType: new FormControl('PAN', [Validators.required]),
        pan: new FormControl('', [Validators.pattern(this.regexPAN)]),

        /*** for all usertype */
        userType: new FormControl(userType, [Validators.required]),

        /*** for user type = 2 only  */
        selectedType: new FormControl(selectedType, [Validators.required]),
      });
    }
    else if (selectedType == 12) {
      /***
             * For FPO =>
             * 2. PAN number
             * Title => FPO Agent details
             * 3. name of FPO Agent
             * 4. city
             * 5. state
             * 6. address
             * 7. pincode
             * 8. pan doc 
             * 9. gstin number (optional) 
             * 10. Adhar number
             */

      this.userForm = this.FormBuilder.group({
        referredBy: new FormControl(this.refBy, [Validators.required]),

        /** name of FPO */
        fpoName: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        address: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
        city: new FormControl('', [Validators.required, Validators.minLength(3)]),
        state: new FormControl('DELHI', [Validators.required, Validators.minLength(3)]),
        pin: new FormControl('', [Validators.required, Validators.pattern(this.regexPIN)]),

        /** doc type => GSN , ADH, PSP, PAN, TAN */
        gstinType: new FormControl('GSN', [Validators.required]),
        gstin: new FormControl('', [Validators.pattern(this.regexGST)]),

        adh: new FormControl('', [Validators.required, Validators.minLength(12),
        Validators.maxLength(12), Validators.pattern(this.regexNumber)]),

        pan: new FormControl('', [Validators.pattern(this.regexPAN)]),

        /*** for all usertype */
        userType: new FormControl(userType, [Validators.required]),

        /*** for user type = 2 only  */
        selectedType: new FormControl(selectedType, [Validators.required]),
      });
    }

  }

  get urlForm(): FormArray {
    return this.userForm.get('urlForm') as FormArray;
  };

  addURL(): void {
    this.urlForm.push(this.FormBuilder.group({ url: new FormControl('') }));
  }

  deleteURL(index: number) {
    const control = <FormArray>this.userForm.controls['urlForm'];
    control.removeAt(index);
  }

  // not using now
  userTypeChange(val) {

    if (val == 2) {
      this.detailsHeading = "CHA";
      this.serviceProviderForm(2, val);
    }
    else if (val == 12) {
      this.detailsHeading = "FPO Agent";
      this.serviceProviderForm(2, val);
    } else if (val == 4) {
      this.detailsHeading = "Franchise";
      this.serviceProviderForm(2, val);
    } else if (val == 11) {
      this.detailsHeading = "Company";
      this.serviceProviderForm(2, val);
    }

    // if (val == '12' && (this.form.controls.gstin.value == "" || this.form.controls.gstin.value == null)) {
    //     //   this.form.controls.gstin.setValidators([])
    //     //   this.form.controls.gstin.setErrors(null)
    // }
    // else {
    //     //   this.form.controls.gstin.setValidators([Validators.required,])
    // }

    // this.form.controls.ieCode.setValue('');
    // this.form.controls.chaLicenseNo.setValue('');
    // this.form.controls.pan.setValue('');
  }

  // for usertype change
  userChange(val) {
    // ("user type >> ", val);

    this.idProof = ""
    $('#doc').val(null)

    if (val == 1) {
      // exporter only
      this.detailsHeading = "Exporter";
      this.isexporterPlusDomestic = false;
      this.isDomesticOnly = false;
      this.createFormBasedOnUsertype(1);
    }
    // else if (val == 2) {
    //     this.detailsHeading = "User";
    //     this.isDomesticOnly = false;
    //     this.isexporterPlusDomestic = false;
    //     this.createFormBasedOnUsertype(2);
    // } 
    else if (val == 30) {
      // only domestic
      this.detailsHeading = "User";
      this.isDomesticOnly = true;
      this.createFormBasedOnUsertype(30);
    } else if (val == 40) {
      // exporter + domestic
      this.detailsHeading = "Exporter";
      this.createFormBasedOnUsertype(40);
      this.isexporterPlusDomestic = true;
    }
    this.thirdPartyObject = { verified: false };
    this.verfn = 0;

    this.changeFormValidation();
  }

  changeFormValidation() {
    // let { gstinType } = this.userForm.value;
    // if (gstinType == "ADH") {
    //     this.userForm.get('adh').setValidators(Validators.required);
    // }
    // else if (gstinType == "GSN") {
    //     this.userForm.get('gstin').setValidators(Validators.required);
    // }
    // else if (gstinType == "PSP") {
    //     this.userForm.get('psp').setValidators(Validators.required);
    // }
    // else if (gstinType == "PAN") {
    //     this.userForm.get('pan').setValidators(Validators.required);
    // }
    // else if (gstinType == "TAN") {
    //     this.userForm.get('tan').setValidators(Validators.required);
    // }

    this.resetFormValidationLikeBefor() // now this will work

    this.fileUploadReset()
  }



  checkGstin(e) {
    if (e.target.checked) {
      this.isGSTIN = true;
      this.userForm.patchValue({ gstin: '' });
      this.userForm.patchValue({ gstinType: 'ADH' });


      // ========================== update form(gstin) validation ==============
      // this.userForm.get('gstin').setValidators([Validators.minLength(4)]) // working
      this.userForm.get('gstin').setValidators([])
      this.userForm.get('gstin').updateValueAndValidity(); // this line is must to update validations completely 


    }
    else {

      this.digiLocekr(); // added new line 14april2023 (to handle domestci user cases)
      // ("called") // added new line 14april2023 (to handle domestci user cases)

      this.isGSTIN = false;
      this.userForm.patchValue({ gstinType: 'GSN' });

      // ========================== update form(gstin) validation ==============
      this.userForm.get('gstin').setValidators([Validators.required, Validators.pattern(this.regexGST)]) // working
      this.userForm.get('gstin').updateValueAndValidity(); // this line is must to update validations completely 

      this.resetFormValidationLikeBefor()

    }

    // ("gstin validation status IN this.userForm ", this.userForm['controls']['gstin'].status)

    // ("this.isGSTIN =  >>> ", this.isGSTIN);
    // localStorage.removeItem('attachment_poi');

    // localStorage.removeItem('attachment_poi');

    this.resetFormValidationLikeBefor() //this.changeFormValidation(); 
    this.fileUploadReset()
  }

  resetFormValidationLikeBefor() {
    // ('reset befor')
    let { gstinType, userType } = this.userForm.value;

    this.userForm.get('adh').setValidators([]);
    this.userForm.get('adh').updateValueAndValidity();

    this.userForm.get('pan').setValidators([]);
    this.userForm.get('pan').updateValueAndValidity();

    if (userType == 1 || userType == "1") {
      this.userForm.get('psp').setValidators([]);
      this.userForm.get('psp').updateValueAndValidity();

      this.userForm.get('tan').setValidators([]);
      this.userForm.get('tan').updateValueAndValidity();
    }


    if (gstinType == "ADH") {
      this.userForm.get('adh').setValidators(Validators.required);
      this.userForm.get('adh').updateValueAndValidity();
    }
    // else if (gstinType == "GSN") { already doing in checkGstin()
    //     this.userForm.get('gstin').setValidators(Validators.required);
    // }
    else if (gstinType == "PSP") {
      this.userForm.get('psp').setValidators(Validators.required);
    }
    else if (gstinType == "PAN") {
      this.userForm.get('pan').setValidators(Validators.required);
    }
    else if (gstinType == "TAN") {
      this.userForm.get('tan').setValidators(Validators.required);
    }

    this.fileUploadReset()

  }


  getreferrals() {
    this.authService.getreferrals().subscribe(response => {
      if (response.success == true) {
        this.refferals = response.data;
        // // (this.refferals);
        var d = this.refferals.filter(e => e.id == this.refBy.toLowerCase().trim());
        if (d.length == 0 && this.refBy != 'direct') {
          this.refBy = 'direct'
        }
        else {
          this.refBy = d[0].id;
        }
      }
      else {
        this.nfs.showInfo('Oops!', response.message, 'error');
      }
    })
  }

  clearVerify() {
    this.userForm.patchValue({
      ieCode: "",
      name: "",
      address: ``,
      city: "",
      state: "DELHI",
      pin: "",
      gstin: "",
    });

    this.thirdPartyObject = { verified: false };
    this.verfn = 0;
  }

  verify3rdparty() {
    this.loading = true;
    this.verfn = 0;
    let { userType, gstin, ieCode } = this.userForm.value;
    if (userType == 30) {
      let obj = { gstin };
      // let ob = {
      //   "response": {
      //     "natureOfBusinessActivity": ["Others", "Retail Business"],
      //     "centerJurisdiction": "RANGE-III",
      //     "constitutionOfBusiness": "Private Limited Company",
      //     "dateOfRegistration": "01/07/2017",
      //     "gstnStatus": "Active",
      //     "stateJurisdictionCode": "PB252",
      //     "dateOfCancellation": "",
      //     "principalPlaceOfBusinessFields": {
      //       "natureOfPrincipalPlaceOfBusiness": "Others, Retail Business",
      //       "principalPlaceOfBusinessAddress": {
      //         "buildingName": "",
      //         "pincode": "141012",
      //         "streetName": "KAGANWAL",
      //         "districtName": "Ludhiana",
      //         "stateName": "Punjab",
      //         "city": "",
      //         "lattitude": "",
      //         "floorNumber": "NEAR SATYAM TUBES",
      //         "buildingNumber": "INDL. AREA C",
      //         "location": "KAGANWAL",
      //         "longitude": ""
      //       }
      //     },
      //     "additionalPlaceOfBusinessFields": null,
      //     "lastUpdatedDate": "13/09/2022",
      //     "tradeName": "MARS EXIM PRIVATE LIMITED",
      //     "taxpayerType": "Regular",
      //     "gstIdentificationNumber": "03AADCM2823K1ZP",
      //     "legalNameOfBusiness": "MARS EXIM (P) LTD",
      //     "stateJurisdiction": "Ludhiana 4 - Ward No.61",
      //     "centerJurisdictionCode": "ZD0103", "eInvoiceStatus": "Yes"
      //   },
      //   "responseStatus": "SUCCESS",
      //   "success": true
      // }

      this.authService.verifyGST(obj).subscribe(res => {
        // ("verifyGST res >>> ", res);
        let { response, success } = res;
        if (response && success) {
          this.thirdPartyObject = { ...response };
          let { gstnStatus, principalPlaceOfBusinessFields, tradeName } = response;
          if (gstnStatus == "Active") {
            this.thirdPartyObject['verified'] = true;
            this.verfn = 1;
            let {
              floorNumber, buildingName, buildingNumber, streetName,
              pincode, districtName, stateName, city, location
            } = principalPlaceOfBusinessFields.principalPlaceOfBusinessAddress;

            let address = floorNumber ? (floorNumber + ", ") : '';
            address += buildingName ? (buildingName + ', ') : '';
            address += buildingNumber ? (buildingNumber + ', ') : '';
            address += streetName ? (streetName + 'Street, ') : '';
            address += districtName ? (districtName + ', ') : '';


            this.fillVerifiedDataGST({ tradeName, city, stateName, pincode, location, address, districtName });

          } else {
            this.thirdPartyObject['verified'] = false;
            this.verfn = 2;
          }
        } else {
          this.verfn = 2;
          this.thirdPartyObject = { verified: false };
        }

        this.loading = false;

        // ("verfn == ", this.verfn, this.thirdPartyObject['verified']);

      });
    } else {
      let obj = { iec: ieCode };
      // // ("verifyIec  obj >>> ", obj);
      this.authService.verifyIec(obj).subscribe(res => {
        // ("verifyIec res >>> ", res);
        let { response, success } = res;
        if (response && success) {
          let { iecNumber, error, iecStatus } = response;
          // // ("this.iecStatusArray.includes(Number(iecStatus)) >>> ", this.iecStatusArray.includes(Number(iecStatus)));
          // // ("error == null >>> ", error == null);
          if (this.iecStatusArray.includes(Number(iecStatus)) && error == null) {
            this.thirdPartyObject = { ...response, verified: true };
            this.verfn = 1;
            this.fillVerifiedDataIEC(response);
          } else {
            this.thirdPartyObject = { ...response, verified: false };
            this.verfn = 2;
          }
        } else {
          this.verfn = 2;
        }
        this.loading = false
      });
    }
    // Note: 0- NORMAL ,  1- BLACK LISTED ,  2- SUSPENDED ,  3- CANCELLED ,
    //   4- CLEAR FROM BLACK LIST ,  7- REVOKE SUSPENSION ,  8- REVOKE CANCELLATION ,  9- AMENDMENT
  }

  fillVerifiedDataGST(data) {
    let { tradeName, city, stateName, pincode, location, address, districtName } = data;
    // ("fill for called >>> ", { tradeName, city, stateName, pincode, location, address });
    this.userForm.patchValue({
      name: tradeName,
      address: address,
      city: city || districtName,
      state: stateName.toUpperCase(),
      pin: pincode
    });
  }

  fillVerifiedDataIEC(data) {
    let { entityName, address1, address2, state, city, pinCode } = data;
    // ("fill for called >>> ", entityName, address1, address2, state, city, pinCode);
    this.userForm.patchValue({
      name: entityName,
      address: `${address1} ${address2}`,
      city: city,
      state: state.toUpperCase(),
      pin: pinCode
    });
  }

  sendOtp() {
    this.verified = false;
    this.timeout = null;
    this.isOtp = false;
    this.isTimeout = false

    let { name, email, mobile } = this.form.value;
    let obj = { name, email, mobile, };

    if (!email.match(this.regexEmail)) {
      this.nfs.showInfo('Invalid Input!', 'Check Email', 'error');
    }
    else if (mobile.toString().length != 10) {
      this.nfs.showInfo('Invalid Input!', 'Check Mobile', 'error');
    }
    else if (name.toString().length < 3) {
      this.nfs.showInfo('Invalid Input!', 'Check Name', 'error');
    } else {
      this.authService.sendOtp(obj).subscribe(result => {
        if (result.success) {
          this.isOtp = true;
          this.timeout = 20000 //60000;
          this.timer();
          this.nfs.showConfirmation('<strong>OTP Sent!</strong>',
            'OTP Sent to ' + mobile + ' & ' + email + ' successfully<br>' +
            '<img src="https://pikmybox.com/assets/img/otp.png">',
            "info",
            'Okay!', true);
          // swal({
          //   title: '<strong>OTP Sent!</strong>',
          //   html: 'OTP Sent to ' + mobile + ' & ' + email + ' successfully<br>' +
          //     '<img src="https://pikmybox.com/assets/img/otp.png">',
          //   showCloseButton: false,
          //   showCancelButton: false,
          //   focusConfirm: false,
          //   confirmButtonText: 'Okay!',
          // });
        } else {
          this.nfs.showInfo('Oops !', result.message, 'error');
        }
      });
    }
  }

  timer() {
    if (this.timeout > 0) {
      setTimeout(() => {
        this.timeout = this.timeout - 1000;
        this.timer();
      }, 1000)
    }
    else if (this.timeout == 0) {
      this.isTimeout = true;
    }
  }

  verifyOtp() {
    this.verified = false;
    let { otp, mobile, email } = this.form.value;
    let obj = { otp, mobile, email };

    if (!otp) {
      this.nfs.showInfo('OOPS!', "OTP not provided.", 'error');
    } else {
      this.authService.verifyotp(obj).subscribe(result => {
        if (!result.success) {
          this.nfs.showInfo('OOPS!', result.message, 'error');
        }
        else {
          this.step = 1;
          this.verified = true;
          this.digiLocekr();
          this.nfs.showInfo('Success', result.message, 'success');
        }
      });
    }
  }

  registerUser(type, step) {
    if (step === 1) {
      let formValue = this.form.value;
      let { name, email, mobile, password } = formValue;
      let obj = { name, email, mobile, password, step: 1 }
      this.authService.newRegistration(obj).subscribe(res => {
        // ("ressss >>> ", res);
        if (res.success) {
          if (type == 'continue') {
            this.step = 2;
            this.userData = res.userData;
            // swal('Success!', 'Account Created Successfully. Please proceed to complete registration.', 'success');
          } else {
            // redirect to login page;
            // on login = isDocsUploaded = false means step 2 not completed;
            this.nfs.showInfo('Success!', 'Account Created Successfully.', 'success');
            this.router.navigate(['/login']);
          }
        }
      });
    } else {

      let { userType } = this.form.value;
      let obj = { step: 2, userId: this.userData._id };
      // let uType = userType;

      if (userType == 30) {
        this.isDomesticOnly = true;
        obj['isExporter'] = false;
        obj['isDomestic'] = true;
      }
      else if (userType == 40) {
        this.isexporterPlusDomestic = true;
        obj['isExporter'] = true;
        obj['isDomestic'] = true;
        // uType = 1;
      } else if (userType == 1) {
        obj['isExporter'] = true;
        obj['isDomestic'] = false;
      }

      obj['idProof'] = this.idProof;
      obj['file'] = this.docName;
      obj['thirdParty'] = this.thirdPartyObject;
      obj['isDocsUploaded'] = true;

      obj = { ...obj, ...this.userForm.value, };

      this.authService.newRegistration(obj).subscribe(res => {
        // ("ressss >>> ", res);
        if (res.success) {
          if (this.thirdPartyObject && this.thirdPartyObject.verified) {
            this.nfs.showInfo('Success!', 'Registration Successful!', 'success');
          } else {
            this.nfs.showInfo('Success!', 'Registration Successful! Please wait till we are verify your account.', 'success');
          }

          this.router.navigate(['/login']);
        } else {
          this.nfs.showInfo('Oops!', res.message, 'error');
        }
      });
    }
  }

  copy_route_fileChangeEvent(fileInput: any, type) {

    //    alert(this.userForm.value.userType)
    if ((this.userForm.value.userType == 1 || this.userForm.value.userType == 40) && !this.userForm.value.ieCode) {
      this.authService.showAlert("Please enter IE Code first")
    } else if (this.userForm.value.userType == 30 && (this.userForm.value.gstinType == 'GSN') && this.userForm.value.gstin == "") {
      this.authService.showAlert("Please enter gstin number first")
    }
    else if (this.userForm.value.userType == 30 && (this.userForm.value.gstinType == 'ADH' && this.userForm.value.adh == "")) {
      this.authService.showAlert("Please enter aadhar no. first")
    } else if (this.userForm.value.userType == 30 && (this.userForm.value.gstinType == 'PAN' && this.userForm.value.pan == "")) {
      this.authService.showAlert("Please enter pan no. first")
    } else {
      //let fileList: FileList = event.target.files;
      this.copy_route_filesToUpload = <Array<File>>fileInput.target.files;
      if (type == "idProof") {
        this.progressbar1 = true;
        this.progressbar = false;
        this.progresValue1 = 0
      }
      else {
        this.progressbar1 = false;
        this.progressbar = true;
        this.progresValue = 0
      }
      var size = this.copy_route_filesToUpload[0].size;
      size = size / 1024;
      if (this.copy_route_filesToUpload[0].type != 'application/pdf') {
        $('#doc').val(null)
        this.progressbar = false
        this.docName = ""
        this.isFileUploading = true
        this.nfs.showInfo('Oops', 'Only PDF document is allowed!', 'warning')
      }
      else if (size <= 1024) {
        this.readThis_route(fileInput.target, type);
      }
      else {
        $('#doc').val(null)
        this.progressbar = false
        this.docName = ""
        this.isFileUploading = true
        this.nfs.showInfo('Oops', 'File is too large, max 1MB file is permitted', 'warning')
      }
    }

  }

  readThis_route(inputValue: any, type): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      if (type == 'idProof') {
        document.getElementById('progress1').style.width = '50%'
        this.progresValue1 = 50
      }
      else {
        document.getElementById('progress').style.width = '50%'
        this.progresValue = 50
      }
      this.imageData_copy_route = myReader.result;
      //   // (this.imageData)
      this.uploadRouteCopy(type)
    }
    myReader.readAsDataURL(file);
  }

  uploadRouteCopy(type) {
    // ("doc type >> ", type);

    this.isFileUploading = true;
    var data = null;
    let { userType, gstinType, adh, psp, pan, tan, gstin, ieCode } = this.userForm.value;

    if (userType == 2) {
      data = {
        uploadedFile: this.imageData_copy_route,
        ieCode: Date.now(),
        docType: ''
      }
    }
    else {
      // if (userType == 3) {
      //     data = {
      //         uploadedFile: this.imageData_copy_route,
      //         ieCode: this.userForm.get('mobile').value,
      //         docType: ''
      //     }
      // }
      // else 
      if (userType == 30) {
        data = {
          uploadedFile: this.imageData_copy_route,
          docType: type
        }

        if (gstinType == 'GSN') {
          data.gstin = gstin;
        }
        else if (gstinType == 'ADH') {
          data.gstin = adh;
        }
        else if (gstinType == 'PAN') {
          data.gstin = pan;
        }
        else if (gstinType == 'PSP') {
          data.gstin = psp;
        }
        else if (gstinType == 'TAN') {
          data.gstin = tan;
        }
      }
      else {
        data = {
          uploadedFile: this.imageData_copy_route,
          ieCode: ieCode,
          docType: type
        }
      }
    }

    this.fileUpload = false
    this.authService.uploadPdf(data).subscribe(res => {
      if (res.success) {

        this.fileUpload = true

        if (data.docType == 'idProof') {
          this.idProof = res.uploadedFile;
          document.getElementById('progress1').style.width = '100%'
          this.progresValue1 = 100
        }
        else {
          this.docName = res.uploadedFile;
          document.getElementById('progress').style.width = '100%'
          this.progresValue = 100
        }
        this.isFileUploading = false;
      }
      else {
        this.isFileUploading = true;
        this.nfs.showInfo('Oops', 'File is too large, max 1MB file is permitted', 'warning')
      }
    })
  }

  fileUpload: boolean = false

  checkIec() {
    let { userType, ieCode, chaLicenseNo, pan, selectedType } = this.userForm.value;
    this.validUser = false;
    var iec = ieCode;
    var cha = chaLicenseNo;
    var a = '';

    if (userType == 1) {
      iec = iec.replace(/\s/g, '');
    }
    else if (userType == 2 && selectedType == 2) {
      cha = cha.replace(/\s/g, '');
    }
    else {
      pan = pan.replace(/\s/g, '');
    }

    if (iec && iec.length == 10 && (userType == 1 || userType == 40)) {
      this.authService.checkieCode(iec).subscribe(result => {
        if (result.success) {
          this.validUser = false
          this.nfs.showInfo('Oops!', result.message, 'error')
          this.userForm.get('ieCode').setValue("");
        }
        else {
          this.validUser = true
        }
      })
    }
    else if (userType == 2 && selectedType == 2) {
      if (cha.length >= 5) {
        this.authService.checkieCode(cha).subscribe(result => {
          if (result.success) {
            this.validUser = false
            this.nfs.showInfo('Oops!', result.message, 'error')
          }
          else {
            this.validUser = true
          }
        });
      }
    }
    else {
      if (pan && pan.length == 10) {
        this.authService.checkieCode(pan).subscribe(result => {
          if (result.success) {
            this.validUser = false
            this.nfs.showInfo('Oops!', result.message, 'error')
          }
          else {
            this.validUser = true
          }
        });
      }
    }
  }

  public removeWhiteSpace(e) {
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    return true;
  }

  tandoc() {
    this.t_c = !this.t_c;
    // (" this.t_c >> ", this.t_c);
  }

  ConfirmedValidator() {
    let { password, cpassword } = this.form.value;

    // // (password, cpassword);
    if (password && (password === cpassword)) {
      // // ("return true");
      return true;
    }
    // // ("return false");
    return false;
  }

  removejscssfile(filename, filetype) {
    var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
    var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
    var allsuspects = document.getElementsByTagName(targetelement)
    for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
      if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
        allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    }
  }

  onStateChange(val) {
    this.getAllStates('city', val)
  }

  stateArr = []
  cityArr = []
  getAllStates(type, state) {
    var obj = {}
    if (type == 'state') {
      obj = {}
      this.authService.stateAndCityList(obj).subscribe(result => {
        if (result.success) {
          this.stateArr = result.data
          this.cityArr = []
          // // (result.data)
        }
        else {
          this.stateArr = []
          this.cityArr = []
          // // (result.message)
        }
      });
    } else {
      obj = { "state": state }
      this.authService.stateAndCityList(obj).subscribe(result => {
        if (result.success) {
          this.cityArr = result.data
          // (result.data)
        }
        else {
          this.cityArr = []
          // (result.message)
        }
      });
    }

  }

  patchCity(city) {
    if (city) {
      for (let cc of this.cityArr) {
        if (cc.city.toLowerCase() == city.toLowerCase())
          this.userForm.patchValue({ city: cc.city, });
      }
    }
  }

  fileUploadReset() {
    if (this.isDomesticOnly) {
      this.progressbar = false
      this.fileUpload = false;
      $('#doc').val(null)
    }

  }

}