<main>
    <div class="container">
        <section class="my-5">
            <h2 class="h1-responsive font-weight-bold text-center my-5">Contact us</h2>
            <p class="text-center w-responsive mx-auto pb-5">Whether you'd like to know about ways to use our services,
                get pricing, or ask a question, we're here to help. Reach a customer service representative via phone or
                email.</p>

            <div class="row">
                <div class="col-lg-5 mb-lg-0 mb-4">
                    <div class="card" style="text-align:center">
                        <div class="col-md-12">
                            <a class="btn-floating warning-color waves-effect waves-light">
                                <i class="fa fa-map-marker"></i>
                            </a>
                            <p>246, Block A, Okhla Industrial Area Phase I, New Delhi, 110020</p>
                            <p class="mb-md-0"></p>
                        </div>

                        <div class="col-md-12">
                            <a class="btn-floating warning-color waves-effect waves-light">
                                <i class="fa fa-envelope"></i>
                            </a>
                            <p>sales@pikmybox.com</p>
                            <!-- <p class="mb-0">sales@pikmybox.com</p> -->
                        </div>

                        <div class="col-md-12">
                            <a class="btn-floating warning-color waves-effect waves-light">
                                <i class="fa fa-phone"></i>
                            </a>
                            <p>011 4082 4082 <br> 011 4117 9941
                                <br>Mon - Sat, 10:00-20:00
                            </p>
                            <!-- <p class="mb-md-0"></p> -->
                        </div>

                    </div>
                </div>

                <div class="col-lg-7">

                    <!-- Google map-->
                    <div id="map-container" class="rounded z-depth-1-half map-container"
                        style="height:346px; position: relative; overflow: hidden;">
                        <img src="../../assets/images/map.png" alt="">
                    </div>
                    <br>
                    <!-- Buttons-->

                </div>
                <!-- Grid column -->

            </div>
            <!-- Grid row -->

        </section>


    </div>

</main>