import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-thresholdlookup',
  templateUrl: './thresholdlookup.component.html',
  styleUrls: ['./thresholdlookup.component.css']
})
export class ThresholdlookupComponent implements OnInit {
  thresholdData: any = [];
  dropdownList: any = [];
  country: any = []
  hsnData: any = [];
  searchItem: any = '';
  group: any = '0';
  isSearch: boolean = false;
  filteredData: any = [];
  selectedItems: any = [];
  settings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'itemName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  previous: any = "";
  isLoading = false;
  constructor(public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
    this.getCountries();
    this.getCountriesthresholdData();
  }

  getCountries() {
    // this.dropdownList=[]
    this.authService.getCountriesthreshold().subscribe((res) => {
      if (res.success == true) {
        // // console.log("the data rec is:"+JSON.stringify(res));
        this.dropdownList = [];
        for (let index = 0; index < res.data.length; index++) {
          this.dropdownList.push({
            itemName: res.data[index],
            id: res.data[index]
          })
        }
      }
      else {
        this.nfs.showInfo('error', res.message, 'error');
      }
    })
  }

  getCountriesthresholdData() {
    this.thresholdData = []
    this.authService.getCountriesthresholdData({}).subscribe((res) => {
      if (res.success == true) {
        if (res.data.length != 0) {
          this.thresholdData = res.data;
        }
        else {
          this.nfs.showInfo('Opps !!', res.message, 'info');
        }
      }
      else {
        this.nfs.showInfo('error', res.message, 'error');
      }
    })
  }

  onItemSelect(Item: any) {
    //// console.log("the data passed in select is:"+JSON.stringify(Item))
    this.country = '';
    this.country = Item;
    //// console.log("the country data is:"+JSON.stringify(this.country));
    //// console.log("the data to be filtered is:"+JSON.stringify(this.thresholdData));
    this.filteredData = [];
    this.filteredData = this.thresholdData.filter(e => {
      return e.Country == this.country.itemName
    })
    //// console.log("the filtered data is:"+JSON.stringify(this.filteredData));

    this.isSearch = true;

  }

  reset() {
    this.getCountries();
    this.selectedItems = []
    this.getCountriesthresholdData();
    this.isSearch = false;
  }
}

