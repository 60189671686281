import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-scanparcel',
  templateUrl: './scanparcel.component.html',
  styleUrls: ['./scanparcel.component.css']
})
export class ScanparcelComponent implements OnInit {

  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  code: any;
  isScan: boolean = true;
  msg = "";
  isSuccess: boolean = false;
  notSuccess: boolean = false;


  constructor(public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
  }

  scanBarcode(v) {
    if (v == 'enter') {
      this.isScan = true;
      this.isSuccess = false;
      this.notSuccess = false;
    }
    else if (v == 'scan') {
      this.notSuccess = false;
      this.isSuccess = false;
      this.isScan = false;
    }
  }


  addFieldValue() {

    if (this.code.length > 5) {
      this.fieldArray.push(this.code);
      this.newAttribute = {};
      this.code = "";
    }
    else {
      this.nfs.showInfo('Oops !', 'Please entera valid Parcel Id', 'info');
    }
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  scanParcel() {
    if (this.code.length < 23) {
      this.nfs.showInfo('Oops !!', 'Please enter a valid Parcel Id ', 'info')
      this.code = "";
    }
    else {
      var obj = { boxIds: this.code };
      this.authService.scanParcel(obj).subscribe(response => {

        if (response.success == true) {
          // swal('success',response.message,'success');
          this.msg = response.message;
          this.isSuccess = true;
          this.code = "";
          // this.fieldArray=[];
        }
        else {
          // swal('Oops !!',response.message,'error');
          this.msg = response.message;
          this.notSuccess = true;
          this.code = "";
        }

      })

    }
  }

}
