<div class="my-container text-center">
    <div class="btn-hide">
        <a routerLink="/orders" class="btn btn-outline-info waves-effect pull-left"><i
                class="fa fa-chevron-left"></i>&nbsp;Back</a>
        <button (click)="sendDownloadRequest()" class="btn btn-outline-secondary waves-effect"><i
                class="fa fa-file-pdf-o"></i>&nbsp;Dwnload As PDF</button>
        <button (click)="print('details')" class="btn btn-outline-pink waves-effect pull-right"><i
                class="fa fa-print"></i>&nbsp;Print</button>
    </div>
</div>

<div class="my-container">
    <div id="details" onafterprint="myFunction()">
        <table class="lg">
            <tbody>
                <tr class="logo_heading">
                    <td class="logo_com">
                        <span style="font-size:20px; font-weight:600">POSTAL BILL FOR EXPORT OF DUTY FREE GOODS THROUGH
                            FOREIGN POST
                            OFFICE USING E- COMMERCE.
                        </span>
                        <br>
                        <span style="font-weight: 700; font-style: italic">(as per Circular no.36/2016 dated 29.07.2016)
                        </span>
                    </td>
                    <!-- <td class="logo_prod">
                <img src="https://www.boltseal.com/assets/images/logo.png">
            </td> -->
                </tr>
            </tbody>
        </table>
        <table class="details">
            <tbody>
                <tr class="pi_heading1">
                    <td colspan="7" rowspan="3">
                        <h4 style="margin: 0;">
                            AAA PRODUCTS PVT. LTD.
                        </h4>
                        <p>
                            9/27, Kalka Ji Extension, New Delhi, 110019
                            <br> State: Delhi
                            <br> CID: U33301DL1995PTC068388
                            <br> GSTIN: 07AABCA0439R1ZH
                        </p>
                    </td>

                    <td colspan="7">
                        <p>
                            <strong>Postal Bill Of Export No. & Time</strong>
                            <br>
                            <br> __________________ & _ _/_ _
                            <br>
                        </p>
                    </td>
                </tr>
                <tr class="pi_heading1">
                    <td colspan="7">
                        <p>
                            <strong>IEC No.: IEC12345678</strong>
                        </p>
                    </td>
                </tr>
                <tr class="pi_heading1">
                    <td colspan="7">
                        <p>
                            <strong>RBI Code: RBI1234567</strong>
                        </p>
                    </td>
                </tr>
                <tr class="pi_heading1">
                    <td colspan="7">
                        <h5>
                            CHA Private Limited
                        </h5>
                        <p>
                            K-20, Lajpat Nagar, New Delhi, 110024
                            <br> State: Delhi
                            <br> GSTIN: 07AACCE6149B1ZY
                        </p>
                    </td>
                    <td rowspan="2" colspan="7">
                        Restrictions under Foreign Trade Policy (if any):
                    </td>
                </tr>
                <tr class="pi_heading1">
                    <td colspan="7">


                        <h5>
                            Name of Foreign Post office.
                        </h5>
                        <p>
                            K-20, Lajpat Nagar, New Delhi, 110024
                            <br> State: Delhi
                            <br> GSTIN: 07AACCE6149B1ZY
                        </p>
                    </td>

                </tr>
                <tr class="pi_heading1">
                    <td colspan="14">
                        <h6>Declaration:</h6>
                        <span style="margin-left: 50px;">
                            (i) I/we hereby declare that the content of this Postal Shipping Bill is true and correct in
                            every
                            respect.
                        </span>
                        <br>
                        <span style="margin-left: 50px;">
                            (ii) I/we declare that we intend to claim rewards under Merchandise Exports from India
                            Scheme
                            (MIES).
                        </span>
                        <br>
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_________________________
                        <br>
                        <br>
                        Signature of the Exporter / Authorised agent
                    </td>
                </tr>
                <tr class="pi_heading1">
                    <td colspan="14">
                        <h6>Let Export Order:</h6>
                        <br>
                        <br>
                        <br>
                        <h6 style="text-align: right; width: 100%">
                            __________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </h6>
                        <br>
                        <h6 style="text-align: right; width: 100%">Signature of officer of Customs along with stamp and
                            date</h6>


                    </td>
                </tr>
            </tbody>

        </table>
        <p style="text-align:left">
            &nbsp; &nbsp; * Details of Parcels overleaf.
        </p>
    </div>
</div>