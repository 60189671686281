<div class="loading" [hidden]="loader">Loading&#8230;</div>



<section class="mt-5">

    <div class="container">
        <div class="row">

            <div class="col-md-4  offset-md-2 " style="margin-bottom:30px">
                <div class="list-group" id="list-tab" role="tablist">
                    <a class="list-group-item text-center list-group-item-action btn active" id="list-home-list"
                        data-toggle="list" style="color: #fff! important;
                    background-color: #ff086bd1;border-radius: 32px" href="#list-home" role="tab"
                        aria-controls="home">Bulk Upload Data Using Excel
                    </a>
                </div>
            </div>

            <div class="col-md-4 " style="margin-bottom:30px">
                <div class="list-group" id="list-tab" role="tablist">
                    <a (click)="redirecttorowdata()"
                        class="list-group-item text-center list-group-item-action btn active" id="list-home-list"
                        data-toggle="list" href="#list-home"
                        style="color: #ffffff! important; background-color: #fec02c!important; border-radius: 32px;"
                        role="tab" aria-controls="home">Raw Data Upload
                    </a>
                </div>
            </div>

        </div>
    </div>

    <div class="wrapper" style="margin-top:0px;" *ngIf="authService.isExporterAdmin()">
        <div class="offset-md-5 col-md-2">
            <select name="" id="" class="form-control" [(ngModel)]="carrier">
                <option value="" disabled>-- Select Carrier --</option>
                <option value="indiaPost">India Post</option>
                <!-- <option value="dhlEcom">DHL Ecom.</option> -->
            </select>
        </div>
    </div>

    <div class="wrapper" style="margin-top:0px;" *ngIf="(carrier!='')">
        <div *ngIf="fileSelected" style="display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap; ">
            <div class="col-md-12 text-center "
                *ngIf="(orderDataType=='ebay' ) || (orderDataType=='amazon' ) || (carrier !='indiaPost' ) ">
                <h4>
                    {{fileDetails.filename}}
                </h4>
            </div>
            <div class="col-md-3 ">

            </div>
            <div class="col-md-3 ">
                <button class="btn btn-outline-warning btn-block " (click)="uploadData() ">
                    <i class="fa fa-send "></i>&nbsp;Upload Data</button>
            </div>
            <div class="col-md-3 ">
                <button class="btn btn-outline-danger btn-block " (click)="cancel() ">
                    <i class="fa fa-times "></i>&nbsp;Cancel</button>
            </div>
        </div>

        <div style="display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap; " *ngIf="!fileSelected ">
            <div id="my_centered_buttons3 " class="col-md-4 " [ngClass]="{'offset-md-4':(carrier !='indiaPost')}"
                style="text-align: center;padding: 24px;margin:auto ">
                <h5>Bulk upload data</h5>
                <hr>

                <div class="row ">
                    <div class="form-group col-md-12 " *ngIf="(y.length<=0) && carrier=='indiaPost'">

                        <button (click)="uploadExcel('upload') " title="Upload Your Filled Excel File" for="upload"
                            data-placement="top " data-toggle="tooltip " class="btn btn-outline-blue-grey btn-block "
                            type="button ">
                            <i class="fa fa-upload "></i>&nbsp;Upload File
                        </button>

                        <input type="file" #fileInput id="upload" (change)="onChange($event, 'other')"
                            style="display:none;">

                    </div>
                    <div class="form-group col-md-12 " *ngIf="carrier!='indiaPost'">
                        <button (click)="uploadExcel('avatar')" *ngIf="(y.length<=0)"
                            title="Upload Your Amazon order excel File" data-placement="top" data-toggle="tooltip"
                            class="btn btn-outline-blue-grey btn-block" type="button">
                            <i class="fa fa-upload"></i>&nbsp;Upload File
                        </button>
                        <input type="file" #fileInput (click)="fileInput.value = null" value="" id="avatar"
                            (change)="onFileChange($event, 'dhlBulk')" style="display:none;">
                    </div>

                    <div class="form-group col-md-12" *ngIf="(y?.length<=0)">
                        <button class="btn btn-outline-warning btn-block" (click)="downloadExcel('2007')">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br> (Office Older versions)</button>
                    </div>
                    <div class="form-group col-md-12" *ngIf="(y?.length<=0)">
                        <button class="btn btn-outline-warning btn-block" (click)="downloadExcel('2013')">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br> (Office 2010 or Later)</button>
                    </div>
                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadInstructions('bulk')"
                            class="btn btn-outline-elegant btn-block">
                            <i class="fa fa-info-circle" *ngIf="!loading"></i>&nbsp;See Instructions
                        </button>
                    </div>
                </div>

            </div>
            <div id="my_centered_buttons1" class="col-md-4" style="text-align: center;padding: 24px;"
                *ngIf="!authService.isCha() && carrier =='indiaPost'">
                <h5>Bulk upload data for Amazon seller</h5>
                <hr>
                <form class="row">
                    <div class="form-group col-md-12">
                        <button (click)="uploadExcel('avatar')" *ngIf="(y.length<=0)"
                            title="Upload Your Amazon order excel File" data-placement="top" data-toggle="tooltip"
                            class="btn btn-outline-blue-grey btn-block" type="button">
                            <i class="fa fa-upload"></i>&nbsp;Upload File
                        </button>
                        <input type="file" #fileInput (click)="fileInput.value = null" value="" id="avatar"
                            (change)="onFileChange($event, 'amazon')" style="display:none;">
                    </div>
                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadExcel('amazon2007')"
                            class="btn btn-outline-warning btn-block">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br>(Office Older versions)
                        </button>
                    </div>
                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadExcel('amazon')"
                            class="btn btn-outline-warning btn-block">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br>(Office 2010 or Later)
                        </button>
                    </div>
                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadInstructions('amazon')"
                            class="btn btn-outline-elegant btn-block">
                            <i class="fa fa-info-circle" *ngIf="!loading"></i>&nbsp;See Instructions
                        </button>
                    </div>
                </form>
            </div>

            <div id="my_centered_buttons2" class="col-md-4" style="text-align: center;padding: 24px;"
                *ngIf="!authService.isCha() && carrier =='indiaPost'">
                <h5>Bulk upload data for Ebay seller</h5>
                <hr>
                <form class="row">
                    <div class="form-group col-md-12">
                        <button (click)="uploadExcel('uploadEbay')" *ngIf="y.length<=0"
                            title="Upload Your Ebay order excel File" data-placement="top" data-toggle="tooltip"
                            class="btn btn-outline-blue-grey btn-block" type="button">
                            <i class="fa fa-upload">
                                <!-- <img src='assets/images/ebay.png' /> -->
                            </i>&nbsp;Upload File
                        </button>
                        <input type="file" #fileInput (click)="fileInput.value = null" value="" id="uploadEbay"
                            (change)="onFileChange($event,'ebay')" style="display:none;">
                    </div>
                    <!-- <div class="form-group col-md-3">
                        <button type="button" class="btn btn-outline-danger btn-block" onClick="this.form.reset()" (click)="clearFile()">
                            <i class="fa fa-times" *ngIf="!loading"></i>
                        </button>
                    </div> -->
                    <!-- <div class="form-group col-md-2">
        <button type="submit" [disabled]="form.invalid || loading" class="btn btn-warning">
            <i class="fa fa-upload" *ngIf="!loading"></i>&nbsp;Upload
            <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
        </button>
    </div> -->

                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadExcel('ebay2007')"
                            class="btn btn-outline-warning btn-block">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br> (Office Older versions)
                        </button>
                    </div>

                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadExcel('ebay')" class="btn btn-outline-warning btn-block">
                            <i class="fa fa-download"></i>&nbsp;Download Sample File
                            <br> (Office 2010 or Later)
                        </button>
                    </div>


                    <div class="form-group col-md-12">
                        <button type="button" (click)="downloadInstructions('ebay')"
                            class="btn btn-outline-elegant btn-block">
                            <i class="fa fa-info-circle" *ngIf="!loading"></i>&nbsp;See Instructions
                        </button>
                    </div>
                    <!-- <button class="btn btn-outline-warning" *ngIf="(y.length> 0)" (click)="uploadData()">
        <i class="fa fa-send"></i>&nbsp;Upload Data</button>
    <button class="btn btn-outline-danger" *ngIf="(y.length>0)" (click)="cancel()">
        <i class="fa fa-times"></i>&nbsp;Cancel</button> -->
                </form>
            </div>


        </div>
    </div>
</section>



<!-- </div> -->
<div class="checkout">

    <!-- 
<vg-player>
    <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
        <source src="http://13.235.86.116/assets/docs/pmb.mpeg" type="video/mp4">
    </video>
</vg-player> -->
    <!-- <video width="400" controls>
    <source src="http://13.235.86.116/assets/docs/pmb.mpeg" type="video/mp4">
  </video> -->
</div>

<div class="checkout">
    <div class="container-fluid">
        <div class="checkout-right" *ngIf="(y?.length>0)">
            <h5 class="text-center">Data Preview </h5>
            <div class="table-responsive">
                <table id="mainTable" class="table table-bordered m-b-0 text-center">
                    <thead>
                        <tr>
                            <th>Row. No.</th>
                            <th *ngIf="authService.isCha()" class="asterisk">I.E.C.</th>
                            <th class="asterisk">PBE Type</th>
                            <th class="asterisk">Description</th>
                            <th class="asterisk">Quantity</th>
                            <th class="asterisk">Unit</th>
                            <th class="asterisk">Weight Per Good</th>
                            <!-- <th class="asterisk">Net Weight</th> -->
                            <th class="asterisk">Gross Weight</th>
                            <th class="asterisk">Invoice No.</th>
                            <th class="asterisk">Invoice Date</th>
                            <th class="asterisk">Consignee Name</th>
                            <th class="asterisk">Consignee Address</th>
                            <th class="asterisk">Consignee City</th>
                            <th>Consignee State</th>
                            <th class="asterisk">Consignee Zipcode</th>
                            <th class="asterisk">Consignee Country</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let s of y; let i =index">

                            <td>
                                {{i+5}}
                            </td>
                            <td *ngIf="authService.isCha()" [ngClass]="{red_col:s.invalidPort}">
                                {{s.ieCode}}
                            </td>
                            <td [ngClass]="{red_col:s.invalidPort}">
                                {{s.pbe}}
                            </td>
                            <td [ngClass]="{red_col:s.invalidSeal}">
                                {{s.description}}
                            </td>
                            <td [ngClass]="{red_col:s.invalidFinalPort}">
                                {{s['quantity_number']}}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidContainer}">
                                {{s['quantity_unit']}}
                            </td>
                            <td [ngClass]="{red_col:s.invalidTruck}">
                                {{s['weight']}}
                            </td>

                            <td [ngClass]="{red_col:s.invalidTruck}">
                                {{s['grossWeight']}}
                            </td>
                            <td [ngClass]="{red_col:s.invalidTruck}">
                                {{s['invoiceNo']}}
                            </td>
                            <td>
                                <!-- {{s['invoiceDate']}} -->
                                {{ s['invoiceDate'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                {{ s['consigneeDetails_name'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                {{ s['consigneeDetails_address'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                {{ s['consigneeDetails_city'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                {{ s['consigneeDetails_state'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                {{ s['consigneeDetails_zipCode'] }}
                            </td>
                            <td [ngClass]="{red_col:s.isvalidTime}">
                                <br> {{ s['consigneeDetails_country'] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>





</div>