<div class="loading" *ngIf="loading">Loading&#8230;</div>
<div class="container">
    <div class="row" style="margin-top:2%">
        <div class="col-md-4" style="margin:auto;box-shadow:0px 0px 5px 2px #eee;text-align: center;padding:10px">
            <div class="form-group">
                <select name="" id="" class="form-control" [(ngModel)]="serviceProvider">
                    <option disabled value="">Select Service Provider</option>
                    <option value="Indiapost">Indiapost</option>
                    <option value="DHLeCom">DHLeCom</option>
                    <!-- <option value="DHLeCom">DHLeCom</option>   -->
                </select>
            </div>
            <div style="width:100%;background: #eee;margin-bottom:5%;padding:15px">
                <img src="assets/img/indiapost.png" *ngIf="serviceProvider=='Indiapost'" style="width:70%">
                <img src="assets/img/logo_dhl.png" *ngIf="serviceProvider!='Indiapost'" style="width:70%">
            </div>
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="trackingId"
                    [placeholder]="((serviceProvider=='Indiapost')?'Enter tracking number':'Enter tracking ID or parcel ID')">
            </div>
            <div class="form-group" style="display: inline-block;">
                <re-captcha site_key="6LfXgVwUAAAAAD3NZEjAFHpOgGfKq97jOGlMv98K"
                    (captchaResponse)="handleCorrectCaptcha($event)" [(ngModel)]="capcha">
                </re-captcha>
            </div>

            <button class="btn btn-sm btn-danger"
                [disabled]="(captchaResponse?.length < 20) || (trackingId?.length < 5)"
                (click)="track()">Submit</button>
            <button class="btn btn-sm btn-info" (click)="reset()">Reset</button>
        </div>

        <!-- <a href="https://web.whatsapp.com/send?text=www.google.com" data-action="share/whatsapp/share">Share via Whatsapp web</a> -->

        <div *ngIf="serviceProvider=='Indiapost' && trackData" class="col-md-9" [innerHtml]="trackData"
            style="margin:auto;margin-top:2%">
        </div>
        <div *ngIf="serviceProvider!='Indiapost' && trackingData" class="col-md-9 card p-3"
            style="margin:auto;margin-top:2%">
            <h6 style="font-weight: 600" class="text-center mb-2">
                Tracking ID: {{trackingData.trackingID}}
            </h6>
            <div class="tracking-list">
                <div class="tracking-item" *ngFor="let e of trackingData.events; let i=index">
                    <div class="tracking-icon status-intransit" [ngClass]="{'currentLocation': (i==0)}">
                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas"
                            data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                            data-fa-i2svg="">
                            <path fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                        </svg>
                    </div>
                    <div class="tracking-date">{{e.dateTime | date:'dd MMM yyyy'}}<span>{{e.dateTime | slice:-8}}
                            {{e.timezone}}</span></div>
                    <div class="tracking-content">{{e.description}}<span>{{e.address.city?e.address.city:''}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>