import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

import { NewhomeComponent } from './components/newhome/newhome.component';
import { AfterloginhomeComponent } from './components/afterloginhome/afterloginhome.component';
import { ShipAbroadUserComponent } from './components/ship-abroad-user/ship-abroad-user.component';
import { ProofOfExportComponent } from './components/proof-of-export/proof-of-export.component';
import { ExportProofComponent } from './components/export-proof/export-proof.component';
import { PickupsubscriptionComponent } from './components/pickupsubscription/pickupsubscription.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ApiComponent } from './components/api/api.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MeisComponent } from './components/meis/meis.component';
import { ExclComponent } from './components/excl/excl.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ThresholdlookupComponent } from './components/thresholdlookup/thresholdlookup.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AdminloginComponent } from './components/adminlogin/adminlogin.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { PostalbillComponent } from './components/postalbill/postalbill.component';
import { CustomloginComponent } from './components/customlogin/customlogin.component';
import { RatecalculatorComponent } from './components/ratecalculator/ratecalculator.component';
import { BillingComponent } from './components/billing/billing.component';
import { GenerateinvoiceComponent } from './components/generateinvoice/generateinvoice.component';
import { ContactComponent } from './components/contact/contact.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { GstinComponent } from './components/gstin/gstin.component';
import { HsnlookupComponent } from './components/hsnlookup/hsnlookup.component';
import { GeneratemeisComponent } from './components/generatemeis/generatemeis.component';
import { DgftComponent } from './components/dgft/dgft.component';
import { ScanparcelComponent } from './components/scanparcel/scanparcel.component';
import { UpdatesComponent } from './components/updates/updates.component';
import { TestComponent } from './components/test/test.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { BlogComponent } from './components/blog/blog.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { HelpdeskComponent } from './components/helpdesk/helpdesk.component';
import { ServiceproviderComponent } from './components/serviceprovider/serviceprovider.component';
import { TrackmyparcelComponent } from './components/trackmyparcel/trackmyparcel.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import(`./components/land/land.module`).then(m => m.LandModule),
  // },
  {
    path: '',
    component: NewhomeComponent
  },
  {
    path: 'home/:token',
    component: AfterloginhomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: AfterloginhomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'shipAbroad',
    component: ShipAbroadUserComponent
  },
  {
    path: 'proofOfExport/:id',
    component: ProofOfExportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exportProof/:page',
    component: ExportProofComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'export-documents',
    loadChildren: () => import(`./components/landingpage/landingpage.module`).then(m => m.LandingpageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pickupsubscription',
    component: PickupsubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login/:logout',  // when logout from domestic, need to do logout from exporter also
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'register/:id',
    component: RegisterComponent,
  },
  {
    path: 'api',
    component: ApiComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import(`./components/users/users.module`).then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'meis',
    component: MeisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulkupload',
    component: ExclComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    component: PreviewComponent
  },
  {
    path: 'bill/:id',
    loadChildren: () => import(`./components/bill/bill.module`).then(m => m.BillModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox',
    loadChildren: () => import(`./components/inbox/inbox.module`).then(m => m.InboxModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pickup/:page',
    loadChildren: () => import(`./components/pickup/pickup.module`).then(m => m.PickupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-billing/:page',
    loadChildren: () => import(`./components/manage-billing/manage-billing.module`).then(m => m.ManageBillingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aramex-billing/:page',
    loadChildren: () => import(`./components/aramex-billing/aramex-billing.module`).then(m => m.AramexBillingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myaccount',
    loadChildren: () => import(`./components/myaccount/myaccount.module`).then(m => m.MyaccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dhl_form/:id',
    loadChildren: () => import(`./components/dhl-form/dhl-form.module`).then(m => m.DhlFormModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dutyfreeimportallowance',
    component: ThresholdlookupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/appsettings/appsettings.module').then(m => m.AppsettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'superuser',
    component: AdminloginComponent,
  },
  {
    path: 'reset-password/:str',
    component: ResetpasswordComponent,

  },
  {
    path: 'pboe',
    component: PostalbillComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'termsofuse',
    loadChildren: () => import('./components/termsofuse/termsofuse.module').then(m => m.TermsofuseModule),
  },
  {
    path: 'officers/:id',
    component: CustomloginComponent,

  },
  {
    path: 'dgft/:id',
    component: CustomloginComponent,
  },
  {
    path: 'all_invoices',
    loadChildren: () => import('./components/allinvoices/allinvoices.module').then(m => m.AllinvoicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pbe',
    loadChildren: () => import('./components/pbe/pbe.module').then(m => m.PbeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'csb',
    loadChildren: () => import('./components/csb/csb.module').then(m => m.CsbModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-agents',
    loadChildren: () => import('./components/manage-agents/manage-agents.module').then(m => m.ManageAgentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'adreg',
    loadChildren: () => import('./components/ad-reg/ad-reg.module').then(m => m.AdRegModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aramex/:id',
    loadChildren: () => import('./components/aramex/aramex.module').then(m => m.AramexModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fedex/:id',
    loadChildren: () => import('./components/fedex/fedex.module').then(m => m.FedexModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fedexparcels',
    loadChildren: () => import('./components/fedexparcels/fedexparcels.module').then(m => m.FedexparcelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dhlexpressparcels',
    loadChildren: () => import('./components/dhlexpressparcels/dhlexpressparcels.module').then(m => m.DhlexpressparcelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dhlexpress/:id',
    loadChildren: () => import('./components/dhlexpress/dhlexpress.module').then(m => m.DhlexpressModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ratecalculator/:details',
    component: RatecalculatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billing/:page',
    component: BillingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'boxes/:type',
    loadChildren: () => import('./components/boxes/boxes.module').then(m => m.BoxesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aramexparcels',
    loadChildren: () => import('./components/aramexparcels/aramexparcels.module').then(m => m.AramexparcelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'generateinvoice/:id',
    component: GenerateinvoiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gstr',
    component: GstinComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hsnlookup',
    component: HsnlookupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'generatemeis',
    component: GeneratemeisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dgft',
    component: DgftComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scanparcel',
    component: ScanparcelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'updates',
    component: UpdatesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    component: TestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice',
    loadChildren: () => import('./components/invoice/invoice.module').then(m => m.InvoiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./components/analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'viewtickets',
    component: TicketsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'newexportinvoice/:type/:id',
    loadChildren: () => import('./components/newexporterinvoice/newexporterinvoice.module').then(m => m.NewexporterinvoiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exportinvoice/:type/:id',
    loadChildren: () => import('./components/exportinvoice/exportinvoice.module').then(m => m.ExportinvoiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pbetemplate',
    loadChildren: () => import('./components/pbetemplate/pbetemplate.module').then(m => m.PbetemplateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exporterdashboard',
    loadChildren: () => import('./components/exporterdashboard/exporterdashboard.module').then(m => m.ExporterdashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent,
  },
  {
    path: 'helpdesk/:id',
    component: HelpdeskComponent
  },
  {
    path: 'serviceprovider',
    component: ServiceproviderComponent
  },
  {
    path: 'trackmyparcel/:id',
    component: TrackmyparcelComponent
  },
  {
    path: 'initiatelist',
    loadChildren: () => import('./components/initiatelist/initiatelist.module').then(m => m.InitiatelistModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./components/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'domesticshipments',
    loadChildren: () => import('./components/domesticshipments/domesticshipments.module').then(m => m.DomesticshipmentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rawdata',
    loadChildren: () => import('./components/ecommerce-rowdata/ecommerce-rowdata.module').then(m => m.EcommerceRowdataModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'domesticrateupload',
    loadChildren: () => import('./components/domesticrateupload/domesticrateupload.module').then(m => m.DomesticrateuploadModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hsnClassifier',
    loadChildren: () => import('./components/hsn-classifier/hsn-classifier.module').then(m => m.HsnClassifierModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'xpressbees',
    loadChildren: () => import('./components/xpressbees/xpressbees.module').then(m => m.XpressbeesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'xpressbeesparcels',
    loadChildren: () => import('./components/xpressbeesparcels/xpressbeesparcels.module').then(m => m.XpressbeesparcelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trackparcels',
    loadChildren: () => import('./components/common-tracking/common-tracking.module').then(m => m.CommonTrackingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delhivery',
    loadChildren: () => import('./components/delhivery/delhivery.module').then(m => m.DelhiveryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delhiveryparcels',
    loadChildren: () => import('./components/delhivery-parcels/delhivery-parcels.module').then(m => m.DelhiveryParcelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'video-resources',
    loadChildren: () => import('./components/video-resources/video-resources.module').then(m => m.VideoResourcesModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./components/sales-report/sales-report.module').then(m => m.SalesReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const RoutingComponents = [
  NewhomeComponent,
  AfterloginhomeComponent,
  ShipAbroadUserComponent,
  ProofOfExportComponent,
  ExportProofComponent,
  PickupsubscriptionComponent,
  LoginComponent,
  RegisterComponent,
  ApiComponent,
  DashboardComponent,
  MeisComponent,
  ExclComponent,
  PreviewComponent,
  ThresholdlookupComponent,
  OrdersComponent,
  AdminloginComponent,
  ResetpasswordComponent,
  PostalbillComponent,
  CustomloginComponent,
  RatecalculatorComponent,
  BillingComponent,
  GenerateinvoiceComponent,
  ContactComponent,
  ResourcesComponent,
  GstinComponent,
  HsnlookupComponent,
  GeneratemeisComponent,
  DgftComponent,
  ScanparcelComponent,
  UpdatesComponent,
  TestComponent,
  TicketsComponent,
  BlogComponent,
  PrivacypolicyComponent,
  HelpdeskComponent,
  ServiceproviderComponent,
  TrackmyparcelComponent
]
