<div class="loading" *ngIf="loading">Loading&#8230;</div>
<div class="wrapper">
    <div class="container-fluid">
        <div class="card p-4 mb-3">

            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-8 text-center">
                                    <label *ngIf="!isPreview" for="dhlInvoice" class="form-control mt-2" style="
        font-size: .74rem;
        font-weight: 700;">
                                        <span *ngIf="invoiceBase64?.length<500">
                                            Upload Invoice received from DHL
                                        </span>
                                        <span *ngIf="invoiceBase64?.length>500 && invoiceFileName?.length<=36">
                                            {{invoiceFileName}}
                                        </span>
                                        <span *ngIf="invoiceBase64?.length>500 && invoiceFileName?.length>36">
                                            {{invoiceFileName| slice:0:36}}
                                        </span>
                                    </label>
                                    <label *ngIf="isPreview" class="form-control mt-2" style="
        font-size: .74rem;
        font-weight: 700;">
                                        <span *ngIf="invoiceBase64?.length>500 && invoiceFileName?.length<=36">
                                            {{invoiceFileName}}
                                        </span>
                                        <span *ngIf="invoiceBase64?.length>500 && invoiceFileName?.length>36">
                                            {{invoiceFileName| slice:0:36}}
                                        </span>
                                    </label>
                                    <input style="display: none" id="dhlInvoice" accept="application/vnd.ms-excel"
                                        type="file" (change)="readFile($event, 'inv')" class="form-control">
                                    <!-- <i (click)="updateForm()" class="fa fa-send" style="
    position: absolute;
    right: 38px;
    top: 122px;
    color: #0dcb5a;
    cursor: pointer;
    "></i> -->
                                </div>
                                <div class="col-md-4 text-center" *ngIf="!isPreview">
                                    <button (click)="uploadDhlInvoice('preview')"
                                        [disabled]="invoiceBase64?.length<500 || invoiceFileName?.length<5"
                                        class="btn btn-outline-success btn-sm mt-2 btn-block">Preview
                                    </button>
                                </div>
                                <div class="col-md-2 text-center" *ngIf="isPreview">
                                    <button (click)="uploadDhlInvoice('upload')"
                                        [disabled]="invoiceBase64?.length<500 || invoiceFileName?.length<5"
                                        class="btn btn-outline-info btn-sm mt-2 btn-block">
                                        <i class="fa fa-upload"></i>
                                    </button>
                                </div>
                                <div class="col-md-2 text-center" *ngIf="isPreview">

                                    <button (click)="resetAll()"
                                        [disabled]="invoiceBase64?.length<500 || invoiceFileName?.length<5"
                                        class="btn btn-outline-danger btn-sm mt-2 btn-block">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>


                        </div>
                        <div class="col-md-7" style="border-left: 2px solid #727376;">
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <input type="text" placeholder="Enter invoice No." class="form-control"
                                        [(ngModel)]="invoiceNo" style="
      font-size: .74rem;
      font-weight: 700;">
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-outline-dark btn-sm btn-block"
                                        (click)="updateBills('paid')"><i class="fa fa-bookmark"></i>&nbsp; Mark as
                                        paid</button>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-outline-warning btn-sm btn-block"
                                        (click)="genVatInvoice('','paid')"><i class="fa fa-bookmark"></i>&nbsp; Mark VAT
                                        Inv. as paid</button>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-outline-primary btn-sm btn-block"
                                        (click)="updateBills('generate')"><i class="fa fa-tag"></i>&nbsp;Gen.
                                        Invoices</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="card p-4" *ngIf="dhlInv?.length>0 && isPreview">
            <div class="row">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Handover ID</th>
                            <th>N.O.E</th>
                            <th>Pickup Date</th>
                            <th>Weight</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let in of dhlInv; let i = index">
                            <td>{{i +1}}</td>
                            <td>{{in.handoverID}} - {{in.boxId | slice:-6}}</td>
                            <td>{{in.exporterName}}</td>
                            <td>{{in.pickupDate}}</td>
                            <td>{{in.chargeableWeight}}</td>
                            <td>{{in.netAmount | currency:'INR':'code':'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
    <div class="container-fluid" *ngIf="!isPreview">
        <div class="card p-2">
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-5">
                            <nav style="float: left;" *ngIf="invoices?.length>0">
                                <ul class="pagination pg-amber justify-content-center" style="margin-top: 4px"
                                    *ngIf="pager.pages && pager.pages.length">
                                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                        <a class="page-link waves-effect" (click)="setPage(1)">First</a>
                                    </li>
                                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                        <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
                                    </li>
                                    <li class="page-item" *ngFor="let page of pager.pages"
                                        [ngClass]="{active:pager.currentPage === page, disabled:pager.currentPage === page}">
                                        <a class="page-link" (click)="setPage(page)">{{page}}</a>
                                    </li>
                                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                                    </li>
                                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-md-2 text-center mt-2">
                            DHL Balance <span class="text-warning" style="font-weight: 600">Rs.{{dhlBalance}}</span>
                        </div>
                        <div class="col-md-5 text-right">
                            Total <span class="text-warning" style="font-weight: 600">{{invoiceCount}}</span> Record(s).
                            <button (click)="filter='';setPage(1)" *ngIf="filter!=''"
                                class="btn btn-outline-warning btn-sm mt-1">All Invoices </button>
                            <button (click)="filter='vat';setPage(1)" *ngIf="filter==''"
                                class="btn btn-outline-primary btn-sm mt-1">VAT Invoices </button>
                            <button (click)="uploadDhlInvoice('download')" *ngIf="filter==''"
                                [disabled]="invoiceCount==0" class="btn btn-outline-indigo btn-sm mt-1">Download Excel
                            </button>
                        </div>
                    </div>

                    <table class="table table-bordered table-striped" *ngIf="invoices?.length>0">
                        <thead>
                            <tr>
                                <td>Sr. No.</td>
                                <td>DHL Invoice No.</td>
                                <td>Exporter Name</td>
                                <td>Ref. ID</td>
                                <td>Decl. Weight</td>
                                <td>Weight Diff.</td>
                                <td>Amount Deb.</td>
                                <td>Invoice Amount</td>
                                <td>Receipt</td>
                                <td>PMB Invoice No.</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let inv of invoices; let j = index">
                                <td>{{j+1}}</td>
                                <td>{{inv.invoiceNumber}}</td>
                                <td>{{inv.exporterName}}</td>
                                <td>{{inv.handoverID}} - {{inv.shipmentID | slice:-6}}</td>
                                <td>{{inv.weight/1000}}</td>
                                <td>{{inv.weightDiff / 1000}}</td>
                                <td>{{inv.amountDebited| currency:'INR':'code':'1.2-2'}}</td>
                                <td>{{inv.invoiceAmount| currency:'INR':'code':'1.2-2'}}</td>
                                <td>{{inv.arrear/1.18| currency:'INR':'code':'1.2-2'}}</td>
                                <td>
                                    <span *ngIf="(inv.pmbInvoiceNo?.length>8)">
                                        {{inv.pmbInvoiceNo}} <i class="fa fa-file-pdf-o text-danger"
                                            style="font-size: 15px; cursor: pointer;"
                                            (click)="genTaxtInv(inv.pmbInvoiceNo)"></i>
                                        <br> {{inv.pmbInvoiceDate | date:'dd/MM/yyyy'}}
                                    </span>
                                    <span
                                        *ngIf="((!inv.pmbInvoiceNo || inv.pmbInvoiceNo?.length<8) && inv.status=='created' && inv.vat )">
                                        <input type="text" id="{{inv.shipmentID}}" placeholder="Enter VAT with GST"
                                            (keypress)="restrictNumeric($event)">
                                        <button class="btn btn-sm btn-amber"
                                            (click)="genVatInvoice(inv,'generate')">Enter VAT</button>
                                    </span>
                                </td>
                                <td>
                                    <span class="text-danger" *ngIf="(inv.status=='created')">Pending</span>
                                    <span class="text-info" *ngIf="(inv.status=='Generated')">Generated</span>
                                    <span class="text-success" *ngIf="(inv.status=='Paid')">Paid</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>