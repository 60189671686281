import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DateRangePickerService {

  private date: BehaviorSubject<any>;

  constructor() {
    this.date = new BehaviorSubject('');
  }

  GetDate(): Observable<any> {
    return this.date as Observable<any>;
  }

  initializeDateRangePicker() {
    // $(function () {
    //   $('input[name="daterange"]').daterangepicker({}, function (start, end, label) {
    //     // console.log("A new date selection was made: " + start.format('DD-MM-YYYY') + ' to ' + end.format('DD-MM-YYYY'));
    //   });
    // });
  }

  initializeDatePicker() {
    // $('input[name="datePicker"]').daterangepicker({
    //   singleDatePicker: true,
    //   showDropdowns: true,
    //   // startDate: '01-01-2001',
    //   startDate: new Date(),
    //   customClass: 'form-control',
    //   locale: {
    //     format: 'DD-MM-YYYY'
    //   }
    //   // minYear: 1901,
    //   // maxYear: parseInt(moment().format('YYYY'), 10)
    // }, function (start, end, label) { });
  }

  onDateChange() {
    // let _this = this;
    // $('input[name="datePicker"]').on('apply.daterangepicker', function (ev, picker) {
    //   let value = picker.startDate.format('YYYY-MM-DD');

    //   _this.date.next({ type: "set", id: ev.target.id, value: value });
    // });

    // $('input[name="datePicker"]').on('cancel.daterangepicker', function (ev, picker) {
    //   $('#' + ev.target.id).val('');
    //   _this.date.next({ type: "reset", id: ev.target.id, value: '' });
    // });
  }

  onDateRangeChange() {
    // $('input[name="dateRangePicker"]').on('apply.daterangepicker', function (ev, picker) {
    //   // // console.log(picker.startDate.format('DD/MM/YYYY HH:mm'));
    //   // // console.log(picker.endDate.format('DD/MM/YYYY HH:mm'));
    //   let id = ev.target.id;
    //   let value = picker.startDate.format('DD/MM/YYYY');
    //   // startdate = picker.startDate.format('DD/MM/YYYY HH:mm');
    //   // enddate = picker.endDate.format('DD/MM/YYYY HH:mm');
    // });

    // $('input[name="dateRangePicker"]').on('cancel.daterangepicker', function (ev, picker) {
    //   $('#' + ev.target.id).val('');
    //   let id = ev.target.id;
    // });
  }

  changeFormat(str) {
    // // console.log("str >> ", str);
    let [d, m, y] = str.split('-');
    return `${y}-${m}-${d}`;
  }

  changeYMDtoDMY(dd) {
    if (dd)
      return dd.split("T")[0].split('-').reverse().join('-');
    return '';
  }

  GetFormatedDate(dateString): any {
    if (dateString) {
      dateString = this.changeFormat(dateString);

      // Create a Date object from the date string
      let date = new Date(new Date(dateString).toString());

      // // console.log("date >> ", date);

      // Extract year, month, and day from the Date object
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // Adding 1 to get the month (1-12)
      let day = date.getDate();

      // // console.log("date >> ", date);
      // // console.log("date.getMonth() >> ", date.getMonth());


      // Create the JSON object
      let jsonObject = {
        "date": {
          "year": year,
          "month": month,
          "day": day
        },
        "jsdate": date.toISOString(),
        "formatted": `${day}-${month}-${year}`,
        "epoc": Math.floor(date.getTime() / 1000)
      };

      return jsonObject;
    }

    return '';
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getDateKeyValueObj(str) {
    try {
      let [y, m, d] = str.split('-');
      let date = { "year": y, "month": m, "day": d };
      return date;
    } catch (error) {
      return '';
    }
  }

}
