<div class="wrapper" style="background-image: url('assets/img/bg1.jpg'); margin-top: -2rem">

    <div class=" mask flex-center rgba-black-strong">

        <div class="container" style="margin-top:120px;height: 550px;">
            <div class="row">
                <div class="col-md-4" *ngIf="authService.isAdmin()">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-primary waves-effect"
                            (click)="goto('/exporterdashboard')">
                            <!-- Title -->
                            <img src="assets/img/graph.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">STATS</h5>
                            <!-- Text -->
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4" *ngIf="!authService.isAdmin()">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-deep-purple waves-effect" (click)="goto('/pbe')"
                            style="padding: 70px 0px 51px 0px;">
                            <!-- Title -->
                            <!-- <i class="fa fa-ticket" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/3.png" alt="" srcset="" style="height:60px">
                            <br>
                            <br>
                            <br>
                            <h5 class="card-title text-center">Exports Through India Post</h5>
                            <!-- Text -->
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-info waves-effect" (click)="goto('/gstr')">
                            <!-- Title -->
                            <!-- <i class="fa fa-sitemap" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/2.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>GST Return For Exports</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4" *ngIf="!authService.isPermitted('isFpoAgent')">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card image -->
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-warning  waves-effect" (click)="goto('/meis')">
                            <!-- Title -->
                            <!-- <i class="fa fa-users" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/1.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>Meis Application</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>


                <div class="col-md-4" *ngIf="authService.isPermitted('isFpoAgent')">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card image -->
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-warning  waves-effect"
                            (click)="goto('/scanparcel')">
                            <!-- Title -->
                            <!-- <i class="fa fa-users" style="font-size: 33px;"></i> -->
                            <img src="assets/img/scanner.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>SCAN PARCEL</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>

            </div>
            <div class="text-center" style="padding: 58px;">
                <div class="card-body">
                    <a href="https://play.google.com/store/apps/details?id=com.pikmybox.pmb" target="_blank">
                        <img alt="pikmybox" src="assets/images/play_store.png" style="height: 45px;">
                    </a>

                </div>
            </div>
        </div>
        <br>
        <br>

    </div>

</div>
<!-- end wrapper -->