import { NgModule } from '@angular/core';
import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { PrettyJsonModule } from 'angular2-prettyjson';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AuthInterceptor } from './interceptor/auth.interceptor';
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';

/******* https://github.com/vlio20/angular-datepicker */
import { DpDatePickerModule } from 'ng2-date-picker';

/**** 
 * currently using 
 * https://www.npmjs.com/package/xlsx
 * https://www.npmjs.com/package/xlsx#parsing-options
 * 
 *   */

/***
 * if module face ivy compatible issue 
 * add below code in package.json
 * scripts : {"postinstall": "ngcc"}
 * And run below command 
 * npm run postinstall *** only for angular version upto 12
 * 
 * 
 * 
 * for date picker
 * <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
  <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
  <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
  <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />

 * https://www.npmjs.com/package/excel4node
 * @auth0/angular-jwt
 */

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RoutingComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    DpDatePickerModule,
    ImageCropperModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
