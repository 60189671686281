import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DateRangePickerService } from 'src/app/services/date-range-picker.service';


@Component({
  selector: 'app-excl',
  templateUrl: './excl.component.html',
  styleUrls: ['./excl.component.css']
})
export class ExclComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  fileDetails;
  carrier = 'indiaPost'
  subscription = null;
  loader: boolean = true;
  fileSelected = false;
  isAllWell;
  loading = false;
  isvalidContainer = true;
  isvalidData = false;
  y = [];
  fileKey;
  newdata = [];
  newArray = [];
  isSubValid = false
  uploadType;
  isUpload = false;
  newArrayObj = [];
  orderDataType;
  form: FormGroup;
  constructor(public authService: AuthService, public router: Router, private fb: FormBuilder, private dp: DateRangePickerService) {
    this.checkforsubscription()
  }

  ngOnInit() { }

  checkforsubscription() {
    this.loader = false;
    this.isSubValid = false
    this.authService.checkforsubscription().subscribe(data => {
      if (data.success) {
        this.subscription = data.data
        this.isSubValid = true
      }
      else {
        this.isSubValid = false
        this.authService.showAlert(data.message);
      }
      this.createForm()
    })
    this.loader = true
  }

  createForm() {
    this.form = this.fb.group({
      avatar: null
    });
  }

  uploadExcel(v) {
    document.getElementById(v).click();
  }

  onChange(e, type) {
    this.newArrayObj = [];

    this.orderDataType = type;
    var file: File = e.target.files[0];
    if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type == "application/vnd.ms-excel") {
      this.loader = false;
      this.processFileToJson({}, file).subscribe(data => {
        // // console.log("xls data >> ", data);
        var result = data['sheets'].Sheet1;
        if (result == null || result == "" || result == undefined) {
          this.authService.showAlert("This file does not have any Data")
          this.loader = true;
          return;
        }
        else {
          this.fileKey = result[0];
          var x = result.filter((t) => { return t.pbe });

          if (x.length <= 5000 && x.length > 1) {
            this.y = [];
            for (var j = 0; j < x.length; j++) {
              if (this.fileKey['PIKMYBOX_FILE'] != undefined && this.fileKey['PIKMYBOX_FILE'].length > 5) {
                try {
                  // // console.log("x[j] 0 >> ", x[j].invoiceDate);

                  // let dd = new Date(x[j].invoiceDate) ? new Date(x[j].invoiceDate).toLocaleDateString() : '';

                  let dd = new Date(Math.round((x[j].invoiceDate - 25569) * 86400 * 1000));
                  let isoDate = dd.toISOString().slice(0, 10);

                  // console.log("isoDate >> ", isoDate);

                  // if (dd) {
                  //   let [mm, d, yy] = dd.split('/');
                  //   if (Number(d) < 10) {
                  //     d = `0${d}`;
                  //   }
                  //   if (Number(mm) < 10) {
                  //     mm = `0${mm}`;
                  //   }

                  //   let str = `${d}-${mm}-${yy}`;
                  //   // console.log(" str >> ", str);

                  x[j].invoiceDate = isoDate.split('-').reverse().join('-');
                  // }
                } catch (error) {
                }

                if (j != 0) {
                  this.y.push(x[j]);
                }
              }
              else {
                // // console.log("x[j] 1 >> ", x[j].invoiceDate);
                this.y.push(x[j]);
              }
            }

            this.loader = true;
            this.uploadType = 'bulk'
            this.fileSelected = true
          }
          else if (x.length == 1) {
            this.loader = true;
            this.authService.showAlert("This file conatains 1st entry without description.")

          }
          else {
            this.loader = true;
            this.authService.showAlert("File size to large, Max 5000 records are allowed.")
          }

          // // console.log("xls data >> ", this.y);
        }
      })
    }
    else {
      this.authService.showAlert("Not a Valid Excel File. Extension must be .xls or .xlsx")
      this.loader = true;
      this.isvalidData = false;
      return;
    }
  }

  onFileChange(event, type) {
    let reader: any = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      this.fileDetails = {
        filename: file.name,
        filetype: file.type,
        type: type
      }
      reader.onload = () => {
        this.form.get('avatar').setValue({
          filename: file.name,
          filetype: file.type,
          type: type,
          value: reader.result.split(',')[1]
        })
        // // console.log(this.form.value)
      };
      this.uploadType = type;
      this.fileSelected = true
    }
  }

  clearFile() {
    this.form.get('avatar').setValue(null);
    this.fileInput.nativeElement.value = '';
    this.form.value.carrier = "indiaPost"
  }

  submitFile(type) {
    this.form.value.carrier = this.carrier
    if (type == 'dhlBulk') {
      this.loader = false;
      // swal('Alert!', 'This section is under renovation', 'info');
      this.authService.saveneworderbyexcel(this.form.value).subscribe(data => {
        if (data.success) {
          // // console.log('Success')
          this.form.reset();
          this.router.navigate(['/dhlboxes/dhl'])
          // swal('Success', data.message, 'success')
          this.authService.showAlert(data.message)
        }
        else {
          this.authService.showAlert(data.message)
          // swal('Error', data.message, 'error')
        }
        this.loader = true;
      })
    }
    else if (type == 'ebay') {
      this.loader = false;
      // swal('Alert!', 'This section is under renovation', 'info');
      this.authService.processebay(this.form.value).subscribe(data => {
        if (data.success) {
          // // console.log('Success')
          this.form.reset();
          this.router.navigate(['/boxes/indiapost'])
          // swal('Success', data.message, 'success')
          this.authService.showAlert(data.message)
        }
        else {
          this.authService.showAlert(data.message)
          // swal('Error', data.message, 'error')
        }
        this.loader = true;
      })
    }
    else {
      this.loader = false;
      this.authService.processamazon(this.form.value).subscribe(data => {
        if (data.success) {
          // // console.log('Success')
          this.form.reset();
          this.router.navigate(['./boxes/indiapost'])
          // swal('Success', data.message, 'success')
          this.authService.showAlert(data.message)
        }
        else {
          // swal('Error', data.message, 'error')
          this.authService.showAlert(data.message)
        }
        this.loader = true;
      })
    }
  }

  downloadExcel(e) {
    if (this.authService.isCha()) {
      if (e == '2007') {
        window.open('../../../assets/docs/Bulk_Upload_Goods_Data_2007_CHA.xls')
      }
      else {
        window.open('../../../assets/docs/Bulk_Upload_Goods_Data_CHA.xlsx')
      }
    }
    else if (e == 'amazon') {
      window.open('../../../assets/docs/Amazon_Bulk_Upload_Goods_Data.xlsx')
    }
    else if (e == 'ebay') {
      // swal('Alert!', 'This section is under renovation', 'info');
      window.open('../../../assets/docs/Ebay_Bulk_Upload_Goods_Data.xlsx')
    }
    else if (e == 'amazon2007') {
      window.open('../../../assets/docs/Amazon_Bulk_Upload_Goods_Data_2007.xls')
    }
    else if (e == 'ebay2007') {
      // swal('Alert!', 'This section is under renovation', 'info');
      window.open('../../../assets/docs/Ebay_Bulk_Upload_Goods_Data_2007.xls')
    }
    else if (this.carrier != 'indiaPost') {
      if (e == '2007') {
        window.open('../../../assets/docs/DHL_Bulk_Upload_Goods_Data_2007.xls')
      }
      else {
        window.open('../../../assets/docs/DHL_Bulk_Upload_Goods_Data.xlsx')
      }
    }
    else {
      if (e == '2007') {
        window.open('../../../assets/docs/Bulk_Upload_Goods_Data_2007.xls')
      }
      else {
        window.open('../../../assets/docs/Bulk_Upload_Goods_Data.xlsx')
      }
    }
  }

  downloadInstructions(e) {
    if (e == 'amazon') {
      window.open('../../../assets/docs/STEPS_TO_UPLOAD_FILE_FOR_AMAZON_SELLER.pdf')
    }
    else if (e == 'ebay') {
      window.open('../../../assets/docs/STEPS_TO_UPLOAD_FILE_FOR_EBAY_SELLER.pdf')
    }
    else {
      if (this.carrier == 'indiaPost') {
        window.open('../../../assets/docs/Bulk_Upload_Instructions.pdf')
      }
      else {
        window.open('../../../assets/docs/STEPS_TO_UPLOAD_FILE_FOR BULK UPLOAD.pdf')
      }
    }
  }


  uploadData() {
    this.loader = false;

    let numsArrKey = ['insurance', 'freight', 'compensationCess_rate', 'customDuties_cess', 'customDuties_exportDutyRate', 'fob_foreign', 'igstDetails_rate'];

    this.y = this.y.map(item => {
      for (let k in item) {
        if (item.hasOwnProperty(k)) {
          if (typeof item[k] == 'number')
            item[k] = String(item[k]);
        }

        if (numsArrKey.includes(k)) { item[k] = Number(item[k]) ? String(Number(item[k]).toFixed(2)) : '0' };

      }
      // item.invoiceDate = new Date(item.invoiceDate).toLocaleDateString('en-GB').replaceAll("/", "-");
      return item;
    });

    // // console.log("this.y >> ", this.y);


    if (this.uploadType == 'ebay' || this.uploadType == 'amazon' || this.uploadType == 'dhlBulk') {
      this.submitFile(this.uploadType)
    }
    else {
      this.loader = false;
      this.authService.saveneworderbyexcel({ usersForm: this.y, type: this.orderDataType, key: this.fileKey }).subscribe(data => {
        if (data.success) {
          this.loader = true;
          this.router.navigate(['/boxes/indiapost'])
          this.authService.showAlert(data.message)
        }
        else {
          this.loader = true;
          this.authService.showAlert(data.message)
        }
      })
    }
  }


  cancel() {
    this.y = [];
    this.fileSelected = false
    this.fileDetails = null
    this.form.get('avatar').setValue(null);
  }

  processFileToJson(object, file): Observable<any> {
    let reader = new FileReader();
    let _this = this;

    return Observable.create(observer => {
      reader.onload = function (e) {
        let data = e.target['result'];

        let workbook = XLSX.read(data, {
          // raw: true,
          type: 'binary',
          // cellDates: true,
          // dense: true,
          // dateNF:'dd/mm/yyyy'
        });

        // console.log('date1904:', workbook.Workbook.WBProps.date1904);

        object.sheets = _this.parseWorksheet(workbook, true, true);
        observer.next(object);
        observer.complete();
      }

      // reader.readAsArrayBuffer(file);
      // let workbook = XLSX.read(data, {
      //   type: 'string', cellDates: true
      // });

      reader.readAsBinaryString(file);
    });
  }

  parseWorksheet(workbook, readCells, toJSON) {
    if (toJSON === true) {
      return this.to_json(workbook);
    }
    let sheets = {};
    _.forEachRight(workbook.SheetNames, function (sheetName) {
      let sheet = workbook.Sheets[sheetName];
      sheets[sheetName] = this.parseSheet(sheet, readCells);
    });
    return sheets;
  }

  parseSheet(sheet, readCells) {
    let range = XLSX.utils.decode_range(sheet['!ref']);
    let sheetData = [];

    if (readCells === true) {
      _.forEachRight(_.range(range.s.r, range.e.r + 1), function (row) {
        let rowData = [];
        _.forEachRight(_.range(range.s.c, range.e.c + 1), function (column) {
          let cellIndex = XLSX.utils.encode_cell({
            'c': column,
            'r': row
          });
          let cell = sheet[cellIndex];
          rowData[column] = cell ? cell.v : undefined;
        });
        sheetData[row] = rowData;
      });
    }

    return {
      'sheet': sheetData,
      'name': sheet.name,
      'col_size': range.e.c + 1,
      'row_size': range.e.r + 1
    }
  }

  to_json(workbook) {
    let result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      if (roa.length > 0) {
        result[sheetName] = roa;
      }
    });
    return result;
  }

  redirecttorowdata() {
    if (this.authService.isCustomAgent()) {
      this.authService.showAlert("This feature is under development.")
    } else {
      this.router.navigate(['/rawdata'])
    }
  }
}
