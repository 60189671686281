import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogData: any = [];
  constructor(public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
    this.getBLogs();
  }

  getBLogs() {
    this.authService.getBlog({}).subscribe(response => {
      if (response.success == true) {
        this.blogData = response.data;
      }
      else {
        this.nfs.showInfo('Oops!', response.message, 'error');
      }
    })
  }

}
