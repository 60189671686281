import { Injectable } from '@angular/core';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  public showInfo(title, text, icon) {
    return swal.fire(title, text, icon);
  }

  public showConfirmation(title, text, icon, confirmButtonText, isHtml = false) {
    if (isHtml) {
      return swal.fire({
        title: title,
        icon: icon,
        html: text,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: confirmButtonText,
        // confirmButtonAriaLabel: 'Thumbs up, great!',
        // cancelButtonText:
        //   '<i class="fa fa-thumbs-down"></i>',
        // cancelButtonAriaLabel: 'Thumbs down'
      })
    } else {
      return swal.fire({
        title, text, icon, confirmButtonText, showCloseButton: true,
        showCancelButton: true,
      });
    }
    // return true;
  }


}
