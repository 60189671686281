<div class="loading" *ngIf="loading">Loading&#8230;</div>
<div class="container-fluid">
    <div class="card mt-4">
        <div class="row m-2 p-2">
            <div class="col-md-3">
                <label for="" style="font-size: small;">Enter to Search </label>
                <input class="form-control" [(ngModel)]="searchInput" placeholder="Enter min 3 char" type="text">
            </div>
            <div class="col-md-2">
                <label for="=" style="font-size: small;">Select To Search</label>
                <select class="form-control" [(ngModel)]="searchQuery">
                    <option value=""> Select </option>
                    <option value="ieCode">IEC</option>
                    <option value="name">Company Name</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="" style="font-size: small;"> From Date </label>

                <!--  -->
                <!-- <input [(ngModel)]="fromdate" name="from"> -->
                <!--  -->

                <dp-date-picker theme="dp-material" [(ngModel)]="fromdate" mode="day" placeholder="Select Date"
                    [config]="datePickerConfig" required="true">
                </dp-date-picker>

            </div>
            <div class="col-md-2">
                <label for="" style="font-size: small;"> To Date </label>

                <!--  -->
                <!-- <input [(ngModel)]="todate" name="to"> -->
                <!--  -->

                <dp-date-picker theme="dp-material" [(ngModel)]="todate" mode="day" placeholder="Select Date"
                    [config]="datePickerConfig" required="true">
                </dp-date-picker>

            </div>

            <div class="col-md-3" style="display: flex; position: relative;">
                <div style="position: absolute; bottom: 15%; left: 5%;">
                    <button type="button" (click)="filter()" [disabled]='!inputfield()'
                        class="btn btn-block btn-outline-info btn-sm" style="width: fit-content;">
                        <i class="fa fa-search mr-2"></i>Search</button>
                </div>
                <div style="position: absolute; bottom: 15%; right: 5%;">
                    <button class="btn btn-block btn-outline-danger btn-sm" style="width: fit-content;"
                        (click)='reset()'>
                        <i class="fa fa-times mr-2"></i>Reset</button>
                </div>
            </div>


        </div>

    </div>
    <div *ngIf="!check" class="text-center font-weight-bold norecord mt-3">
        No record found
    </div>
    <div *ngIf="check" class="top-bar">
        <nav>
            <ul class="pagination  pg-amber justify-content-center" style="margin: auto"
                *ngIf="pager.pages && pager.pages.length">
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link waves-effect" (click)="setPage(1)">First</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
                </li>
                <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                    <a class="page-link" (click)="setPage(page)">{{page}}</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                </li>
            </ul>
        </nav>
        <div>
            <h6>Total
                <span class="text-primary" style="font-weight: 700">&nbsp;{{dataCount}}</span> Record(s)
                Found
            </h6>
        </div>
    </div>
    <form [formGroup]="submitForm" ngNoForm id="masterForm" novalidate>
        <table *ngIf="check" class="mainTable table table-bordered table-responsive" style="display: table">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>IEC</th>
                    <th>NOE</th>
                    <th>Address</th>
                    <th>No. of PBE's</th>
                    <th>No. of Parcel per pickup</th>
                    <th>Remarks</th>
                    <th>Rate</th>
                    <th>Total pickup requests</th>
                    <th>Subscription on dates</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody *ngIf="check">
                <tr *ngFor="let p of users; let i =index">
                    <td>{{i+1}}</td>
                    <td>{{p.ieCode}}</td>
                    <td>{{p.name}}</td>
                    <td> {{p.address}}</td>
                    <td style="min-width: 15vh;">
                        <div class="d-flex justify-content-around">
                            <div *ngIf="!checkEditButton[i][3]">{{p.monthlyPickupSubscription.noPbePerMonth}}</div>
                            <div *ngIf="!checkEditButton[i][3] && !p.monthlyPickupSubscription.rate"><i
                                    class="fa fa-edit text-danger" (click)="checkEdit(i,3)"></i></div>
                        </div>
                        <div *ngIf="checkEditButton[i][3]">
                            <input type="number" style="width:13vh;margin: auto;" (keyup)="enterfieldPbe($event)"
                                [value]="p.monthlyPickupSubscription.noPbePerMonth" class="form-control form-control-sm"
                                required />
                        </div>
                        <div *ngIf="checkEditButton[i][3]" class="d-flex justify-content-around mt-2">
                            <div><i class="fa fa-check text-success" style="font-weight: bold; font-size: larger;"
                                    (click)=updateValuePbe(updatedPbe,p._id,i,3)></i></div>
                            <div><i class="fa fa-times text-danger" style="font-weight: bold; font-size: larger;"
                                    (click)="cancelEdit(i,3)"></i></div>
                        </div>
                    </td>

                    <td style="min-width: 15vh;">
                        <div class="d-flex justify-content-around">
                            <div *ngIf="!checkEditButton[i][4]">{{p.monthlyPickupSubscription.noParcelsPerPickup}}</div>
                            <div *ngIf="!checkEditButton[i][4] && !p.monthlyPickupSubscription.rate"><i
                                    class="fa fa-edit text-danger" (click)="checkEdit(i,4)"></i></div>
                        </div>
                        <div *ngIf="checkEditButton[i][4]">
                            <input type="number" class="form-control form-control-sm" (keyup)="enterfieldParcel($event)"
                                style="width:13vh;margin: auto;"
                                [value]="p.monthlyPickupSubscription.noParcelsPerPickup" required />
                        </div>
                        <div *ngIf="checkEditButton[i][4]" class="d-flex justify-content-around mt-2">
                            <div><i class="fa fa-check text-success" style="font-weight: bold; font-size: larger;"
                                    (click)=updateValueParcel(updatedParcel,p._id,i,4)></i></div>
                            <div><i class="fa fa-times text-danger" style="font-weight: bold; font-size: larger;"
                                    (click)="cancelEdit(i,4)"></i></div>
                        </div>
                    </td>
                    <td style="min-width: 10vh;">
                        {{p.monthlyPickupSubscription.remark?p.monthlyPickupSubscription.remark:"-"}}
                    </td>
                    <td style="min-width: 15vh;">
                        <div>
                            <div *ngIf="p.monthlyPickupSubscription.rate && !checkEditButton[i][6]">
                                {{p.monthlyPickupSubscription.rate}}
                            </div>
                        </div>
                        <div *ngIf="!p.monthlyPickupSubscription.rate" class="form-group">
                            <input type="number" formControlName="rate" name="rate" class="form-control form-control-sm"
                                style="margin: auto;" placeholder="Enter Rate" required />
                        </div>
                    </td>
                    <td style="min-width: 15vh;">
                        <div>
                            <div *ngIf="p.monthlyPickupSubscription.totalPickupRequests">
                                {{p.monthlyPickupSubscription.totalPickupRequests}}</div>
                        </div>
                        <div *ngIf="!p.monthlyPickupSubscription.totalPickupRequests" class="form-group">
                            <input type="number" formControlName="pickupRequest" name="pickupRequest"
                                class="form-control form-control-sm" style="width: auto;margin: auto;"
                                placeholder="Enter total pickup requests" required />
                        </div>
                    </td>

                    <td>
                        <div class="form-group m-1">

                            <div>
                                <div>
                                    <div *ngIf="p.monthlyPickupSubscription.startDate" style="min-width: 15vh;">Start
                                        Date -
                                        <!-- {{displayDate(p.monthlyPickupSubscription.startDate)}} -->
                                        {{ p.monthlyPickupSubscription.startDate | date: 'dd-MM-yyyy'}}
                                    </div>
                                    <div *ngIf="p.monthlyPickupSubscription.endDate " style="min-width: 15vh;">End Date
                                        -
                                        <!-- {{displayDate(p.monthlyPickupSubscription.endDate)}} -->
                                        {{ p.monthlyPickupSubscription.endDate | date: 'dd-MM-yyyy'}}
                                    </div>
                                </div>
                            </div>
                            <input *ngIf="!p.monthlyPickupSubscription.startDate" type="text"
                                formControlName="startDate" name="startDate" class="form-control form-control-sm mb-2"
                                style="margin: auto;" placeholder="Enter start Date" onfocus="(this.type='date')"
                                required />
                            <input *ngIf="!p.monthlyPickupSubscription.endDate" type="text" formControlName="endDate"
                                name="endDate" class="form-control form-control-sm" style="margin: auto;"
                                placeholder="Enter end Date" onfocus="(this.type='date')" required />
                        </div>
                    </td>
                    <td>
                        <button *ngIf="!p.monthlyPickupSubscription.rate" type="button" class="btn btn-default btn-sm"
                            style="width: max-content; margin: auto; padding: 5px 8px;"
                            (click)="submitDataRow(submitForm.value,p._id,p.monthlyPickupSubscription.noPbePerMonth,p.monthlyPickupSubscription.noParcelsPerPickup,p.pickupAddress.email,i)">ACTIVATE
                            SUBSCRIPTION
                        </button>
                        <div *ngIf="checkArr[i]" class="text-danger">Please enter all fields</div>
                        <div *ngIf="p.monthlyPickupSubscription.rate">Number of PBE per month (Total Pickups/Remaining
                            Pickups) : {{p.monthlyPickupSubscription.noPbePerMonth}} /
                            {{p.monthlyPickupSubscription.remainingNoPbePerMonth}}
                        </div>

                        <div *ngIf="p.monthlyPickupSubscription.rate">
                            <div *ngIf="checkActive(p.monthlyPickupSubscription.totalPickupRequests,p.monthlyPickupSubscription.endDate,  p.monthlyPickupSubscription.remainingNoPbePerMonth)"
                                style="color:green">
                                Active</div>
                            <div *ngIf="!checkActive(p.monthlyPickupSubscription.totalPickupRequests,p.monthlyPickupSubscription.endDate, p.monthlyPickupSubscription.remainingNoPbePerMonth)"
                                style="color:red">
                                Expired</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
    <div *ngIf="check" class="d-flex justify-content-center mt-3 mb-3">
        <nav>
            <ul class="pagination  pg-amber" style="margin: auto" *ngIf="pager.pages && pager.pages.length">
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link waves-effect" (click)="setPage(1)">First</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
                </li>
                <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                    <a class="page-link" (click)="setPage(page)">{{page}}</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                </li>
            </ul>
        </nav>
        <div>
        </div>
    </div>
</div>