<div class="wrapper" style="margin-top:0px">
    <div style="background-image: url('../../../assets/img/bg1.jpg'); margin-top: 0px">
        <div class=" mask flex-center rgba-black-strong">
            <div class="container" style="margin-top:20px;margin-bottom:20px;min-height: 550px;">

                <div class="row">

                    <div class="col-md-2 mx-mn-height"></div>

                    <div class="col-md-2 mx-mn-height"></div>

                </div>

                <div class="row">
                    <div class="col-md-12" style="margin: auto;">

                        <div class="row" style="margin-top: 10px" *ngFor="let d of allData;let i=index">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink" (click)="view(d.file,d.isVideo)">
                                        <a *ngIf="d.isVideo" href="{{d.file}}" target="new"><img
                                                style="margin-right:30px;" src="../../../assets/images/youtube.png"
                                                alt="" srcset="" style="width:10%"></a>
                                        <a *ngIf="!d.isVideo" target="new">

                                            <img *ngIf="(d?.file?.split('.')[d.file.split.length-1]=='xlsx' || d?.file?.split('.')[d.file.split.length-1]=='xls')"
                                                style="margin-right:30px;" src="../../../assets/images/excel.png" alt=""
                                                srcset="" style="width:10%">
                                            <img *ngIf="(d?.file?.split('.')[d.file.split.length-1]=='doc' || d?.file?.split('.')[d.file.split.length-1]=='docx')"
                                                style="margin-right:30px;" src="../../../assets/images/word.png" alt=""
                                                srcset="" style="width:10%">
                                            <img *ngIf="(d?.file?.split('.')[d.file.split.length-1]=='pdf')"
                                                style="margin-right:30px;" src="../../../assets/images/pdf.png" alt=""
                                                srcset="" style="width:10%">
                                            <img *ngIf="(d?.file?.split('.')[d.file.split.length-1]=='jpeg' || d?.file?.split('.')[d.file.split.length-1]=='png')"
                                                style="margin-right:30px;" src="../../../assets/images/image.png" alt=""
                                                srcset="" style="width:10%">

                                        </a>
                                        <h5 class="card-title text-center py-2">
                                            <a *ngIf="d.isVideo" href="{{d.file}}" target="new">{{d.description}}</a>
                                            <a *ngIf="!d.isVideo" target="new">{{d.description}}</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 mx-mn-height"></div>

                        </div>

                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href=" https://www.youtube.com/watch?v=OWiyJqhlwxk" target="new"><img
                                                style="margin-right:30px;" src="../../../assets/images/youtube.png"
                                                alt="" srcset="" style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="https://www.youtube.com/watch?v=dGydVbScUbU" target="new">How to
                                                complete a Postal Bill of Export (PBE) form </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 mx-mn-height"></div>

                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href=" https://www.youtube.com/watch?v=OWiyJqhlwxk" target="new"><img
                                                style="margin-right:30px;" src="../../../assets/images/youtube.png"
                                                alt="" srcset="" style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href=" https://www.youtube.com/watch?v=OWiyJqhlwxk" target="new">How to
                                                complete a RBI Exchange
                                                Declaration Form (EDF) form</a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>
                        </div>


                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn  btn-outline-pink">
                                        <a href="../../../assets/docs/Getting_your_IEC_Certificate.pdf"
                                            target="new"><img style="margin-right:30px;"
                                                src="../../../assets/images/pdf.png" alt="" srcset=""
                                                style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/Getting_your_IEC_Certificate.pdf"
                                                target="new">Getting
                                                Your IEC
                                                Certificate</a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>
                        </div>

                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn  btn-outline-pink">
                                        <a href="../../../assets/docs/Setting_Up_Your_Book_Now_Pay_Later(BPNL)_Account.pdf"
                                            target="new"><img style="margin-right:30px;"
                                                src="../../../assets/images/pdf.png" alt="" srcset=""
                                                style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/Setting_Up_Your_Book_Now_Pay_Later(BPNL)_Account.pdf"
                                                target="new">How to
                                                set up your BPNL Account</a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>
                        </div>

                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href="../../../assets/docs/circ14-2018cs-new.pdf" target="new"> <img
                                                style="margin-right:30px;" src="../../../assets/images/pdf.png" alt=""
                                                srcset="" style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/circ14-2018cs-new.pdf" target="new">CBIC
                                                Circular
                                                14/2018</a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>
                        </div>

                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href="../../../assets/docs/MEISApp3BTable1_for_country(1).pdf"
                                            target="new"><img style="margin-right:30px;"
                                                src="../../../assets/images/pdf.png" alt="" srcset=""
                                                style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/MEISApp3BTable1_for_country(1).pdf"
                                                target="new">MEIS
                                                Appendix 3B (Table 1):
                                                List of Countries under Country Group A, B and C </a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>
                        </div>

                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href="../../../assets/docs/MEISApp3BTable2.pdf" target="new"><img
                                                style="margin-right:30px;" src="../../../assets/images/pdf.png" alt=""
                                                srcset="" style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/MEISApp3BTable2.pdf" target="new">MEIS
                                                Appendix 3B
                                                (Table 2): ITC (HS) Code
                                                Wise List of Products with Entitlement/Reward Rates
                                            </a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>

                        </div>


                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-2 mx-mn-height"></div>
                            <div class="col-md-8 mx-mn-height">

                                <div class="card waves-effect mx-mn-height">

                                    <div class="card-body btn btn-outline-pink">
                                        <a href="../../../assets/docs/Appendix_3c.pdf" target="new"><img
                                                style="margin-right:30px;" src="../../../assets/images/pdf.png" alt=""
                                                srcset="" style="width:10%"></a>
                                        <h5 class="card-title text-center py-2 ">
                                            <a href="../../../assets/docs/Appendix_3c.pdf" target="new">MEIS Appendix
                                                3C:
                                                Sr.
                                                No. of Category of
                                                Export for E-Commerce Exports</a>
                                        </h5>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 mx-mn-height"></div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>