<div class="loading" *ngIf="loading">Loading&#8230;</div>

<div class="wrapper">
    <div class="container-fluid">

        <div class="card p-3">
            <form [formGroup]="downloadForm">
                <div class="row ">
                    <div class="col-md-3">

                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text">Enter To search</label>
                            <input type="text" name="" formControlName="searchInput" [(ngModel)]="searchInput"
                                class="form-control" placeholder="Enter min. 3 char." id="">
                        </div>
                    </div>



                    <div class="col-md-2">
                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text">Select To Search</label>
                            <select name="" formControlName="searchType" [(ngModel)]="searchType" class="form-control"
                                id="" style="padding-top:7px ">
                                <option value="iec">IE Code</option>
                                <option value="docket">Docket No</option>
                                <option value="company">Company Name</option>
                            </select>
                        </div>
                    </div>


                    <div class="col-md-5">
                    </div>

                    <div class="col-sm-2">
                        <button title="Please add Docket Number" class="btn btn-sm btn-outline-primary"
                            (click)="navigateProofOfExport(searchInput)">
                            Upload Export Proof</button>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3 ">
                    <div class="col-md-2 ">
                        <!-- <button class="btn btn-block btn-outline-info btn-sm" (click)="filter('filter')" [disabled]="!inputfield()"><i
              class="fa fa-search"></i>Search</button> -->

                        <div *ngIf=" (authService.isSuperAdmin() || authService.isCbic())">
                            <div class="form-group">
                                <button type="button" (click)="filter('filter')" [disabled]='!inputfield()'
                                    class="btn btn-block btn-outline-info btn-sm">
                                    <i class="fa fa-search"></i>Search</button>


                            </div>
                        </div>
                    </div>


                    <div class="col-md-2 ">
                        <button class="btn btn-block btn-outline-danger btn-sm" (click)='resetDownloadFilter()'>
                            <i class="fa fa-times"></i>Reset</button>
                    </div>

                </div>
            </form>
        </div>

        <div *ngIf="boxes?.length==0" class="text-center font-weight-bold norecord">
            No record found
        </div>
        <hr>

        <div class='col-md-12'>
            <h5 *ngIf="allPbeCount" style="text-align: right;">Total
                <span class="text-primary" style="font-weight: 700">&nbsp;{{allPbeCount}}</span> Record(s) Found
            </h5>
            <nav style="margin: auto" *ngIf="((boxes?.length>0) && (!isLoading))">
                <ul class="pagination  pg-amber justify-content-center" style="margin-top: 4px"
                    *ngIf="pager.pages && pager.pages.length">
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link waves-effect" (click)="setPage1(1)">First</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage1(pager.currentPage - 1)">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pager.pages"
                        [ngClass]="{active:pager.currentPage === page}">
                        <a class="page-link" (click)="setPage1(page)">{{page}}</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage1(pager.currentPage + 1)">Next</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage1(pager.totalPages)">Last</a>
                    </li>
                </ul>
            </nav>
        </div>

        <table id="mainTable" class="table table-bordered m-b-0 text-center"
            *ngIf="((boxes?.length>0) && (!isLoading))">
            <thead>
                <tr>
                    <th>Sl. No.</th>
                    <th>Docket No.</th>
                    <th *ngIf="authService.isCha() || authService.isAdmin() || authService.isCustomLoggedIn()">IEC</th>
                    <th>Company Name</th>
                    <th>Manage Documents</th>
                    <th *ngIf="!authService.isPermitted('isFpoAgent')">PBE No.</th>


                </tr>
            </thead>
            <tbody *ngFor="let b of boxes; let i=index">
                <tr>
                    <td>
                        <span>
                            {{i+1}}
                        </span>
                    </td>
                    <td style="padding: 0">
                        <label type="button"
                            style="color: #59698d! important;font-weight: 600;padding: 0;background: transparent;box-shadow: unset;"
                            (click)="selectedOrderToView(b.tempSBill,b.boxIds)" data-toggle="modal"
                            data-target="#boxModal">{{b.tempSBill}}</label>
                        <br>

                    </td>
                    <td>
                        {{b.exporterDetails.ieCode | uppercase}}
                    </td>
                    <td>
                        {{b.exporterDetails.name}}
                    </td>
                    <td>
                        <button class="btn btn-sm btn-outline-primary" (click)="proofOfExport(b.tempSBill)">
                            View</button>
                    </td>
                    <td *ngIf="!authService.isPermitted('isFpoAgent')">
                        <span *ngIf="(b.shippingBillDetails.bill)">
                            {{b.shippingBillDetails.bill}} <br> {{b.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                            <i class="fa fa-pencil pen" *ngIf="authService.isCustomLoggedIn()"
                                (click)="selectRefPbe(b.tempSBill)" data-toggle="modal" data-target="#fpoModal"></i>
                        </span>
                        <span *ngIf="!(b.shippingBillDetails.bill)">
                            ---- / --
                            <i class="fa fa-pencil pen" *ngIf="authService.isCustomLoggedIn()"
                                (click)="selectRefPbe(b.tempSBill)" data-toggle="modal" data-target="#fpoModal"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class='col-md-12' style="margin-top: 5vh; margin-bottom: 5vh;">
            <nav style="margin: auto" *ngIf="((boxes?.length>0) && (!isLoading))">
                <ul class="pagination  pg-amber justify-content-center" style="margin-top: 4px"
                    *ngIf="pager.pages && pager.pages.length">
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link waves-effect" (click)="setPage1(1)">First</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage1(pager.currentPage - 1)">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pager.pages"
                        [ngClass]="{active:pager.currentPage === page}">
                        <a class="page-link" (click)="setPage1(page)">{{page}}</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage1(pager.currentPage + 1)">Next</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage1(pager.totalPages)">Last</a>
                    </li>
                </ul>
            </nav>
        </div>