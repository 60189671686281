<!-- <toaster-container [toasterconfig]="config"></toaster-container> -->

<div class="container" *ngIf="ticketNumber!=''">
    <div class="row">
        <div class="col-sm-12">
            <div class="alert alert-success" role="alert">
                {{ticketNumber}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="col-md-5" style="margin: auto;">
    <div class="m-b-20">
        <div class="card-body">
            <div class="accountbg"></div>
            <div class="wrapper-page">
                <div class="p-0">
                    <div class=" m-b-20">
                        <div class="">
                            <ul class="nav nav-tabs nav-justified" role="tablist">
                                <li class="nav-item waves-effect waves-light">
                                    <a class="nav-link">Helpdesk</a>
                                </li>
                            </ul>
                            <div class="tab-content card">
                                <div class="tab-pane active p-5" id="home2" role="tabpanel">
                                    <form class="text-center" [formGroup]="bugForm" (ngSubmit)="createTicket()">

                                        <div class="form-group" style="text-align: left">
                                            <select class="form-control" [(ngModel)]="currentUrl"
                                                [ngModelOptions]="{standalone: true}" (change)="getValue($event)">
                                                <option value="Issue">Report an Issue</option>
                                                <option value="Demo Request">Get a Demo</option>
                                                <option value="Feature Request">Suggest a Feature</option>
                                            </select>
                                        </div>


                                        <div class="form-group" style="text-align: left">
                                            <label><span style="color:red">*</span>&nbsp;Name</label>
                                            <input type="text" formControlName="name" class="form-control mb-4"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                                [readonly]="authService.loggedIn() && !authService.isSuperAdmin()">
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required" style="text-align:left">Name is
                                                    required</div>
                                            </div>
                                        </div>

                                        <div class="form-group" style="text-align: left">
                                            <label><span style="color:red">*</span>&nbsp;Email</label>
                                            <input type="email" id="defaultSubscriptionFormEmail"
                                                formControlName="email" class="form-control mb-4" placeholder="E-mail"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                [readonly]="authService.loggedIn() && !authService.isSuperAdmin()">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required" style="text-align:left">Email is
                                                    required</div>
                                                <div *ngIf="f.email.errors.email" style="text-align:left">Email must be
                                                    a valid email address</div>
                                            </div>
                                        </div>

                                        <div class="form-group" style="text-align: left">
                                            <label><span style="color:red">*</span>&nbsp;Mobile</label>
                                            <input type="text" (keypress)="onlyNumberKey($event)"
                                                id="defaultSubscriptionFormEmail" formControlName="mobile"
                                                class="form-control mb-4" placeholder="Mobile"
                                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                                [readonly]="authService.loggedIn() && !authService.isSuperAdmin()">
                                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                                <div *ngIf="f.mobile.errors.required" style="text-align:left">Mobile is
                                                    required</div>
                                                <div *ngIf="f.mobile.errors.maxLength" style="text-align:left">Mobile
                                                    must be 10 digits only</div>
                                            </div>
                                        </div>

                                        <div class="form-group" style="text-align: left" *ngIf="demo">
                                            <label>Select Date</label>

                                            <div style="width: 100%;">
                                                <dp-date-picker theme="dp-material" (onchange)="setDate(dd)"
                                                    style="width: 100%;" placeholder="Select Date"
                                                    [config]="datePickerConfig" required="true">
                                                </dp-date-picker>
                                            </div>

                                        </div>


                                        <div class="form-group" style="text-align: left" *ngIf="demo">
                                            <label>Select Time Slot</label>
                                            <select class="form-control" formControlName="timeSlot">
                                                <option value="" disabled>Please Select</option>
                                                <option value="10AM-1PM">10AM-1PM</option>
                                                <option value="1PM-4PM">1PM-4PM</option>
                                                <option value="4PM-7PM">4PM-7PM</option>
                                            </select>
                                        </div>

                                        <div class="form-group" style="text-align: left" *ngIf="issue">
                                            <label>Please Select Issue Type</label>
                                            <select class="form-control" formControlName="title">
                                                <option value="" disabled>Please Select</option>
                                                <option value="Data entry form">Data entry form</option>
                                                <option value="Bulk data upload from excel">Bulk data upload from excel
                                                </option>
                                                <option value="	Bulk data upload from excel – Amazon file ">Bulk data
                                                    upload from excel –
                                                    Amazon file</option>]
                                                <option value="Bulk data upload from excel – eBay file">Bulk data upload
                                                    from excel – eBay file</option>
                                                <option value="Bulk data upload from API">Bulk data upload from API
                                                </option>
                                                <option value="PBE">PBE</option>
                                                <option value="CN-22">CN-22</option>
                                                <option value="CN-23">CN-23</option>
                                                <option value="RBI EDF">RBI EDF</option>
                                                <option value="International Bulk Mail (IBM) file">International Bulk
                                                    Mail (IBM) file</option>
                                                <option value="Shipping label">Shipping label</option>
                                                <option value="Packing list">Packing list</option>
                                                <option value="Invoices">Invoices</option>
                                                <option value="MEIS application">MEIS application</option>
                                                <option value="GSTR data download">GSTR data download</option>
                                                <option value="HS Look up tool">HS Look up tool</option>
                                                <option value="Postage calculator">Postage calculator</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div class="form-group" style="text-align: left" *ngIf="feature">
                                            <label>Please Select Feature Type</label>
                                            <select class="form-control" formControlName="title">
                                                <option value="" disabled>Please Select</option>
                                                <option value="New form or document">New form or document</option>
                                                <option value="Changes in an existing form or document">Changes in an
                                                    existing form or document</option>
                                                <option value="New FPO location">New FPO location</option>
                                                <option value="New functionality for app">New functionality for app
                                                </option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <!-- <div class="form-group" *ngIf="!issue">
                      <input type="text" id="defaultSubscriptionFormEmail" formControlName="title" class="form-control mb-4"
                        placeholder="Title">
                      <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Title is required</div>
                        <div *ngIf="f.title.errors.minlength">Title must be at least 10 characters</div>
                      </div>
                    </div> -->

                                        <div class="form-group" *ngIf="issue">
                                            <input type="text" id="defaultSubscriptionFormEmail" formControlName="url"
                                                class="form-control mb-4"
                                                placeholder="URL of the page where an issue was found">

                                            <div style="color:red;text-align: left"
                                                *ngIf="f.url.value && f.url.invalid">
                                                not a valid URL
                                            </div>
                                        </div>

                                        <div class="form-group" *ngIf="issue || feature">
                                            <textarea cols="5" placeholder="Description" formControlName="description"
                                                class="form-control"></textarea>
                                        </div>

                                        <br>

                                        <!-- <div class="input-group" *ngIf="!issue">
                      <div class="input-group-prepend">
                      </div>
                      <div class="custom-file" style="border:1px soild #ccc">
                        <input type="file"  (change)="changeListener($event)" multiple accept="image/*" id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01">
                      </div>
                    </div> -->
                                        <div class="form-group file-area" *ngIf="issue">

                                            <input type="file" name="images" id="images" required="required"
                                                (change)="changeListener($event)" multiple accept="image/*" />
                                            <div class="file-dummy">
                                                <div class="success">Great, your files are selected. Keep on.</div>
                                                <div class="default">Please select files to upload</div>
                                            </div>
                                        </div>

                                        <br />

                                        <div class="form-group" style="
                    display: flex;
                    text-align: center;
                ">
                                            <div class="capt">
                                                <div id="captcha">
                                                </div>
                                                <!-- <h2 type="text" class="m-0">{{mainCaptcha}}</h2> -->

                                                <!-- <input id="Button1" type="button" value="Check" (click)="ValidCaptcha();"/> -->
                                            </div>
                                            <i (click)="Captcha();" class="fa fa-refresh text-info"
                                                style="cursor: pointer; font-size: 40px;line-height: 66px;position: relative;right: 38px;"></i>
                                            <!-- <div id="recap" class="g-recaptcha" data-sitekey="6Ld5Va0UAAAAAEz9etSfLvv3DRF6RiznYtTB44zZ"></div> -->
                                            <!-- <re-captcha siteKey="6LfXgVwUAAAAAD3NZEjAFHpOgGfKq97jOGlMv98K" (resolved)="handleCorrectCaptcha($event)"
                         [ngClass]="{ 'is-invalid': submitted && f.capcha.errors }">

                        <div *ngIf="submitted && f.capcha.errors" class="invalid-feedback">
                          <div *ngIf="f.capcha.errors.required">captcha is required</div>
                        </div>

                      </re-captcha> -->

                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="captcha"
                                                placeholder="Enter Captcha">
                                        </div>
                                        <br />
                                        <div class="form-group">
                                            <button class="btn btn-primary">Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>