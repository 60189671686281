<div class="container-fluid" style=" height: 100%;">
    <!-- background-color: #25263A; -->

    <div class="row">
        <div class="col-md-5" style="margin: auto;">
            <div class="m-b-20">

                <div class="card-body">
                    <div class="accountbg"></div>
                    <div class="wrapper-page">
                        <div class="p-0">

                            <div class=" m-b-20">
                                <div class="">

                                    <ul class="nav nav-tabs nav-justified" role="tablist">
                                        <li class="nav-item waves-effect waves-light">
                                            <a class="nav-link" (click)="changeUserType('1')"
                                                [ngClass]="{'active': (selectedUserType=='1')}">User</a>
                                        </li>

                                        <li class="nav-item" *ngIf="isDomesticLogin==false">
                                            <a class="nav-link" (click)="changeUserType('2')"
                                                [ngClass]="{'active': (selectedUserType=='2')}">Service Provider</a>
                                        </li>
                                    </ul>
                                    <!-- Tab panes -->
                                    <div class="tab-content card">
                                        <!-- style="background-color: #25263A;color: white;" -->

                                        <div class="tab-pane active p-5" id="home2" role="tabpanel">
                                            <!-- style="border:0.5px solid #A3A3A4" -->

                                            <form class="form-horizontal m-t-30">
                                                <div class="form-group" *ngIf="(selectedUserType=='1')">
                                                    <div>
                                                        <label for="iecode">Username</label>
                                                        <input type="text" class="form-control" id="iecode"
                                                            placeholder="Enter Username">
                                                    </div>
                                                    <!-- <div *ngIf="!isDomesticLogin">
                              <label for="iecode">IE Code </label>
                              <input type="text" class="form-control" id="iecode" placeholder="Enter IE Code">
                            </div> -->
                                                </div>
                                                <!-- <div class="form-group" *ngIf="(selectedUserType=='3')">
                            <label for="email">Mobile</label>
                            <input type="text" class="form-control" id="mobile" placeholder="Enter Mobile No.">
                          </div> -->
                                                <div class="form-group" *ngIf="(selectedUserType=='2')">
                                                    <label for="chaLicenseNo">CHA License No./User Code</label>
                                                    <input type="text" class="form-control" id="chaLicenseNo"
                                                        placeholder="Enter CHA License No/User Code">
                                                </div>
                                                <div class="form-group" *ngIf="!isForgotPassword">
                                                    <label for="userpassword">Password</label>
                                                    <input type="password" class="form-control" id="userpassword"
                                                        placeholder="Enter password">
                                                </div>
                                                <div class="m-t-20">
                                                    <div class="row text-center" *ngIf="!isForgotPassword">
                                                        <div class="col-sm-6 text-center form-group">
                                                            <button
                                                                class="btn btn-outline-blue w-md waves-effect waves-light btn-block"
                                                                type="reset">
                                                                <i class="fa fa-refresh"></i>&nbsp;Reset</button>
                                                        </div>

                                                        <div class="col-sm-6 text-center form-group">
                                                            <button
                                                                class="btn btn-outline-primary w-md waves-effect waves-light btn-block"
                                                                type="submit" (click)="login()">
                                                                <i class="fa fa-key"></i>&nbsp; Log In</button>
                                                        </div>
                                                    </div>
                                                    <div class="row text-center" *ngIf="isForgotPassword">
                                                        <div class="col-sm-6 text-center form-group">
                                                            <button
                                                                class="btn btn-warning w-md waves-effect waves-light"
                                                                type="button" (click)="reset(false)">Cancel</button>
                                                        </div>
                                                        <div class="col-sm-6 text-center form-group">
                                                            <button
                                                                class="btn btn-primary w-md waves-effect waves-light"
                                                                type="button" (click)="forgot()">Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group m-t-10 mb-0 row" *ngIf="!isForgotPassword">
                                                    <div class="col-12 m-t-20">
                                                        <a href="javascript: void(0)" class="text-muted"
                                                            (click)="reset(true)">
                                                            <i class="mdi mdi-lock"></i> Forgot your password?</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>