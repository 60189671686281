<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div class="container-fluid">
    <div class="row" style="margin-top:2%">

        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="padding-bottom:0px">
                    <div class="row">
                        <div class="col-sm-9">
                            <div class="dark-font">
                                <ol class="breadcrumb" style="background-color: #fff">
                                    <li class="breadcrumb-item">
                                        <a class="black-text" routerLink="/home">Home</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a class="black-text" routerLink="/pbe">My PBE</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li class="breadcrumb-item active" style="color:#000">
                                        <span><strong>Docket No. : {{selectedDocketNo}}</strong></span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-3 text-right">
                            <button class="btn btn-outline-primary btn-sm" (click)=location.back()><i
                                    class="fa fa-arrow-left"></i>&nbsp;&nbsp;Back</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="row">
        <div class="col-sm-12" style="margin-bottom:30px;">

            <ul class="nav nav-tabs nav-justified" role="tablist" style="margin-top:2%">
                <li class="nav-item waves-effect waves-light">
                    <a class="nav-link active" id="home-tab-md" data-toggle="tab" href="#home-md" role="tab"
                        aria-controls="home-md" aria-selected="true">Download Documents</a>
                </li>
            </ul>

            <div class="tab-content card pt-5" id="myTabContentMD">

                <div class="tab-pane fade show active" id="home-md" role="tabpanel" aria-labelledby="home-tab-md">
                    <table id="mainTable" class="table table-bordered m-b-0 text-center" *ngIf="selectedDoc">
                        <thead>
                            <tr>
                                <td>Document Name</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody
                            *ngIf="authService.isExporterAdmin() || authService.isCha() || authService.isSuperAdmin() || (authService.isCustomLoggedIn())">
                            <tr>
                                <th class="lHeight">PBE</th>
                                <td><button class="btn btn-outline-primary btn-sm"
                                        (click)="printOrder(selectedDoc.docketNo, 'pbe', 'view')">View/Download</button>
                                </td>
                            </tr>

                            <tr>
                                <th class="lHeight">PBE Summary</th>
                                <td><button class="btn btn-outline-primary btn-sm"
                                        (click)="viewSummary()">View/Download</button></td>
                            </tr>

                            <tr
                                *ngIf="authService.isExporterAdmin() || authService.isCha() || authService.isSuperAdmin()">
                                <th class="lHeight">CN22/23</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printallcnform(selectedDoc.docketNo)">View/Download</button>
                                    <button class="btn btn-outline-info waves-effect btn-sm"
                                        (click)="printallcn2223form('22')" *ngIf="isCn22">CN
                                        22</button>
                                    <button class="btn btn-outline-danger waves-effect btn-sm"
                                        (click)="printallcn2223form('23')" *ngIf="isCn23">CN
                                        23</button>
                                </td>

                            <tr
                                *ngIf=" ii>0 && parcelType() && (authService.isExporterAdmin() || authService.isCha() || authService.isSuperAdmin())">
                                <th class="lHeight">Harmonized Label</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm" data-toggle="modal"
                                        data-target="#harmonize" style="padding: 7px 44px;">Generate</button>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">RBI EDF &nbsp;
                                    <a target="_blank" href="https://www.rbi.org.in/scripts/EDF_UserDetails.aspx">
                                        <i class="fa fa-info-circle" aria-hidden="true"
                                            style="cursor:pointer;color:red;font-size: 16px;"
                                            title="https://www.rbi.org.in/scripts/EDF_UserDetails.aspx">
                                        </i>
                                    </a>
                                </th>
                                <td><button class="btn btn-outline-primary btn-sm" data-toggle="modal"
                                        data-target="#detailModal"
                                        (click)="selectedOrderForBox(selectedDoc.docketNo)">View/Download</button></td>
                            </tr>

                            <tr
                                *ngIf="authService.isExporterAdmin() || authService.isCha() || authService.isSuperAdmin()">
                                <th style="padding-top: 9vh;">International Bulk Mail file for India Post
                                    &nbsp;<i class="fa fa-info-circle" aria-hidden="true"
                                        style="cursor:pointer;color:red;font-size: 16px;" data-target="#info"
                                        data-toggle="modal"></i>
                                </th>
                                <td style="  text-align: center; margin: auto;">
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none" colspan="2">
                                                <button class="btn btn-outline-primary btn-sm"
                                                    (click)="postalCSI(selectedDoc.docketNo)">Download</button>
                                            </td>
                                        </tr>

                                        <tr colspan="2" style="border: none">
                                            <td style="border:none">
                                                <input
                                                    *ngIf="!authService.isCustomLoggedIn() && (!selectedDoc.updatedIbm || selectedDoc.updatedIbm.length<5)"
                                                    id="control6" style="    overflow: hidden;" type="file"
                                                    class="form-control input-size" (change)="changeListener1($event)"
                                                    accept="application/vnd.ms-excel">
                                            </td>
                                            <td style="border:none;">
                                                <button
                                                    *ngIf="!authService.isCustomLoggedIn() && (!selectedDoc.updatedIbm || selectedDoc.updatedIbm.length<5)"
                                                    class="btn btn-sm btn-primary"
                                                    (click)="uploadEditedIbm('ibm','control6')"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload with Edits</button>
                                                <button
                                                    *ngIf="!authService.isCustomLoggedIn() && (selectedDoc.updatedIbm && selectedDoc.updatedIbm.length>4)"
                                                    class="btn btn-warning btn-sm" (click)="downloadIbm()"> <i
                                                        class="fa fa-download"></i>&nbsp;
                                                    Download with Edits </button>
                                            </td>
                                        </tr>

                                    </table>
                                </td>
                            </tr>

                            <tr *ngIf="!authService.isCustomLoggedIn()">
                                <th class="lHeight">Shipping Label</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printallshippinglabels(selectedDoc.docketNo, false)">View/Download
                                        (format 1) </button>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printallshippinglabels(selectedDoc.docketNo, true)">View/Download
                                        (format 2) </button>
                                </td>
                            </tr>

                            <tr *ngIf="!authService.isCustomLoggedIn() && !authService.isCha()">
                            <!-- <tr *ngIf="!authService.isCustomLoggedIn() && !authService.isCha()"> -->
                                <th class="lHeight">All Export Invoices
                                    <!-- {{cureentpbe.isAllInvoicesGen}} -->
                                </th>

                                <td *ngIf="authService.isSuperAdmin()">
                                    <button class="btn btn-outline-primary btn-sm">Generate Invoices</button>

                                    <label *ngIf="cureentpbe?.isAllInvoicesGen=='Processing'" for=""
                                        class="text-info">Invoice creation in
                                        progress.
                                    </label>

                                    <button *ngIf="cureentpbe?.isAllInvoicesGen=='Generated'"
                                        class="btn btn-outline-success btn-sm"
                                        (click)="downloadAllInvoices(selectedDoc.docketNo,'download')">Download</button>
                                </td>

                                <td *ngIf="!authService.isSuperAdmin()" style="display: flex; justify-content: center;">

                                    <div class="ttp" style="width: fit-content; margin: 0px; padding: 0px;">
                                        <span class="tooltiptext">
                                            {{
                                            (isAllowedbtn==false)
                                            ? 'Your request is in progress.' : 'Click to generate invoice pdf.'
                                            }}
                                        </span>

                                        <button class="btn btn-outline-primary btn-sm " [disabled]="!isAllowedbtn"
                                            (click)="downloadAllInvoices(selectedDoc?.docketNo, 'generate')">
                                            Generate Invoices
                                        </button>
                                    </div>

                                    <div
                                        style="display: flex;text-align: center; justify-content: center;  align-items: center;">
                                        <label *ngIf="cureentpbe?.isAllInvoicesGen=='Processing'" style="margin: 0px;"
                                            class="text-info">
                                            Invoice creation in progress.
                                        </label>

                                        <button *ngIf="cureentpbe?.isAllInvoicesGen=='Generated'"
                                            class="btn btn-outline-success btn-sm"
                                            (click)="downloadAllInvoices(selectedDoc?.docketNo,'download')">
                                            Download
                                        </button>
                                    </div>
                                </td>
                            </tr>

                            <!-- ===================== CN FORM WITH SHIPPING LABEL =================== -->
                            <tr
                                *ngIf="authService.isExporterAdmin() || authService.isCha() || authService.isSuperAdmin()">
                                <th class="lHeight">CN22/23 With Shipping Label (BETA)
                                    <!-- <i class="fa fa-info-circle" style="cursor:pointer;color:red;font-size: 16px;" title="This is a beta version release and as such you may encounter some speed or other issues. Please let us know your feedback as we work to improve this feature."></i> -->
                                </th>
                                <td>

                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printallcnformWithShippingLabel(selectedDoc.docketNo, false)">View/Download
                                        (format 1)</button>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printallcnformWithShippingLabel(selectedDoc.docketNo, true)">View/Download
                                        (format 2)
                                    </button>


                                    <button class="btn btn-outline-info waves-effect btn-sm"
                                        (click)="printallcn2223formWithShippingBill('22')" *ngIf="isCn22Bill">CN
                                        22 + Shipping Label</button>
                                    <button class="btn btn-outline-danger waves-effect btn-sm"
                                        (click)="printallcn2223formWithShippingBill('23')" *ngIf="isCn23Bill">CN
                                        23 + Shipping Label</button>

                                </td>
                            </tr>
                            <!-- ===================== ============================================ -->

                            <tr *ngIf="!authService.isCustomLoggedIn()">
                                <th class="lHeight">Packing List</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printOrder(selectedDoc.docketNo, 'postal', 'view')">View/Download</button>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">Additional Documents (if any)
                                    <br>
                                    <span>(eg. Drug Licenses, Jewellery Certificates, etc.)</span>
                                    <br>
                                    <span *ngIf="selectedDoc.otherStatus" class="text-success">Uploaded</span>
                                    <!-- <span *ngIf="!selectedDoc.otherStatus" class="text-warning">Pending</span> -->
                                </th>

                                <td>
                                    <!-- *ngIf="selectedDocStatus=='Pending' || !selectedDocStatus=='Filed'" -->
                                    <div *ngIf="!authService.isCustomLoggedIn() && !authService.isSuperAdmin()">
                                        <input id="control5" type="file" multiple (change)="changeListener($event)"
                                            accept="application/pdf">
                                        <div class="d-flex justify-content-center">
                                            <input style="width: 60%;height: 3.3vh;" maxlength=""
                                                placeholder="Enter min 5 & max 40 characters about the file." id="desc"
                                                class="form-control mt-2 input-size1" [(ngModel)]="desc" type="text">
                                            <button class="btn btn-sm btn-primary"
                                                (click)="sendFiles('other','control5')"><i
                                                    class="fa fa-upload"></i></button>
                                        </div>
                                    </div>
                                    <!-- <hr *ngIf="selectedDoc.otherStatus && !selectedDocStatus=='Filed' && !authService.isCustomLoggedIn()" /> -->
                                    <div *ngIf="selectedDoc.otherStatus">
                                        <span *ngFor="let d of selectedDoc.other">
                                            <button class="btn btn-outline-success btn-sm"
                                                (click)="viewDoc(d.fileName)">{{d.desc}}</button>
                                            <button
                                                *ngIf="selectedDocStatus=='Pending' && !authService.isCustomLoggedIn() && !authService.isSuperAdmin()"
                                                class="btn btn-outline-danger btn-sm"
                                                (click)="deletedocument('other',d.fileName,selectedDoc.docketNo)"><i
                                                    class="fa fa-times"></i></button>
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="!selectedDoc.otherStatus &&( authService.isCustomLoggedIn() || authService.isSuperAdmin())">
                                        <span>N/A</span>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="!authService.isCustomLoggedIn() && selectedDoc.pickupStatus!='Pending'">
                                <th class="lHeight">Pickup Instructions & Checkist</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printOrder(selectedDoc.docketNo, 'checklist', 'view')">View/Download</button>
                                </td>
                            </tr>
                            <tr *ngIf="!authService.isCustomLoggedIn() && selectedDoc.pickupStatus!='Pending'">
                                <th class="lHeight">Authority Letter</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printOrder(selectedDoc.docketNo, 'authorityLetter', 'view')">View/Download</button>
                                </td>
                            </tr>
                            <tr *ngIf="!authService.isCustomLoggedIn() && selectedDoc.pickupStatus!='Pending'">
                                <th class="lHeight">Handover Note</th>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm"
                                        (click)="printOrder(selectedDoc.docketNo, 'pickupPackingList', 'view')">View/Download</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="tab-pane fade" id="profile-md" role="tabpanel" aria-labelledby="profile-tab-md">
                    <!-- *ngIf="selectedDoc" -->
                    <table id="mainTable" class="table table-bordered m-b-0 text-center">
                        <thead>
                            <tr>
                                <td>Document Name</td>
                                <td>Status</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="modal" id="cnModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Form CN22/23</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <button class="btn btn-info btn-sm" (click)="printallcn2223form('22')">CN22</button>
                <button class="btn btn-danger btn-sm" (click)="printallcn2223form('23')">CN23</button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">Parcels Against Docket No. {{selectedDocketNo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeTracking()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" id="selectAll" (change)="selectAll($event);" class="inp-cbx"
                                    style="display: none">
                                <label for="selectAll" class="cbx"
                                    *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin() || authService.isCha()">
                                    <span style="    border-color: #333;">
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                                S. No.
                            </th>
                            <th>
                                Parcel Id
                            </th>
                            <th>
                                Invoice (No.)
                            </th>
                            <th>
                                Destination (Country)
                            </th>

                            <th>
                                Tracking no.
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf=" boxData?.length">
                        <tr *ngFor="let bt of boxData; let sl = index">
                            <td style="padding: 0px">
                                <div class="brd" *ngFor="let in of bt.newInv;let k=index">
                                    <input [checked]="(checkSelected(in.invoice))" value="{{bt._id}}" type="checkbox"
                                        id="cbx{{sl}}" (change)="selectBoxIds($event,in.invoice);" class="inp-cbx"
                                        style="display: none">
                                    <label
                                        *ngIf="(!authService.isAdmin() && ((!(authService.isCustomLoggedIn() || authService.isCbic())) && in.isEdf))"
                                        for="cbx{{sl}}" class="cbx">
                                        <span>
                                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </svg>
                                        </span>
                                    </label>
                                    <span>{{sl+1}} </span>
                                </div>


                            </td>
                            <td style="padding: 0px">
                                <button style="float: left;" class="btn btn-link btn-sm" style="text-decoration: none">
                                    <!-- <span *ngIf="(bt._id?.length<11)"> {{bt._id}}</span> -->
                                    <!-- <span *ngIf="(bt._id?.length>11)"> {{bt._id | slice:17}}</span> -->
                                    <span> {{bt._id | slice:-6}}</span>

                                </button>

                                <!-- <button style="float: right;" *ngIf="((bt.status=='Pending') && !(authService.isCbic() || authService.isExporterAdmin() || authService.isCustomLoggedIn()))"
                                  (click)="generateBill(bt._id)" data-dismiss="modal" class="btn btn-outline-primary btn-sm">
                                  <i class="fa fa-pencil pen"></i>EDIT
                              </button> -->
                            </td>
                            <td style="padding: 0px">
                                <div class="brd" *ngFor="let in of bt.newInv;let k=index">
                                    <button class="btn btn-link btn-sm" style="text-decoration: none"> {{in.invoice}}

                                    </button>

                                    <button style="float: right;"
                                        *ngIf="((!in.isEdfDetails) && (!(authService.isCustomLoggedIn() || authService.isCbic())) && in.isEdf && selectedInvoices?.length==0)"
                                        (click)="selectInvoice(in.invoice, false);getedfdataforpbe()"
                                        class="btn btn-outline-info btn-sm" data-dismiss="modal" data-toggle="modal"
                                        data-target="#edfModal">
                                        <i class="fa fa-send pen"></i> Submit EDF Details
                                    </button>
                                    <button style="float: right;"
                                        *ngIf="((in.isEdfDetails)  && (!(authService.isCustomLoggedIn() || authService.isCbic())) && in.isEdf && selectedInvoices?.length==0)"
                                        (click)="selectInvoice(in.invoice, false);getedfdataforpbe('update')"
                                        class="btn btn-outline-success btn-sm" data-dismiss="modal" data-toggle="modal"
                                        data-target="#edfModal">
                                        <i class="fa fa-pencil pen"></i> Update EDF Details
                                    </button>

                                    <span class="badge indigo"
                                        *ngIf="((in.isEdfDetails)  && (!(authService.isCustomLoggedIn() || authService.isCbic())) && in.isEdf && selectedInvoices?.length>0)">Contains
                                        EDF Data</span>




                                    <!-- <button style="float: right;" *ngIf="(in.isEdf && !in.isRbiDetails)" (click)="selectInvoice(in.invoiceNo)" class="btn btn-outline-info btn-sm"
                                  data-dismiss="modal" data-toggle="modal" data-target="#edfModal">
                                  <i class="fa fa-pencil pen"></i> Update EDF Details
                              </button> -->

                                    <button (click)="getedfdataforpbe('update')" style="float: right;visibility: hidden"
                                        *ngIf="(!in.isEdf)" class="btn btn-outline-info btn-sm" data-dismiss="modal"
                                        data-toggle="modal" data-target="#edfModal">
                                        <i class="fa fa-pencil pen"></i> Update EDF Details
                                    </button>
                                </div>
                            </td>
                            <td>
                                {{bt.consigneeDetails.country}}
                            </td>
                            <td>
                                <span *ngIf="(bt.trackingNo)">
                                    {{bt.trackingNo}}
                                    <!-- <i *ngIf="((bt.status!='LEO Granted') &&  (!(authService.isCustomLoggedIn() || authService.isCbic())))"
                    class="fa fa-pencil pen" data-dismiss="modal" (click)="getBoxId(bt._id)" data-toggle="modal"
                    data-target="#pbeModal"></i> -->
                                    <button style="padding-top: 3px;padding-bottom: 3px;" class="btn btn-info btn-sm"
                                        (click)="trackShipment(bt.trackingNo)">Track
                                        Now</button>
                                </span>
                                <span *ngIf="!(bt.trackingNo)">
                                    ----
                                </span>

                            </td>
                        </tr>
                    </tbody>
                </table>


                <div class="text-center" *ngIf="selectedInvoices?.length>0">

                    <button (click)="selectInvoice(selectedInvoices,true);getedfdataforpbe('update')"
                        class="btn btn-outline-info btn-sm" data-dismiss="modal" data-toggle="modal"
                        data-target="#edfModal">
                        <i class="fa fa-send pen"></i> Submit/Update EDF Details
                    </button>
                </div>

            </div>
            <div class="modal-footer" *ngIf="boxData?.length>0">

                <button type="button" *ngIf="(checkEdf())" [disabled]='isLoading' class="btn btn-outline-success"
                    (click)="printOrder(selectedDocketNo, 'edf','view')">
                    <span *ngIf='isLoading'>
                        <i class="fa fa-spin fa-spinner"></i>&nbsp; Loading..</span>
                    <span *ngIf='!isLoading'>View RBI EDF</span>
                </button>
                <button type="button" *ngIf="(checkEdf())" [disabled]='isLoading' class="btn btn-outline-secondary"
                    (click)="printOrder(selectedDocketNo, 'edf','print')">
                    <span *ngIf='isLoading'>
                        <i class="fa fa-spin fa-spinner"></i>&nbsp; Loading..</span>
                    <span *ngIf='!isLoading'>Download RBI EDF</span>
                </button>

                <!-- <button type="button" *ngIf="selectedBoxToView" class="btn btn-info" [disabled]='isLoading' (click)="printOrder(selectedBoxToView, 'postal', 'view')">
                  <span *ngIf='isLoading'>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp; Loading..</span>
                  <span *ngIf='!isLoading'>Packing List(For India Post)</span>
              </button> -->
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="edfModal" tabindex="-1" role="dialog" aria-labelledby="edfModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="edfModalLabel">RBI EDF Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="max-height: 390px;overflow-y: auto;">
                <form [formGroup]="form" class="form row" id="edd">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">RBI Approval No (if any)</label>
                            <input type="text" class="form-control" formControlName="rbiApprovalNo">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">RBI Approval Date (if any)</label>

                            <dp-date-picker theme="dp-material" formControlName="rbiApprovalDate" placeholder="YY-MM-DD"
                                [config]="datePickerConfig1" required="true">
                            </dp-date-picker>

                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Category of Exporter</label>
                            <select class="form-control" formControlName="categoryOfExporter"
                                (change)="setValuecategoryOfExporter1($event.target.value)">
                                <option value="1">Custom (DTA units)</option>
                                <option value="2">SEZ</option>
                                <option value="3">Status holder exporters</option>
                                <option value="4">100% EOU</option>
                                <option value="5">Warehouse Export</option>
                                <option value="6">Others</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Mode of Realisation</label>
                            <select class="form-control" formControlName="modeOfRealisation">
                                <option value="L/C">L/C</option>
                                <option value="BG">BG</option>
                                <option
                                    value="Others advance payment, OPGSP- credit/debit card etc. including transfer/remittance">
                                    Others(advance payment, OPGSP- credit/debit card etc. including transfer/remittance
                                    to bank
                                    account maintained oversea)</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Nature of Contract</label>
                            <select class="form-control" formControlName="natureOfContract">
                                <option value="1">FOB</option>
                                <option value="2">CIF</option>
                                <option value="3">C&F</option>
                                <option value="4">CI</option>
                                <option value="5">Others(door to door)</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Declaration by Exporter</label>
                            <select class="form-control" formControlName="exporterDeclaration">
                                <option value="Already realized by debit/credit card">Already realized by debit/credit
                                    card</option>
                                <option value="90 days">90 days</option>
                            </select>
                        </div>
                    </div>
                    <hr>


                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Form No.</label>
                            <input type="text" formControlName="formNo" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="text">Third Party Name <span class="text-primary">
                                    (In case of
                                    third Party Payments for Exports)
                                </span> </label>
                            <input type="text" formControlName="thirdPartyName" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="text">Third Party Address</label>
                            <input type="text" formControlName="thirdPartyAddress" class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="text">Whether payment to be received through ACU</label>
                            <select class="form-control" formControlName="acuPayment">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="form-group">

                            <div class="row">
                                <div class="col-sm-3">
                                    <label for="text">Number of parcel receipts</label>
                                    <input type="text" class="form-control" formControlName="receiptNumber">
                                </div>
                                <div class="col-sm-4">
                                    <label for="text">Date of parcel receipts</label>

                                    <dp-date-picker theme="dp-material" formControlName="receiptDate"
                                        placeholder="YY-MM-DD" [config]="datePickerConfig1" required="true">
                                    </dp-date-picker>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Freight Amount (In Foreign)</label>
                            <input type="text" formControlName="freightAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Insurance Amount (In Foreign)</label>
                            <input type="text" formControlName="insuranceAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Commission Amount (In Foreign)</label>
                            <input type="text" formControlName="commissionAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Discount Amount (In Foreign)</label>
                            <input type="text" formControlName="discountAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Packing Amount (In Foreign)</label>
                            <input type="text" formControlName="packingAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="text">Other Deduction (In Foreign)</label>
                            <input type="text" formControlName="deductionAmountForeign"
                                (keypress)="restrictNumeric($event)" class="form-control">
                        </div>
                    </div>


                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="submitEdf(form.value)" class="btn btn-outline-success"
                    data-dismiss="modal">
                    <i class="fa fa-send"></i> &nbsp;Submit
                </button>
                <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="info" tabindex="-1" role="dialog" aria-labelledby="info" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center" style="background: rgb(255, 30, 119);color:#fff">
                <h3 class="modal-title" id="exampleModalLabel">Instruction for IBM File</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color:#fff">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <ul style="list-style-type: decimal;font-weight:650">
                            <li style="line-height: 30px;">Please enter HS Tariff 1 in Column(Y) in file. Refer to HS
                                Lookup tool <a href="https://www.pikmybox.com/hsnlookup" target="new"
                                    style="text-decoration: underline">here</a>
                            </li>
                            <li style="line-height: 30px;">Please enter state (if any) in Column(H) in file.</li>
                            <li style="line-height: 30px;">Please enter consignee email in colomn(J).</li>
                            <li style="line-height: 30px;">All other required details are already completed. Please
                                review.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal bottom" id="fluidModalBottomDangerDemo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    data-backdrop="true">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <h6 class="modal-title text-center">Parcel Tracking</h6>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center" style="max-height: 400px;overflow-y: auto;" innerHTML="{{trackingData}}"></div>
            </div>

            <!--Footer-->
            <div class="modal-footer">

            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<!-- ==================== harmonized label modal ======================== -->
<div class="modal" id="harmonize" tabindex="-1" role="dialog" aria-labelledby="info" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center" style="background: rgb(255, 30, 119);color:#fff">
                <h3 class="modal-title" id="exampleModalLabel">Harmonized Label</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color:#fff">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="text">Category of Item</label>
                            <select class="form-control" [(ngModel)]="categoryOfItem">
                                <option value="Commercial Sample">Commercial Sample</option>
                                <option value="Return Goods">Return Goods</option>
                                <option value="Documents"> Documents </option>
                                <option value="Sales of Goods">Sales of Goods</option>
                                <option value="Other">Other</option>
                                <option value="Gift">Gift</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="text">Date of Mailing </label>
                            <input type="date" class="form-control" [(ngModel)]="mailingDate">
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button [disabled]="!categoryOfItem || !mailingDate " type="button" class="btn btn-primary"
                    (click)="generateHarmonizedLabel(selectedDoc.docketNo)"> Generate </button>
            </div>
        </div>
    </div>
</div>