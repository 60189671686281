import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-proof-of-export',
  templateUrl: './proof-of-export.component.html',
  styleUrls: ['./proof-of-export.component.css']
})
export class ProofOfExportComponent implements OnInit {

  /* TODO : change the veiw button condition on submit */

  constructor(private route: ActivatedRoute, public router: Router,
    public authService: AuthService, private sanitizer: DomSanitizer) { }

  /*declarations*/
  docketNo;
  data;
  isLoading = false;
  selectedDoc: any;
  checkGeneratedView = false;
  generatedFile;

  pbeData: any[] = [];
  rbiData: any[] = [];
  postalReceiptData: any[] = [];
  exportInvoiceData: any[] = [];
  additionalDocData: any[] = [];

  postalcount = 0;
  exportInvoiceCount = 0;
  PBECount = 0;
  RBICount = 0;
  AdditionalDocCount = 0;

  postalCountCheck = false;
  exportInvoiceCheck = false;
  PBECountCheck = false;
  RBICountCheck = false;
  AdditionalDocCountCheck = false;

  pbe: any[] = [];
  rbi: any[] = [];
  exportInvoice: any[] = [];
  receipts: any[] = [];
  additionalDocs: any[] = [];

  checkPBE = false;
  checkRBI = false;
  checkExport = false;
  checkPostal = false;
  checkAdditional = false;

  showPBE: boolean = false;
  showRBI: boolean = false;
  showExport: boolean = false;
  showPostal: boolean = false;
  showAdditional: boolean = false;


  myForm = new FormGroup({});

  pbeimagearray: any = [];
  rbiimagearray: any = [];
  exportimagearray: any = [];
  otherimagearray: any = [];

  window: Window;

  ngOnInit() {
    this.docketNo = this.route.snapshot.params['id'];
    this.exportInvoiveDetails();
    this.PostalReceiptsDetails();
    this.RBIDetails();
    this.getPBEDetails();
    this.AdditionalDocDetails();
    this.getGeneratedInvoiceDetails()
    this.checkGeneratedDoc();
  }

  /*function to convert file to base64*/
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  /*PBE functions*/
  getPBEDetails() {
    this.authService.getDocketData({ type: 'pbe', docketNo: this.docketNo }).subscribe(res => {
      this.pbeData = res.data.pbe;
      this.getPBECount();
    }
    )
  }
  onFileChangePBE(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.toBase64(file).then(result => {
        this.pbe.push({ 'fileName': file.name, 'fileType': file.type, 'base64': result });
      });
    }
  }
  submitPBE() {
    if (this.pbe.length == 0) {
      return null;
    }
    this.isLoading = true
    this.authService.uploadDoc({ type: 'pbe', docketNo: this.docketNo, documents: this.pbe }).subscribe(res => {
      this.isLoading = false
      this.checkPBE = true;
      this.pbe.length = 0;
      if (res.success) {
        this.authService.showAlert(res.message);
      }
    })
  }
  viewPBE() {
    this.getPBEDetails();
    this.showPBE = true;
  }
  getPBECount() {
    this.PBECount = this.pbeData.length;
    if (this.PBECount > 0) {
      this.PBECountCheck = true;
    }
    else {
      this.PBECountCheck = false;
    }
  }

  /*RBI Functions*/
  RBIDetails() {
    this.authService.getDocketData({ type: 'rbi', docketNo: this.docketNo }).subscribe(res => {
      this.rbiData = res.data.rbi;
      this.getRBICount();
    }
    )
  }
  onFileChangeRBI(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.toBase64(file).then(result => {
        this.rbi.push({ 'fileName': file.name, 'fileType': file.type, 'base64': result });
      });
    }
  }
  submitRBI() {
    if (this.rbi.length == 0) {
      return null;
    }
    this.isLoading = true
    this.authService.uploadDoc({ type: 'rbi', docketNo: this.docketNo, documents: this.rbi }).subscribe(res => {
      this.isLoading = false;
      this.checkRBI = true;
      this.rbi.length = 0;
      if (res.success) {
        this.authService.showAlert(res.message)
      }
    })
  }
  viewRBI() {
    this.RBIDetails();
    this.showRBI = true;
  }
  getRBICount() {
    this.RBICount = this.rbiData.length;
    if (this.RBICount > 0) {
      this.RBICountCheck = true;
    }
    else {
      this.RBICountCheck = false;
    }
  }

  /*Export Invoice Functions*/
  exportInvoiveDetails() {
    this.authService.getDocketData({ type: 'exportInvoice', docketNo: this.docketNo }).subscribe(res => {
      this.exportInvoiceData = res.data.exportInvoice;
      this.getExportInvoiceCount();
    })
  }
  onFileChangeExport(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.toBase64(file).then(result => {
        this.exportInvoice.push({ 'fileName': file.name, 'fileType': file.type, 'base64': result });
      });
    }
  }
  submitExportInvoice() {
    if (this.exportInvoice.length == 0) {
      return null;
    }
    this.isLoading = true
    this.authService.uploadDoc({ type: 'exportInvoice', docketNo: this.docketNo, documents: this.exportInvoice }).subscribe(res => {
      this.isLoading = false;
      this.exportInvoice.length = 0;
      if (res.success) {
        this.authService.showAlert(res.message)
      }
      this.checkExport = true;
    })
  }
  viewExportInvoices() {
    this.exportInvoiveDetails();
    this.showExport = true;
  }
  getExportInvoiceCount() {
    this.exportInvoiceCount = this.exportInvoiceData.length;
    if (this.exportInvoiceCount > 0) {
      this.exportInvoiceCheck = true;
    }
    else {
      this.exportInvoiceCheck = false;
    }
  }
  checkGeneratedDoc() {
    var obj = { docketNo: this.docketNo }
    this.authService.checkDocumentStatus(obj).subscribe(res => {
      if (res.success == true) {
        this.selectedDoc = res.data;
        if (this.selectedDoc.isAllInvoicesGen == 'Generated') {
          this.checkGeneratedView = true;
        }
      }
      return true;
    })
    return false;
  }
  getGeneratedInvoiceDetails() {
    this.generatedFile = this.authService.socketUrl + 'api/v1/downloadZip/' + this.docketNo;
  }

  /*Postal Receipt functions*/
  PostalReceiptsDetails() {
    this.authService.getDocketData({ type: 'receipts', docketNo: this.docketNo }).subscribe(res => {
      this.postalReceiptData = res.data.receipts;
      this.getPostalReceiptsCount();
    }
    )
  }
  onFileChangePostal(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.toBase64(file).then(result => {
        this.receipts.push(result);
      });
    }
  }
  submitPostalReciepts() {
    if (this.receipts.length == 0) {
      return null;
    }
    this.isLoading = true
    for (let index = 0; index < this.receipts.length; index++) {
      this.authService.uploadreceipts({ tempSBill: this.docketNo, file: this.receipts[index] }).subscribe(res => {
        this.isLoading = false;
        this.receipts.length = 0;
        if (res.success) {
          this.authService.showAlert(res.message)
        }
        this.checkPostal = true;
      })
    }
  }
  viewPostalReciepts() {
    this.PostalReceiptsDetails();
    this.showPostal = true;
  }
  getPostalReceiptsCount() {
    this.postalcount = this.postalReceiptData.length;
    if (this.postalcount > 0) {
      this.postalCountCheck = true;
    }
    else {
      this.postalCountCheck = false;
    }
  }

  /*Additional Doc functions*/
  AdditionalDocDetails() {
    var obj = { docketNo: this.docketNo }
    this.authService.checkDocumentStatus(obj).subscribe(res => {
      this.additionalDocData = res.data.other;
      if (this.additionalDocData.length > 0) {
        this.getAdditionalDocCount();
      }
    }
    )
  }
  onFileChangeAdditionalDocs(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.toBase64(file).then(result => {
        this.additionalDocs.push({ 'fileName': file.name, 'fileType': file.type, 'base64': result });
      });

    }
  }
  submitAdditionalDocs() {
    if (this.additionalDocs.length == 0) {
      return null;
    }
    this.isLoading = true
    var obj = { docketNo: this.docketNo, type: 'other', files: this.additionalDocs, desc: 'other' }
    this.authService.eVerifiedUpload(obj).subscribe(res => {
      this.isLoading = false;
      this.additionalDocs.length = 0; /*flush the previous uploads from arrayr*/
      if (res.success) {
        this.authService.showAlert(res.message)
      }
      this.checkAdditional = true;
    })
  }
  viewAdditionalDocs() {
    this.AdditionalDocDetails();
    this.showAdditional = true;
  }
  getAdditionalDocCount() {
    this.AdditionalDocCount = this.additionalDocData.length;
    if (this.AdditionalDocCount > 0) {
      this.AdditionalDocCountCheck = true;
    }
    else {
      this.AdditionalDocCountCheck = false;
    }
  }

  /*exit function*/
  closepbe() {

    this.router.navigate(['/pbe']);

  }
  closeexport() {
    this.router.navigate(['/exportProof/1']);
  }


  pbe_rbi_exportInvoiceView(doctype, fileName) {
    this.isLoading = true
    if (doctype && fileName) {
      this.authService.getAllExportDocRequest({ docketNo: this.docketNo, type: doctype, fileName: fileName }).subscribe(res => {
        if (res.success == true) {
          const blob = new Blob([new Uint8Array(res.data.data)], { type: fileName.includes(".pdf") ? "application/" + fileName.split('.')[1] : "image/" + fileName.split('.')[1] })
          var url = URL.createObjectURL(blob);
          this.isLoading = false
          window.open(url, '_blank')
        }
      });
    } else {

    }
  }
  additionalView(id) {
    id = id.fileName;
    this.authService.viewDoc({ fileName: id }).subscribe(res => {
      if (res.success == true) {
        const blob = new Blob([new Uint8Array(res.link.data)], { type: id.includes(".pdf") ? "application/" + id.split('.')[1] : "image/" + id.split('.')[1] })
        var url = URL.createObjectURL(blob);
        this.isLoading = false
        window.open(url, '_blank')
      }
    });

  }
}
