<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<style>
    @media print {
        #frmmm {
            display: none
        }

        #frmmmmm {
            display: block
        }

        html,
        body {
            height: 100%;
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
            font-family: 'Bree Serif', serif !important;
        }

        .lg {
            width: 100%
        }

        body {
            padding-left: 0%;
            padding-right: 0%
        }

        table.details {
            border: 2px solid #000;
            width: 100%;
            height: 29.6926cm;
            border-collapse: collapse;
        }

        .pi_heading {
            text-align: center
        }

        .pi_heading1>td {
            padding: 5px
        }

        .pi_heading h5 {
            line-height: 30px;
            margin: 0px;
            font-size: 20px;
        }

        .pi_heading1>td>h4 {
            margin: 0px;
        }

        .pi_heading2 {
            text-align: left
        }

        .logo_heading {
            width: 100%
        }

        /* .logo_heading img {
                  height: 30px
              } */
        .logo_com img {
            float: left
        }

        .logo_prod img {
            float: right;
            margin-right: 40px;
            transform: scale(1.8);
        }

        .logo_prod,
        .logo_com {
            width: 50%
        }

        /* table.details {
                 
              } */
        table.details th,
        table.details td {
            border: 1px solid black;
        }

        .pi_heading1>td>p {
            margin: 0px;
        }

        .pi_heading1>td.order_row {
            width: 33.33333%;
        }

        input,
        textarea {
            border: none;
        }


    }


    table.details {
        border: 2px solid #000;
        width: 100%
    }

    .pi_heading {
        text-align: center
    }

    .logo_heading {
        width: 100%
    }

    /* .logo_heading img {
              height: 30px
          } */
    .logo_com img {
        float: left
    }

    .logo_prod img {
        float: right;
        margin-right: 40px;
        transform: scale(1.8);
    }

    .lg {
        width: 100%;
        padding: 20px
    }

    .logo_com {
        width: 50%
    }

    .pi_heading2 {
        text-align: left
    }


    table.details {
        border-collapse: collapse;
        border: 2px solid black;
    }

    .logo_prod,
    .logo_com {
        width: 50%;
    }

    .pi_heading h5 {
        line-height: 30px;
        margin: 0px;
        font-size: 20px;
    }

    table.details th,
    table.details td {
        border: 1px solid black;
    }

    .pi_heading1>td {
        padding: 5px;
    }

    .pi_heading1>td>h4 {
        margin: 0px;
    }

    .pi_heading1>td>p {
        margin: 0px;
    }

    .piTable>td {
        font-weight: 700;
    }

    input,
    textarea {
        padding: 0 5px;
        border: 1px solid #eee;
    }

    input {
        width: 100%
    }

    @page {
        size: A4;
        margin: 5mm 7mm 7mm 7mm;
    }

    strong {
        font-weight: 700;
    }
</style>
<form action="" name="invoiceForm" id="frmmm">
    <div class="checkout">
        <div class="container" style="margin-bottom:20px;margin-top:20px;">
            <div class="col-md-10 m-auto">
                <!-- <button class="btn btn-info" (click)=print()>Print</button> -->

                <!-- <h5 *ngIf="!invoiceDetails">
          {{errmsg}}
      </h5> -->
                <!-- <div class="row" style="text-align: center">
    <button class="btn btn-primary" (click)="printInvoice()">
      <i class="fa fa-print"></i>
      Print
    </button>
    <button class="btn btn-primary" (click)="download()">
      <i class="fa fa-download"></i>
      Download
    </button>
  </div> -->

                <div id="#inv">
                    <table class="lg">
                        <tbody>
                            <tr class="logo_heading">
                                <td class="logo_com">
                                    <span style="font-size:20px; font-weight:600"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                        <table class="details" style="margin-top:20px">
                            <tbody>
                                <tr class="pi_heading">
                                    <td colspan="14">
                                        <h5 style="text-transform:uppercase;font-weight: 700">
                                            <span>Tax Invoice</span>
                                        </h5>
                                    </td>
                                </tr>



                                <tr class="pi_heading1" *ngIf="itemList?.length>0">
                                    <td colspan="9" rowspan="2" style="text-align:left">

                                        <h4>Exporter Details</h4>
                                        <br />
                                        <h5 style="font-weight: 700">
                                            {{itemList[0].exporterName}}
                                        </h5>
                                        <p>
                                            <strong>{{itemList[0].exporterAddress}}</strong>
                                            <br />
                                            <strong>India</strong>
                                        </p>
                                        <br />
                                        <p>
                                            <span><strong>IEC of Exporter</strong> : {{itemList[0].ieCode}}</span>
                                            <br>
                                            <span>
                                                <strong>GSTIN Number</strong>: {{itemList[0].gstin}}</span>
                                        </p>

                                    </td>
                                    <td colspan="3" style="text-align: left">

                                        <h4>Consignee Details</h4>
                                        <br />
                                        <strong>Name : </strong>{{itemList[0].consigneeName}}<br />
                                        <strong>Address :</strong>{{itemList[0].consigneeAddress}}<br />
                                        <strong>City :</strong>{{itemList[0].consigneeCity}}<br />
                                        <strong>ZipCode :</strong>{{itemList[0].consigneeZip}}<br />
                                        <strong>Country :</strong>{{itemList[0].consigneeCountry}}

                                    </td>

                                    <td colspan="2" style="text-align: left">
                                        <form class="md-form" action="#">
                                            <div class="file-field big-2" style="text-align: center">
                                                <img src="{{imgUrl}}" style="max-width:140px">
                                                <h3 *ngIf="errMsg">{{errMsg}}</h3>
                                            </div>
                                        </form>

                                    </td>

                                </tr>
                                <tr class="pi_heading1" *ngIf="data?.length>0">
                                    <td colspan="5" style="text-align: left" rowspan="1">



                                        <div style="width:100%;text-align: left">
                                            <h4>Order Details</h4>

                                            <div style="width:30%;float:left"><span><strong>Invoice No.</strong></span>
                                            </div>
                                            <div style="width:55%;float:left;"><span>{{data[0].invoiceNo}}</span></div>

                                            <div style="width:30%;float:left"><span><strong>Invoice Date</strong></span>
                                            </div>
                                            <div style="width:55%;float:left">
                                                <span>{{convertDate(data[0].invoiceDate)}}</span>
                                            </div>

                                            <div style="width:30%;float:left"><span><strong>Mode of GST
                                                        Payment</strong></span></div>
                                            <div style="width:55%;float:left;margin-bottom:1%">

                                                <select [(ngModel)]="gstMode" [ngModelOptions]="{standalone: true}">
                                                    <option value="Not Applicable">Not Applicable</option>
                                                    <option value="Export with Payment of IGST">Export with Payment of
                                                        IGST</option>
                                                    <option value="Export under LUT/Bond">Export under LUT/Bond</option>
                                                </select>
                                                <!-- <span><input type="text" [(ngModel)]="gstMode" [ngModelOptions]="{standalone: true}"></span> -->
                                            </div>

                                            <div style="width:30%;float:left"><span><strong>Sales Basis</strong></span>
                                            </div>
                                            <div style="width:55%;float:left;margin-bottom:1%">
                                                <select [(ngModel)]="salesBasis" [ngModelOptions]="{standalone: true}">
                                                    <option value="Advance Payment">Advance Payment</option>
                                                    <option value="Payment term 15 days">Payment term 15 days</option>
                                                    <option value="Payment term 30 days">Payment term 30 days</option>
                                                    <option value="Payment term 90 days">Payment term 90 days</option>
                                                </select>
                                                <!-- <span><input type="text" [(ngModel)]="salesBasis" [ngModelOptions]="{standalone: true}"></span> -->
                                            </div>
                                            <div style="width:30%;float:left;margin-bottom:1%"><span><strong>Transaction
                                                        Id</strong></span></div>
                                            <div style="width:55%;float:left;"><span><input type="text"
                                                        [(ngModel)]="transactionId"
                                                        [ngModelOptions]="{standalone: true}"></span></div>
                                            <div style="clear:both"></div>
                                            <div style="width:30%;float:left;margin-bottom:1%"><span><strong>Order
                                                        Id</strong></span></div>
                                            <div style="width:55%;float:left;"><span><input type="text"
                                                        [(ngModel)]="orderid"
                                                        [ngModelOptions]="{standalone: true}"></span></div>

                                        </div>


                                    </td>

                                </tr>


                                <tr class="pi_heading1 piTable" *ngIf="data?.length>0">
                                    <td>S.No.</td>
                                    <td colspan="5">Description Of Goods</td>
                                    <td colspan="5">HSN Code</td>
                                    <td>Qty.</td>
                                    <td>Rate</td>
                                    <td>Amount <br>({{data[0].paymentDetails.currency}})</td>
                                    <!-- <td style="text-align: center">IGST Rate</td>
            <td style="text-align: center">IGST Amount <br/> ({{data[0].paymentDetails.currency}})</td> -->
                                </tr>

                                <tr class="pi_heading1" *ngFor="let item of itemList;let i=index">

                                    <td><input type="text" name="" id="" [(ngModel)]="item.serialNo"
                                            [ngModelOptions]="{standalone: true}"></td>
                                    <td colspan="5"><input type="text" name="" rows="1" id=""
                                            [(ngModel)]="item.description" [ngModelOptions]="{standalone: true}"></td>
                                    <td colspan="5"><input type="text" name="" id="" [(ngModel)]="item.hsnCode"
                                            [ngModelOptions]="{standalone: true}"></td>
                                    <td>
                                        <div style="width:100%;display: flex">
                                            <div> <input type="text" name="" id="" [(ngModel)]="item.quantity"
                                                    [ngModelOptions]="{standalone: true}"></div>
                                            <div> <input type="text" name="" id="" [(ngModel)]="item.unit"
                                                    [ngModelOptions]="{standalone: true}" style="border:none"></div>
                                        </div>

                                    </td>
                                    <td><input type="text" name="" id="" [(ngModel)]="item.rate"
                                            [ngModelOptions]="{standalone: true}"></td>
                                    <td><input type="text" name="" id="" [(ngModel)]="item.amount"
                                            [ngModelOptions]="{standalone: true}"></td>
                                    <!-- <td><input type="text" name="" id=""  [(ngModel)]="item.igst"   [ngModelOptions]="{standalone: true}" (ngModelChange)="quantityChange('rate',i)"></td>
              <td><input type="text" name="" id="" disabled [ngModel]="item.igstAmount | number:'1.2-2'" [ngModelOptions]="{standalone: true}"></td> -->

                                </tr>


                                <tr class="pi_heading1" *ngIf="data?.length>0">
                                    <td colspan="9" rowspan="5" style="border-bottom:1px solid #fff">

                                        <textarea rows="3" placeholder="Amount In Words"
                                            style="width:100%; resize: none;" [(ngModel)]="amountInWrd"
                                            [ngModelOptions]="{standalone: true}"></textarea>

                                    </td>
                                    <td colspan="3" style="text-align: right" rowspan="1">
                                        <p>

                                            Total Amount ({{data[0].paymentDetails.currency}})

                                        </p>
                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">
                                        <p>
                                            <strong>
                                                <input type="text" [(ngModel)]="totalAmount"
                                                    [ngModelOptions]="{standalone: true}">
                                            </strong>
                                        </p>

                                    </td>
                                </tr>

                                <tr class="pi_heading1" style="height:20px">

                                    <td colspan="3" style="text-align: right;border-right:1px solid #fff" rowspan="1">

                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">

                                    </td>
                                </tr>

                                <tr class="pi_heading1">
                                    <td colspan="3" style="text-align: right" rowspan="1">
                                        <p>

                                            GST Rate


                                        </p>
                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">
                                        <p>
                                            <strong>

                                                <input type="text" [(ngModel)]="igstTotal"
                                                    [ngModelOptions]="{standalone: true}">

                                            </strong>
                                        </p>

                                    </td>
                                </tr>

                                <tr class="pi_heading1">
                                    <td colspan="3" style="text-align: right" rowspan="1">
                                        <p>


                                            GST Amount

                                            (INR)

                                        </p>
                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">
                                        <p>
                                            <strong>
                                                <input type="text" [(ngModel)]="paidAmount"
                                                    [ngModelOptions]="{standalone: true}">
                                            </strong>
                                        </p>

                                    </td>

                                </tr>
                                <tr class="pi_heading1">

                                    <td colspan="3" style="text-align: right" rowspan="1">

                                        <input type="text" [(ngModel)]="descHeading" placeholder="Add Description1"
                                            style="width:60%" [ngModelOptions]="{standalone: true}">

                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">
                                        <p>
                                            <strong>
                                                <textarea rows="2" style="width:100%;padding:3px"
                                                    [(ngModel)]="description" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Value"></textarea>
                                            </strong>
                                        </p>

                                    </td>

                                </tr>

                                <tr class="pi_heading1">
                                    <td colspan="9"></td>
                                    <td colspan="3" style="text-align: right" rowspan="1">

                                        <input type="text" [(ngModel)]="valueHeading" placeholder="Add Description2"
                                            style="width:60%;padding:3px" [ngModelOptions]="{standalone: true}">

                                    </td>
                                    <td colspan="2" style="text-align: left" rowspan="1">
                                        <p>
                                            <strong>
                                                <textarea rows="2" style="width:100%;padding:3px"
                                                    [(ngModel)]="descValue" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Value"></textarea>
                                            </strong>
                                        </p>

                                    </td>

                                </tr>

                                <!-- <tr class="pi_heading1">
          <td colspan="8" style="font-weight: 700;text-align:center">
              Invoice Reference No: 4654654654</td>
          <td colspan="6">
              <span style="font-weight: 700"> Date: </span>
              <span>20/12/2017</span>
          </td>
          </tr> -->
                                <tr class="pi_heading1">

                                    <td colspan="14">
                                        <br />
                                        <div class="form-group row">
                                            <label for="inputEmail3MD" class="col-sm-3 col-form-label">Heading (e.g
                                                Declaration)</label>
                                            <div class="col-sm-6">
                                                <div class="md-form mt-0">
                                                    <input type="text" style="width:100%" [(ngModel)]="heading"
                                                        [ngModelOptions]="{standalone: true}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputEmail3MD" class="col-sm-3 col-form-label">Extra
                                                Information</label>
                                            <div class="col-sm-6">
                                                <div class="md-form mt-0">
                                                    <textarea rows="3" style="width:100%" [(ngModel)]="extraInfo"
                                                        [ngModelOptions]="{standalone: true}"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    <!-- <td colspan="5" style="text-align:right">

                  <br><strong>Signature & Date</strong>
        
              </td> -->
                                </tr>

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center">
            <button (click)="printOrder('view')" class="btn btn-outline-primary mt-3 ">Generate</button>
            <!-- <button (click)="sendData()" class="btn btn-outline-primary mt-3 ">Download</button>  -->
        </div>
    </div>
</form>

<p id="frmmmmm" style="display: none">
    This feature is not available with your current plan
</p>

<!-- 

<div class="col-md-10 m-auto text-center" style="margin-bottom:5%;">
<br><strong>Signature & Date</strong>
</div> -->