<div class="container">
    <div class="card" style="margin-top:3%;margin-bottom:5%">
        <div class="card-body">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-md-4">
                        <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                            <button class="btn btn-block btn-outline-orange btn-lg" type="button"
                                (click)="scanBarcode('enter')">
                                <i class="fa fa-sign-in"></i> &nbsp;Enter</button>
                        </div>
                    </div>
                    <div class="col-sm-2" style="text-align: center;line-height:100px">
                        OR
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                            <button class="btn btn-block btn-outline-primary btn-lg" type="button"
                                (click)="scanBarcode('scan')">
                                <i class="fa fa-barcode"></i> &nbsp;Scan</button>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-4">
            <input type="text" class="form-control" [(ngModel)]="code" *ngIf="isScan" placeholder="Enter Parcel Id">
            <input type="text" class="form-control" [(ngModel)]="code" (change)="scanParcel()" *ngIf="!isScan"
                placeholder="Scan Barcode">
        </div>
        <div class="col-sm-2">
            <!-- <button *ngIf="isScan" class="btn btn-sm btn-primary btn-block" style="height:39px" (click)="addFieldValue()"><i class="fa fa-plus"></i></button> -->
            <button class="btn btn-sm btn-danger btn-block" style="height:39px" (click)="scanParcel()"
                *ngIf="isScan">Submit</button>
        </div>
        <div class="col-sm-3"></div>

    </div>

    <!-- <div class="col-sm-12" style="margin-top:3%;text-align: center">
        <table class="table table-bordered m-b-0 text-center"  *ngIf="fieldArray?.length>0">
            <thead style="background:#59698d;color:#fff">
                <tr>
                  <th>Code</th>
                  <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of fieldArray; let i = index">
                    <td>
                        <input value="{{c}}" class="form-control" type="text" style="border:none;outline:none" />
                    </td>
                    <td>
                        <button class="btn btn-sm btn-danger btn-outline"  type="button" (click)="deleteFieldValue(i)"><i class="fa fa-minus"></i></button>
                    </td>
                </tr>
               
            </tbody>
        </table>

        <button class="btn btn-lg btn-outline-danger" *ngIf="fieldArray?.length>0" (click)="scanParcel()">Submit</button>

    </div> -->

    <div class="alert alert-info" role="alert" style="margin-top:5%" *ngIf="isSuccess">
        {{msg}}
    </div>

    <div class="alert alert-danger" role="alert" style="margin-top:5%" *ngIf="notSuccess && msg.length>0">
        {{msg}}
    </div>

</div>