import { Component, OnInit } from '@angular/core';
import { SocketService } from '../../services/socket.service';
declare function unescape(p: any);
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var Highcharts: any;

@Component({
  selector: 'app-newhome',
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.css']
})
export class NewhomeComponent implements OnInit {

  b
  isSafari = false;
  // API example variable to display code through interpolation
  api_example: string = '{\n"invoiceNo": "", "goodsDetails": [ {} ]\n}'

  // hover images
  register: string = '../../../assets/login_register/login_2_1.png'
  ficci_icon: string = '../../../assets/NewImages/Backup/ficci.png'
  digilocker_icon: string = '../../../assets/NewImages/Backup/digiLocker.png'
  login_icon: string = '../../../assets/login_register/Register_white.png'
  google_icon: string = '../../../assets/NewImages/get_1.png'
  apple_icon: string = '../../../assets/NewImages/available_1.png'
  google_phone_icon: String = '../../../assets/NewImages/google_phone.png'
  apple_phone_icon: String = '../../../assets/NewImages/apple_phone.png'
  //navbar
  hideNavbar = true;
  route: string;
  isAdModal = false
  trackingData = ''
  trackingId = ''
  isTracking = false
  // isSafari = false

  map_data: any = [
    {
      name1: 'Delhi',
      lat: 28.7041,
      lon: 77.1025,
      color: "rgb(255, 4, 104)"
    },

    {
      name1: 'Kolkata',
      lat: 22.5726,
      lon: 88.3639,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Srinagar',
      lat: 34.083656,
      lon: 74.797371,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Chennai',
      lat: 13.0827,
      lon: 80.2707,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Mumbai',
      lat: 18.5760,
      lon: 73.0777,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Mumbai Airport (APSO)',
      lat: 19.0896,
      lon: 72.8656,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Jaipur',
      lat: 26.9124,
      lon: 75.7873,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Ludhiana',
      lat: 30.9010,
      lon: 75.8573,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Agra',
      lat: 27.1767,
      lon: 78.0081,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Kochi',
      lat: 9.9312,
      lon: 76.2673,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Bangalore',
      lat: 12.9716,
      lon: 77.5946,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Bhopal',
      lat: 23.2599,
      lon: 77.4126,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'Ahmedabad',
      lat: 23.0225,
      lon: 72.5714,
      color: "rgb(255, 4, 104)"
    },
    {
      name1: 'FPO Raipur',
      lat: 21.2514,
      lon: 81.6296,
      color: "rgb(255, 4, 104)"
    }
  ]

  iconHover(icon_type) {
    // change icons if you hover
    if (icon_type === 'login') {
      this.register = '../../../assets/login_register/login_2.png'
    }
    else if (icon_type === 'ficci') {
      this.ficci_icon = '../../../assets/NewImages/Backup/ficci_1.png'
    }
    else if (icon_type === 'digilocker') {
      this.digilocker_icon = '../../../assets/NewImages/Backup/digiLocker_1.png'
    }
    else if (icon_type === 'register') {
      this.login_icon = '../../../assets/login_register/Register_black.png'
    }
    else if (icon_type === 'google') {
      this.google_icon = '../../../assets/NewImages/get.png'
    }
    else if (icon_type === 'apple') {
      this.apple_icon = '../../../assets/NewImages/available.png'
    }
  }

  iconOut(icon_type) {
    // revert icon back when you move mouse out
    if (icon_type === 'login') {
      this.register = '../../../assets/login_register/login_2_1.png'
    }
    else if (icon_type === 'ficci') {
      this.ficci_icon = '../../../assets/NewImages/Backup/ficci.png'
    }
    else if (icon_type === 'digilocker') {
      this.digilocker_icon = '../../../assets/NewImages/Backup/digiLocker.png'
    }
    else if (icon_type === 'register') {
      this.login_icon = '../../../assets/login_register/Register_white.png'
    }
    else if (icon_type === 'google') {
      this.google_icon = '../../../assets/NewImages/get_1.png'
    }
    else if (icon_type === 'apple') {
      this.apple_icon = '../../../assets/NewImages/available_1.png'
    }
  }

  // display india chart with particular data
  displayIndiaChart() {
    // Create the chart
    Highcharts.mapChart('india-map', {
      chart: {
        map: 'countries/in/custom/in-all-disputed',
        reflow: false,
        margin: [0, 0, 0, 0],
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },
      title: {
        text: ''
      },
      responsive: {
        rules: [{
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      series: [
        {
          name: 'Basemap',
          borderColor: 'transparent',
          nullColor: 'rgba(0, 0, 0, 0.9)',
          showInLegend: false
        },
        {
          // Specify points using lat/lon
          type: 'mappoint',
          name: ' ',
          showInLegend: false,
          data: this.map_data
        }
      ],
      tooltip: {
        // pointFormat: '{point.name1}',
        useHTML: true,
        formatter: function () {
          return '<b>' + this.point.name1 + '</b>';
        }
      }
    });
  }


  constructor(public authService: AuthService, public router: Router, private nfs: NotificationService,
    public formBuilder: FormBuilder, private sanitizer: DomSanitizer, private socketService: SocketService) {

    // ========= safari ======
    this.b = navigator.userAgent
      .split(")")
      .reverse()[0]
      .match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0]
      .split("/");
    if (this.b && this.b.length > 0) {
      // alert(b[0])
      this.isSafari =
        this.b[0].toLowerCase().includes("safari") ||
          this.b[0].toLowerCase().includes("mobile")
          ? true
          : false;
    }
    // ====================


    if (this.authService.loggedIn()) {
      if (this.authService.isCbic() || this.authService.isCustomLoggedIn()) {
        this.router.navigate(['/pbe'])
      }
      else {
        // console.log("yha se aja rha h");

        this.router.navigate(['/home'])
      }
    }
    let b = navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/');
    if (b && b.length > 0) {
      // alert(b[0])
      this.isSafari = (b[0].toLowerCase().includes("safari") || b[0].toLowerCase().includes("mobile")) ? true : false
    }

    this.createForm()
  }

  ngOnInit() { }

  ngAfterViewInit() {
    // not sure why not working without timeout
    setTimeout(() => { this.displayIndiaChart() }, 500)
  }

  playVideo1() {
    // $('#vid1').trigger('play');
    // $('#ft').click()
    // (<HTMLDivElement>document.getElementById('ft')).click();
    let v1 = <HTMLVideoElement>document.getElementById("vid1");
    v1.muted = true;
    v1.autoplay = true;
    v1.play()
      .then((r) => {
        // // console.log(r)
        v1.onended = () => {
          this.router.navigate(["/login"]);
        };
      })
      .catch((e) => {
        // // console.log(e)
      });
  }

  playVideo2() {
    // $('#vid1').trigger('play');
    // $('#ft').click()

    let v2 = <HTMLVideoElement>document.getElementById("vid2");
    v2.muted = true;
    v2.autoplay = true;
    v2.play()
      .then((r) => {
        // // console.log(r)
        v2.onended = () => {
          // window.location.href = 'https://beta3.pikmybox.com/'
          window.location.href = environment.p2pUrl
        };
      })
      .catch((e) => {
        // // console.log(e)
      });
  }

  goto(type) {
    if (type == "pmb") {
      this.playVideo2();
    } else {
      this.playVideo1();
    }
  }

  getwidth() {
    return $(window).width()
  }

  // ts for contact-us form
  form: FormGroup;
  form1: FormGroup;

  createForm() {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        this.emailValidation
      ])],
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      cname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      mobile: ['', Validators.compose(
        [
          Validators.required,
          this.mobileValidation])
      ],
      message: ['', Validators.compose(
        [
          Validators.required,
          Validators.minLength(10)])
      ],
    });

    this.form1 = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        this.emailValidation
      ])],
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      cname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      mobile: ['', Validators.compose(
        [
          Validators.required, this.mobileValidation])
      ],
      message: ['', Validators.compose(
        [
          Validators.required, Validators.minLength(10)])
      ],
    })
  }


  mobileValidation(control) {
    if (control.value == null || control.value == "" || control.value == undefined) {
      return { 'mobileNull': false };
    }
    else {
      var mobile = control.value.toString();
      var regexMobile = /^\d{10}(,\d{10})*$/;
      if (mobile.match(regexMobile)) {
        return { 'mobile': true };
      }
      else {
        return { 'mobile': false };
      }
    }

  }

  emailValidation(control) {
    if (control.value == null || control.value == "") return null;
    var email = control.value;
    var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/i;
    if (email.match(regexEmail)) {
      return { 'email': true };
    }
    else {
      return { 'email': false };
    }
  }

  getQuote(type) {
    this.form.get('message').setValue(type)
  }

  submitForm(formVal) {
    this.authService.contactFormEmail(formVal).subscribe(data => {
      if (data.success) {
        this.nfs.showInfo('Success', data.message, 'success');
        this.form.reset()
      }
      else {
        this.nfs.showInfo('Error', data.message, 'error')
      }
    })
  }
  submitForm1(formVal) {
    this.authService.contactFormEmail(formVal).subscribe(data => {
      if (data.success) {
        this.nfs.showInfo('Success', data.message, 'success');
        this.form.reset()
      }
      else {
        this.nfs.showInfo('Error', data.message, 'error')
      }
    })
  }


  //ts for navbar
  onLogoutClick() {
    this.nfs.showConfirmation(
      'Are you sure?',
      "You want to logout!",
      'warning',
      'Yes, Logout',
    ).then((result) => {
      if (result.value) {
        var userId = this.authService.getuserProfile().userId
        localStorage.removeItem('token');
        this.socketService.logout({ userId: userId }).subscribe(response => {
          this.router.navigate(['/'])
        });
        this.router.navigate(['/'])
      }
    })

  }

  trackShipment() {
    this.trackingId = this.trackingId.replace(/\s/g, '');
    this.trackingId = this.trackingId.toUpperCase();
    if (this.trackingId.length > 12 && this.trackingId.length < 15) {
      this.isTracking = true;
      this.trackingData = '<h5 class="text-center"><i class="fa fa-spinner fa-spin text-primary" style="font-size:30px" ></i>&nbsp; Please wait while we get tracking information for ' + this.trackingId + '</h5>'
      this.authService.trackshipment({ trackingId: this.trackingId }).subscribe(d => {
        if (d.success) {
          if (unescape(d.data) == null || unescape(d.data) == 'null' || unescape(d.data) == '') {
            this.trackingData = '<h5 class="text-center">No tracking info. available for tracking Id <b>' + this.trackingId + '</b></h5>'
          }
          else {
            this.trackingData = unescape(d.data);
            setTimeout(() => {
              if (!$('#trackShipment table').hasClass('table'))
                $('#trackShipment table').addClass('table table-bordered text-center table-striped')
            }, 500);
          }
          // // console.log(this.trackingData)
        }
        else {
          this.nfs.showInfo('Error', d.message, 'error')
        }

      })
    }
    else {
      this.nfs.showInfo('Error', 'Invalid Tracking No.', 'error')
    }
  }
  trackMore() {
    this.isTracking = false;
    this.trackingId = ''
    this.trackingData = ''
  }

  openModal() {
    this.isAdModal = true
    setTimeout(() => {
      var i = <HTMLIFrameElement>document.getElementById('adIframe')
      i.contentDocument.location.reload();
      $('#adCodeReg').modal()

    }, 500);
  }

  closeModal() {
    $('#adCodeReg').modal('hide')
    this.isAdModal = false
  }

  payForAdCodeReg() {
    this.nfs.showConfirmation(
      'Are you sure?',
      "Do you want to make payment of Rs. 2000 + 18% GST for AD Code Registration, once paid it can't be refunded!",
      'warning',
      'Confirm Payment',
    ).then((result) => {
      if (result.value) {
        this.authService.getReq('lookup/payforadCodeReg').subscribe((res) => {
          if (res.success) {
            this.nfs.showInfo('Success!', res.message, 'success');
            var i = <HTMLIFrameElement>document.getElementById('adIframe')
            i.src = 'https://www.pikmybox.com/api/v1/adCoderegiframe'
          }
          else {
            this.nfs.showInfo('Oops!', res.message, 'error');
          }
        })
      }
    })
  }



}

