import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-hsnlookup',
  templateUrl: './hsnlookup.component.html',
  styleUrls: ['./hsnlookup.component.css']
})
export class HsnlookupComponent implements OnInit {

  countries: any = [];
  hsnData: any = [];
  searchItem: any = '';
  group: any = '0';
  isSearch: boolean = false;
  previous: any = "";
  isLoading = false;
  constructor(public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
    this.getCountries();
    this.getItemLookUp();
  }

  goback() {
    if (this.previous == "all" || this.previous == undefined) {
      this.getItemLookUp();
      this.previous = "";
      return;
    }

    let obj = { catCode: this.previous, isSearch: this.isSearch };
    this.authService.getMainCat(obj).subscribe(data => {

      if (data.success == true) {
        if (data.data.length != 0) {
          this.hsnData = data.data;
          this.previous = data.catCode;
        }
        else {
          this.nfs.showInfo('info', 'No More Data', 'info');
        }
      }

      else {
        this.nfs.showInfo('error', data.message, 'error');
      }
    })

  }

  getCountries() {
    this.countries = []
    this.authService.getCountries({}).subscribe((res) => {
      if (res.success == true) {
        if (res.data.length != 0) {
          this.countries = res.data;
        }
        else {
          this.nfs.showInfo('Oops !!', res.message, 'info');
        }
      }
      else {
        this.nfs.showInfo('error', res.message, 'error');
      }
    })
  }


  getItemLookUp() {
    this.hsnData = [];
    this.isLoading = true;
    this.authService.getItemLookUp().subscribe(data => {

      if (data.success == true) {
        this.isLoading = false;
        this.hsnData = data.data;
      }

      else {
        this.isLoading = false;
        this.nfs.showInfo('error', data.message, 'error');
      }
    })
  }

  reset() {
    this.previous = [];
    this.isSearch = false;
    this.getItemLookUp();
  }

  back() {

  }

  getMainCat(code) {
    // var d=this.previous.filter(c=>{c.length==code.length})
    // if(d>0)
    // {
    //   if(this.previous.includes(code)>-1)
    //   {
    //     this.previous.splice(code,1);
    //   }
    //   this.previous.push(code);
    // }
    // else
    // {
    //   this.previous.push(code);
    // }
    // // this.previous.push(code)
    // // console.log(this.previous);

    this.isLoading = true;
    this.hsnData = [];
    this.isSearch = false;
    let obj = { catCode: code, isSearch: this.isSearch };
    // console.log(obj)
    this.authService.getMainCat(obj).subscribe(data => {
      this.isLoading = false;
      if (data.success == true) {
        this.previous = data.catCode;
        if (data.data.length != 0) {
          this.hsnData = data.data;
        }
        else {
          this.isLoading = false;
          this.nfs.showInfo('info', 'No More Data', 'info');
        }
      }

      else {
        this.nfs.showInfo('error', data.message, 'error');
      }
    })
  }

  getCountry(val) {
    this.group = val.target.value;
  }


  getHSNRecord() {
    this.isLoading = true;
    if (this.searchItem.length < 3 || this.searchItem == undefined || this.searchItem == '') {
      this.nfs.showInfo('info', 'Please enter min 3 characters to search!.', 'info');
      return
    }
    else {
      this.hsnData = [];
      this.isSearch = true;
      const pattern = /[^A-Za-z0-9]/
      var str = this.searchItem.replace(/[^a-zA-Z ]/g, "");
      var newstr = str.replace(/ /g, '');
      if (!pattern.test(newstr)) {
        let obj = { query: newstr, group: this.group, isSearch: this.isSearch };
        this.authService.getMainCat(obj).subscribe(data => {
          this.isLoading = false;
          if (data.success == true) {
            if (data.data.length != 0) {
              this.hsnData = data.data;
            }
            else {
              this.isLoading = false;
              this.nfs.showInfo('info', 'No Record Found', 'info');

            }
          }

          else {
            this.isLoading = false;
            this.nfs.showInfo('error', data.message, 'error');
          }
        })
      }
      else {
        this.isLoading = false;
        this.getItemLookUp();
        this.nfs.showInfo('Oops !!', 'Do not use special characters', 'info');
      }
    }
  }


}
