import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-serviceprovider',
  templateUrl: './serviceprovider.component.html',
  styleUrls: ['./serviceprovider.component.css']
})
export class ServiceproviderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
