import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  allData: any = [];
  constructor(public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
    this.getResourses();
  }


  getResourses() {
    this.authService.gethelpfulresources().subscribe(response => {
      if (response.success == true) {
        this.allData = response.data;
        // console.log(this.allData);
      }
      else {
        this.nfs.showInfo('error', response.messgae, 'error');
      }
    })
  }

  view(fName, isVideo) {

    if (fName == undefined || fName == "") {
      this.nfs.showInfo('Oops!!', 'No file to view', 'info');
      return;
    }

    if (isVideo) {
      // window.open(fName,'_blank');
    }
    else {
      var isResource = true
      this.authService.viewDoc({ fileName: fName, isResource: isResource }).subscribe(res => {
        if (res.success == true) {
          var l = fName.split('.');
          var filetype = this.getFileType(l[l.length - 1]);
          // // console.log(this.getFileType(l[l.length-1]))
          const blob = new Blob([new Uint8Array(res.link.data)], { type: filetype })
          var url = URL.createObjectURL(blob);
          window.open(url, '_blank')
        }
        else {
          this.nfs.showInfo('error', res.messgae, 'error');
        }
      });

    }

  }


  getFileType(signature) {
    switch (signature) {
      case 'doc':
        return 'application/msword'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      case 'pdf':
        return 'application/pdf'
      case 'xls':
        return 'application/vnd.ms-excel'
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      default:
        return 'image/' + signature
    }
  }

}
