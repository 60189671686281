import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.css']
})
export class ApiComponent implements OnInit {
  apiKey;
  circularObj = {}
  sampleData = {}
  categories: any = {};

  buttonText = "copy"
  constructor(public auth: AuthService, private nfs: NotificationService) {
    this.getYourApi()
  }

  ngOnInit() {

    this.getCategories();
    this.circularObj = {
      "authKey": "Your API key Here",
      "orderData": [
        {
          "desc": "Product/Item Description",
          "hsn": "HSN Code",
          "uqc": "Unit of measurement",
          "nos": "Quantity of Product/Item",
          "amountPerPiece": "Amount per Item/Prod. In F.C.",
          "compensationCess_rate": "Compensation Cess Rate in %",
          "igstDetails_rate": "IGST rate applied in %",
          "customDuties_Rate": "Export duty rate in %",
          "customDuties_CessRate": "Customm Duty cess in %",
          "netWeight": "Net Weight in gms.",
          "grossWeight": "Gross weight in gms.",
          "url": "URL of E-Comm. site if sold by website",
          "parcelType": "Shipping Method",
          "txn": "Transaction Id in case of E-comm",
          "sku": "SKU no.",
          "consigneeName": "Name of Buyer",
          "consigneeAddress": "Street Address of buyer",
          "consigneeCountry": "Buyer Country",
          "consigneeEmail": "Buyer Email",
          "consigneeMobile": "Buyer Mobile",
          "consigneeBusinessName": "Buyer firm/Comp. Name",
          "consigneeImporterCode": "Buyer firm/Comp. Importer Code",
          "consigneeCity": "Buyer city",
          "consigneeZipCode": "Buyer ZIP Code",
          "fob_foreign": "Total Amount paid by customer in F.C.",
          "currency": "Currency Code in which customer has paid",
          "adName": "AD Name",
          "adAddress": "AD Address",
          "adCode": "AD Code",
          "invoiceNo": "Invoice Number",
          "invoiceDate": "Invoice Date (E.g.- DD-MM-YYYY)",
          "invoiceAmount": "Invoice Amount in INR",
          "serialNo": "Serial no. of item in Invoice",
          "trackingNo": "Tracking No. (If Any)",
          "category": "Category of product",
          "boxId": "Box Id Eg: 12345",
        }
      ]
    }

    this.sampleData = JSON.stringify({
      "authKey": "2e67f579c3fdff6796fa19941c7f690774c3e48...",
      "orderData": [
        {
          "pbe": "pbe1",
          "description": "Mobile phone back cover",
          "packageDesc": "Package Desc",
          "otherCharge": "",
          "hsnCode": "1234",
          // "quantity_unit": "pieces",
          "unit": "pieces",
          "quantity": "10",
          "compensationCess_rate": "0",
          "igstDetails_rate": "0",
          "customDuties_CessRate": "0",
          "itemNetWeight": "700",
          "itemGrossWeight": "700",
          "exportDutyRate": "5",
          "freight": "20",
          "insurance": "20",
          "dutyCess_rate": "5",
          "grossWeight": "700",
          "category": "Handicraft",
          "url": "www.pikmybox.com",
          "parcelType": "Air Parcel (CD)",
          "trxnId": "PL78454898989",
          "orderId": "OD12345 ",
          "sku": "SK787854545",
          "consigneeName": "Mark Weins",
          "consigneeAddress": "95, Wagon street",
          "consigneeCountry": "Thailand",
          "consigneeState": "XYZ",
          "consigneeEmail": "weins.mark@pikmybox.com",
          "consigneeMobile": "7777777777",
          "consigneeBusinessName": "Migrationology.com",
          "consigneeImporterCode": "THIC454545",
          "consigneeCity": "Phuket",
          "consigneeZipCode": "56895",
          "fob_foreign": "900",
          "currency": "USD",
          "invoiceNo": "INV001",
          "invoiceDate": "25-01-2019",
          "invoiceTerms": "Advance Payment",
          "igstMode": "Not Applicable",
          "serialNo": "01",
          "boxId": "1456",
          "trackingNo": "PP47854787587IN"
        },
        {
          "pbe": "pbe2",
          "description": "laptop back cover",
          "packageDesc": "Package Desc",
          "otherCharge": "",
          "hsnCode": "12345",
          // "quantity_unit": "pieces",
          "unit": "pieces",
          "quantity": "10",
          "compensationCess_rate": "0",
          "igstDetails_rate": "0",
          "customDuties_CessRate": "0",
          "itemNetWeight": "700",
          "itemGrossWeight": "700",
          "exportDutyRate": "5",
          "freight": "20",
          "insurance": "20",
          "dutyCess_rate": "5",
          "grossWeight": "700",
          "category": "Handicraft",
          "url": "www.pikmybox.com",
          "parcelType": "Air Parcel (CD)",
          "trxnId": "PL78454898989",
          "orderId": "OD12345 ",
          "sku": "SK787854545",
          "consigneeName": "Mark Weins",
          "consigneeAddress": "95, Wagon street",
          "consigneeCountry": "Thailand",
          "consigneeState": "XYZ",
          "consigneeEmail": "weins.mark@pikmybox.com",
          "consigneeMobile": "7777777777",
          "consigneeBusinessName": "Migrationology.com",
          "consigneeImporterCode": "THIC454545",
          "consigneeCity": "Phuket",
          "consigneeZipCode": "56895",
          "fob_foreign": "900",
          "currency": "USD",
          "invoiceNo": "INV002",
          "invoiceDate": "25-01-2019",
          "invoiceTerms": "Advance Payment",
          "igstMode": "Not Applicable",
          "serialNo": "02",
          "boxId": "1455",
          "trackingNo": "PP47854787587IN"
        },
      ]
    }, undefined, 4)


  }

  goTo(id) {
    document.getElementById(id).click()
  }

  getYourApi() {
    this.auth.getAuthKey().subscribe(data => {
      if (data.success) {
        // // console.log(data.authKey)
        this.apiKey = data.authKey
      }
      else {
        this.apiKey = ''
      }
    })
  }

  genYourApi(n) {
    if (n == '0') {
      this.genKey()
    }
    else {
      this.nfs.showConfirmation(
        'Generate new API Key',
        "Are You sure want to generate a new API key, older key will no longer work after new API key.",
        'warning',
        'Yes',
      ).then((result) => {
        if (result.value) {
          this.genKey()
        }
      })
    }
  }

  genKey() {
    this.auth.genAuthKey().subscribe(data => {
      if (data.success) {
        // // console.log(data.authKey)
        this.apiKey = data.authKey;
        this.buttonText = 'copy'
        this.nfs.showInfo('API Key Generated', 'You have successfully generated API & ready to use.', 'success')
      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'error')
      }
    })
  }

  getCategories() {
    this.categories = []
    this.auth.getCategories().subscribe((res) => {
      if (res.success) {
        if (res.data.length != 0) {

          // const data = crypto.AES.decrypt(res.data.toString(),'secret key 123');
          this.categories = res.data;

          // // console.log(data.toString(crypto.enc.Utf8));

        }
        else {
          this.nfs.showInfo('Oops!', "No Currency Found", "info");
        }
      }
      else {
        this.nfs.showInfo('Oops!', "Some error occurred while fetching currencies", "error");
      }
    })
  }


  copyKeyToCP() {
    /* Get the text field */
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = this.apiKey;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;

    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
    this.buttonText = "Copied to clipboard!"
  }



}

