<div class="wrapper">
    <div class="container-fluid">


        <div class="row">

            <div class="col-md-9">
                <h5 _ngcontent-c3="" class="red-text text-left" style="font-weight: 700;">**Important Notice**</h5>
                <h5 class="red-text text-left" style="font-weight: 700;">Please refer to the following documents for
                    completing your MEIS form.</h5>
                <label class="red-text text-left" style="font-weight: 700; font-size: 14px">
                    1) For ITC (HS Code) and Product SI. No., <a class="link blue-text"
                        href="/assets/docs/MEISApp3BTable2.pdf" target="new"> refer here.</a>
                    <br> 2) For SI. No. of Export Country, <a class="link blue-text"
                        href="/assets/docs/MEISApp3BTable1_for_country(1).pdf" target="new">refer here.</a>
                    <br>3) For Sr. No. of Category of Export, <a class="link blue-text"
                        href="/assets/docs/Appendix_3c.pdf" target="new">refer here.</a>

                </label>
            </div>
        </div>


        <div class="card" style="margin-bottom: 15px">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text" class="active">Enter To search</label>
                            <input class="form-control ng-pristine ng-valid ng-touched" type="text"
                                [(ngModel)]="searchItem">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text">Select Status To Search</label>

                            <select class="form-control" (change)="getSearch($event.target.value)"
                                [(ngModel)]="selectedStatus">

                                <option value="All">All</option>
                                <option value="Pending Review">Pending Review</option>
                                <option value="Approved">Approved</option>
                                <option value="Under Review">Under Review</option>
                                <option value="Additional Documents Requested">Add. Docs Requested</option>
                                <option value="Rejected">Rejected</option>

                            </select>
                        </div>
                    </div>

                    <div class="col-md-6" style="margin: auto">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block btn-outline-default btn-block" type="button"
                                        (click)="getMeisData()">
                                        <i class="fa fa-eye"></i>&nbsp; View</button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block btn-outline-danger btn-block" type="button"
                                        (click)="reset()">
                                        <i class="fa fa-refresh"></i> &nbsp;Reset</button>
                                </div>
                            </div>

                            <div class="col-md-4" *ngIf="(selectedBoxIdsForPBE?.length>0)">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block  btn-outline-orange" type="button"
                                        (click)="downloadMeisExcelAfterUpload()">
                                        <i class="fa  fa-download"></i>&nbsp; Download</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 40%"></div>
        <table id="mainTable" class="table table-bordered m-b-0 text-center">
            <thead>
                <tr>
                    <th>
                        <input type="checkbox" [(ngModel)]="selectedAll" id="selectAll" (change)="selectAll();"
                            class="inp-cbx" style="display: none">
                        <label *ngIf="!authService.isAdmin()" for="selectAll" class="cbx">
                            <span style="    border-color: #fff;">
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        Sl. No.
                    </th>
                    <th>
                        MEIS Ref. No.
                    </th>
                    <th>
                        PBE No. & Date
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Entitlement Amount
                    </th>
                    <!-- <th>
              Amount
            </th> -->
                    <th>Upload Documents</th>
                    <th>Remarks</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody *ngIf="(orders?.length && !isLoading)">
                <tr *ngFor='let o of orders; let i = index'>
                    <td>
                        <input [checked]="(o.selected)" *ngIf="(!authService.isAdmin())" value="{{o.refNo}}"
                            type="checkbox" id="cbx{{i}}" (change)="selectBoxIds($event);" class="inp-cbx"
                            style="display: none">
                        <label *ngIf="(!authService.isAdmin())" for="cbx{{i}}" class="cbx">
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        {{i+1}}
                    </td>
                    <td>
                        {{o.refNo}}
                    </td>
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.orderDetails" class="brd">
                            {{k.shippingBillDetails.bill}} / {{k.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                        </div>
                    </td>
                    <!-- <td class="text-center" style="padding: 0">
                <div *ngFor="let k of o.orderDetails" class="brd">
                   {{k.shippingBillDetails.bill}} / {{k.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                </div>
            </td> -->
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.orderDetails" class="brd">
                            {{k.description}}
                        </div>
                    </td>
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.excelData;let i=index" class="brd">
                            {{k['Rate of entitlement as per Appendix 3B (Enter relevant rate as per Appendix 3B (Table
                            2))'] * o.orderDetails[i].paymentDetails.fob | currency:'INR':'code':'1.2-2'}}
                        </div>
                    </td>

                    <!-- 
            <td class="text-center" style="padding: 0">
                <div *ngFor="let k of o.orderDetails" class="brd">
                    {{k.paymentDetails.fob | currency:'INR':'code':'1.2-2'}}
                </div>
            </td> -->

                    <td>

                        <button (click)="getDocs(o)" *ngIf="o.editPermitted" class="btn btn-primary btn-sm"
                            aria-hidden="true" data-toggle="modal" data-target="#uploadDocuments"><i
                                class="fa fa-cloud-upload"></i>
                            Upload
                        </button>

                        <button (click)="getDocs(o)"
                            *ngIf="o.status=='Under Review' || o.status=='Pending Review' || o.status=='Approved' || o.status=='Rejected' "
                            class="btn btn-primary btn-sm" aria-hidden="true" data-toggle="modal"
                            data-target="#uploadDocuments"><i class="fa fa-eye-slash" aria-hidden="true"></i>
                            view
                        </button>

                        <button *ngIf="o.editPermitted" class="btn btn-success btn-sm"
                            (click)="sendMeisToDgft(o.refNo)"><i class="fa fa-send"></i>
                            Submit to DGFT
                        </button>



                    </td>

                    <td class="text-center" style="padding: 0">

                        <div *ngIf="o.remarks?.length>0">
                            <div *ngFor="let k of o.remarks" class="brd">
                                {{k.text}} / {{k.date | date:'dd-MM-yyyy'}}
                            </div>
                        </div>

                        <div *ngIf="o.remarks.length==0">
                            <div>
                                ----
                            </div>
                        </div>

                    </td>

                    <td style="padding: 0"
                        [ngClass]="{'red':(o.status=='Generated'),'orange':(o.status=='Under Review'),'yellow':(o.status=='Pending Review'),'green':(o.status=='Approved')}">
                        {{o.status}}
                    </td>

                </tr>
            </tbody>
        </table>

        <div class="row" *ngIf="isLoading">
            <div class="card-body">
                <div class="lds-css ng-scope">
                    <div class="lds-blocks" style="margin: auto">
                        <div style="left:38px;top:38px;animation-delay:0s">
                        </div>
                        <div style="left:80px;top:38px;animation-delay:0.125s">
                        </div>
                        <div style="left:122px;top:38px;animation-delay:0.25s">
                        </div>
                        <div style="left:38px;top:80px;animation-delay:0.875s"></div>
                        <div style="left:122px;top:80px;animation-delay:0.375s"></div>
                        <div style="left:38px;top:122px;animation-delay:0.75s"></div>
                        <div style="left:80px;top:122px;animation-delay:0.625s"></div>
                        <div style="left:122px;top:122px;animation-delay:0.5s"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- Central Modal Medium Danger -->
<div class="modal" id="uploadDocuments" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Upload Documents</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="selectedMeis">
                <table id="mainTable" class="table table-bordered m-b-0 text-center">
                    <thead>
                        <tr>
                            <td>Document Name</td>
                            <td>Status</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <th>Proof of Delivery</th>
                            <td *ngIf="selectedMeis.podStatus" class="text-success">Uploaded</td>
                            <td *ngIf="!selectedMeis.podStatus" class="text-warning">Pending</td>
                            <td>
                                <div *ngIf="selectedMeis.editPermitted">
                                    <input type="file" multiple (change)="changeListener($event)"
                                        accept="application/pdf, image/*">
                                    <button class="btn btn-sm btn-success" (click)="sendFiles('pod')"><i
                                            class="fa fa-send"></i></button>
                                </div>
                                <hr *ngIf="selectedMeis.podStatus && selectedMeis.status=='Generated'" />

                                <div *ngIf="selectedMeis.podStatus">
                                    <span *ngFor="let d of selectedMeis.pod">
                                        <!-- <button class="btn btn-sm btn-success"  (click)="viewDocument(d)"><i class="fa fa-eye"></i>&nbsp;View</button> -->
                                        <a class="btn btn-outline-success btn-sm" target="_blank"
                                            href="https://s3.ap-south-1.amazonaws.com/pikmybox-meis/{{d.fileName}}">View</a>
                                        <button *ngIf="selectedMeis.status=='Generated'" class="btn btn-danger btn-sm"
                                            (click)="deleteobject('pod',d)"><i class="fa fa-times"></i></button>
                                    </span>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <th>Proof of Payment</th>
                            <td *ngIf="selectedMeis.popStatus" class="text-success">Uploaded</td>
                            <td *ngIf="!selectedMeis.popStatus" class="text-warning">Pending</td>
                            <td>
                                <div *ngIf="selectedMeis.editPermitted">
                                    <input type="file" multiple (change)="changeListener($event)"
                                        accept="application/pdf, image/*">
                                    <button class="btn btn-sm btn-success" (click)="sendFiles('pop')"><i
                                            class="fa fa-send"></i></button>
                                </div>
                                <hr *ngIf="selectedMeis.popStatus && selectedMeis.status=='Generated'" />
                                <div *ngIf="selectedMeis.popStatus">
                                    <span *ngFor="let d of selectedMeis.pop">
                                        <a class="btn btn-outline-success btn-sm" target="_blank"
                                            href="https://s3.ap-south-1.amazonaws.com/pikmybox-meis/{{d.fileName}}">View</a>
                                        <button *ngIf="selectedMeis.status=='Generated'" class="btn btn-danger btn-sm"
                                            (click)="deleteobject('pop',d.fileName)"><i
                                                class="fa fa-times"></i></button>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Invoices</th>
                            <td *ngIf="selectedMeis.coiStatus" class="text-success">Uploaded</td>
                            <td *ngIf="!selectedMeis.coiStatus" class="text-warning">Pending</td>
                            <td>
                                <div *ngIf="selectedMeis.editPermitted">
                                    <input type="file" multiple (change)="changeListener($event)"
                                        accept="application/pdf, image/*">
                                    <button class="btn btn-sm btn-success" (click)="sendFiles('coi')"><i
                                            class="fa fa-send"></i></button>
                                </div>
                                <hr *ngIf="selectedMeis.coiStatus && selectedMeis.status=='Generated'" />
                                <div *ngIf="selectedMeis.coiStatus">
                                    <span *ngFor="let d of selectedMeis.coi">
                                        <a class="btn btn-outline-success btn-sm" target="_blank"
                                            href="https://s3.ap-south-1.amazonaws.com/pikmybox-meis/{{d.fileName}}">View</a>
                                        <button *ngIf="selectedMeis.status=='Generated'" class="btn btn-danger btn-sm"
                                            (click)="deleteobject('coi',d)"><i class="fa fa-times"></i></button>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Additional Documents</th>
                            <td *ngIf="selectedMeis.adStatus" class="text-success">Uploaded</td>
                            <td *ngIf="!selectedMeis.adStatus" class="text-warning">Pending</td>
                            <td>
                                <div *ngIf="selectedMeis.editPermitted">
                                    <input type="file" multiple (change)="changeListener($event)"
                                        accept="application/pdf, image/*">
                                    <button class="btn btn-sm btn-success" (click)="sendFiles('ad')"><i
                                            class="fa fa-send"></i></button>
                                </div>
                                <hr *ngIf="selectedMeis.adStatus && selectedMeis.status=='Generated'" />
                                <div *ngIf="selectedMeis.adStatus">
                                    <span *ngFor="let d of selectedMeis.ad">
                                        <a class="btn btn-outline-success btn-sm" target="_blank"
                                            href="https://s3.ap-south-1.amazonaws.com/pikmybox-meis/{{d.fileName}}">View</a>
                                        <button *ngIf="selectedMeis.status=='Generated'" class="btn btn-danger btn-sm"
                                            (click)="deleteobject('ad',d)"><i class="fa fa-times"></i></button>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!-- Central Modal Medium Danger-->