import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { SocketService } from './services/socket.service';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'app';
  currentRoute = '';
  // @HostListener('window:beforeunload', ['$event'])

  form: FormGroup;
  form1: FormGroup;
  t = 3600000;
  userId = '';
  messages: any
  loading: Boolean = false
  asd: AppComponent;

  constructor(public authService: AuthService, private socketService: SocketService,
    public router: Router, public formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    router.events.subscribe(() => {
      if (this.currentRoute == '') {
        this.currentRoute = this.router.url;
        this.getNotice();
      }
      else if (this.currentRoute != this.router.url) {
        this.currentRoute = this.router.url;
        this.getNotice();
      }

    });

    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        this.emailValidation
      ])],
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      cname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      mobile: ['', Validators.compose(
        [
          Validators.required, this.mobileValidation])
      ],
      message: ['', Validators.compose(
        [
          Validators.required, Validators.minLength(10)])
      ],
    });

  }


  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        }
        else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
          this.loading = false;
        }
        this.detectChanges();
      });
    if (this.authService.isSuperAdmin()) {
      this.socketService.pickupRespose().subscribe(socketRes => {
        // // console.log(socketRes)
        let mes: any = socketRes.message
        if (!window.location.href.includes('pickup')) {
          this.authService.showAlert('Exporter has requested to pickup docket number : ' + mes.tempSBill)
        }
      })
    }
  }

  getNotice() {
    if (this.authService.loggedIn()) {
      this.authService.getNotification();
    }
  }

  hideShowForm(val) {
    if (val == 'open') {
      document.getElementById('contactForm').style.display = 'block';
      document.getElementById('launcher').style.display = 'none';
      document.getElementById('contactForm').classList.add('animate');

    }
    else {
      document.getElementById('contactForm').style.display = 'none';
      document.getElementById('launcher').style.display = 'block';
    }
  }


  mobileValidation(control) {
    if (control.value == null || control.value == "" || control.value == undefined) {
      return { 'mobileNull': false };
    }
    else {
      var mobile = control.value.toString();
      var regexMobile = /^\d{10}(,\d{10})*$/;
      if (mobile.match(regexMobile)) {
        return { 'mobile': true };
      }
      else {
        return { 'mobile': false };
      }
    }

  }

  emailValidation(control) {
    if (control.value == null || control.value == "") return null;
    var email = control.value;
    var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/i;
    if (email.match(regexEmail)) {
      return { 'email': true };
    }
    else {
      return { 'email': false };
    }
  }

  getQuote(type) {
    this.form.get('message').setValue(type)
  }

  submitForm(formVal) {
    this.authService.contactFormEmail(formVal).subscribe(data => {
      if (data.success) {
        swal.fire('SUCCESS', data.message, 'success');
        this.form.reset()
      }
      else {
        swal.fire('Oops!', data.message, 'error');
      }
    })
  }

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.authService.establishSocketConnection();
    }

    this.timer()
    var x = document.body
    x.onmouseover = () => {
      this.resetTimer()
    };
    x.onkeypress = () => {
      this.resetTimer()
    }
    x.onkeydown = () => {
      this.resetTimer()
    }
    x.onkeyup = () => {
      this.resetTimer()
    }
    x.onmouseenter = () => {
      this.resetTimer()
    }
    x.onmousemove = () => {
      this.resetTimer()
    }
    x.onclick = () => {
      this.resetTimer()
    }
  }

  timer() {
    // setInterval(() => {
    //   this.t = this.t - 1000;
    //   // // console.log(this.t);
    //   if (this.t == 0) {
    //     this.sessionOut();
    //   }
    // }, 1000);
    //  this.timer()
  }

  sessionOut() {
    this.authService.authToken = null;
    this.authService.user = null;
    localStorage.removeItem('token');
    // this.router.navigate(['/']);
    location.reload();
  }

  resetTimer() {
    this.t = 3600000;
    // // console.log(this.t)
  }

}

