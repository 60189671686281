<div class="wrapper">
    <div class="container-fluid">

        <div class="card" style="margin-bottom: 15px">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text" class="active">Enter To search</label>
                            <input class="form-control ng-pristine ng-valid ng-touched" type="text"
                                [(ngModel)]="searchItem">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group" style="margin-bottom:0px">
                            <label for="text">Select Status To Search</label>

                            <select class="form-control" (change)="getSearch($event.target.value)"
                                [(ngModel)]="selectedStatus">
                                <option value="All">All</option>
                                <option value="Pending Review">Pending Review</option>
                                <option value="Approved">Approved</option>
                                <option value="Under Review">Under Review</option>
                                <option value="Additional Documents Requested">Add. Docs Requested</option>
                                <option value="Rejected">Rejected</option>

                            </select>
                        </div>
                    </div>

                    <div class="col-md-6" style="margin: auto">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block btn-outline-default btn-block" type="button"
                                        (click)="getMeisData()">
                                        <i class="fa fa-eye"></i>&nbsp; View</button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block btn-outline-danger btn-block" type="button"
                                        (click)="reset()">
                                        <i class="fa fa-refresh"></i> &nbsp;Reset</button>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="(selectedBoxIdsForPBE?.length>0)">
                                <div class="form-group" style="margin-top: 26px;margin-bottom: 0;">
                                    <button class="btn btn-block  btn-outline-orange" type="button"
                                        (click)="downloadMeisExcelAfterUpload()">
                                        <i class="fa  fa-download"></i>&nbsp; Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 40%"></div>
        <table id="mainTable" class="table table-bordered m-b-0 text-center">
            <thead>
                <tr>
                    <th>
                        <input type="checkbox" [(ngModel)]="selectedAll" id="selectAll" (change)="selectAll();"
                            class="inp-cbx" style="display: none">
                        <label *ngIf="!authService.isAdmin()" for="selectAll" class="cbx">
                            <span style="    border-color: #fff;">
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        Sl. No.
                    </th>
                    <th>IE Code</th>
                    <th>
                        MEIS Ref. No.
                    </th>
                    <th>
                        PBE No. & Date
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Entitlement Amount
                    </th>
                    <!-- <th>
              Amount
            </th> -->
                    <!-- <th>Upload Documents</th> -->

                    <th>Status</th>
                    <th>Action</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            <tbody *ngIf="(orders?.length && !isLoading)">
                <tr *ngFor='let o of orders; let i = index'>
                    <td>
                        <input [checked]="(o.selected)" *ngIf="(!authService.isAdmin())" value="{{o.refNo}}"
                            type="checkbox" id="cbx{{i}}" (change)="selectBoxIds($event);" class="inp-cbx"
                            style="display: none">
                        <label *ngIf="(!authService.isAdmin())" for="cbx{{i}}" class="cbx">
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        {{i+1}}
                    </td>
                    <td>
                        {{o.orderDetails[0].exporterDetails.ieCode}}
                    </td>
                    <td>
                        {{o.refNo}}
                    </td>
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.orderDetails" class="brd">
                            {{k.shippingBillDetails.bill}} / {{k.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                        </div>
                    </td>
                    <!-- <td class="text-center" style="padding: 0">
                <div *ngFor="let k of o.orderDetails" class="brd">
                   {{k.shippingBillDetails.bill}} / {{k.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                </div>
            </td> -->
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.orderDetails" class="brd">
                            {{k.description | slice:0:20}}...
                        </div>
                    </td>
                    <td class="text-center" style="padding: 0">
                        <div *ngFor="let k of o.excelData;let i=index" class="brd">
                            {{k['Rate of entitlement as per Appendix 3B (Enter relevant rate as per Appendix 3B (Table
                            2))'] * o.orderDetails[i].paymentDetails.fob | currency:'INR':'code':'1.2-2'}}
                        </div>
                    </td>

                    <!--   
            <td class="text-center" style="padding: 0">
                <div *ngFor="let k of o.orderDetails" class="brd">
                    {{k.paymentDetails.fob | currency:'INR':'code':'1.2-2'}}
                </div>
            </td> -->

                    <!-- <td><i class="fa fa-cloud-upload" aria-hidden="true" style="font-size:30px;cursor: pointer;"></i></td> -->

                    <td style="padding: 0;width:12%"
                        [ngClass]="{'red':(o.status=='Generated'),'yellow':(o.status=='Pending Review'),'orange':(o.status=='Under Review'),'green':(o.status=='Approved')}">
                        <div *ngIf="o.status=='Under Review'">
                            <span>{{o.status}}</span>
                            <span *ngFor="let in of counter(o.reviewCount)">
                                <sup><i class="fa fa-asterisk" style="font-size:6px"></i></sup>
                            </span>
                        </div>
                        <span *ngIf="o.status!='Under Review' ">{{o.status}}</span>
                    </td>

                    <td>
                        <div class="col-sm-12" *ngIf="o.status!='Approved'">
                            <select class="form-control" (change)="getAction($event)" value="{{o.status}}"
                                style="font-size:.9rem" [disabled]="(o.status=='Approved' || o.status=='Rejected')">
                                <option value="Pending Review" disabled>Pending Review</option>
                                <option value="Approved">Approved</option>
                                <option value="Under Review">Under Review</option>
                                <option value="Additional Documents Requested">Add. Docs Requested</option>
                                <option value="Rejected">Rejected</option>

                            </select>
                            <button *ngIf="!(o.status=='Approved'  || o.status=='Rejected')"
                                class="btn btn-primary btn-sm" (click)="updatestatusbydgft(o.refNo)">Submit</button>
                        </div>

                        <div *ngIf="(o.status=='Approved' || o.status=='Rejected')">
                            <span>Reviewed By : {{o.reviewedBy}}</span>
                            <br />
                            <span>On : {{o.updatedAt | date:'dd-MM-yyyy hh:mm a'}}</span>
                        </div>
                    </td>

                    <td>
                        <div class="col-sm-12">
                            <!-- <textarea cols="5" style="width:100%" [(ngModel)]="remark" class="form-control"></textarea> -->
                            <!-- <button class="btn btn-primary btn-sm" (click)="addremark(o.refNo)">Submit</button> -->
                            <!-- <button class="btn btn-sm btn-primary" data-target="#exampleModal" data-toggle="modal" (click)="getRemark(o)">Remark</button> -->
                            <img src="assets/images/comment.png" data-target="#exampleModal" data-toggle="modal"
                                (click)="getRemark(o)" style="cursor: pointer;margin-bottom:10%;margin-top:20%"
                                title="">
                            <p style="font-size:10px;">Provide remarks or request<br />additional info from
                                <br />applicant here</p>
                        </div>
                    </td>


                </tr>
            </tbody>
        </table>


        <div class="row" *ngIf="isLoading">
            <div class="card-body">
                <div class="lds-css ng-scope">
                    <div class="lds-blocks" style="margin: auto">
                        <div style="left:38px;top:38px;animation-delay:0s">
                        </div>
                        <div style="left:80px;top:38px;animation-delay:0.125s">
                        </div>
                        <div style="left:122px;top:38px;animation-delay:0.25s">
                        </div>
                        <div style="left:38px;top:80px;animation-delay:0.875s"></div>
                        <div style="left:122px;top:80px;animation-delay:0.375s"></div>
                        <div style="left:38px;top:122px;animation-delay:0.75s"></div>
                        <div style="left:80px;top:122px;animation-delay:0.625s"></div>
                        <div style="left:122px;top:122px;animation-delay:0.5s"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal right" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-full-height modal-right" role="document">
        <div class="modal-content" *ngIf="remarks">

            <div class="modal-body" style="overflow: auto">
                <div class="card grey lighten-3 chat-room">
                    <div class="card-body">
                        <h6 class="font-weight-bold mb-3 text-center text-lg-left">Remarks</h6>
                        <div class="row px-2" *ngFor="let rm of remarks.remarks">
                            <div class="col-md-12 col-xl-12 px-0">
                                <div class="white z-depth-1 px-6 pt-6 pb-0" style="border-radius:5px">
                                    <ul class="list-unstyled friend-list">
                                        <li class="p-2">
                                            <a href="javascript:void(0)" class="d-flex justify-content-between">
                                                <div class="text-small">
                                                    <strong>By : {{rm.by}} </strong>
                                                    <p class="last-message text-muted">{{rm.text}}</p>
                                                </div>
                                                <div class="chat-footer">
                                                    <span class="badge badge-danger float-right">{{rm.date |
                                                        date:'dd-MM-yyyy'}}</span>
                                                </div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>

                <div class="col-sm-12" style="margin-top:5%">
                    <textarea placeholder="Enter Remark" class="form-control" [(ngModel)]="rem"></textarea>
                </div>


                <div class="modal-footer justify-content-center" style="border-top:1px solid #fff">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" *ngIf="remarks.status!='Approved'" class="btn btn-primary"
                        data-dismiss="modal" aria-label="Close" (click)="addremark()">Add Remark</button>
                    <!-- <button type="button" *ngIf="remarks.status!='Rejected'" class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="addremark()">Add Remark</button> -->
                    <!-- <button type="button" *ngIf="remarks.status!='Rejected' || remarks.status!='Approved'" class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="addremark()">Add Remark</button> -->
                </div>
            </div>

        </div>
    </div>