<div class="container-fluid main d-flex justify-content-center">
    <div class="table-back">
        <div class="m-3 d-flex justify-content-end position-relative">
            <button type="button" class="btn btn-primary btn-md1 font-weight-bold btn-shipment" data-toggle="modal"
                data-target="#form1">
                Create new Shipment
            </button>
        </div>



        <div *ngIf="spinner" class="d-flex justify-content-center mb-2">
            <div class="spinner-grow text-warning" role="status">
                <span>Loading...</span>
            </div>

        </div>

        <nav style="margin: auto" *ngIf="allpickupsCount > 0 && showHeader">
            <ul class="pagination pg-amber justify-content-center" style="margin: auto"
                *ngIf="pager.pages && pager.pages.length">
                <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
                    <a class="page-link waves-effect text-light" (click)="setPage(1)">First</a>
                </li>
                <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
                    <a class="page-link text-light" (click)="setPage(pager.currentPage - 1)">Previous</a>
                </li>
                <li class="page-item" *ngFor="let page of pager.pages"
                    [ngClass]="{ active: pager.currentPage === page }">
                    <a class="page-link text-light" (click)="setPage(page)">{{ page }}</a>
                </li>
                <li class="page-item" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                    <a class="page-link text-light" (click)="setPage(pager.currentPage + 1)">Next</a>
                </li>
                <li class="page-item" [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                    <a class="page-link text-light" (click)="setPage(pager.totalPages)">Last</a>
                </li>
            </ul>
        </nav>


        <div *ngIf="norecord ">
            <div class="mt-5 font-weight-bold text-center text-light" style="font-size: large">
                No pickups scheduled
            </div>
        </div>

        <div *ngIf="!norecord && showHeader" class="tablebig">
            <div class="table-div">
                <table class="table table-bordered table-responsive w-100 d-block d-md-table">
                    <thead>
                        <tr class="text-warning text-center">
                            <th>S.No.</th>
                            <th>Ref. ID</th>
                            <th>NAME</th>
                            <th>COUNTRY</th>
                            <th>ITEMS</th>
                            <th>WEIGHT</th>
                            <th>SHIPPING LABELS</th>
                            <th>STATUS</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>

                    <tbody class="text-light">
                        <tr *ngFor="let d of userData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ d.boxId }}</td>
                            <td>{{ d.consigneeDetails.name }}</td>
                            <td>{{ d.consigneeDetails.country }}</td>
                            <td>{{ d.packageDesc }}</td>
                            <td>{{ d.grossWeight }}</td>
                            <td>
                                <button class="btn btn-sm btn-warning btn-des" (click)="printCNForms(d.boxId)">
                                    View/Print
                                </button>
                            </td>

                            <td *ngIf="
                  d.status == 'Pickup Request Pending' ||
                  d.status == 'initiated'
               ">
                                <div class="text-light">{{ d.status }}</div>
                            </td>
                            <td *ngIf="d.status == 'Pickup Requested'">
                                <div class="text-light">
                                    Pick up requested for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>
                            <td *ngIf="d.status == 'Pickup Scheduled'">
                                <div class="text-light">
                                    Pick up scheduled for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>

                            <td *ngIf="d.status == 'Pickup Declined'">
                                <div class="text-light">Pickup request declined</div>
                            </td>

                            <td *ngIf="d.status == 'Pickup Date Changed'">
                                <div class="text-light">
                                    New pickup date Requested - {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>

                            <td class="text-light" *ngIf="d.status == 'Pickup Confirmed'">
                                {{ d.status }} for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                            </td>

                            <td class="text-light" *ngIf="d.status == 'Booking Completed'">
                                {{ d.status }}

                                <div class="text-default" *ngIf="!(d.customerFeedback.reached_on_time == null)">
                                    <span *ngFor="let s of getArrayP2P(d.customerFeedback.stars)">
                                        <i class="fa fa-star" style="
                        font-size: 15px;
                        color: gold;
                        width: 20px;
                        height: 20px;
                        border: none;
                        border-radius: 50px;
                     "></i>
                                    </span>
                                </div>
                            </td>

                            <td class="text-light" *ngIf="
                  d.status == 'In Transit' ||
                  d.status == 'Arrived for Pickup' ||
                  d.status == 'Pickup Done' ||
                  d.status == 'Arrived at Hub' ||
                  d.status == 'Departed from Hub' ||
                  d.status == 'Arrived at Post Office'
               ">
                                {{ d.status }} on {{ d.comments[d.comments.length - 1].timeStamp | date:"dd-MM-yyyy" }}
                            </td>

                            <td>
                                <div class="d-flex justify-content-center">
                                    <span *ngIf="!(d.status == 'Booking Completed')"><img
                                            src="../../../assets/personal_export/Edit.png" class="img-size bdr"
                                            (click)="updateShipment(d.boxId, i)" /></span>
                                    <span *ngIf="d.status == 'Booking Completed'"><img
                                            src="../../../assets/personal_export/Edit_1.png" class="img-size bdr"
                                            (click)="updateShipment(d.boxId, i)" /></span>
                                    <span *ngIf="
                      !(
                        d.status == 'Pickup Confirmed' ||
                        d.status == 'Booking Completed' ||
                        d.status == 'In Transit' ||
                        d.status == 'Arrived for Pickup' ||
                        d.status == 'Pickup Done' ||
                        d.status == 'Arrived at Hub' ||
                        d.status == 'Departed from Hub' ||
                        d.status == 'Arrived at Post Office'
                      )
                   "><img src="../../../assets/personal_export/Delete.png" class="img-size"
                                            (click)="deleteShipment(d.boxId)" /></span>

                                    <span *ngIf="
                      d.status == 'Pickup Confirmed' ||
                      d.status == 'Booking Completed' ||
                      d.status == 'In Transit' ||
                      d.status == 'Arrived for Pickup' ||
                      d.status == 'Pickup Done' ||
                      d.status == 'Arrived at Hub' ||
                      d.status == 'Departed from Hub' ||
                      d.status == 'Arrived at Post Office'
                   "><img src="../../../assets/personal_export/Delete_1.png" class="img-size" />
                                    </span>
                                </div>

                                <!-- <button class="btn btn-sm btn-warning btn-des">View Receipt</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="!norecord" class="tablesmall">
            <div *ngFor="let d of userData; let i = index">
                <div class="table-div1">
                    <table class="table table-bordered">
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                S.No.
                            </td>
                            <td class="text-light">
                                {{ i + 1 }}
                            </td>
                        </tr>

                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                Ref. ID
                            </td>
                            <td class="text-light">{{ d.boxId }}</td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                NAME
                            </td>
                            <td class="text-light">{{ d.consigneeDetails.name }}</td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                COUNTRY
                            </td>
                            <td class="text-light">{{ d.consigneeDetails.country }}</td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                ITEMS
                            </td>
                            <td class="text-light">{{ d.packageDesc }}</td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                WEIGHT
                            </td>
                            <td class="text-light">{{ d.grossWeight }}</td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                SHIPPING LABELS
                            </td>
                            <td>
                                <button class="btn btn-sm btn-warning btn-des" (click)="printCNForms(d.boxId)">
                                    View/Print
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                STATUS
                            </td>
                            <td *ngIf="
                  d.status == 'Pickup Request Pending' ||
                  d.status == 'initiated'
               ">
                                <div class="text-light">{{ d.status }}</div>
                            </td>
                            <td *ngIf="d.status == 'Pickup Requested'">
                                <div class="text-light">
                                    Pick up requested for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>
                            <td *ngIf="d.status == 'Pickup Scheduled'">
                                <div class="text-light">
                                    Pick up scheduled for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>

                            <td *ngIf="d.status == 'Pickup Declined'">
                                <div class="text-light">Pickup request declined</div>
                            </td>

                            <td *ngIf="d.status == 'Pickup Date Changed'">
                                <div class="text-light">
                                    New pickup date Requested - {{ d.pickupDate | date:"dd-MM-yyyy" }}
                                </div>
                            </td>

                            <td class="text-light" *ngIf="d.status == 'Pickup Confirmed'">
                                {{ d.status }} for {{ d.pickupDate | date:"dd-MM-yyyy" }}
                            </td>

                            <td class="text-light" *ngIf="d.status == 'Booking Completed'">
                                {{ d.status }}

                                <div class="text-default" *ngIf="!(d.customerFeedback.reached_on_time == null)">
                                    <span *ngFor="let s of getArrayP2P(d.customerFeedback.stars)">
                                        <i class="fa fa-star" style="
                        font-size: 15px;
                        color: gold;
                        width: 20px;
                        height: 20px;
                        border: none;
                        cursor: pointer;
                        border-radius: 50px;
                     "></i>
                                    </span>
                                </div>
                            </td>
                            <td class="text-light" *ngIf="
                  d.status == 'In Transit' ||
                  d.status == 'Arrived for Pickup' ||
                  d.status == 'Pickup Done' ||
                  d.status == 'Arrived at Hub' ||
                  d.status == 'Departed from Hub' ||
                  d.status == 'Arrived at Post Office'
               ">
                                {{ d.status }} on {{ d.comments[d.comments.length - 1].timeStamp | date:"dd-MM-yyyy" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-warning text-center font-weight-bold" style="width: 40%">
                                ACTIONS
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <span *ngIf="!(d.status == 'Booking Completed')"><img
                                            src="../../../assets/personal_export/Edit.png" class="img-size bdr"
                                            (click)="updateShipment(d.boxId, i)" /></span>

                                    <span *ngIf="d.status == 'Booking Completed'"><img
                                            src="../../../assets/personal_export/Edit_1.png" class="img-size bdr"
                                            (click)="updateShipment(d.boxId, i)" /></span>

                                    <span *ngIf="
                      d.status == 'Pickup Confirmed' ||
                      d.status == 'Booking Completed' ||
                      d.status == 'In Transit' ||
                      d.status == 'Arrived for Pickup' ||
                      d.status == 'Pickup Done' ||
                      d.status == 'Arrived at Hub' ||
                      d.status == 'Departed from Hub' ||
                      d.status == 'Arrived at Post Office'
                   "><img src="../../../assets/personal_export/Delete_1.png" class="img-size" /></span>
                                    <span *ngIf="
                      !(
                        d.status == 'Pickup Confirmed' ||
                        d.status == 'Booking Completed' ||
                        d.status == 'In Transit' ||
                        d.status == 'Arrived for Pickup' ||
                        d.status == 'Pickup Done' ||
                        d.status == 'Arrived at Hub' ||
                        d.status == 'Departed from Hub' ||
                        d.status == 'Arrived at Post Office'
                      )
                   "><img src="../../../assets/personal_export/Delete.png" class="img-size"
                                            (click)="deleteShipment(d.boxId)" /></span>
                                </div>

                                <!-- <button class="btn btn-sm btn-warning btn-des">View Receipt</button> -->
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="form1" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog1">
        <!-- Modal content-->
        <div class="modal-content modal-overlay">
            <div class="modal-header btn-warning">
                <div class="font-weight-bold">Create new Shipment for customer</div>
                <button type="button" class="close" (click)="closeModal()">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="false" #myCarousel>
                    <div class="carousel-inner">
                        <div class="carousel-item active text-light">
                            <form [formGroup]="shipAbroadForm" (ngSubmit)="onFormSubmit(shipAbroadForm.value)">
                                <div class="form-group">
                                    <label for="form-cutomerID">Customer ID<span class="star-color">*</span></label>

                                    <input type="text" formControlName="cutomerID"
                                        class="form-control form-control-sm input-field" id="form-cutomerID" [ngClass]="{
                      'is-invalid': submitted && fval.cutomerID.errors
                    }" />

                                    <div *ngIf="submitted && fval.cutomerID.errors" class="invalid-feedback">
                                        <div *ngIf="fval.cutomerID.errors.required">
                                            Customer ID is required
                                        </div>
                                        <div *ngIf="fval.cutomerID.errors.minLength">
                                            Customer ID should be of 8 digits
                                        </div>
                                    </div>
                                </div>

                                <div class="font-weight-bold mt-2 mb-2">
                                    Where are you sending your shipment?
                                </div>
                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="name">Name<span class="star-color">*</span></label>

                                                <input type="text" [ngClass]="{
                            'is-invalid': submitted && fval.name.errors
                          }" formControlName="name" name="name" id="name"
                                                    class="form-control form-control-sm input-field mt-1" />
                                                <div class="invalid-feedback">Name is Required.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="form-phone">Phone<span class="star-color">*</span></label>

                                                <input type="text" formControlName="phone"
                                                    class="form-control form-control-sm input-field mt-1"
                                                    id="form-phone" [ngClass]="{
                            'is-invalid': submitted && fval.phone.errors
                          }" />

                                                <div *ngIf="submitted && fval.phone.errors" class="invalid-feedback">
                                                    <div *ngIf="fval.phone.errors.required">
                                                        Phone is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="form-email">Email</label>
                                                <input type="email" formControlName="email"
                                                    class="form-control form-control-sm input-field mt-1"
                                                    id="form-email" />
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-country">Country<span class="star-color">*</span></label>

                                            <select formControlName="country"
                                                class="form-control form-control-sm input-field mt-1" [ngClass]="{
                          'is-invalid': submitted && fval.country.errors
                        }" id="form-country">
                                                <option value="">Choose your country</option>
                                                <option *ngFor="let countries of country" [ngValue]="countries"
                                                    (change)="changeCountry($event)">
                                                    {{ countries }}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && fval.country.errors" class="invalid-feedback">
                                                <div *ngIf="fval.country.errors.required">
                                                    country is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg">
                                            <label for="form-state">State<span class="star-color">*</span></label>

                                            <input type="text" formControlName="state"
                                                class="form-control form-control-sm input-field" id="form-state"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.state.errors
                        }" />

                                            <div *ngIf="submitted && fval.state.errors" class="invalid-feedback">
                                                <div *ngIf="fval.state.errors.required">
                                                    State is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg">
                                            <label for="form-City">City<span class="star-color">*</span></label>

                                            <input type="text" formControlName="city"
                                                class="form-control form-control-sm input-field" id="form-city"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.city.errors
                        }" />

                                            <div *ngIf="submitted && fval.city.errors" class="invalid-feedback">
                                                <div *ngIf="fval.city.errors.required">
                                                    City is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <label for="form-address">Address<span class="star-color">*</span></label>

                                            <input type="text" formControlName="address"
                                                class="form-control form-control-sm input-field" id="form-address"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.address.errors
                        }" />

                                            <div *ngIf="submitted && fval.address.errors" class="invalid-feedback">
                                                <div *ngIf="fval.address.errors.required">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-pincode">Zipcode<span class="star-color">*</span></label>

                                            <input type="text" formControlName="pincode"
                                                class="form-control form-control-sm input-field" id="form-pincode"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.pincode.errors
                        }" />

                                            <div *ngIf="submitted && fval.pincode.errors" class="invalid-feedback">
                                                <div *ngIf="fval.pincode.errors.required">
                                                    Pincode is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div class="font-weight-bold mb-2">
                                    Details of your shipment
                                </div>

                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="form-group col-lg">
                                            <label for="form-itemDes">Item Description<span
                                                    class="star-color">*</span></label>

                                            <input type="text" formControlName="itemDes"
                                                class="form-control form-control-sm input-field" id="form-itemDes"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.itemDes.errors
                        }" />

                                            <div *ngIf="submitted && fval.itemDes.errors" class="invalid-feedback">
                                                <div *ngIf="fval.itemDes.errors.required">
                                                    Item Description is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-productCategory">Product Category<span
                                                    class="star-color">*</span></label>

                                            <select formControlName="productCategory"
                                                class="form-control form-control-sm input-field mt-1" [ngClass]="{
                          'is-invalid': submitted && fval.productCategory.errors
                        }" id="form-state" (change)="HSN($event)">
                                                <option value="">Choose your Product Category</option>
                                                <option *ngFor="let products of product" [ngValue]="products"
                                                    (change)="changeProductCategory($event)">
                                                    {{ products }}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && fval.productCategory.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="fval.productCategory.errors.required">
                                                    Product Category is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-hsn">HSN<span class="star-color">*</span></label>

                                            <input type="text" formControlName="hsn"
                                                class="form-control form-control-sm input-field" id="form-hsn"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.hsn.errors
                        }" disabled />
                                            <div *ngIf="submitted && fval.hsn.errors" class="invalid-feedback">
                                                <div *ngIf="fval.hsn.errors.required">
                                                    HSN is required
                                                </div>
                                                <div *ngIf="fval.hsn.errors.min">
                                                    Invalid HSN (Min 4 and max 8 digits)
                                                </div>

                                                <div *ngIf="fval.hsn.errors.max">
                                                    Invalid HSN (Min 4 and max 8 digits)
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-value">Value (INR)<span class="star-color">*</span></label>
                                            <input type="number" formControlName="value"
                                                class="form-control form-control-sm input-field" id="form-value"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.value.errors
                          }" placeholder="Please provide value in INR" />

                                            <div *ngIf="submitted && fval.value.errors" class="invalid-feedback">
                                                <div *ngIf="fval.value.errors.required">
                                                    Value is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="font-weight-bold mt-2 mb-2">Box Details</div>

                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="form-group col-lg">
                                            <label for="form-length">Length (CM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="length"
                                                class="form-control form-control-sm input-field" id="form-length"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.length.errors
                          }" placeholder="ex. (75cm)" />


                                            <div *ngIf="submitted && fval.length.errors" class="invalid-feedback">
                                                <div *ngIf="fval.length.errors.required">
                                                    Length is required
                                                </div>
                                                <div *ngIf="fval.length.errors.min">
                                                    Length can not be 0
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-width">Width (CM)<span class="star-color">*</span></label>
                                            <input type="number" formControlName="width"
                                                class="form-control form-control-sm input-field" id="form-width"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.width.errors
                          }" placeholder="ex. (75cm)" />

                                            <div *ngIf="submitted && fval.width.errors" class="invalid-feedback">
                                                <div *ngIf="fval.width.errors.required">
                                                    Width is required
                                                </div>
                                                <div *ngIf="fval.width.errors.min">
                                                    Width can not be 0
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-height">Height (CM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="height"
                                                class="form-control form-control-sm input-field" id="form-height"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.height.errors
                          }" placeholder="ex. (75cm)" />

                                            <div *ngIf="submitted && fval.height.errors" class="invalid-feedback">
                                                <div *ngIf="fval.height.errors.required">
                                                    height is required
                                                </div>
                                                <div *ngIf="fval.height.errors.min">
                                                    Height can not be 0
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-weight">Shipment Weight (GM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="weight"
                                                class="form-control form-control-sm input-field" id="form-weight"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.weight.errors
                          }" placeholder="ex. (20gm)" />

                                            <div *ngIf="submitted && fval.weight.errors" class="invalid-feedback">
                                                <div *ngIf="fval.weight.errors.required">
                                                    Weight is required
                                                </div>
                                                <div *ngIf="fval.weight.errors.min">
                                                    Weight can not be 0
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 form-group text-center">
                                    <button class="btn btn-md btn-primary" href="#carouselExampleControls"
                                        data-slide="next">
                                        NEXT
                                    </button>
                                    <button class="btn btn-md btn-primary" type="button" (click)="closeModal()">
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="carousel-item text-light" id="slide2">
                            <form [formGroup]="priceCheckForm" (ngSubmit)="submitPrice()">
                                <div class="font-weight-bold mt-5 mb-3">
                                    Select shipping speed >>
                                </div>

                                <div class="border p-3 borser-des">
                                    <div class="text-center" *ngIf="
                      !(
                        priceData.costForEMSSpeedPost &&
                        priceData.costForInternationalAirParcel &&
                        priceData.costForAirservice &&
                        priceData.costForInternationalTrackedPackets
                      )
                   ">
                                        No shipping method available
                                    </div>
                                    <div class="form-group text-light">
                                        <div class="border-des" *ngIf="priceData.costForEMSSpeedPost != 'Not Available'"
                                            class="d-flex justify-content-between m-2">
                                            <div *ngIf="priceData.costForEMSSpeedPost && checkEMS">
                                                <input id="radio1" type="radio" value="costForEMSSpeedPost"
                                                    name="priceCheck" formControlName="priceCheck" />

                                                <label class="label1" for="costForEMSSpeedPost">
                                                    EMS Speed Post end to end tracking & delivery time 7
                                                    -9 days
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkEMS == false && priceData.costForEMSSpeedPost
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForEMSSpeedPost">
                                                    EMS Speed Post end to end tracking & delivery time 7
                                                    -9 days
                                                </label>
                                            </div>

                                            <div *ngIf="priceData?.costForEMSSpeedPost" class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForEMSSpeedPost }}
                                            </div>
                                        </div>

                                        <div *ngIf="
                        priceData.costForInternationalAirParcel !=
                        'Not Available'
                     " class="d-flex justify-content-between m-2">
                                            <div *ngIf="
                          priceData.costForInternationalAirParcel &&
                          checkInternational
                       ">
                                                <input id="costForInternationalAirParcel" type="radio"
                                                    value="costForInternationalAirParcel" name="priceCheck"
                                                    formControlName="priceCheck" />
                                                <label class="label1" for="costForInternationalAirParcel">
                                                    International Air Parcel end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkInternational == false &&
                          priceData.costForInternationalAirParcel
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForInternationalTrackedPackets">
                                                    International Tracked Packets end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>

                                            <div *ngIf="priceData?.costForInternationalAirParcel"
                                                class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForInternationalAirParcel }}
                                            </div>
                                        </div>

                                        <div *ngIf="priceData.costForAirservice != 'Not Available'"
                                            class="d-flex justify-content-between m-2">
                                            <div *ngIf="priceData.costForAirservice && checkAirService">
                                                <input id="costForAirservice" type="radio" value="costForAirservice"
                                                    name="priceCheck" formControlName="priceCheck" />
                                                <label class="label1" for="costForAirservice">Small Packets (Air
                                                    Service) No tracking & delivery
                                                    time 20 -25 days
                                                </label>
                                            </div>

                                            <div *ngIf="
                          checkAirService == false &&
                          priceData.costForAirservice
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForAirservice">
                                                    Small Packets (Air Service) No tracking & delivery
                                                    time 20 -25 days
                                                </label>
                                            </div>

                                            <div *ngIf="priceData.costForAirservice" class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForAirservice }}
                                            </div>
                                        </div>

                                        <div *ngIf="
                        priceData.costForInternationalTrackedPackets !=
                        'Not Available'
                     " class="d-flex justify-content-between m-2">
                                            <div *ngIf="
                          priceData.costForInternationalTrackedPackets &&
                          checkTrackedPackets
                       ">
                                                <input id="costForInternationalTrackedPackets" type="radio"
                                                    value="costForInternationalTrackedPackets" name="priceCheck"
                                                    formControlName="priceCheck" />
                                                <label class="label1"
                                                    for="costForInternationalTrackedPackets">International Tracked
                                                    Packets end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkTrackedPackets == false &&
                          priceData.costForInternationalTrackedPackets
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForInternationalTrackedPackets">
                                                    International Tracked Packets end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>

                                            <div *ngIf="priceData.costForInternationalTrackedPackets"
                                                class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForInternationalTrackedPackets }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mt-5 mb-5">
                                    <button class="btn btn-md btn-primary" type="button" (click)="goBack()">
                                        Back
                                    </button>

                                    <button class="btn btn-md btn-primary" type="submit"
                                        [disabled]="!priceCheckForm.valid">
                                        Submit
                                    </button>
                                </div>




                                <div *ngIf="spinner" class="d-flex justify-content-center">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span>Loading...</span>
                                    </div>

                                </div>

                            </form>
                        </div>
                        <div class="carousel-item text-light">
                            <div class="font-weight-bold mt-5 mb-3">
                                Schedule a Pickup >>>
                            </div>

                            <form [formGroup]="dateCheckForm" (ngSubmit)="submitdate(dateCheckForm.value)">
                                <div class="border p-3 borser-des">
                                    <div class="form-group d-flex justify-content-center">
                                        <div style="width: 40vh">
                                            <label for="form-date">Date</label>
                                            <!--  -->
                                            <!-- <input formControlName="date" id="form-date" type="date"
                                                [ngClass]="{ 'is-invalid': submitted && fval1.date.errors  }"> -->
                                            &nbsp;
                                            <dp-date-picker theme="dp-material" formControlName="date"
                                                placeholder="Select Date" [config]="datePickerConfig" required="true">
                                            </dp-date-picker>

                                            <!--  -->
                                            <div *ngIf="submitted && fval1.date.errors" class="invalid-feedback">
                                                <div *ngIf="fval1.date.errors.required">
                                                    Date is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5 mb-5 form-group text-center">
                                    <button type="submit" class="btn btn-md btn-primary"
                                        [disabled]="!dateCheckForm.valid" (click)="closeModal()">
                                        SAVE
                                    </button>

                                    <button type="button" class="btn btn-md btn-primary" (click)="closeModal()">
                                        SCHEDULE LATER
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="form2" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog1">
        <!-- Modal content-->
        <div class="modal-content modal-overlay">
            <div class="modal-header btn-warning">
                <button type="button" class="close" (click)="closeModal()">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="text-light">
                    <div class="font-weight-bold mt-5 mb-3">Schedule Pickup</div>

                    <form [formGroup]="dateCheckForm" (ngSubmit)="submitdate1(dateCheckForm.value)">
                        <div class="border p-3 borser-des">
                            <div class="form-group d-flex justify-content-center">
                                <div style="width: 40vh">
                                    <label for="form-date">Date</label>

                                    <!--  -->
                                    <input formControlName="date" id="form-date"
                                        [ngClass]="{ 'is-invalid': submitted && fval1.date.errors  }">
                                    <!--  -->

                                    <div *ngIf="submitted && fval1.date.errors" class="invalid-feedback">
                                        <div *ngIf="fval1.date.errors.required">
                                            Date is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 mb-5 form-group text-center">
                            <button class="btn btn-md btn-primary" [disabled]="!dateCheckForm.valid">
                                SAVE
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="form3" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog1">
        <!-- Modal content-->
        <div class="modal-content modal-overlay">
            <div class="modal-header btn-warning">
                <div class="font-weight-bold">Edit your shipment</div>
                <button type="button" class="close" (click)="closeModal()">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div id="myCarousel1" class="carousel slide" data-ride="carousel" data-interval="false" #myCarousel>
                    <div class="carousel-inner">
                        <div class="carousel-item active text-light">
                            <form [formGroup]="shipAbroadForm" (ngSubmit)="onFormSubmit(shipAbroadForm.value)">
                                <div class="form-group">
                                    <label for="form-cutomerID">Customer ID<span class="star-color">*</span></label>

                                    <input type="text" formControlName="cutomerID"
                                        class="form-control form-control-sm input-field" id="form-cutomerID"
                                        [(ngModel)]="concustomerId" disabled />
                                </div>
                                <div class="font-weight-bold mt-2 mb-2">
                                    Where are you sending your shipment?
                                </div>
                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="name">Name<span class="star-color">*</span></label>

                                                <input type="text" [ngClass]="{
                            'is-invalid': submitted && fval.name.errors
                          }" formControlName="name" name="name" id="name" [(ngModel)]="conname"
                                                    class="form-control form-control-sm input-field mt-1"
                                                    [readonly]="checkStatus" />
                                                <div class="invalid-feedback">Name is Required.</div>
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="form-phone">Phone<span class="star-color">*</span></label>

                                                <input type="text" formControlName="phone"
                                                    class="form-control form-control-sm input-field mt-1"
                                                    id="form-phone" [ngClass]="{
                            'is-invalid': submitted && fval.phone.errors
                          }" [(ngModel)]="conmobile" [readonly]="checkStatus" />

                                                <div *ngIf="submitted && fval.phone.errors" class="invalid-feedback">
                                                    <div *ngIf="fval.phone.errors.required">
                                                        Phone is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="form-email">Email</label>

                                                <input type="email" formControlName="email"
                                                    class="form-control form-control-sm input-field mt-1"
                                                    id="form-email" [ngClass]="{
                            'is-invalid': submitted && fval.email.errors
                          }" [(ngModel)]="conemail" [readonly]="checkStatus" />

                                                <div *ngIf="submitted && fval.email.errors" class="invalid-feedback">
                                                    <div *ngIf="fval.email.errors.email">
                                                        Enter valid email address
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-country">Country<span class="star-color">*</span></label>

                                            <select formControlName="country"
                                                class="form-control form-control-sm input-field mt-1" [ngClass]="{
                          'is-invalid': submitted && fval.country.errors
                        }" id="form-state" [(ngModel)]="concountry" [disabled]="checkStatus">
                                                <option value="">Choose your country</option>
                                                <option *ngFor="let countries of country" [ngValue]="countries"
                                                    (change)="changeCountry($event)" [disabled]="checkStatus">
                                                    {{ countries }}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && fval.country.errors" class="invalid-feedback">
                                                <div *ngIf="fval.country.errors.required">
                                                    country is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg">
                                            <label for="form-state">State<span class="star-color">*</span></label>

                                            <input type="text" formControlName="state"
                                                class="form-control form-control-sm input-field" id="form-state"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.state.errors
                        }" [(ngModel)]="constate" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.state.errors" class="invalid-feedback">
                                                <div *ngIf="fval.state.errors.required">
                                                    State is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg">
                                            <label for="form-City">City<span class="star-color">*</span></label>

                                            <input type="text" formControlName="city"
                                                class="form-control form-control-sm input-field" id="form-city"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.city.errors
                        }" [(ngModel)]="concity" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.city.errors" class="invalid-feedback">
                                                <div *ngIf="fval.city.errors.required">
                                                    City is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg">
                                            <label for="form-address">Address<span class="star-color">*</span></label>

                                            <input type="text" formControlName="address"
                                                class="form-control form-control-sm input-field" id="form-address"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.address.errors
                        }" [(ngModel)]="conaddress" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.address.errors" class="invalid-feedback">
                                                <div *ngIf="fval.address.errors.required">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-pincode">Pincode<span class="star-color">*</span></label>

                                            <input type="text" formControlName="pincode"
                                                class="form-control form-control-sm input-field" id="form-pincode"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.pincode.errors
                        }" [(ngModel)]="conpincode" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.pincode.errors" class="invalid-feedback">
                                                <div *ngIf="fval.pincode.errors.required">
                                                    Pincode is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div class="font-weight-bold mt-2 mb-2">
                                    Details of your shipment
                                </div>

                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="form-group col-lg">
                                            <label for="form-itemDes">Item Description<span
                                                    class="star-color">*</span></label>

                                            <input type="text" formControlName="itemDes"
                                                class="form-control form-control-sm input-field mt-1" id="form-itemDes"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.itemDes.errors
                        }" [(ngModel)]="conpackageDesc" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.itemDes.errors" class="invalid-feedback">
                                                <div *ngIf="fval.itemDes.errors.required">
                                                    Item Description is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-productCategory">Product Category<span
                                                    class="star-color">*</span></label>

                                            <select formControlName="productCategory"
                                                class="form-control form-control-sm input-field mt-1" [ngClass]="{
                          'is-invalid': submitted && fval.productCategory.errors
                        }" id="form-productCategory" [(ngModel)]="conproductCatogory" (change)="HSN($event)"
                                                [disabled]="checkStatus">
                                                <option value="">Choose your Product Category</option>
                                                <option *ngFor="let products of product" [ngValue]="products"
                                                    (change)="changeProductCategory($event)" [disabled]="checkStatus">
                                                    {{ products }}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && fval.productCategory.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="fval.productCategory.errors.required">
                                                    Product Category is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg">
                                            <label for="form-hsn">HSN<span class="star-color">*</span></label>

                                            <input type="text" formControlName="hsn"
                                                class="form-control form-control-sm input-field" id="form-hsn"
                                                [ngClass]="{
                          'is-invalid': submitted && fval.hsn.errors
                        }" [ngModel]="conhsn" [readonly]="checkStatus" disabled />
                                            <div *ngIf="submitted && fval.hsn.errors" class="invalid-feedback">
                                                <div *ngIf="fval.hsn.errors.required">
                                                    HSN is required
                                                </div>
                                                <div *ngIf="fval.hsn.errors.min">
                                                    Invalid HSN (Min 4 and max 8 digits)
                                                </div>

                                                <div *ngIf="fval.hsn.errors.max">
                                                    Invalid HSN (Min 4 and max 8 digits)
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-value">Value (INR)<span class="star-color">*</span></label>

                                            <input type="number" formControlName="value"
                                                class="form-control form-control-sm input-field" id="form-value"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.value.errors
                          }" [(ngModel)]="convalue" placeholder="Please provide value in INR"
                                                [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.value.errors" class="invalid-feedback">
                                                <div *ngIf="fval.value.errors.required">
                                                    Value is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="font-weight-bold mt-4 mb-2">Box Details</div>

                                <div class="border p-3 borser-des">
                                    <div class="row">
                                        <div class="form-group col-lg">
                                            <label for="form-length">Length (CM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="length"
                                                class="form-control form-control-sm input-field" id="form-length"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.length.errors
                          }" [(ngModel)]="conlength" placeholder="ex. (75cm)" [readonly]="checkStatus" />


                                            <div *ngIf="submitted && fval.length.errors" class="invalid-feedback">
                                                <div *ngIf="fval.length.errors.required">
                                                    Length is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-width">Width (CM)<span class="star-color">*</span></label>
                                            <input type="number" formControlName="width"
                                                class="form-control form-control-sm input-field" id="form-width"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.width.errors
                          }" [(ngModel)]="conwidth" placeholder="ex. (75cm)" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.width.errors" class="invalid-feedback">
                                                <div *ngIf="fval.width.errors.required">
                                                    Width is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-height">Height (CM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="height"
                                                class="form-control form-control-sm input-field" id="form-height"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.height.errors
                          }" [(ngModel)]="conheight" placeholder="ex. (75cm)" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.height.errors" class="invalid-feedback">
                                                <div *ngIf="fval.height.errors.required">
                                                    height is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg">
                                            <label for="form-weight">Shipment Weight (GM)<span
                                                    class="star-color">*</span></label>
                                            <input type="number" formControlName="weight"
                                                class="form-control form-control-sm input-field" id="form-weight"
                                                [ngClass]="{
                            'is-invalid': submitted && fval.weight.errors
                          }" [(ngModel)]="conweight" placeholder="ex. (20gm)" [readonly]="checkStatus" />

                                            <div *ngIf="submitted && fval.weight.errors" class="invalid-feedback">
                                                <div *ngIf="fval.weight.errors.required">
                                                    Weight is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center mt-2">
                                    <button type="button" class="btn btn-sm btn-outline-default"
                                        (click)="updateTrackingId()">
                                        Update Tracking Id
                                    </button>
                                </div>
                                <div class="mt-3 form-group text-center">
                                    <button class="btn btn-md btn-primary" href="#carouselExampleControls"
                                        data-slide="next" [disabled]="checkStatus">
                                        NEXT
                                    </button>
                                    <button class="btn btn-md btn-primary" type="button" (click)="closeModal()">
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="carousel-item text-light" id="slide2">
                            <form [formGroup]="priceCheckForm" (ngSubmit)="submitUpdatePrice()">
                                <div class="font-weight-bold mt-5 mb-3">
                                    Select Shipping Speed >>
                                </div>

                                <div class="border p-3 borser-des">
                                    <div class="text-center" *ngIf="
                      !(
                        priceData.costForEMSSpeedPost &&
                        priceData.costForInternationalAirParcel &&
                        priceData.costForAirservice &&
                        priceData.costForInternationalTrackedPackets
                      )
                   ">
                                        No shipping method available
                                    </div>

                                    <div class="form-group text-light">
                                        <div class="border-des" *ngIf="priceData.costForEMSSpeedPost != 'Not Available'"
                                            class="d-flex justify-content-between m-2">
                                            <div *ngIf="checkEMS && priceData.costForEMSSpeedPost">
                                                <input id="costForEMSSpeedPost" type="radio" value="costForEMSSpeedPost"
                                                    name="priceCheck" formControlName="priceCheck"
                                                    [(ngModel)]="conshippingMethod" [readonly]="checkStatus" />
                                                <label class="label1" for="costForEMSSpeedPost">
                                                    EMS Speed Post end to end tracking & delivery time 7
                                                    -9 days
                                                </label>
                                            </div>

                                            <div *ngIf="
                          checkEMS == false && priceData.costForEMSSpeedPost
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForEMSSpeedPost">
                                                    EMS Speed Post end to end tracking & delivery time 7
                                                    -9 days
                                                </label>
                                            </div>
                                            <div *ngIf="priceData?.costForEMSSpeedPost" class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForEMSSpeedPost }}
                                            </div>
                                        </div>

                                        <div *ngIf="
                        priceData.costForInternationalAirParcel !=
                        'Not Available'
                     " class="d-flex justify-content-between m-2">
                                            <div *ngIf="
                          checkInternational &&
                          priceData.costForInternationalAirParcel
                       ">
                                                <input id=" costForInternationalAirParcel" type="radio"
                                                    value="costForInternationalAirParcel" name="priceCheck"
                                                    formControlName="priceCheck" [(ngModel)]="conshippingMethod"
                                                    [readonly]="checkStatus" />
                                                <label class="label1" for="costForInternationalAirParcel">
                                                    International Air Parcel end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkInternational == false &&
                          priceData.costForInternationalAirParcel
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForEMSSpeedPost">
                                                    International Air Parcel end to end tracking &
                                                    delivery time 14 - 20
                                                </label>
                                            </div>
                                            <div *ngIf="priceData?.costForInternationalAirParcel"
                                                class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForInternationalAirParcel }}
                                            </div>
                                        </div>

                                        <div *ngIf="priceData.costForAirservice != 'Not Available'"
                                            class="d-flex justify-content-between m-2">
                                            <div *ngIf="checkAirService && priceData.costForAirservice">
                                                <input id="costForAirservice" type="radio" value="costForAirservice"
                                                    name="priceCheck" formControlName="priceCheck"
                                                    [(ngModel)]="conshippingMethod" [readonly]="checkStatus" />

                                                <label class="label1" for="costForAirservice">Small Packets (Air
                                                    Service) No tracking & delivery
                                                    time 20 -25 days
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkAirService == false &&
                          priceData.costForAirservice
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForEMSSpeedPost">
                                                    Small Packets (Air Service) No tracking & delivery
                                                    time 20 -25 days
                                                </label>
                                            </div>
                                            <div *ngIf="priceData?.costForAirservice" class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForAirservice }}
                                            </div>
                                        </div>

                                        <div *ngIf="
                        priceData.costForInternationalTrackedPackets !=
                        'Not Available'
                     " class="d-flex justify-content-between m-2">
                                            <div *ngIf="
                          checkTrackedPackets &&
                          priceData.costForInternationalTrackedPackets
                       ">
                                                <input id="costForInternationalTrackedPackets" type="radio"
                                                    value="costForInternationalTrackedPackets" name="priceCheck"
                                                    formControlName="priceCheck" [(ngModel)]="conshippingMethod"
                                                    [readonly]="checkStatus" />
                                                <label class="label1"
                                                    for="costForInternationalTrackedPackets">International Tracked
                                                    Packets end to end tracking & delivery time 14 - 20
                                                </label>
                                            </div>
                                            <div *ngIf="
                          checkTrackedPackets == false &&
                          priceData.costForInternationalTrackedPackets
                       ">
                                                <img class="ml-2" style="width: 23px"
                                                    src="../../../assets/personal_export/filled-circle.png" />
                                                <label class="label1 ml-2" for="costForEMSSpeedPost">
                                                    International Tracked Packets end to end tracking & delivery time 14
                                                    - 20
                                                </label>
                                            </div>
                                            <div *ngIf="priceData?.costForInternationalTrackedPackets"
                                                class="font-weight-bold" style="
                          background-color: #f80;
                          border-radius: 16px;
                          padding: 8px;
                          margin: 0px;
                       ">
                                                Rs. {{ priceData.costForInternationalTrackedPackets }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mt-5 mb-5">
                                    <button class="btn btn-md btn-primary" type="button" (click)="goBack1()">
                                        Back
                                    </button>
                                    <button class="btn btn-md btn-primary" type="submit"
                                        [disabled]="!priceCheckForm.valid">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="trackingIdUpdate">
    <div class="modal-dialog modal-dialog2 modal-dialog-centered" role="document">
        <div class="modal-content modal-overlay">
            <div class="modal-header">
                <h5 class="modal-title text-warning">Update Tracking Id</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="" class="text-light">Tracking Id</label>
                <input type="text" class="form-control" [(ngModel)]="contrackingNo" />
                <div class="text-danger mt-1" *ngIf="checktracking" style="font-size: smaller">
                    Tracking ID is required
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="btn btn-default btn-sm" (click)="submitTrackingId(refrenceNumber)">
                    Submit
                </button>
                <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal" aria-label="Close">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>