import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import { AuthService } from 'src/app/services/auth.service';
import { DateRangePickerService } from 'src/app/services/date-range-picker.service';
import { PagerService } from 'src/app/services/pager.service';


@Component({
  selector: 'app-pickupsubscription',
  templateUrl: './pickupsubscription.component.html',
  styleUrls: ['./pickupsubscription.component.css']
})
export class PickupsubscriptionComponent implements OnInit {

  datePickerConfig: IDatePickerConfig = {
    format: 'DD-MM-YYYY'
    // format: 'YYYY-DD-MM'
  }

  constructor(public router: Router, public authService: AuthService, private dp: DateRangePickerService,
    public pagerService: PagerService, private formBuilder: FormBuilder) {
    this.createForm1();
  }

  dataCount = 0;
  pager: any = {};
  pagedItems: any[];
  users = []
  checkArr = []
  checkEditButton = []
  data = []
  date;
  loading = false
  searchInput = '';
  searchQuery = ''
  todate: any = '';
  fromdate: any = '';
  pickupEditDate: any = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } }

  isSearch = false

  submitForm: FormGroup;
  rate;
  pickupRequest;
  startDate;
  endDate;
  submitted = false;
  updatedPbe;
  updatedParcel;
  checkActivate = false;
  requestObject = {};
  isLoading = true;
  editButtonPbe = [];
  public myDatePickerOptions1 =
    {
      dateFormat: 'dd-mm-yyyy',
      markCurrentDay: true,
      disableWeekends: true,
      disableUntil: new Date().getHours() >= 12 ? { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } : { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 },
      disableSince: new Date().getHours() >= 12 ? { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 3 } : { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 2 },
    };
  public myDatePickerOptions =
    {
      dateFormat: 'dd-mm-yyyy',
      markCurrentDay: true,
    };

  public myDatePickerOptions2 =
    {
      dateFormat: 'dd-mm-yyyy',
      markCurrentDay: true,
      disableWeekends: false,
    };

  ngOnInit() {
    this.setPage(1);
  }

  check = false;

  setPage(p) {
    this.submitForm.setValue({ rate: "", startDate: "", endDate: "", pickupRequest: "" });
    this.authService.allUsersCount({
      userType: 1, ismonthlyPickupSubscriptionUser: true, isSearch: this.isSearch,
      searchInput: this.searchInput, searchQuery: this.searchQuery,
      fromdate: this.dp.GetFormatedDate(this.fromdate),
      todate: this.dp.GetFormatedDate(this.todate),
    }).subscribe(result => {
      if (result.success) {
        this.dataCount = result.count;
        this.pager = this.pagerService.getPager(this.dataCount, p);
        this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
      }
      else {
        this.dataCount = 0
      }
    })

    this.authService.allusers({
      page: p, ismonthlyPickupSubscriptionUser: true, userType: 1,
      isSearch: this.isSearch, searchInput: this.searchInput,
      searchQuery: this.searchQuery,

      fromdate: this.dp.GetFormatedDate(this.fromdate),
      todate: this.dp.GetFormatedDate(this.todate),
    }).subscribe(data => {
      if (data.success && data.hasOwnProperty('users')) {
        this.users = data.users;
        this.checkArr = new Array(this.users.length).fill(false);
        this.checkEditButton = new Array(this.users.length).fill(false).map(() => new Array(10).fill(false));
        this.editButtonPbe = new Array(this.users.length).fill(false);
        this.check = true;
      }
      else {
        this.users = [];
        this.dataCount = 0;
        this.check = false;
        this.authService.showAlert(data.message)
      }
    })
  }

  filter() {
    this.isSearch = true;
    this.setPage(1);
  }

  createForm1() {
    this.submitForm = this.formBuilder.group({
      rate: ['', [Validators.required, Validators.pattern("^[0-9]{1,}")]],
      pickupRequest: ['', [Validators.required, Validators.pattern("^[0-9]{1,}")]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  get f() { return this.submitForm.controls; }

  submitDataRow(value, id, pbe, parcel, email, index) {
    this.submitted = true;
    if (this.submitForm.invalid) {
      this.checkArr[index] = true;
      return;
    }
    if (!value.rate) {
      return;
    }
    if (!value.startDate) {
      return;
    }
    if (!value.endDate) {
      return;
    }
    if (!value.pickupRequest) {
      return;
    }

    this.authService.addMonthlySubscriptionbysuperuser({ rate: value.rate, startDate: value.startDate, endDate: value.endDate, totalPickupRequests: value.pickupRequest, userId: id, noPbePerMonth: pbe, noParcelsPerPickup: parcel, customerEmail: email }).subscribe(data => {
      if (data.success) {

        this.setPage(this.pager.currentPage);
        this.submitForm.setValue({ rate: "", startDate: "", endDate: "", pickupRequest: "" });
        this.authService.showAlert(data.message)
      }
      else {
        this.authService.showAlert(data.message)
      }
    })
  }


  enterfieldPbe(event) {
    this.updatedPbe = event.target.value;
    // console.log(this.updatedPbe);
  }
  updateValuePbe(value, id, i, num) {
    this.authService.editPickupSubscriptionValues({ userId: id, noPbePerMonth: value }).subscribe(data => {
      if (data.success) {
        this.authService.showAlert(data.message);
        this.cancelEdit(i, num);
        this.setPage(this.pager.currentPage);
      }
      else {
        if (!value) {
          this.authService.showAlert("Please enter the new value");
          return;
        }
      }
    })
  }


  enterfieldParcel(event) {
    this.updatedParcel = event.target.value;
    // console.log(this.updatedParcel);
  }
  updateValueParcel(value, id, i, num) {
    this.authService.editPickupSubscriptionValues({ userId: id, noParcelsPerPickup: value }).subscribe(data => {
      if (data.success) {
        this.authService.showAlert(data.message);
        this.cancelEdit(i, num);
        this.setPage(this.pager.currentPage);
      }
      else {
        if (!value) {
          this.authService.showAlert("Please enter the new value");
          return;
        }
      }
    })
  }

  convertPickupRequesttoObject() {
    let requestObject = {};
    for (let d of this.data) {
      requestObject[d._id] = d.pickupStatus;
    }
    return requestObject;
  }

  inputfield() {
    if (this.searchInput) {
      return true;
    }
    if (this.fromdate && this.todate) {
      return true;
    }
    else
      return false;
  }

  reset() {
    this.searchInput = '';
    this.searchQuery = '';
    this.todate = '';
    this.fromdate = '';
    this.isSearch = false;
    this.setPage(1);
  }

  displayDate(dateFix) {
    const date = new Date(dateFix);
    return date.getUTCDate() + "-" + date.getUTCMonth() + "-" + date.getUTCFullYear();
  }

  today = new Date();
  todayDate = this.today.getUTCDate() + '-' + (this.today.getUTCMonth() + 1) + '-' + this.today.getUTCFullYear();

  checkActive(num, date, remainingNoPbePerMonth) {
    return num > 0 && this.today.getTime() < new Date(date).getTime() && remainingNoPbePerMonth > 0;
  }

  checkEdit(rowindex, colindex) {

    this.checkEditButton[rowindex][colindex] = true;
  }
  cancelEdit(index, colindex) {
    this.checkEditButton[index][colindex] = false;
  }



}
