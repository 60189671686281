import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token
    let jwtToken = localStorage.getItem('token');
    // // console.log('jwtToken', jwtToken);
    // check another param which is observable and indicate user logged in 
    if (this.authService.loggedIn()) {
      try {
        let urlStr = request.url.split("/");
        // // console.log("urlStr >> ", urlStr);

        if (urlStr.includes("betad2.pikmybox.com")) {
          let token = localStorage.getItem("d2Auth");
          request = request.clone({
            setHeaders: {
              authorization: token,
            },
          });
        }
        else {
          request = request.clone({
            setHeaders: {
              authorization: jwtToken,
            },
          });
        }
      } catch (error) {
        // console.log("catch >> ", error);
      }
    }
    // else {
    //   let data = this.cprService.bdl(request.body);
    //   request = request.clone({
    //     body: { encStr: data }
    //   });
    // }
    // // console.log(request);
    return next.handle(request);
  }

}
