import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'robots', content: 'nofollow,noindex' });
    // this.meta.addTag({ name: 'descriptions', content: 'How to use Angular 4 meta service' });
    // var meta = document.createElement('meta');
    // meta.name = "robots"
    // meta.content = "nofollow,noindex";
    // document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit() {
    // var meta = document.createElement('meta');
    // meta.name = "robots"
    // meta.content = "nofollow,noindex";
    // document.getElementsByTagName('head')[0].appendChild(meta);
  }
  ngOnDestroy() {
    this.meta.removeTag('name="robots"')
  }

}
