import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  currentUrl;
  isVerifiedLink = false;
  message;
  form;
  constructor(public authService: AuthService, public router: Router,
    public activatedRoute: ActivatedRoute, public formBuilder: FormBuilder, private nfs: NotificationService) {
    this.createForm()
  }

  ngOnInit() {
    this.currentUrl = this.activatedRoute.snapshot.params;
    this.checkForValidString(this.currentUrl.str);
  }

  createForm() {
    this.form = this.formBuilder.group({
      password: ['',
        Validators.compose([
          Validators.required, Validators.minLength(8)
        ])],
      cpassword: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ])],
    })
  }

  passwordValidation(control) {
    if (control.value == null || control.value == "") return null;
    var c = (<HTMLInputElement>document.getElementById('cpassword')).value;;
    var newP = (<HTMLInputElement>document.getElementById('password')).value;
    if (c == newP) {
      return { 'cpassword': true };
    }
    else {
      return { 'cpassword': false };
    }
  }



  checkForValidString(str) {
    this.authService.checkEncrptedString(str).subscribe(data => {
      if (!data.success) {
        this.message = data.message;
        this.isVerifiedLink = false
      }
      else {
        this.isVerifiedLink = true
      }
    })
  }

  public removeWhiteSpace(e) {
    // // console.log("e.which >> ", e.which);

    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;

    }
    return true;
  }


  resetPassword() {
    var newP = this.form.controls.password.value;
    var conP = this.form.controls.cpassword.value;

    if (newP != conP) {
      this.nfs.showInfo('Error', 'New Password & Confirm Password are not same.', 'error')
    }
    else {
      const obj = {
        password: newP,
        str: this.currentUrl.str
      }
      this.authService.setNewpassword(obj).subscribe((data) => {
        if (data.success) {
          this.form.reset;
          this.router.navigate(['/login'])
          this.nfs.showInfo('Success', data.message, 'success');
        }
        else {
          this.nfs.showInfo('Error', data.message, 'error');
        }
      })
    }

  }

}
