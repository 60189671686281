<div class="container">
    <div class="row" style="margin-top:5%">
        <div class="col-sm-4 offset-md-3">


            <!--  -->
            <ng-multiselect-dropdown [placeholder]="'Select Country'" [settings]="settings" [data]="dropdownList"
                [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
            </ng-multiselect-dropdown>
            <!--  -->

        </div>
        <div class="col-sm-2">
            <button class="btn btn-outline-primary btn-block" (click)="reset()"
                style="margin: 0; height: 43px;">Reset</button>
        </div>
    </div>
</div>

<div class="container" style="margin-top:1%;width:100%" *ngIf="!isSearch">
    <table class="table table-bordered m-b-0 text-center" id="mainTable">
        <thead>
            <tr>
                <td rowspan="2" style="width: 10%">S.No.</td>
                <th rowspan="2" style="width:10%">Country </th>
                <th colspan="3" rowspan="1" style="text-align: center;">De minimis value "no duty/tax collection"</th>
                <th colspan="3" rowspan="1" style="text-align: center;">Threshold for Simplied Declarations</th>
                <th style="width:20%;" rowspan="2">Remark</th>

            </tr>
            <tr>

                <td style="text-align: center;">National Currency</td>
                <td style="text-align: center;">USD Value</td>
                <td style="text-align: center;">SDR Value</td>
                <td style="text-align: center;">National Currency</td>
                <td style="text-align: center;">USD Value</td>
                <td style="text-align: center;">SDR Value</td>
            </tr>

        </thead>
        <tbody>
            <tr *ngFor="let k of thresholdData;let i=index">
                <td> {{i+1}}</td>
                <td>{{k.Country}}</td>
                <td>{{k.National_Currency_Dem}}</td>
                <td>{{k.USD_Value_Dem==null?'_':k.USD_Value_Dem}}</td>
                <td>{{k.SDR_Value_Dem==null?'_':k.SDR_Value_Dem}}</td>
                <td>{{k.National_Currency_Inf}}</td>
                <td>{{k.USD_Value_Inf==null?'_':k.USD_Value_Inf}}</td>
                <td>{{k.SDR_Value_Inf==null?'_':k.SDR_Value_Inf}}</td>
                <td>{{k.Remark==null?'_':k.Remark}}</td>

            </tr>

        </tbody>

    </table>

    <p style="text-align: center;">* Disclaimer: This list contains information from the Global Express Association
        (GEA) database and other publicly available sources. It may not be perfectly accurate or up to date. We do not
        accept any liability for any inaccuracies contained therein or decisions made on the basis of the information
        listed here. Please help us improve this list by sending us additional information from authoritative sources to
        support@pikmybox.com and we will update it as appropriate</p>
    <!-- <div class="text-center" style="margin-bottom:5%">
            <button  class="btn btn-outline-primary" (click)="reset()">Reset</button>
            <button  class="btn btn-outline-primary"  (click)="goback()" *ngIf="previous?.length>1">Back</button>
          </div> -->

</div>

<div class="container" style="margin-top:1%;width:100%" *ngIf="isSearch">
    <table class="table table-bordered m-b-0 text-center" id="mainTable">
        <thead>
            <tr>
                <td rowspan="2" style="width: 10%">S.No.</td>
                <th rowspan="2" style="width:10%">Country </th>
                <th colspan="3" rowspan="1" style="text-align: center;">De minimis value "no duty/tax collection"</th>
                <th colspan="3" rowspan="1" style="text-align: center;">Threshold for Simplied Declarations</th>
                <th style="width:20%" rowspan="2">Remark</th>

            </tr>
            <tr>

                <td style="text-align: center;">National Currency</td>
                <td style="text-align: center;">USD Value</td>
                <td style="text-align: center;">SDR Value</td>
                <td style="text-align: center;">National Currency</td>
                <td style="text-align: center;">USD Value</td>
                <td style="text-align: center;">SDR Value</td>
            </tr>

        </thead>
        <tbody>
            <tr *ngFor="let k of filteredData;let i=index">
                <td> {{i+1}}</td>
                <td>{{k.Country}}</td>
                <td>{{k.National_Currency_Dem}}</td>
                <td>{{k.USD_Value_Dem==null?'_':k.USD_Value_Dem}}</td>
                <td>{{k.SDR_Value_Dem==null?'_':k.SDR_Value_Dem}}</td>
                <td>{{k.National_Currency_Inf}}</td>
                <td>{{k.USD_Value_Inf==null?'_':k.USD_Value_Inf}}</td>
                <td>{{k.SDR_Value_Inf==null?'_':k.SDR_Value_Inf}}</td>
                <td>{{k.Remark==null?'_':k.Remark}}</td>

            </tr>

        </tbody>

    </table>

    <p style="text-align: center;">* Disclaimer: This list contains information from the Global Express Association
        (GEA) database and other publicly available sources. It may not be perfectly accurate or up to date. We do not
        accept any liability for any inaccuracies contained therein or decisions made on the basis of the information
        listed here. Please help us improve this list by sending us additional information from authoritative sources to
        support@pikmybox.com and we will update it as appropriate</p>
    <!-- <div class="text-center" style="margin-bottom:5%">
        <button  class="btn btn-outline-primary" (click)="reset()">Reset</button>
        <button  class="btn btn-outline-primary"  (click)="goback()" *ngIf="previous?.length>1">Back</button>
      </div> -->

</div>