<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4" style="margin: auto;">
                <div class="card m-b-20">
                    <div class="card-body">
                        <div class="accountbg"></div>
                        <div class="wrapper-page">
                            <div class="p-3">
                                <h4 class="text-muted font-18 m-b-5 text-center">{{greeting}}</h4>
                                <div class="m-b-20">
                                    <div class="">
                                        <ul class="nav nav-tabs nav-justified" role="tablist">
                                            <li class="nav-item waves-effect waves-light" *ngIf="isCustoms">
                                                <a class="nav-link" (click)="changeUserType('5')"
                                                    [ngClass]="{'active': (selectedUserType=='5')}">FPO</a>
                                            </li>
                                            <li class="nav-item" *ngIf="isCustoms">
                                                <a class="nav-link" (click)="changeUserType('6')"
                                                    [ngClass]="{'active': (selectedUserType=='6')}">CBIC</a>
                                            </li>
                                            <li class="nav-item" *ngIf="!isCustoms">
                                                <a class="nav-link" (click)="changeUserType('7')"
                                                    [ngClass]="{'active': (selectedUserType=='7')}">DGFT</a>
                                            </li>
                                        </ul>
                                        <br>
                                        <div class="tab-content">
                                            <div class="tab-pane active p-3" id="home2" role="tabpanel">
                                                <form class="form-horizontal m-t-30">
                                                    <!-- <div class="form-group">
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" (click)="changeUserType('5')" checked class="custom-control-input" id="defaultInline1" name="inlineDefaultRadiosExample">
                                                            <label class="custom-control-label" for="defaultInline1">FPO</label>
                                                        </div>

                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" (click)="changeUserType('6')" class="custom-control-input" id="defaultInline2" name="inlineDefaultRadiosExample">
                                                            <label class="custom-control-label" for="defaultInline2">CBIC </label>
                                                        </div>
                                                    </div> -->
                                                    <div class="form-group">
                                                        <label for="uname">Username</label>
                                                        <input type="text" class="form-control" [(ngModel)]="userName"
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter Username">
                                                    </div>
                                                    <div class="form-group" *ngIf="!isForgotPassword">
                                                        <label for="userpassword">Password/OTP</label>
                                                        <input type="password" class="form-control"
                                                            [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter password or OTP">
                                                    </div>
                                                    <div class="form-group" *ngIf="!isForgotPassword && isFirstLogin">
                                                        <label for="newpassword">New Password</label>
                                                        <input type="password" class="form-control"
                                                            [(ngModel)]="newPassword"
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter new password">
                                                    </div>
                                                    <div class="form-group" *ngIf="!isForgotPassword && isFirstLogin">
                                                        <label for="confirmpassword">Confirm Password</label>
                                                        <input type="password" class="form-control"
                                                            [(ngModel)]="confirmPassword"
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter new password again">
                                                    </div>
                                                    <div class="form-group row m-t-20">
                                                        <!-- <div class="col-sm-6">
                                                          <div class="custom-control custom-checkbox">
                                                              <input type="checkbox" class="custom-control-input" id="customControlInline">
                                                              <label class="custom-control-label" for="customControlInline">Remember me</label>
                                                          </div>
                                                      </div> -->
                                                        <div class="col-sm-12 text-center" *ngIf="!isForgotPassword">
                                                            <button [disabled]="inProcess"
                                                                class="btn btn-outline-warning waves-effect waves-light"
                                                                *ngIf="isFirstLogin" type="submit"
                                                                (click)="verifyAndSetPassword()">Submit & Login</button>
                                                            <button
                                                                class="btn btn-outline-warning waves-effect waves-light"
                                                                *ngIf="!isFirstLogin" type="submit"
                                                                (click)="login()">Log In</button>
                                                            <button
                                                                class="btn btn-outline-info waves-effect waves-light"
                                                                (click)="isFirstLogin=false;" type="button"
                                                                type="reset">Reset</button>
                                                        </div>
                                                        <div class="col-sm-12 text-center" *ngIf="isForgotPassword">
                                                            <button
                                                                class="btn btn-warning w-md waves-effect waves-light"
                                                                type="button" (click)="reset(false)">Cancel</button>
                                                            <button
                                                                class="btn btn-primary w-md waves-effect waves-light"
                                                                type="button" (click)="forgot()">Reset</button>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form-group m-t-10 mb-0 row">
                                                      <div class="col-12 m-t-20">
                                                          <a href="pages-recoverpw.html" class="text-muted">
                                                              <i class="mdi mdi-lock"></i> Forgot your password?</a>
                                                      </div>
                                                  </div> -->
                                                    <div class="form-group m-t-10 mb-0 row" *ngIf="!isForgotPassword">
                                                        <div class="col-12 m-t-20">
                                                            <a href="javascript: void(0)" class="text-muted"
                                                                (click)="reset(true)">
                                                                <i class="mdi mdi-lock"></i> Forgot your password?</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>