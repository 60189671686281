<!-- <toaster-container [toasterconfig]="config"></toaster-container> -->
<div class="wrapper" style="margin-top:0px">
    <div class="container-fluid" style="margin-top:1%">
        <div class="row">
            <div class="col-md-2 col-xs-12" style="margin-bottom:30px">
                <div class="list-group" id="list-tab" role="tablist">
                    <a class="list-group-item list-group-item-action btn" id="list-home-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="home" (click)="getValue('All')">All Tickets</a>

                    <a class="list-group-item list-group-item-action btn active" id="list-pending-list"
                        data-toggle="list" href="#list-home" role="tab" aria-controls="profile"
                        (click)="getValue('Pending')">Pending Tickets</a>

                    <a class="list-group-item list-group-item-action btn" id="list-approved-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="profile" (click)="getValue('Approved')">Approved
                        Tickets</a>

                    <a class="list-group-item list-group-item-action btn" id="list-progress-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="shipping" (click)="getValue('In Process')">In
                        Process
                    </a>

                    <a class="list-group-item list-group-item-action btn" id="list-progress-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="shipping" (click)="getValue('Assigned')">Assigned
                        Tickets
                    </a>

                    <a class="list-group-item list-group-item-action btn" id="list-resolved-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="currency" (click)="getValue('Resolved')">Resolved
                        Tickets
                    </a>

                    <!-- <a class="list-group-item list-group-item-action btn" id="list-progress-list" data-toggle="list"
            href="#list-home" role="tab" aria-controls="shipping" (click)="getValue('Rejected')">Rejected
          </a> -->

                </div>
            </div>
            <div class="col-md-10">
                <div class="card">

                    <!-- Card image -->

                    <div class="tab-content" id="nav-tabContent">

                        <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                            aria-labelledby="list-home-list">

                            <h4 class="card-title textShadow">
                                {{tableHeading}} Tickets
                            </h4>
                            <div class="card-body">
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="form-group" style="margin-bottom:0px">
                                            <select class="form-control" [(ngModel)]="searchText">
                                                <option value="All">All</option>
                                                <option value="Issue">Report an Issue</option>
                                                <option value="Demo Request">Get a Demo</option>
                                                <option value="Feature Request">Suggest a Feature</option>
                                                <option value="Contact Us Form">Contact Us Form</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-2 text-center">
                                        <div class="form-group" style="margin-bottom:0px" style="margin-bottom: 0;">
                                            <button type="button" (click)="setPage(1)"
                                                class="btn btn-block btn-sm btn-outline-default btn-block">
                                                <i class="fa fa-eye"></i>&nbsp; View</button>
                                        </div>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="form-group" style="margin-bottom:0px" style="margin-bottom: 0;">
                                            <button type="button"
                                                (click)='(searchText="All");setPage(1)'
                                                class="btn btn-sm btn-block btn-outline-danger btn-block">
                                                <i class="fa fa-refresh"></i> &nbsp;Reset</button>
                                        </div>
                                    </div>

                                </div>


                                <div class="">
                                    <h5 *ngIf="count" style="text-align: right;">Total
                                        <span class="text-primary" style="font-weight: 700">&nbsp;{{count}}</span>
                                        Tickets Found
                                    </h5>
                                    <nav style="margin: auto" *ngIf="ticketsData?.length>0">
                                        <ul class="pagination  pg-amber justify-content-center"
                                            *ngIf="pager.pages && pager.pages.length">
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link waves-effect" (click)="setPage(1)">First</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                                <a class="page-link"
                                                    (click)="setPage(pager.currentPage - 1)">Previous</a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pager.pages"
                                                [ngClass]="{active:pager.currentPage === page}">
                                                <a class="page-link" (click)="setPage(page)">{{page}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                                <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                                <table class="table table-bordered mainTable" *ngIf="ticketsData?.length>0">
                                    <thead class="text-center">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Ticket Id</th>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Created By</th>
                                            <th>Comments</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of ticketsData;let i=index">
                                            <td>{{i+1}}</td>
                                            <td><a style="padding:5px;width:105px;font-weight: 600"
                                                    class="btn btn-primary btn-sm"
                                                    (click)="getticketdetails(data._id)">{{data.ticketId}}</a></td>
                                            <td><span *ngIf="data.type!='Demo Request'">{{data.title}}</span>
                                                <span *ngIf="data.type=='Demo Request'">{{data.type}}</span>
                                            </td>
                                            <td>{{data.type}}</td>
                                            <td>{{data.reportedBy.name}}</td>
                                            <td>{{data.reportedBy.email}}</td>
                                            <td>{{data.reportedBy.mobile}}</td>
                                            <td>{{data.createdBy}}</td>
                                            <td style="cursor:pointer" data-toggle="modal"
                                                data-target="#fullHeightModalRight"
                                                (click)="getComments(data._id,data.ticketId)"><i class="fa fa-comment"
                                                    style="color:#ff6f00; font-size:20px"></i>
                                                <i *ngIf="data.comments?.length>0" class="fa fa-circle"
                                                    style="color: #2bbbad;font-size: 9px;position: absolute;"></i>

                                            </td>
                                            <!-- <td>
                        <button class="btn btn-sm btn-info" *ngIf="data.images?.length>0" (click)="viewImages(data.images)">View</button>
                        <button class="btn btn-sm btn-danger" *ngIf="data.images==''">NA</button>
                      </td> -->
                                            <td style="cursor:pointer" style="width:140px;">
                                                <button *ngIf="data.status=='Pending'"
                                                    class="btn btn-outline-primary btn-sm"
                                                    (click)="deleteTicket(data._id,'All')"><i
                                                        class="fa fa-trash"></i></button>

                                                <select
                                                    *ngIf="data.status=='Approved' || data.status=='In Process' || data.status=='Resolved' || data.status=='Rejected'"
                                                    class="form-control" [(ngModel)]="data.status"
                                                    (change)="updateTicket(data._id,data.status)">
                                                    <!-- <option value="Pending" [disabled]="(data.status!='Pending' || data.status=='Rejected')">Pending</option>-->
                                                    <option value="Approved"
                                                        [disabled]="(data.status=='Approved' || data.status=='In Process' || data.status=='Resolved' || data.status=='Rejected')">
                                                        Approved</option>
                                                    <option value="Rejected"
                                                        [disabled]="(data.status!='Pending' && data.status!='Rejected')">
                                                        Rejected</option>
                                                    <option value="In Process"
                                                        [disabled]="(data.status!='Approved' || data.status=='In Process' || data.status=='Resolved' || data.status=='Rejected')">
                                                        In
                                                        Process</option>
                                                    <!-- <option value="Assigned" [disabled]="(data.status!='In Process')">Assign
                              </option> -->
                                                    <option value="Resolved"
                                                        [disabled]="data.status=='Pending' || data.status=='Rejected'">
                                                        Resolved</option>
                                                </select>


                                                <button class="btn btn-info btn-sm" data-toggle="modal"
                                                    data-target="#ticketModal" (click)="assignTicketBtnClick(data)"
                                                    *ngIf="data.status=='In Process'">Assign</button>

                                                <button class="btn btn-warning btn-sm" data-toggle="modal"
                                                    data-target="#taskModal"
                                                    (click)="getTaskDetails(data.pmsId, data.ticketId)"
                                                    *ngIf="data.status=='Assigned'">Assigned</button>
                                                <button class="btn btn-indigo btn-sm" data-toggle="modal"
                                                    data-target="#taskModal"
                                                    (click)="getTaskDetails(data.pmsId, data.ticketId)"
                                                    *ngIf="data.status=='Resolved' && data.pmsId?.length>5">View
                                                    Task</button>

                                                <div *ngIf="data.status=='Pending'">
                                                    <p class="onoff">
                                                        <input [checked]="data.status=='Approved'"
                                                            [ngClass]="{'input:checked + label':(data.status=='Approved')}"
                                                            type="checkbox" (change)="checkEvent($event,i,data._id)"
                                                            value="{{data.status}}" id="checkbox{{i}}">
                                                        <label for="checkbox{{i}}"
                                                            id="check{{i}}">{{data.status}}</label>
                                                    </p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<!-- Modal: modalCart -->
<div class="modal" id="modalImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">All Images</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <!--Body-->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3" *ngFor="let im of images;let i=index">
                        <a *ngIf="checkFileExtn(im)"
                            href="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{im}}"
                            target="_blank"><img class="imgIcon"
                                src="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{im}}"
                                style="width:100%"></a>
                        <a class="btn btn-info btn-sm" *ngIf="!checkFileExtn(im)"
                            href="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{im}}"
                            target="_blank">Download File</a>
                    </div>
                </div>
                <!-- <table class="table table-bordered mainTable">
          <thead class="text-center">
            <tr>
              <th>S.No</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let im of images;let i=index">
              <td>{{i+1}}</td>
              <td><a class="btn btn-outline-success btn-sm" href="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{im}}"
                  target="_blank">View</a></td>

            </tr>
          </tbody>
        </table> -->

            </div>
            <!--Footer-->
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal: modalCart -->

<!-- Full Height Modal Right -->
<div class="modal right" id="fullHeightModalRight" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">

    <!-- Add class .modal-full-height and then add class .modal-right (or other classes from list above) to set a position to the modal -->
    <div class="modal-dialog modal-full-height modal-right" role="document">


        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel">Ticket Id : {{tid}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding:0px">
                <div class="frame">
                    <ul *ngIf="comments?.length>0">
                        <li style="width:100%" *ngFor="let comm of comments">
                            <div class="msj macro" *ngIf="(comm.name!=authService.getuserName())">
                                <!-- <div class="avatar"></div> -->
                                <div class="text text-l">
                                    <p>{{comm.text}}</p>
                                    <p style="margin-bottom:0"><small style="float:right;
                    color: rgb(255,0,102);">{{comm.name}}</small></p>
                                    <p><small>{{comm.date|timeAgo}}</small></p>
                                </div>
                            </div>

                            <div class="msj-rta macro" *ngIf="(comm.name==authService.getuserName())">
                                <div class="text text-r">
                                    <p>{{comm.text}}</p>
                                    <p style="margin-bottom:0"><small style="float:right;
                        color: rgb(145, 10, 64);">{{comm.name}}</small></p>
                                    <p><small>{{comm.date|timeAgo}}</small></p>
                                </div>
                            </div>

                        </li>


                    </ul>

                    <div class="col-md-12 text-center" style="top:45%" *ngIf="comments==''">
                        <h3 class="text-danger">Add your first comment</h3>
                    </div>

                    <div
                        style="padding-top: 10px;z-index: 99999;background: #a7a7a7;padding: 10px;bottom:0">
                        <div class="msj-rta macro" style="
            margin-top: 0px;
            width: 100%;
        ">
                            <div class="text text-r" style="background:whitesmoke !important">
                                <input class="mytext" placeholder="Type a message" [(ngModel)]="mycomment">
                            </div>

                        </div>
                        <div style="padding:10px;">
                            <span class="glyphicon glyphicon-share-alt"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="addComment()">Add Comment</button>
            </div>
        </div>
    </div>
</div>


<div id="open-modal" style="margin:0;" class="modal-window modal-dialog" [ngClass]="modal?'disModal':'hideModal'"
    *ngIf="description">
    <div class="modal-content" style="height:500px;overflow: auto">
        <a href="#" title="Close" class="modal-close" (click)="modal=false" style="background: #e41764;
    color: #fff;
    font-weight: 600;
    font-size: 12px;">Close</a>
        <h1>Ticket Id : {{description.ticketId}}</h1>
        <div>
            <table class="table table-bordered">
                <tbody class="text-center">
                    <tr>
                        <th>Type of Request</th>
                        <td>{{description.type}}</td>
                    </tr>
                    <tr>
                        <th>Category</th>
                        <td>{{description.title}}</td>
                    </tr>

                    <tr *ngIf="description.type=='Demo Request'">
                        <th>Demo Date</th>
                        <td>{{description.demo.date|date:'dd-MM-yyyy'}}</td>
                    </tr>
                    <tr *ngIf="description.type=='Demo Request'">
                        <th>Demo Time</th>
                        <td>{{description.demo.timeSlot}}</td>
                    </tr>

                    <tr>
                        <th>Status</th>
                        <td>{{description.status}}</td>
                    </tr>
                    <tr>
                        <th>Reported By</th>
                        <td>{{description.reportedBy.name}}</td>
                    </tr>
                    <tr *ngIf="description.status!='Pending'">
                        <th>Reviewed By</th>
                        <td>{{description.reviewedBy.name}}</td>
                    </tr>
                    <tr *ngIf="description.images?.length>0">
                        <th>Images/Files</th>
                        <td>
                            <div class="row">

                                <div class="col-md-3" *ngFor="let comm of description.images;let i=index">
                                    <img *ngIf="checkFileExtn(comm)" (click)="openModal();currentSlide(i+1)"
                                        class="hover-shadow cursor"
                                        src="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{comm}}"
                                        style="width:100px;height:100px">
                                    <a class="btn btn-info btn-sm" *ngIf="!checkFileExtn(comm)"
                                        href="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{comm}}"
                                        target="_blank">Download File</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="description.comments?.length>0">
                        <th>Comments</th>
                        <td>
                            <nav aria-label="breadcrumb" *ngFor="let comm of description.comments">
                                <ol class="breadcrumb" style="font-weight:600">
                                    <li class="breadcrumb-item"><a href="#">{{comm.text}}</a></li>
                                    <li class="breadcrumb-item"><a href="#">{{comm.name}}</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{comm.date| timeAgo}}</li>
                                </ol>
                            </nav>

                        </td>
                    </tr>

                    <tr>
                        <th>Updated At</th>
                        <td>{{description.updatedAt |date:'dd-MM-yyyy hh:mm a'}}</td>
                    </tr>

                    <tr *ngIf="description.desc">
                        <th>Description</th>
                        <td>{{description.desc}}</td>
                    </tr>

                    <tr *ngIf="description.url">
                        <th>URL</th>
                        <td style="cursor:pointer"><a href="https://{{description.url}}"
                                target="new">{{description.url}}</a></td>
                    </tr>

                </tbody>

            </table>
        </div>
    </div>
</div>


<div id="myModal" class="modal1" *ngIf="description">
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <div class="modal1-content">

        <div class="mySlides" *ngFor="let comm of description.images;let i=index">
            <div class="numbertext">{{i+1}} / {{description.images.length}}</div>
            <img src="https://s3.ap-south-1.amazonaws.com/picmybox-internal/tickets/{{comm}}" style="width:100%">
        </div>

        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>

    </div>
</div>


<div class="modal right" id="ticketModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="TicketModalLabel" aria-hidden="true">

    <!-- Add class .modal-full-height and then add class .modal-right (or other classes from list above) to set a position to the modal -->
    <div class="modal-dialog modal-full-height modal-right" role="document" style="padding-top: 14px;">


        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title w-100" id="guideModalLabel">Ticket ID - {{selectedTicketId}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>

                    <div class="form-group row">
                        <div class="col-sm-12" style="display:flex !important;">
                            <div class="col-md-6">
                                <label for="">Priority</label>
                                <select [(ngModel)]="priority" name="priority" class="form-control">
                                    <option value="Select Priority">Select Priority</option>
                                    <option value="Highest">Highest</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                    <option value="Lowest">Lowest</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="">Select Deadline</label>
                              
                                <input  [(ngModel)]="deadLine" >
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" style="margin-bottom: 0 !important;">
                        <div class="col-md-12">
                            <label for="" style="margin-left: 15px;">Assign To</label>
                          

                            <!--  -->
                            <ng-multiselect-dropdown [placeholder]="'custom placeholder'" [settings]="userSettings"
                                [data]="pmsUsers" [(ngModel)]="selectedUser" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown>
                            <!--  -->

                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="col-md-12">
                                <label for="">Title</label>
                                <input class="form-control" [(ngModel)]="title" type="text" placeholder="Enter Title">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="col-md-12">
                                <label>Description</label>
                                <textarea class="form-control" [(ngModel)]="desc" name=""
                                    placeholder="Describe the task..." id="" rows="5"></textarea>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-success" (click)="updateTicket(selectedTicket._id,'Assigned')"
                        data-dismiss="modal">Submit</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal right" id="taskModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="TaskModalLabel" aria-hidden="true">

    <!-- Add class .modal-full-height and then add class .modal-right (or other classes from list above) to set a position to the modal -->
    <div class="modal-dialog modal-full-height modal-right" role="document" style="padding-top: 14px;">


        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title w-100" id="guideModalLabel">Ticket ID - {{selectedTicketId}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="form-group row" style="    height: 415px;
          overflow-y: auto;">
                    <div class="col-sm-12" style="display:flex !important;">
                        <table class="table table-bordered" *ngIf="taskDetails">
                            <tbody>
                                <tr>
                                    <th>Task Code</th>
                                    <td>{{taskDetails.code}}</td>
                                </tr>
                                <tr>
                                    <th>Assignee</th>
                                    <td>
                                        <span *ngFor="let m of taskDetails.members">
                                            <label for="">{{m.name}}</label><br>
                                        </span>

                                    </td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{{taskDetails.status}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Title</th>
                                    <td>{{taskDetails.title}}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{{taskDetails.description}}</td>
                                </tr>
                                <tr>
                                    <th>Task Type</th>
                                    <td>{{taskDetails.taskType}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Created At</th>
                                    <td>{{taskDetails.createdAt | date:'dd/MM/yyyy'}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Deadline</th>
                                    <td>{{taskDetails.deadline | date:'dd/MM/yyyy'}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Created By</th>
                                    <td>{{taskDetails.createdBy.name}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Completed By</th>
                                    <td>{{taskDetails.completedBy.name}}

                                    </td>
                                </tr>
                                <tr>
                                    <th>Last Upadted At</th>
                                    <td>{{taskDetails.updatedAt | date:'dd/MM/yyyy'}}

                                    </td>
                                </tr>

                                <tr>
                                    <th>Comments</th>
                                    <td>
                                        <div *ngFor="let c of taskDetails.comments" style="    background: whitesmoke;
                                      padding: 5px;
                                      border-radius: 5px;
                                      margin-bottom: 10px;">
                                            {{c.name}}
                                            <br>
                                            {{c.comment}}
                                            <br>
                                            {{c.date | date:'dd/MM/yyyy'}}
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <button *ngIf="!taskDetails" class="btn btn-sm btn-outline-danger btn-block">No Data
                            Found</button>
                    </div>
                </div>

                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>