import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-afterloginhome',
  templateUrl: './afterloginhome.component.html',
  styleUrls: ['./afterloginhome.component.css']
})
export class AfterloginhomeComponent implements OnInit {
  clients;
  userData: any = {};
  ld = false;
  iecDigi = '';
  isSharing = false;
  isFileShared = false;
  docName = '';
  progressbar = false;
  progressbar1 = false;
  progresValue = 0;
  progresValue1 = 0;
  copy_route_filesToUpload: Array<File> = [];
  isFileUploading = true;
  imageData_copy_route: any = '';
  gstin: any = '';

  domesticUrl = environment.domesticUrl

  constructor(public authService: AuthService, public router: Router, private route: ActivatedRoute, private nfs: NotificationService) {
    if (this.authService.loggedIn()) {
      this.userData = this.authService.decodeToken();
      // // console.log("userData >>> ", this.userData);
      if (this.userData && (!this.userData.isExporter && !this.userData.isActive && this.userData.isDomestic)) {
        // this.redirectToDomestic();
        let token = localStorage.getItem("authToken");
        if (!this.userData.isDomesticActive) {
          this.nfs.showInfo('Oops', "Your Domestic account is in review.", 'warning');
        }
        else if (this.userData.isDomesticActive && this.userData.isDomestic) {
          window.open(this.domesticUrl + `?token=${token}`, '_self');
        }
      }

      if (this.authService.isCustomLoggedIn() || this.authService.isCbic()) {
        this.router.navigate(['/pbe'])
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // // console.log("params >>> ", params);
      if (params && params.token) {
        /** set auth token to local storage */
        localStorage.setItem("authToken", params.token);

        /** get user token */
        this.authService.getUserToken().subscribe(res => {
          // // console.log("getUserToken >>> ", res);
          if (res.success) {
            localStorage.setItem("token", res.userToken);
            this.router.navigate(['./home']);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("authToken");
            this.router.navigate(['']);
          }
        });
      }
    });
  }

  redirectToDomestic() {
    /** get AUTH token from localstorage */
    let token = localStorage.getItem("authToken");

    var user // let user = this.authService.decodeToken('token');

    this.authService.getProfileDetails().subscribe(response => {
      if (response.success == true) {
        user = { ...response.data, userId: response.data._id }
        // console.log("==true==")
      } else {
        // console.log("==false==")
        user = this.authService.decodeToken();
      }

      // // console.log("decoded user >>> ", user);
      if (user && !user.isDomesticActive && user.isDomestic) { // (user && !user.isDomesticActive && user.gstin)
        this.nfs.showInfo('Oops', "Your Domestic account is in review.", 'warning');
      }
      else if (user.isDomesticActive) {
        // window.open(`https://betad.pikmybox.com?token=${token}`, '_self'); //this.domesticUrl
        window.open(this.domesticUrl + `?token=${token}`, '_self'); //this.domesticUrl
      }
      else if (user && !user.isDomesticActive && !user.isDomestic) {
        this.nfs.showConfirmation(
          'Are you sure?',
          "Want to register domestic account with existing details.!",
          'warning',
          'Yes, Register',
        ).then((result) => {
          if (result.value) {

            if (!user.gstin || user.gstin == '') {
              // data-toggle="modal" data-target="#exampleModal"
              $('#gst').modal('show');
            } else {
              //  call api for create domestic account
              this.authService.regitserWithDetails(user).subscribe(res => {
                if (res.success) {
                  this.nfs.showInfo('Registered', res.message, 'success');
                  this.gstin = '';
                  this.docName = '';
                  this.authService.logoutFormServer().subscribe(rr => {
                    this.authService.logout();
                    this.router.navigate(['/login']);
                  });
                } else {
                  this.nfs.showInfo('Error', res.message, 'error');
                }
              });
            }
          }
        });
      } else {
        // console.log("Invalid click")
      }


    })


  }

  confirmAccount() {
    let user = this.authService.decodeToken();
    this.authService.regitserWithDetails({ ...user, gstin: this.gstin }).subscribe(res => {
      if (res.success) {
        this.nfs.showInfo('Registered', res.message, 'success');
        this.gstin = '';
        this.docName = '';
        this.authService.logoutFormServer().subscribe(rr => {
          this.authService.logout();
          this.router.navigate(['/login']);
        });
      } else {
        this.nfs.showInfo('Error', res.message, 'error');
      }
    });
  }

  goto(val) {
    this.router.navigate(['/' + val]);
  }

  copy_route_fileChangeEvent(fileInput: any, type) {
    //let fileList: FileList = event.target.files;
    this.copy_route_filesToUpload = <Array<File>>fileInput.target.files;

    this.progressbar1 = false;
    this.progressbar = true;
    this.progresValue = 0

    var size = this.copy_route_filesToUpload[0].size;
    size = size / 1024;
    if (this.copy_route_filesToUpload[0].type != 'application/pdf') {
      $('#doc').val(null)
      this.progressbar = false
      this.docName = "gstin"
      this.isFileUploading = true
      this.nfs.showInfo('Oops', 'Only PDF document is allowed!', 'warning')
    }
    else if (size <= 1024) {
      this.readThis_route(fileInput.target, type);
    }
    else {
      $('#doc').val(null)
      this.progressbar = false
      this.docName = ""
      this.isFileUploading = true
      this.nfs.showInfo('Oops', 'File is too large, max 1MB file is permitted', 'warning')
    }

  }

  readThis_route(inputValue: any, type): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {

      document.getElementById('progress').style.width = '50%'
      this.progresValue = 50

      this.imageData_copy_route = myReader.result;
      //   // console.log(this.imageData)
      this.uploadRouteCopy(type)
    }
    myReader.readAsDataURL(file);
  }

  uploadRouteCopy(type) {
    this.isFileUploading = true;
    var data = {
      uploadedFile: this.imageData_copy_route,
      gstin: this.gstin,
      docType: "gstin"
    }

    this.authService.uploadPdf(data).subscribe(res => {
      if (res.success) {
        this.docName = res.uploadedFile;
        document.getElementById('progress').style.width = '100%'
        this.progresValue = 100

        this.isFileUploading = false;
      }
      else {
        this.isFileUploading = true;
        this.nfs.showInfo('Oops', 'File is too large, max 1MB file is permitted', 'warning')
      }
    })
  }

  checkLocal() {
    $('#attachment_poi>span').remove();
    $('#attachment_poi>label').remove();
    if (!this.ld) {
      let e = document.getElementById('attachment_poi');
      if (e != undefined && e != null) {
        if (e.children.length > 0) {
          $('#attachment_poi>a').removeAttr('href');
          let ed = e.children[0]
          // ed.se=' https://pikmybox.com/assets/images/digi.jpg';
          if (ed.children.length > 0) {
            let i = ed.children[0]
            i.setAttribute('src', 'https://pikmybox.com/assets/images/digi.jpg')
            i.setAttribute('style', 'height:60px;cursor:pointer')
            // ed.se.css('height','60px')
            this.ld = true
          }

        }
      }
    }
    var t = localStorage.getItem('attachment_poi')
    if (t !== null && t != "" && t != undefined) {
      var js = JSON.parse(t)
      if (this.iecDigi != js.filename) {
        this.isSharing = true
        this.iecDigi = js.filename
        var data = null;

        data = {
          gstin: this.gstin,
          docType: 'gstin',
          data: js
        }

        this.authService.sendDoc(data).subscribe(res => {
          if (res.success) {
            this.isSharing = false
            this.isFileShared = true;
            localStorage.removeItem('attachment_poi');
            this.docName = res.uploadedFile;
          }
          else {
            this.nfs.showInfo('Oops!', res.message, 'error');
          }
        })

      }
      return true
    }
    else {
      return false
    }
  }

}
