<div class="wrapper" style="margin-top:0px">
    <div class="col-md-12">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Privacy Policy</h5>
        </div>
        <div class="modal-body">
            <h4>1. INTRODUCTION</h4>
            <p>
                Your use of the Site or services indicates your acceptance of the terms of this Privacy
                Policy. By using our website, you consent to the terms of our Online Privacy
                Policy and to our website's processing of Personal Information for the purposes
                given herein as well as those explained where our website collects Personal
                Information on the Web.
            </p>

            <p>
                We understand that privacy is critically important to you and we value your trust. This Privacy Policy
                explains
                how we handle
                and protect your personal information that is shared with us. For the purpose
                of your usage of www.pikmybox.com and availing of services offered, we have
                to collect certain information from you. The use and disclosure of information
                collected from you is governed under this agreement.
            </p>

            <p>
                "Personal Information" means any information that may be used to identify an individual or company/firm,
                including, but not
                limited to, a first and last name, or physical address and an email address
                or other contact information. Personal Information also includes the identifying
                particulars you provide at the time of registration with our website. The
                information required from you is by law would be shared only with the relevant
                statutory authorities or accessed by authorised personnel of the Company
                or other affiliated service providers engaged in providing services that
                you subscribe.

            </p>

            <p>
                Accordingly, we have designed this privacy policy in order to communicate what information we collect,
                why we
                collect this
                information, how we use this information, some of the steps taken to protect
                the information, and the choices you have to review, revise and/or restrict
                our usage of this information. This Policy is part of the Terms & Conditions
                of Use that govern the Website and is binding on all users of this site and
                its services.

            </p>

            <h4>
                2. COLLECTION OF INFORMATION

            </h4>
            <p>
                You can browse our website without providing any personal information. However, when you register with
                our
                website, subscribe
                to our service, post material or request further services, you are giving
                us your consent to the collection, use and disclosure of the personal information
                such as your name; company/firm’s name, billing or other physical address;
                email address; phone number or other contact information etc. in accordance
                with the terms of this privacy policy. Every time you use our service, we
                collect information about your usage, such as on:

            </p>

            <ul>
                <li>
                    Details of transactions you carry out through our site and of the fulfilment of your orders.

                </li>
                <li>
                    Details of your visits to our site including, but not limited to, traffic data, location data,
                    weblogs and
                    other communication
                    data, whether this is required for our own billing purposes
                    or otherwise and the resources that you access.
                </li>
            </ul>


            <h4>3. USE OF INFORMATION</h4>

            <p>
                We use the personal information about you stored in our database in various ways to provide our services
                to
                you,
                to communicate
                with you, and to otherwise conduct our business.

            </p>

            <p>
                We use the information you provide to us to provide the services you have subscribed, to confirm the
                transaction
                you have
                made, as per Central Board of Indirect Taxes Notification 48/2018 – Customs
                dated 4th June 2018 and Circular 14/2018-Customs dated 4th June 2018 and
                to troubleshoot your problems and resolve your issues and to help promote
                safe transactions.

            </p>

            <p>
                We also use your personal information to inform you about new services or features introduced on our
                website.

            </p>

            <p>
                We also use your personal information to fulfil your requests. If you request us for a service or
                information
                about a service,
                we may use the personal information you provide to respond your request.

            </p>

            <p>
                We or our representatives may also contact you as part of customer satisfaction surveys.

            </p>

            <h4>
                4. IP ADDRESS AND COOKIES

            </h4>

            <p>
                As an online service provider, we collect information that web browsers, mobile devices, and servers
                typically
                make available,
                such as the browser type, IP address, unique device identifiers, language
                preference, referring site, the date and time of access, operating system,
                and mobile network information. This is statistical data about our users'
                browsing actions and patterns, and does not identify any individual.

            </p>

            <p>
                We may also use cookies to collect information as you navigate our websites. A cookie is a small amount
                of data
                that is sent
                to your browser from a web server and stored on your device. We use cookies
                to improve our service to you and personalize your web browsing experience,
                such as by gaining a better understanding of your interests and requirements
                regarding our websites, our business and services. These cookies use aggregate
                information to provide statistics on how our websites are used, help us improve
                our websites by measuring errors, testing different designs on our websites,
                and helping us measure the traffic on our websites so that we can optimize
                our content accordingly. Cookies also help us speed up your searches. Our
                system will issue cookies when you log on to our site. You may disable cookies
                by changing the settings of your browser. However, if you select this setting
                you may be unable to access our entire site.

            </p>

            <h4>
                5. INFORMATION SECURITY

            </h4>

            <p>
                Our website takes appropriate security measures to protect against unauthorized access to or
                unauthorized
                alteration, misuse,
                disclosure or destruction of data. Our website restricts access to your personally
                identifying information to employees who need to know that information in
                order to operate, develop or improve our services or for statutory purposes.

            </p>
            <h4>
                6. UPDATING YOUR INFORMATION

            </h4>
            <p>
                When you register with our website, we may contact you from time to time about updating your content to
                provide
                features
                which we believe may benefit you or in compliance of statutory requirements.

            </p>
            <h4>
                7. SHARING OF INFORMATION

            </h4>
            <p>
                We will not sell or rent your personal information to third parties for their marketing purposes.
                However, we
                share information
                about you in the limited circumstances spelled out below and with appropriate
                safeguards on your privacy:

            </p>
            <p>
                We disclose your information to Central Board of Indirect Taxes and Customs, India (including to the
                authorities
                subordinate
                to it) and/or any other statutory or governmental authority which requires
                such information under any law/regulation/public notice etc. We may disclose
                information about you to our subsidiaries, our employees, and individuals
                who are our independent contractors that need to know the information in
                order to help us provide our Services or to process the information on our
                behalf. We require our subsidiaries, employees, and service providers to
                follow this Privacy Policy for personal information that we share with them.

            </p>
            <p>
                We may share information about you with third party vendors who need to know information about you in
                order to
                provide their
                services to us, such as payment providers that process your credit and debit
                card information. We require vendors to agree to privacy commitments in order
                to share information with them.
            </p>
            <p>
                We may also disclose your personally identifiable information to protect our rights or property, or for
                insurance
                claim processing,
                or to prevent harm to yourself or others. We may disclose information about
                you in response to a subpoena, court order, or other governmental request.
            </p>
            <p>In connection with any merger, sale of company assets, or acquisition of all or a
                portion of our business by another company, user information would likely
                be one of the assets that is transferred or acquired by a third party. If
                any of these events were to happen, this Privacy Policy would continue to
                apply to your information and the party receiving your information may continue
                to use your information, but only consistent with this Privacy Policy.
            </p>
            <h4>
                8. SECURITY
            </h4>
            <p>
                We will protect your privacy in accordance with the terms of this privacy policy. We use all reasonable
                efforts
                to limit
                access to your Personal Information by employees, agents and third party
                service providers who need the information to perform their functions. We
                also have appropriate security system in place to protect your personal information
                that you share with us. We use Secure Socket Layer (SSL) to protect your
                personal data from data theft. However, no internet data transmission can
                be guaranteed to be secure from access by unintended parties, and, by registering
                with us and providing personal information, you agree that you will not hold
                us responsible for any breach of security, unless such breach is due to our
                gross negligence or deliberate violation of this privacy policy.
            </p>
            <h4>
                9. PROTECTION OF YOUR INFORMATION
            </h4>
            <p>
                We have implemented appropriate physical, administrative and technical safeguards to help us protect
                your
                personal information
                from unauthorized access, misuse, or alteration. However, we acknowledge
                that no security system is impenetrable. Hence, we cannot take absolute guarantee
                of the security of our database system. Further, we owe no guarantee, in
                event if the information you share with us is intercepted while being transmitted
                over the Internet.
            </p>
            <h4>
                10. YOUR PRIVACY RESPONSIBILITIES

            </h4>
            <p>
                To help protect your privacy, be sure not to share your user ID or password with anyone else; to log off
                the
                Web
                site when
                you are finished; to take customary precautions to guard against "malware"
                (viruses, Trojan horses, bots, etc.), for example by installing and updating
                suitable anti-virus software; and that you sign off from your account if
                you are accessing our website from a shared computer.

            </p>
            <h4>11. REVIEW OF PERSONAL INFORMATION
            </h4>
            <p>
                If you delete or withhold or refuse or withdraw your consent to share any essential information with us,
                we may
                not be able
                to provide you certain services.

            </p>
            <h4>
                12. COMMUNICATION

            </h4>
            <p>
                Users will receive promotional e-mail communication from our website regarding items on the site, after
                they
                register on
                our website. Customers will also receive transactional e-mails if they engage
                in any transactions with our website.
            </p>
            <h4>
                13. AUTHORIZED STATEMENT

            </h4>
            <p>
                This Privacy Policy is the sole agreement between the parties concerning the collection, usage, and
                disclosure
                of
                personal
                information of the User. Any other Privacy Policy published by third parties
                will not be binding between the parties and will have no legal effect whatsoever.

            </p>
            <p>
                Please be aware that we do not control, operate the privacy practices of our partners or third parties.
                We
                encourage you
                to read the privacy policy of each third party/partner website you visit
                before you share your information with that third party/partner. In any such
                event, you are responsible for any loss or damage arising out of such practices.

            </p>
            <h4>
                14. CHANGES TO THIS PRIVACY POLICY

            </h4>
            <p>
                This Privacy Policy is subject to revision from time to time. We will post any revised version of the
                Privacy
                Policy on this
                Site. Any amended Privacy Policy will be effective as of the date stated
                in the revised policy.

            </p>
            <p>
                Continued use of our Site or Services following notice of such changes will indicate your
                acknowledgement of
                such
                changes
                and agreement to be bound by the revised terms and conditions.

            </p>
            <h4>
                15. CONTACT

            </h4>
            <p>
                If you have any questions about our privacy practices or use of your personal information, please feel
                free to
                contact us
                at support@pikmybox.com

            </p>
        </div>

    </div>
</div>