import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

declare var $: any;

@Component({
  selector: 'app-generatemeis',
  templateUrl: './generatemeis.component.html',
  styleUrls: ['./generatemeis.component.css']
})
export class GeneratemeisComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  orders;
  page = 1;
  today;
  dd;
  form: FormGroup;
  loading: boolean = false;
  mm;
  pdfSrc;
  yyyy;
  noMoreData = false;
  selectedBoxIdsForPBE;
  selectedAll;
  isLoading = false;
  selectedMeis: any;
  noMore: boolean = false;
  image: any;
  podArr: any = [];
  searchItem: any = "";
  statusVal;
  selectedStatus = "All"
  download: boolean = false;

  constructor(public authService: AuthService, private fb: FormBuilder, private nfs: NotificationService) {
    // this.getMeisData()
  }

  ngOnInit() {
    this.getMeisData();
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      avatar: null
    });
  }

  getMeisData() {

    this.isLoading = true
    this.authService.getgeneratedmeis({ page: this.page, query: this.searchItem.trim(), status: this.selectedStatus }).subscribe(data => {
      if (data.success) {
        this.isLoading = false;
        // // console.log(data.data);

        if (data.data.length > 0) {
          this.orders = data.data;
          this.noMoreData = false

        }
        else {
          this.nfs.showInfo('No Data Found', '  ', 'info')
          this.noMoreData = true
        }

      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'error')
      }
      this.isLoading = false
    })
  }

  getMeisData1() {

    this.isLoading = true;
    this.page = this.page - 1;
    if (this.page == 0) {
      this.getMeisData();
      this.noMore = false;
    }
    else {
      this.authService.getMeisData({ page: this.page }).subscribe(data => {
        if (data.success) {
          if (data.data.length > 0) {
            this.orders = data.data;
            this.noMoreData = false
          }
          else {
            this.noMoreData = true
          }

        }
        else {
          this.nfs.showInfo('Oops!', data.message, 'error')
        }
        this.isLoading = false
      })
    }
  }


  getSearch(val) {
    this.selectedStatus = val;
  }

  reset() {
    this.selectedStatus = "All";
    this.searchItem = "";
    this.getMeisData();
  }


  moreData() {
    this.getMeisData()
    this.noMore = true;
  }

  backData() {
    this.getMeisData1();
  }

  generateMeis(e) {
    var isMail = false;
    var email = '';
    this.authService.downloadAsPdf({ orderIds: this.authService.x, type: 'meis', isMail: isMail, email: email }).subscribe(data => {
      const blob = new Blob([new Uint8Array(data.link.data)], { type: "application/pdf" })
      var objectUrl = URL.createObjectURL(blob);
      if (e == 'view') {
        window.open(objectUrl, '_blank')
      }
      else {
        this.JSONToCSVConvertor(objectUrl, '.pdf')
      }
    })
  }


  JSONToCSVConvertor(url, f) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }


    // // console.log(this.today);
    a.download = 'PIKMYBOX_' + this.today + f;
    a.click();
    // window.URL.revokeObjectURL(url);
  }


  excelDownload() {
    var isMail = false;
    var email = '';
    this.authService.downloadMeisExcel({ orderIds: this.authService.x, type: 'meis', isMail: isMail, email: email }).subscribe(data => {
      const blob = new Blob([new Uint8Array(data.link.data)])
      var objectUrl = URL.createObjectURL(blob);
      this.JSONToCSVConvertor(objectUrl, '.xlsx')
    })
  }

  getDocs(o) {
    this.selectedMeis = o;
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {

    this.podArr = [];
    for (var i = 0; i < inputValue.files.length; i++) {
      this.getBase64(inputValue.files[i]);
    }
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.podArr.push({ fileName: file.name, fileType: file.type, base64: reader.result });
    };
    reader.onerror = function (error) {
      // // console.log('Error: ', error);
    };
  }

  sendFiles(name) {
    var obj = { meisRefNo: this.selectedMeis.refNo, type: name, files: this.podArr }
    this.authService.uploadMeisDoc(obj).subscribe(response => {

      if (response.success == true) {
        this.getMeisData();
        this.nfs.showInfo('success', response.message, 'success');
        $('#uploadDocuments').modal('hide');
      }
      else {
        this.nfs.showInfo('Oops !!', response.message, 'error');
      }

    })
  }

  deleteobject(type, d) {
    var obj = { meisRefNo: this.selectedMeis.refNo, fileName: d.fileName, type: type }
    this.authService.deleteobject(obj).subscribe(response => {

      if (response.success == true) {
        setTimeout(() => {
          this.page = 1;
          this.getMeisData();
          var tt = this.orders.filter(e => e.refNo == this.selectedMeis.refNo)
          this.selectedMeis = tt[0];
          // // console.log(tt);
        }, 500);
      }
      else {
        this.nfs.showInfo('Oops !!', response.message, 'error');
      }

    })
  }

  sendMeisToDgft(refNo) {
    var obj = { refNo: refNo };
    this.authService.sendmeistodgft(obj).subscribe(response => {

      if (response.success == true) {
        this.page = 1;
        this.getMeisData();
        this.nfs.showInfo('success', response.message, 'success');
      }
      else {
        this.nfs.showInfo('Oops !!', response.message, 'error');
      }

    })
  }



  selectAll() {

    for (var i = 0; i < this.orders.length; i++) {
      this.orders[i].selected = this.selectedAll;
    }
    this.authService.x = this.orders.reduce((refNo, thing) => {
      if (thing.selected) {
        refNo.push(thing.refNo);
      }
      return refNo;
    }, []);
    this.selectedBoxIdsForPBE = this.authService.x
    // // console.log(this.authService.x)
  }

  selectBoxIds(e) {
    if (e.target.checked) {

      var k = this.functiontofindIndexByKeyValue(this.orders, 'refNo', e.target.value);
      this.orders[k].selected = true
    }
    else {

      var k = this.functiontofindIndexByKeyValue(this.orders, 'refNo', e.target.value)
      this.orders[k].selected = false
    }
    this.authService.x = this.orders.reduce((refNo, thing) => {
      if (thing.selected) {
        refNo.push(thing.refNo);
      }
      return refNo;
    }, []);
    this.selectedBoxIdsForPBE = this.authService.x
    // // console.log(this.authService.x)
  }
  functiontofindIndexByKeyValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return -1;
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('avatar').setValue({
          filename: file.name,
          filetype: file.type,
          // value: reader.result.split(',')[1]
        })
      };
    }
  }
  clearFile() {
    this.form.get('avatar').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;
    // In a real-world app you'd have a http request / service call here like
    this.authService.meisFormGenerate(formModel).subscribe(data => {
      // alert('done!');
      if (data.success) {
        const blob = new Blob([new Uint8Array(data.link.data)], { type: "application/pdf" })
        var url = URL.createObjectURL(blob);
        // if (p == 'view') {
        //   window.open(url, '_blank')
        // }
        // else {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        this.today = new Date();
        this.dd = this.today.getDate();
        this.mm = this.today.getMonth() + 1; //January is 0!
        this.yyyy = this.today.getFullYear();

        if (this.dd < 10) {
          this.dd = '0' + this.dd;
          this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
        }

        if (this.mm < 10) {
          this.mm = '0' + this.mm;
          this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
        }
        a.download = 'PIKMYBOX' + this.today + ".pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      }
      else {
        this.nfs.showInfo('error', data.message, 'error');
      }

      this.loading = false;
    })
  }

  downloadMeisExcelAfterUpload() {
    var obj = { refNos: this.authService.x }
    this.authService.downloadMeisExcelAfterUpload(obj).subscribe(response => {

      const blob = new Blob([new Uint8Array(response.link.data)])
      var objectUrl = URL.createObjectURL(blob);
      this.JSONToCSVConvertor(objectUrl, '.xlsx')

    })
  }

}


