<div class="wrapper" style="background-image: url('assets/img/bg1.jpg');background-position: center; 
    margin-top: -4rem; background-size: cover; position: relative;
    height: calc(100vh - 3.0rem);">

    <div *ngIf="this.authService.getuserProfile().userType == 1 " class="redirectBtn" (click)="redirectToDomestic()">
        <div class="grow">
            <div style="display: inline-flex; justify-content: space-evenly;">
                <div>
                    <svg class="truckIcon" width="500pt" height="500pt" version="1.1" viewBox="0 0 500 500"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <style>
                                .cls-1 {
                                    fill: #fff;
                                    stroke-width: 10px;
                                }
                            </style>
                        </defs>
                        <path class="cls-1"
                            d="m590.59 442.6h-7.3984v-29.598l-0.003906-162.79c0-4.0859-3.3086-7.3984-7.3984-7.3984h-236.79c-4.0859 0-7.3984 3.3125-7.3984 7.3984v155.39h-14.801v-125.8c0-4.0859-3.3125-7.3984-7.3984-7.3984h-88.797c-2.4766 0-4.7852 1.2344-6.1562 3.2969l-59.199 88.797c-0.80859 1.2109-1.2422 2.6406-1.2422 4.1016v103.59c0 4.0898 3.3125 7.3984 7.3984 7.3984h37.746c3.4375 16.871 18.383 29.602 36.25 29.602s32.812-12.734 36.25-29.598h186.49c3.4414 16.867 18.387 29.598 36.254 29.598 17.918 0 32.883-12.809 36.266-29.746 0.25 0.027343 0.47266 0.14844 0.73047 0.14844h44.398c4.0898 0 7.3984-3.3086 7.3984-7.3984v-14.801h7.3984c4.0898 0 7.3984-3.3086 7.3984-7.3984 0.003907-4.0898-3.3047-7.4023-7.3945-7.4023zm-22.199-184.99v147.99h-29.598l-0.003906-147.99zm-221.99 0h177.59v147.99h-22.199v-51.797c0-4.0859-3.3086-7.3984-7.3984-7.3984h-22.199v-51.797c0-4.0859-3.3086-7.3984-7.3984-7.3984h-59.199c-4.0898 0-7.3984 3.3125-7.3984 7.3984v51.797h-22.199c-4.0859 0-7.3984 3.3125-7.3984 7.3984v51.797h-22.199zm37 148v-44.398h44.398v44.398zm73.996-59.199h-44.398v-44.398h44.398zm-14.801 14.801h44.398v44.398h-44.398zm-218.03-74h33.039v81.398h-87.301zm-55.758 118.4h29.598v14.801l-29.598-0.003906zm0 29.598h37c4.0859 0 7.3984-3.3086 7.3984-7.3984v-29.598c0-4.0898-3.3125-7.3984-7.3984-7.3984l-37-0.003907v-7.3984h96.195c4.0859 0 7.3984-3.3086 7.3984-7.3984l0.003906-88.801h29.598v177.59h-30.348c-3.4375-16.863-18.383-29.598-36.25-29.598s-32.812 12.734-36.25 29.598h-30.348zm66.598 59.199c-12.242 0-22.199-9.957-22.199-22.199 0-12.242 9.957-22.199 22.199-22.199s22.199 9.957 22.199 22.199c0 12.238-9.957 22.199-22.199 22.199zm258.99 0c-12.242 0-22.199-9.957-22.199-22.199 0-12.242 9.957-22.199 22.199-22.199 12.242 0 22.199 9.957 22.199 22.199 0 12.238-9.9609 22.199-22.199 22.199zm73.996-29.602h-37c-0.26172 0-0.48047 0.12109-0.73438 0.14844-3.3789-16.938-18.348-29.746-36.262-29.746-17.867 0-32.812 12.734-36.25 29.598h-141.34v-44.398h251.59z" />
                    </svg>
                </div>
                <div>
                    <h6 style="margin-left: 10px;font-weight: 600;
                font-size: 14px;">Go To Domestic
                    </h6>
                </div>
            </div>
        </div>
    </div>

    <!-- <img width="10%" height="10%" src="../../../assets/Icon/truck.png" alt=""> -->

    <div class="mask flex-center rgba-black-strong" style="position: relative;">

        <!--  -->
        <!-- <div class="switchBg" *ngIf="!authService.isSuperAdmin()">
            Exporter &nbsp;
            <label class="switch">
                <input type="checkbox" (change)="redirectToDomestic()">
                <span class="slider round"></span>
            </label>
            &nbsp;
            Domestic
        </div> -->
        <!--  -->

        <div class="container" style="padding-top: 20vh; margin-top: 5rem;">
            <!-- style="margin-top:120px;height: 550px;"--->
            <div class="row">
                <div class="col-md-4" *ngIf="authService.isAdmin()">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-primary waves-effect"
                            (click)="goto('/exporterdashboard')">
                            <!-- Title -->
                            <img src="assets/img/graph.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">STATS</h5>
                            <!-- Text -->
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4" *ngIf="!authService.isAdmin()">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-deep-purple waves-effect"
                            (click)="goto('/orders')">
                            <!-- Title -->
                            <!-- <i class="fa fa-ticket" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/6.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">Manage Parcels</h5>
                            <!-- Text -->
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-info waves-effect" (click)="goto('/dashboard')">
                            <!-- Title -->
                            <!-- <i class="fa fa-sitemap" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/5.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>Manage Documents</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>
                <div class="col-md-4" *ngIf="authService.isSuperAdmin()">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card image -->
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-warning  waves-effect" (click)="goto('/users')">
                            <!-- Title -->
                            <!-- <i class="fa fa-users" style="font-size: 33px;"></i> -->
                            <img src="assets/Icon/4.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>CLIENTS & USERS</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>

                <div class="col-md-4" *ngIf="!authService.isSuperAdmin()">
                    <div class="card">
                        <div class="card-body mycard btn btn-outline-warning  waves-effect"
                            (click)="goto('/trackparcels')">
                            <img src="assets/img/trackParcel.jpg" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>Track Parcels</a>
                            </h5>
                        </div>
                    </div>
                </div>


                <div class="col-md-4" *ngIf="authService.isPermitted('isFpoAgent')">
                    <!-- Card -->
                    <div class="card">
                        <!-- Card image -->
                        <!-- Card content -->
                        <div class="card-body mycard btn btn-outline-warning  waves-effect"
                            (click)="goto('/scanparcel')">
                            <!-- Title -->
                            <!-- <i class="fa fa-users" style="font-size: 33px;"></i> -->
                            <img src="assets/img/scanner.png" alt="" srcset="" style="height:100px">
                            <br>
                            <br>
                            <h5 class="card-title text-center">
                                <a>SCAN PARCEL</a>
                            </h5>
                        </div>
                    </div>
                    <!-- Card -->
                </div>

            </div>
            <div class="text-center" style="padding: 58px;">
                <div class="card-body">
                    <a href="https://play.google.com/store/apps/details?id=com.pikmybox.pmb" target="_blank">
                        <img alt="pikmybox" src="assets/images/play_store.png" style="height: 45px;">
                    </a>
                    <a href="https://apps.apple.com/us/app/pikmybox/id1473778437?ls=1" target="_blank">
                        <img alt="pikmybox" src="assets/images/appstore.png" style="height: 45px;">
                    </a>

                </div>
            </div>
        </div>
    </div>

</div>
<!-- end wrapper -->



<div class="modal" id="gst" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Header-->
            <div class="modal-header d-flex justify-content-center">
                <p class="heading">GSTIN Certificate</p>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <p>To Complete Your Domestic Account Registration Please Upload GSTIN Certificate</p>
                    </div>
                </div>
                <!-- <div class="col-12 "> -->
                <div class="row m-4   form-group justify-content-center">
                    <div class="col-2" style="text-align: left;">
                        <label class="asterisk">GSTIN </label>
                    </div>
                    <div class="col-5 ">
                        <input [(ngModel)]="gstin" class="form-control" type="text" placeholder="Enter GSTIN number"
                            maxlength="15">
                    </div>
                </div>

                <!-- </div> -->
                <!-- <div class="col-12 mt-3"> -->
                <div class="row m-0 justify-content-center">

                    <div class="col-md-7">
                        <div class=" form-group">
                            <label class="asterisk">
                                GSTIN Certificate (.pdf & less then 1MB)
                            </label>
                            <div for="doc" style="margin-top: 5px;" class="btn btn-warning btn-block btn-sm float-left">
                                <input type="file" id="doc" (change)="copy_route_fileChangeEvent($event,'certificate')"
                                    accept="application/pdf">
                            </div>
                        </div>
                        <div class="progress" style="height: 20px" *ngIf="progressbar">
                            <div class="progress-bar" role="progressbar" id="progress" style="width: 0%; height: 20px"
                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{progresValue}}%</div>
                        </div>
                    </div>

                    <div *ngIf="false" class="col-md-8">
                        <div class="form-group">
                            <!-- <label *ngIf="verified"></label> -->
                            <div style="display:flex">
                                <span
                                    style="font-size:18px; text-decoration: underline; line-height: 60px">OR</span><span
                                    style=" line-height: 60px">&nbsp;&nbsp;Share&nbsp;via&nbsp;&nbsp;</span>

                                <div class="share_fm_dl_sm" id="attachment_poi"></div>
                                <div class="text-digi">
                                    Complete your KYC with PikMyBox through the National Digital Locker
                                    system launched by the Govt. of India. As we are a Digilocker
                                    partner, you can access your account or signup with Digilocker
                                    directly from our app!
                                </div>
                            </div>
                            <span *ngIf="checkLocal()"></span>
                            <p *ngIf="isFileShared" style="color:green">
                                <i class="fa fa-check"></i> Sharing Successful
                            </p>
                            <p *ngIf="isSharing" style="color:#f80!important">
                                <i class="fa fa-spinner fa-spin"></i> Fetching Document...
                            </p>

                        </div>
                    </div>
                </div>
                <!-- </div> -->

            </div>

            <!--Footer-->
            <div class="modal-footer flex-center">
                <!-- {{gstin.length}} -->
                <button [disabled]="(gstin == '') || (docName == '') || gstin.length != 15 " type="button"
                    style="cursor: pointer;" class="btn btn-outline-danger btn-sm" data-dismiss="modal"
                    (click)="confirmAccount()">
                    Confirm Registration
                </button>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>