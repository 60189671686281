<main>

    <div class="container-fluid">
        <!-- <section class="team-section my-5">
          <h3 class="title my-5 wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;text-align:center !important">
              <strong>Service Providers Directory</strong>
          </h3>
        
          <p class="grey-text text-center w-responsive mx-auto mb-5">
            We have partnered with service providers from around the country to meet all your logistics needs. Our partners offer services such as local pick up services,custom clearance, support with documentation, tax and compliance consulting services, and much more.Contact us with your specific needs and we will connect you with the right partner.</p>

              <h3 style="margin-bottom:3rem">Our featured partners -</h3>
            
      
         
 

            <div class="col-xl-12 col-lg-12 mb-5 d-md-flex justify-content-between">
              <div class="text-center z-depth-1 cust">
                <img src="assets/images/FB_IMG_1546919924481.jpg" class="rounded" alt="Sample avatar">
              </div>
              <div class="col-sm-10">
                <h4 class="mb-3">SKJ Express - </h4>
                <p class="grey-text" style="margin-bottom:0px;">Offers local pick-up services and custom clearance in Jaipur</p>
                <p style="margin-bottom:0px;">W : www.skjexpress.com </p>
                <p style="margin-bottom:0px;">E : skjexpress@gmail.com </p>
                <p>Tel: +91 141 4002686</p>
              </div>
            </div> 

          </div>
        
        </section> -->

        <h3 class="title my-3 wow fadeIn" data-wow-delay="0.2s"
            style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;text-align:center !important">
            <strong>Service Providers Directory</strong>
        </h3>

        <p class="grey-text text-center w-responsive mx-auto mb-2">
            We have partnered with service providers from around the country to meet all your logistics needs. Our
            partners offer services such as local pick up services,custom clearance, support with documentation, tax and
            compliance consulting services, and much
            more.Contact us with your specific needs and we will connect you with the right partner.</p>

        <div class="row" style="background-image: url('assets/images/service-bg.jpg');margin-top:3%;height:350px;">



            <div class="col-sm-12">
                <h3 style="text-align: center;color:#fff;margin-top:2%;margin-bottom:2%">Our featured partners</h3>
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/Vamaship-Logo.jpg" alt="First slide">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>BVC Vamaship</h3>
                                <p>
                                    Offers fully integrated logistics services, including door to door shipping,
                                    insurance and custom clearance in all major cities.
                                </p>
                            </div>

                        </div>
                        <div class="carousel-item  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/Picture1.png" alt="First slide">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>RDA Global</h3>
                                <p>Offers local pick-up services and custom clearance in Delhi.</p>
                                W :<a href="http://www.rdlogistic.co.in/ ">http://www.rdlogistic.co.in</a>
                                <p style="margin-bottom:0px;">E : sales@rdlogistic.co.in </p>
                                <p>Tel: +91 9810411706</p>
                            </div>

                        </div>

                        <div class="carousel-item  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/sf logistics.png" alt="First slide">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>SF Logistics and Solutions</h3>
                                <p style="margin-bottom:0px;">Offers local pick-up services and custom clearance in
                                    Delhi </p>
                                W :<a href="http://www.sfsolutions.com/ ">www.sfsolutions.com</a>
                                <p style="margin-bottom:0px;">E :info@sflsolutions.com</p>
                                <p>Tel: +91 11 26955166</p>
                            </div>

                        </div>
                        <div class="carousel-item  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/billleftlogo.jpg" alt="First slide"
                                        style="width:40%">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>MCPL Logistics</h3>
                                <p style="margin-bottom:0px;">Offers local pick-up services and custom clearance in
                                    Jaipur</p>
                                W :<a href="http://www.mcplsons.com/  ">http://www.mcplsons.com</a>
                                <p style="margin-bottom:0px;">E : mcplsons@gmail.com </p>
                                <p>Tel: +91 141 2570673</p>
                            </div>

                        </div>
                        <div class="carousel-item  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/Picture2.png" alt="First slide"
                                        style="width:40%">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>Exim Concepts</h3>
                                <p style="margin-bottom:0px;">Offers local pick-up services and custom clearance in
                                    Jaipur</p>
                                <p style="margin-bottom:0px;">E : eximconcepts@yahoo.com</p>
                                <p>Tel: +91 141-2361362</p>
                            </div>

                        </div>
                        <div class="carousel-item  imgCls">
                            <div style="color:#fff;float:left;width:30%">
                                <div
                                    style="width:180px;height:180px;line-height:180px;border-radius:50%;text-align: center;background: #fff;float:right">
                                    <img class="block" src="assets/images/FB_IMG_1546919924481.jpg" alt="First slide"
                                        style="width:40%">
                                </div>
                            </div>
                            <div style="color:#fff;float:left;width:50%;margin-left:2%;margin-top:2%">
                                <h3>SKJ Express</h3>
                                <p style="margin-bottom:0px;">Offers local pick-up services and custom clearance in
                                    Jaipur</p>
                                <p style="margin-bottom:0px;">W : www.skjexpress.com </p>
                                <p style="margin-bottom:0px;">E : skjexpress@gmail.com </p>
                                <p>Tel: +91 141 4002686</p>
                            </div>

                        </div>

                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-angle-left"
                                style="font-size:50px;"></i></span>
                        <span class="sr-only"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-angle-right"
                                style="font-size:50px"></i></span>
                        <span class="sr-only"></span>
                    </a>
                </div>
            </div>

        </div>

    </div>


</main>