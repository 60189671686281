<div class="wrapper" [ngClass]="{'wrp': (step==0 || step==1)}">
    <div class="container-fluid p-0">

        <!-- Part 1 -->
        <div class="row" *ngIf="step==0 || step==1">
            <div class="col-10 col-sm-8 col-md-6 col-lg-6 offset-1 offset-sm-2 offset-md-3 offset-lg-3">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="form">
                            <div class="row form-horizontal heading">

                                <div class="col-12" *ngIf="step==0 || step==1">
                                    <label>Personal Details</label>
                                </div>

                                <div class="col-12" *ngIf="step==0 || step==1">
                                    <div class="row sub-heading">
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="name" class="asterisk">Name</label>
                                            <input formControlName="name" class="form-control"
                                                [attr.disabled]="verified ? '':null" [ngClass]="{'is-invalid': form.controls.name?.errors && form.controls.name.dirty, 
                    'is-valid':form.controls.name.valid && form.controls.name.dirty}">
                                            <div class="invalid-feedback">Name must have minimum 3 characeters
                                            </div>
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="email" class="asterisk">Email</label>
                                            <input formControlName="email" class="form-control"
                                                [attr.disabled]="(verified || isOtp) ? '':null" [ngClass]="{'is-invalid': form.controls.email?.errors && form.controls.email.dirty, 
                    'is-valid':form.controls.email.valid && form.controls.email.dirty}">
                                            <div class="invalid-feedback">Invalid email</div>
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="mobile" class="asterisk">Mobile</label>
                                            <input formControlName="mobile" class="form-control" maxlength="10"
                                                [attr.disabled]="(verified || isOtp) ? '':null" [ngClass]="{'is-invalid': form.controls.mobile?.errors && form.controls.mobile.dirty, 
                    'is-valid':form.controls.mobile.valid && form.controls.mobile.dirty}">
                                            <div class="invalid-feedback">Invalid mobile number</div>
                                        </div>

                                        <div *ngIf="isOtp && !verified"
                                            class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="otp" class="asterisk">Enter OTP</label>
                                            <input formControlName="otp" class="form-control" maxlength="6" [ngClass]="{'is-invalid': form.controls.otp?.errors && form.controls.otp.dirty, 
                    'is-valid':form.controls.otp.valid && form.controls.otp.dirty}">
                                            <!-- <div class="invalid-feedback">Invalid otp</div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- set password -->
                                <div class="col-12" *ngIf="verified && step==1">
                                    <label>Set Password</label>
                                </div>


                                <div class="col-12" *ngIf="verified && step==1">
                                    <div class="row sub-heading">
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="company" class="asterisk">Password</label>
                                            <input type="password" formControlName="password" class="form-control">
                                            <!-- {{form.controls.password?.dirty}}
                                            {{form.controls.password?.touched}}
                                            {{form.controls.password?.untouched}} -->

                                            <!-- *ngIf="form.controls.password?.invalid && (form.controls.password?.dirty || form.controls.password?.touched)" -->
                                            <div class="error-danger"
                                                *ngIf="form.controls.password?.invalid && (form.controls.password?.touched)">

                                                <div *ngIf="form.controls.password?.errors?.required">
                                                    Field is required
                                                </div>
                                                <div *ngIf="form.controls.password?.errors?.pattern">
                                                    Password must contain at least one number, one uppercase and
                                                    a
                                                    lowercase letter and at least 8 characters<br />Password
                                                    cannot
                                                    contain whitespace
                                                </div>
                                            </div>

                                        </div>
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="address" class="asterisk">Confirm Password</label>
                                            <input type="password" formControlName="cpassword" class="form-control">

                                            <div *ngIf="form.controls.cpassword?.dirty || form.controls.cpassword?.touched"
                                                class="error-danger">

                                                <div *ngIf="form?.controls?.cpassword?.errors?.required">
                                                    Field is required
                                                </div>

                                                <div *ngIf="form?.controls?.cpassword?.dirty && !ConfirmedValidator()">
                                                    Passwords did not match
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end - set password -->


                                <!-- step 1 buttons -->
                                <!-- buttons -->
                                <div class="col-12" style="text-align: center;" *ngIf="step==0 || step==1">
                                    <button type="button" *ngIf="!isOtp" [disabled]="form?.controls?.email?.errors
             || form?.controls?.mobile?.errors || form?.controls?.name?.errors" class="btn btn-md btn-primary otpbtn"
                                        (click)="sendOtp()">
                                        Get OTP
                                    </button>


                                    <div class="col-12 text-center">
                                        <p class="text-danger" style="font-size: 16px;"
                                            *ngIf="isOtp && (timeout!=0) && !verified">
                                            Resend OTP button will appear in {{timeout/1000}} seconds.
                                        </p>

                                        <button *ngIf="isOtp && !verified"
                                            class="btn btn-info w-md waves-effect waves-light" type="button"
                                            (click)="verifyOtp()">
                                            <i class="mdi mdi-send"></i> &nbsp;Verify OTP</button>

                                        <button *ngIf="isTimeout && isOtp && !verified"
                                            class="btn btn-danger w-md waves-effect waves-light" type="button"
                                            (click)="sendOtp()">
                                            <i class="mdi mdi-send"></i> &nbsp;Resend OTP</button>
                                    </div>

                                    <div class="col-12 text-center"
                                        style="display: flex; justify-content: space-around;">
                                        <button *ngIf="verified && step==1" (click)="registerUser('save',1)"
                                            class="btn " [disabled]=" !ConfirmedValidator()" type="button"
                                            style="background-color: rgb(182, 141, 6);border-radius: 25px; ">
                                            <!-- <i class="mdi mdi-send"></i> -->
                                            &nbsp;Save & Login</button>

                                        <button *ngIf="verified && step==1" (click)="registerUser('continue',1)"
                                            style="border-radius: 25px;" [disabled]=" !ConfirmedValidator()"
                                            class="btn btn-danger w-md waves-effect waves-light" type="button">
                                            <i class="mdi mdi-send"></i> &nbsp;Next Step</button>
                                    </div>
                                </div>
                                <!-- buttons -->
                                <!-- End step 1 buttons -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End - Part 1 -->


        <!-- Part 2 -->
        <div class="row" *ngIf="step==2">
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 offset-1 offset-sm-1 offset-md-1 offset-lg-1">

                <h6 style="font-size: 18px;font-weight: 300;">
                    Complete your documentation to register
                </h6>

                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="userForm">
                            <div class="row form-horizontal heading" *ngIf="step==2">

                                <div class="col-12">
                                    <label>{{ detailsHeading }} Details</label>
                                </div>

                                <!--   details -->
                                <div class="col-12 mb-3">
                                    <div class="row sub-heading">

                                        <!-- usertype drop down -->
                                        <div class="form-group col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="userType" class="asterisk">Where are you shipping?</label>
                                            <select formControlName="userType" class="form-control"
                                                (change)="userChange($event.target.value)" id="userType">
                                                <option [value]='1'>International Only</option>
                                                <option [value]='30'>India only</option>
                                                <option [value]='40'>India + International</option>
                                                <!-- <option [value]='2'>Service Provider</option> -->
                                            </select>
                                        </div>
                                        <!-- usertype drop down -->

                                        <!-- cha drop down -->
                                        <!-- <div *ngIf="userForm?.value?.userType==2"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="selectedType" class="asterisk">Type</label>
                                            <select formControlName="selectedType"
                                                (change)="userTypeChange($event.target.value)" id="selectedType"
                                                class="form-control">
                                                <option [value]="2">CHA (Custom House Agent)</option>
                                                <option [value]="12">FPO Agent</option>
                                                <option [value]="4">Franchise</option>
                                                <option [value]="11">Logistics Services</option>
                                            </select>
                                        </div> -->
                                        <!-- End - cha drop down -->

                                        <!-- iec input -->
                                        <div *ngIf="userForm?.value?.userType==1 || userForm?.value?.userType==40"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">

                                            <label for="ieCode" class="asterisk">IE Code</label>

                                            <input [attr.disabled]="thirdPartyObject?.verified ? true : false"
                                                [ngClass]="{'is-invalid': (userForm?.controls?.ieCode?.errors && userForm?.controls?.ieCode?.dirty), 
                                                'is-valid':!userForm?.controls?.ieCode?.errors  && userForm?.controls?.ieCode?.dirty}"
                                                type="text" placeholder="Enter IE Code" class="form-control"
                                                (input)="checkIec()" formControlName="ieCode" id="ieCode"
                                                maxlength="12">

                                            <div class="invalid-feedback">
                                                IEC must be of 10 alphanumeric characters only
                                                (No special characters are allowed).
                                            </div>
                                        </div>
                                        <!-- End iec input -->

                                        <!-- gstin input -->
                                        <div *ngIf="userForm?.value?.userType==30 && isGSTIN == false"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="ieCode" class="asterisk">GSTIN</label>
                                            <input
                                                [ngClass]="{'is-invalid': userForm?.controls?.gstin?.errors && userForm?.controls?.gstin?.dirty, 
                                                  'is-valid':!userForm?.controls?.gstin?.errors && userForm.controls.gstin.dirty}"
                                                type="text" placeholder="Enter GSTIN number" class="form-control"
                                                formControlName="gstin" id="gstin" maxlength="15">

                                            <div class="invalid-feedback">
                                                GSTIN must be valid.
                                            </div>
                                        </div>
                                        <!-- End gstin input -->

                                        <!--  -->
                                        <!-- only for domestic -->
                                        <div *ngIf="userForm?.value?.userType==30  && isGSTIN"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="lname">Document Type</label>
                                            <select class="form-control" formControlName="gstinType"
                                                (change)="changeFormValidation()">
                                                <option value="GSN" *ngIf="!isGSTIN">GSTIN</option>
                                                <option value="ADH">Aadhar no.</option>
                                                <option value="PAN">PAN No.</option>
                                            </select>
                                        </div>


                                        <div class="form-group col-6 col-sm-6 col-md-3 col-lg-3"
                                            *ngIf="userForm?.value?.userType==30 && userForm?.controls?.gstinType?.value == 'ADH'">
                                            <label for="adh" class="asterisk">Aadhar No.</label>
                                            <input [ngClass]="{'is-invalid': (userForm?.controls?.adh?.errors || false) && userForm?.controls?.adh?.dirty, 
                                      'is-valid':!userForm?.controls?.adh?.errors && userForm?.controls?.adh?.dirty}"
                                                type="text" placeholder="Enter Aadhar No." class="form-control"
                                                formControlName="adh" id="adh">
                                            <div class="invalid-feedback">Invalid Aadhar No.</div>
                                        </div>

                                        <div class="form-group col-6 col-sm-6 col-md-3 col-lg-3"
                                            *ngIf="userForm?.value?.userType==30 &&  userForm?.controls?.gstinType?.value == 'PAN'">
                                            <label for="pan" class="asterisk">PAN No.</label>
                                            <input [ngClass]="{'is-invalid': userForm?.controls?.pan?.errors && userForm?.controls?.pan?.dirty, 
                                      'is-valid':!userForm?.controls?.pan?.errors   && userForm?.controls?.pan?.dirty}"
                                                type="text" placeholder="Enter PAN No." class="form-control"
                                                formControlName="pan" id="pan">
                                            <div class="invalid-feedback">Invalid PAN No.</div>
                                        </div>

                                        <!-- only for domestic -->
                                        <!--  -->
                                        <!--  -->

                                        <!-- chaLicenseNo input -->
                                        <!-- <div *ngIf="userForm?.value?.userType==2 && userForm?.value?.selectedType==2"
                                            class="form-group  col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="chaLicenseNo" class="asterisk">CHA License No.</label>
                                            <input
                                                [ngClass]="{'is-invalid': userForm?.controls?.chaLicenseNo?.errors && userForm?.controls?.chaLicenseNo?.dirty,
                                               'is-valid':!userForm?.controls?.chaLicenseNo?.errors  && userForm?.controls?.chaLicenseNo?.dirty}"
                                                type="text" placeholder="Enter CHA License" class="form-control"
                                                (input)="checkIec()" formControlName="chaLicenseNo" id="chaLicenseNo">

                                            <div class="invalid-feedback">Invalid License Number.</div>
                                        </div> -->
                                        <!-- End - chaLicenseNo input -->

                                        <!-- PAN input -->
                                        <!-- <div *ngIf="userForm?.value?.userType==2 && (userForm?.value?.selectedType==4 || userForm?.value?.selectedType==11 || userForm?.value?.selectedType==12)"
                                            class="form-group  col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="pan" class="asterisk">Pan No.</label>
                                            <input
                                                [ngClass]="{'is-invalid': !userForm?.controls?.pan?.valid && userForm?.controls?.pan?.dirty, 
                                              'is-valid':userForm?.controls?.pan?.valid && userForm?.controls?.pan?.dirty}"
                                                type="text" (input)="checkIec()" 
                                                placeholder="Enter PAN No." class="form-control" formControlName="pan"
                                                id="pan">
                                            <div class="invalid-feedback">Invalid PAN Number.</div>
                                        </div> -->
                                        <!-- End - PAN input -->



                                        <!-- verify IEC  -->
                                        <div *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType==40 ) || 
                                         (isGSTIN == false && userForm?.value?.userType==30)"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">

                                            <div style="display: flex;">

                                                <button *ngIf="!thirdPartyObject?.verified"
                                                    [disabled]="loading || userForm?.controls?.ieCode?.errors"
                                                    style="padding: 8px; min-width:60px" class="btn btn-primary forU1"
                                                    (click)="verify3rdparty()">
                                                    Verify
                                                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>

                                                <button *ngIf="thirdPartyObject?.verified"
                                                    [ngClass]="{'forU1':(userForm?.value?.userType == 1) }"
                                                    style="padding: 8px; min-width:60px" class="btn btn-primary"
                                                    (click)="clearVerify()">
                                                    Clear
                                                </button>

                                                <!-- [ngClass]="{'forU1m':(userForm?.value?.userType == 1 || userForm?.value?.userType == 30) }" -->
                                                <div *ngIf="verfn==2 && !thirdPartyObject?.verified"
                                                    class="invalid-feedback forU1m" style="color: red; display: block;">
                                                    {{userForm?.value?.userType == 30 ? 'GSTIN':'IEC'}} not verified.
                                                </div>

                                                <div *ngIf="verfn==1 && thirdPartyObject?.verified"
                                                    class="invalid-feedback forU1m"
                                                    style="color: green;display: block; ">
                                                    {{userForm?.value?.userType == 30 ? 'GSTIN':'IEC'}} Verified ULIP.
                                                </div>
                                            </div>

                                        </div>
                                        <!-- End - verify IEC  -->

                                        <!--  -->
                                        <!-- only for domestic -->
                                        <div class="form-group col-6 col-sm-6 col-md-3 col-lg-3"
                                            *ngIf="(userForm?.value?.userType == 30 && !thirdPartyObject?.verified)">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" (change)="checkGstin($event)"
                                                    class="custom-control-input" id="defaultUnchecked">
                                                <label class="custom-control-label " for="defaultUnchecked">
                                                    <span class="">GSTIN Unavailable</span>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- only for domestic -->
                                        <!--  -->

                                    </div>
                                </div>

                                <div class="col-12 mb-3">

                                    <!-- company details / address -->
                                    <div class="row sub-heading">

                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="name" *ngIf="(userForm?.value?.userType == 30)"
                                                class="asterisk">
                                                Trader Name
                                            </label>
                                            <label for="name"
                                                *ngIf="(userForm?.value?.userType == 40 || userForm?.value?.userType == 1)"
                                                class="asterisk">
                                                Name of Exporter
                                            </label>
                                            <input [attr.disabled]="thirdPartyObject?.verified ? '' : null"
                                                formControlName="name" class="form-control" id="name">
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="address" class="asterisk">Address</label>
                                            <input [attr.disabled]="thirdPartyObject?.verified ? '' : null"
                                                formControlName="address" class="form-control">
                                        </div>



                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="state" class="asterisk">State</label>

                                            <select [attr.disabled]="thirdPartyObject?.verified ? '' : null"
                                                class="form-control" (change)="onStateChange($event.target.value)"
                                                formControlName="state" id="state">
                                                <option value="0" disabled>Select State</option>
                                                <option *ngFor="let state of stateArr"
                                                    [selected]="userForm.value.state == state" [value]="state">
                                                    {{state}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="city" class="asterisk">City </label>

                                            <select [attr.disabled]="thirdPartyObject?.verified ? '' : null"
                                                class="form-control" formControlName="city">
                                                <option value="0" disabled>Select City</option>
                                                <option *ngFor="let city of cityArr" [value]="city.city"
                                                    [selected]="userForm.value.city == city.city">
                                                    {{city.city}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="pincode" class="asterisk">Pincode</label>
                                            <input [attr.disabled]="thirdPartyObject?.verified ? '' : null"
                                                formControlName="pin" class="form-control">
                                        </div>

                                        <!-- </div> -->
                                        <!-- company details / address -->

                                        <!-- docs upload  -->
                                        <!-- <div class="row sub-heading mb-3"> -->
                                        <!-- (userForm?.value?.userType ==1 || userForm?.value?.userType == 30) -->

                                        <!-- {{userForm?.value?.userType}}, {{thirdPartyObject?.verified}} -->
                                        <!-- *ngIf="(isGSTIN == false && !thirdPartyObject?.verified) || isGSTIN"// -->
                                        <div *ngIf=" ( (userForm?.value?.userType == 1 || userForm?.value?.userType == 40) && ( (!thirdPartyObject?.verified && isGSTIN == false) || (!thirdPartyObject?.verified) ) )
                                        || (userForm?.value?.userType ==30 && !thirdPartyObject?.verified )"
                                            class="col-6 col-sm-6 col-md-3 col-lg-3 mb-3"
                                            style="display: flex; flex-direction: column;">

                                            <div class="form-group">
                                                <label class="asterisk" *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40)
                                                     && !thirdPartyObject?.verified   ">
                                                    IEC Certificate (.pdf & less than 1MB)
                                                </label>

                                                <label class="asterisk"
                                                    *ngIf="userForm?.value?.userType==30 && isGSTIN == false && !thirdPartyObject?.verified">
                                                    GSTIN Certificate (.pdf & less than 1MB)
                                                </label>

                                                <label class="asterisk"
                                                    *ngIf="isGSTIN == true && userForm?.value?.gstinType=='ADH' && userForm?.value?.userType==30">
                                                    Aadhar (.pdf & less than 1MB)
                                                </label>
                                                <label class="asterisk"
                                                    *ngIf="isGSTIN == true && userForm?.value?.gstinType=='PAN' && userForm?.value?.userType==30">
                                                    PAN (.pdf & less than 1MB)
                                                </label>
                                                <label class="asterisk"
                                                    *ngIf="isGSTIN == true && userForm?.value?.gstinType=='PSP' && userForm?.value?.userType==30 ">
                                                    Passport (.pdf & less than 1MB)
                                                </label>
                                                <label class="asterisk"
                                                    *ngIf="isGSTIN == true && userForm?.value?.gstinType=='TAN' && userForm?.value?.userType==30">
                                                    TAN (.pdf & less than 1MB)
                                                </label>

                                                <div *ngIf="(isGSTIN==false && !thirdPartyObject?.verified) || isGSTIN == true"
                                                    for="doc" style="margin-top: 5px;"
                                                    class="btn btn-warning btn-block btn-sm float-left">
                                                    <input type="file" id="doc"
                                                        (change)="copy_route_fileChangeEvent($event,'certificate')"
                                                        accept="application/pdf">
                                                </div>
                                            </div>

                                            <div class="progress" style="height: 20px" *ngIf="progressbar">
                                                <div class="progress-bar" role="progressbar" id="progress"
                                                    style="width: 0%; height: 20px" aria-valuenow="25" aria-valuemin="0"
                                                    aria-valuemax="100">{{progresValue}}%</div>
                                            </div>
                                        </div>

                                        <!-- cha doc upload  -->
                                        <!-- <div *ngIf=" userForm?.value?.userType==2"
                                            class=" col-6 col-sm-6 col-md-3 col-lg-3  mb-3"
                                            style="display: flex; flex-direction: column;">

                                            <div class="form-group">
                                                <label>
                                                    <span
                                                        *ngIf=" userForm?.value?.userType==2 && userForm?.value?.selectedType==2">
                                                        CHA License
                                                    </span>
                                                    <span *ngIf="userForm?.value?.userType==2 && (userForm?.value?.selectedType==12 ||  userForm?.value?.selectedType==4 
                                                        || userForm?.value?.selectedType==11)">
                                                        PAN Copy
                                                    </span>
                                                    (.pdf & less than 1MB)
                                                </label>
                                                <div for="doc" style="margin-top: 5px;"
                                                    class="btn btn-warning btn-block btn-sm float-left">
                                                    <input type="file" id="doc"
                                                        (change)="copy_route_fileChangeEvent($event,'certificate')"
                                                        accept="application/pdf">
                                                </div>
                                            </div>

                                            <div class="progress" style="height: 20px" *ngIf="progressbar">
                                                <div class="progress-bar" role="progressbar" id="progress"
                                                    style="width: 0%; height: 20px" aria-valuenow="25" aria-valuemin="0"
                                                    aria-valuemax="100">{{progresValue}}%</div>
                                            </div>
                                        </div> -->
                                        <!-- end - cha doc upload  -->

                                        <!-- digilocker -->
                                        <!-- <div *ngIf="(thirdPartyObject?.verified == false && isGSTIN==false)  "
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3 mb-3">

                                            <label></label>
                                            <div style="display:flex" data-toggle="tooltip" data-placement="top" title="Complete your KYC with PikMyBox through the National Digital
                                            Locker system launched by the Govt. of India. As we are a Digilocker
                                            partner, you can access your account or signup with Digilocker
                                            directly from our app!">
                                                <span
                                                    style="font-size:18px; text-decoration: underline; line-height: 60px">OR</span><span
                                                    style=" line-height: 60px">&nbsp;&nbsp;Share&nbsp;via&nbsp;&nbsp;</span>

                                                <div class="share_fm_dl_sm" id="attachment_poi"></div>

                                            </div>
                                            <span *ngIf="checkLocal()"></span>
                                            <p *ngIf="isFileShared" style="color:green">
                                                <i class="fa fa-check"></i> Sharing Successful
                                            </p>
                                            <p *ngIf="isSharing" style="color:#f80!important">
                                                <i class="fa fa-spinner fa-spin"></i> Fetching Document...
                                            </p>

                                        </div> -->
                                        <!-- digilocker -->

                                        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3">
                                            <label for="refer" class="">Referred By</label>
                                            <select class="form-control" formControlName="referredBy" id="refer">
                                                <option *ngFor="let ref of refferals" [value]="ref.id">
                                                    {{ref.name}}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- </div> -->
                                        <!-- end- docs upload -->

                                        <!-- gstin and other docs -->
                                        <!-- <div class="row sub-heading"
                                        *ngIf="userForm?.value?.userType == 1 || userForm?.value?.selectedType==12"> -->

                                        <!-- iec gst in check -->
                                        <!-- only for iec user type 1 -->
                                        <div class="form-group col-6 col-sm-6 col-md-3 col-lg-3">

                                            <label for="gstin" [ngClass]="{'asterisk':!isGSTIN }"
                                                *ngIf="userForm?.value?.userType == 1 || userForm?.value?.userType == 40">
                                                GSTIN
                                            </label>

                                            <input
                                                *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40) && !isGSTIN"
                                                [ngClass]="{'is-invalid': userForm?.controls?.gstin?.errors && userForm?.controls?.gstin?.dirty, 
                                              'is-valid':!userForm?.controls?.gstin?.errors && userForm?.controls?.gstin?.dirty}"
                                                type="text" placeholder="Enter GSTIN number" class="form-control"
                                                formControlName="gstin" id="gstin" maxlength="15">

                                            <div *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40) && !isGSTIN  
                                             && userForm?.controls?.gstin?.errors?.required" class="invalid-feedback">
                                                GSTIN must be valid.
                                            </div>

                                            <div *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40) 
                                            && !isGSTIN && userForm?.controls?.gstin?.errors?.pattern"
                                                class="invalid-feedback">
                                                GSTIN Required.
                                            </div>

                                            <div *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40)"
                                                class="custom-control custom-checkbox mt-3">
                                                <input type="checkbox" (change)="checkGstin($event)"
                                                    class="custom-control-input" id="defaultUnchecked">
                                                <label class="custom-control-label " for="defaultUnchecked">
                                                    <span>GSTIN Unavailable</span>
                                                </label>
                                            </div>

                                        </div>
                                        <!-- only for iec user type 1 -->
                                        <!-- end - iec gst in check -->


                                        <!-- other docs  -->
                                        <!-- only for iec user type 1 -->
                                        <div *ngIf="(userForm?.value?.userType == 1 || userForm?.value?.userType == 40)  && isGSTIN"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">
                                            <label for="lname">Document Type</label>
                                            <select class="form-control" formControlName="gstinType"
                                                (change)="changeFormValidation()">
                                                <option value="GSN" *ngIf="!isGSTIN">GSTIN</option>
                                                <option value="ADH">Aadhar no.</option>
                                                <option value="PSP">Passport No.</option>
                                                <option value="PAN">PAN No.</option>
                                                <option value="TAN">TAN No.</option>
                                            </select>
                                        </div>
                                        <!-- end - only for iec user type 1 -->

                                        <div *ngIf="userForm?.value?.userType == 1 || userForm?.value?.userType == 40"
                                            class="form-group col-6 col-sm-6 col-md-3 col-lg-3">

                                            <!-- only for fpo agent  selectedType 12 -->
                                            <!-- <label for="lname" *ngIf="userForm?.value?.selectedType==12">GSTIN</label> -->

                                            <!-- <input *ngIf="userForm?.value?.selectedType==12"
                                                [ngClass]="{'is-invalid': userForm?.controls?.gstin?.errors && userForm?.controls?.gstin?.dirty,
                                                  'is-valid':!userForm?.controls?.gstin?.errors && userForm?.controls?.gstin?.dirty}" type="text"
                                                placeholder="Enter GSTIN" class="form-control" formControlName="gstin"
                                                id="gstin"> -->
                                            <!-- <div class="invalid-feedback">Invalid GSTIN</div> -->
                                            <!-- END - only for fpo agent  selectedType 12 -->

                                            <!-- only for iec usertype 1 -->
                                            <div class="form-group"
                                                *ngIf="userForm?.controls?.gstinType?.value == 'ADH'">
                                                <label for="adh" class="asterisk">Aadhar No.</label>
                                                <input
                                                    [ngClass]="{'is-invalid': (userForm?.controls?.adh?.errors || false) && userForm?.controls?.adh?.dirty, 
                                                    'is-valid':!userForm?.controls?.adh?.errors && userForm?.controls?.adh?.dirty}"
                                                    type="text" placeholder="Enter Aadhar No." class="form-control"
                                                    formControlName="adh" id="adh">

                                                <div *ngIf="userForm?.controls?.adh?.errors?.required"
                                                    class="invalid-feedback">Aadhar Number required.</div>

                                                <!-- <div *ngIf="userForm?.controls?.adh?.errors?.invalid"
                                                    class="invalid-feedback">
                                                    Invalid Aadhar No.
                                                </div> -->
                                            </div>

                                            <div class="form-group"
                                                *ngIf="userForm?.controls?.gstinType?.value == 'PSP'">
                                                <label for="psp" class="asterisk">Passport No.</label>
                                                <input
                                                    [ngClass]="{'is-invalid': userForm?.controls?.psp?.errors && userForm.controls.psp.dirty,
                                                     'is-valid':!userForm?.controls?.psp?.valid  && userForm?.controls?.psp?.dirty}"
                                                    type="text" placeholder="Enter Passport No." class="form-control"
                                                    formControlName="psp" id="psp">

                                                <div class="invalid-feedback">Passport No. required</div>
                                            </div>

                                            <div class="form-group"
                                                *ngIf="userForm?.controls?.gstinType?.value == 'PAN'">
                                                <label for="pan" class="asterisk">PAN No.</label>
                                                <input
                                                    [ngClass]="{'is-invalid': userForm?.controls?.pan?.errors && userForm?.controls?.pan?.dirty, 
                                                    'is-valid':!userForm?.controls?.pan?.errors   && userForm?.controls?.pan?.dirty}"
                                                    type="text" placeholder="Enter PAN No." class="form-control"
                                                    formControlName="pan" id="pan">
                                                <div class="invalid-feedback">PAN No. required</div>
                                            </div>

                                            <div class="form-group"
                                                *ngIf="userForm?.controls?.gstinType?.value == 'TAN'">
                                                <label for="tan" class="asterisk">TAN No.</label>
                                                <input
                                                    [ngClass]="{'is-invalid': userForm?.controls?.tan?.errors && userForm?.controls?.tan?.dirty, 
                                                    'is-valid':userForm?.controls?.tan?.valid  && userForm?.controls?.tan?.dirty}"
                                                    type="text" placeholder="Enter TAN No." class="form-control"
                                                    formControlName="tan" id="tan">
                                                <div class="invalid-feedback">TAN No. required</div>
                                            </div>
                                            <!-- end - only for iec usertype 1 -->
                                        </div>
                                        <!-- END - other docs  -->

                                    </div>
                                    <!-- END - gstin and other docs -->

                                </div>
                                <!-- end -  details -->

                                <!-- AD details -->
                                <div class="col-12" *ngIf="userForm.value.userType==1 || userForm.value.userType== 40">
                                    <label>AD Details</label>
                                </div>

                                <div class="col-12 mb-3"
                                    *ngIf="userForm.value.userType==1 || userForm.value.userType== 40">
                                    <div class="row sub-heading">
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="adCode">AD Code</label>
                                            <input
                                                [ngClass]="{'is-invalid':userForm?.controls?.adCode?.errors && userForm?.controls?.adCode?.dirty, 
                                                'is-valid':!userForm?.controls?.adCode?.errors  && userForm?.controls?.adCode?.dirty}"
                                                type="text" placeholder="Enter AD Code" class="form-control"
                                                formControlName="adCode" id="adCode">
                                            <div class="invalid-feedback">AD Code is Mandatory</div>
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="adName">AD Name</label>
                                            <input
                                                [ngClass]="{'is-invalid':userForm?.controls?.adName?.errors && userForm?.controls?.adName?.dirty, 
                                                'is-valid':!userForm?.controls?.adName?.errors  && userForm?.controls?.adName?.dirty}"
                                                type="text" placeholder="Enter AD Name" class="form-control"
                                                formControlName="adName" id="adName">
                                            <div class="invalid-feedback">AD Name is Mandatory</div>
                                        </div>


                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="adAddress">AD Address</label>
                                            <input
                                                [ngClass]="{'is-invalid':userForm?.controls?.adAddress?.errors  && adAddress?.controls?.adAddress?.dirty,
                                                     'is-valid':!adAddress?.controls?.adAddress?.valid  && adAddress?.controls?.adAddress?.dirty}"
                                                type="text" placeholder="Enter AD Address" class="form-control"
                                                formControlName="adAddress" id="adAddress">
                                            <div class="invalid-feedback">AD Address is Mandatory</div>
                                        </div>

                                    </div>
                                </div>
                                <!-- AD details -->


                                <!-- bussiness details (only for Exporter ) -->
                                <div class="col-12"
                                    *ngIf="userForm?.value?.userType==1 || userForm?.value?.userType== 40 ">
                                    <label> Business Details (Optional)</label>
                                </div>

                                <div class="col-12"
                                    *ngIf="userForm?.value?.userType==1 || userForm?.value?.userType== 40 ">
                                    <div class="row sub-heading">
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                            <label for="businessName">Bussiness Name</label>
                                            <input formControlName="businessName" class="form-control">
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                            <label for="url">URL of Ecomm Website of Sale</label>
                                            <!--  -->
                                            <div formArrayName="urlForm"
                                                *ngFor="let item of urlForm['controls']; let i = index;">
                                                <!-- <label for="url">URL of Ecomm Website of Sale</label> -->
                                                <div [formGroupName]="i" class="form-horizontal row">

                                                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 form-group">
                                                        <input
                                                            [ngClass]="{'is-invalid':  (!userForm['controls'].urlForm['controls'][i]['controls'].url.valid 
                                                                 && userForm['controls'].urlForm['controls'][i]['controls'].url.dirty), 
                                                                 'is-valid':(userForm['controls'].urlForm['controls'][i]['controls'].url.valid 
                                                                 && userForm['controls'].urlForm['controls'][i]['controls'].url.dirty)}"
                                                            type="text" placeholder="Enter Website Url"
                                                            class="form-control" formControlName="url" id="url">
                                                        <div class="invalid-feedback">Please Enter a valid URL</div>
                                                    </div>

                                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4">

                                                        <button *ngIf="urlForm['controls'].length > 1 && !(i==0)"
                                                            (click)="deleteURL(i)" type="button"
                                                            class="btn btn-primary px-3"
                                                            style=" padding: 0 !important; 
                                                             border-radius: 50%; height: 30px; width: 30px; font-size: 10px">
                                                            <i class="fa fa-times" aria-hidden="true"></i>
                                                        </button>

                                                        <button *ngIf="i==urlForm['controls'].length-1"
                                                            (click)="addURL()" type="button"
                                                            class="btn btn-primary px-3"
                                                            style=" padding: 0 !important;border-radius: 50%; height: 30px; width: 30px; font-size: 10px;">
                                                            <i class="fa fa-plus" aria-hidden="true"></i></button>

                                                    </div>
                                                </div>
                                            </div>
                                            <!--  -->
                                        </div>
                                    </div>
                                </div>
                                <!-- bussiness details -->




                                <!-- terms -->
                                <div class="col-12 text-center mt-3">
                                    <p class="text-muted mb-0" style="font-size: 15px;">
                                        <input value="t_c" type="checkbox" id="t_c" class="inp-cbx"
                                            style="display: none" (change)="tandoc()">
                                        <label for="t_c" class="cbx">
                                            <span>
                                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                </svg>
                                            </span>
                                            &nbsp;By registering you agree to the PikMyBox
                                        </label>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#tandc">Terms
                                            of Use</a>
                                    </p>
                                    <br>

                                </div>
                                <!-- end terms -->
                                <!-- {{fileUpload}}
                                {{userForm.value | json}} <br> -->

                                <!-- !userForm?.valid && !t_c -->
                                <div class="col-12" style="text-align: center">
                                    <button [disabled]="!userForm?.valid || !t_c || !fileUpload"
                                        (click)="registerUser('submit',2)" class="btn btn-primary"
                                        type="submit">Submit</button>
                                </div>
                                <!-- {{userForm?.valid}} -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End - Part 2 -->



        <!-- terms modal -->
        <div class="modal" id="tandc" tabindex="-1" role="dialog" aria-labelledby="tandcLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="tandcLabel">Terms & Conditions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="color: black;">
                        <p>
                            These Terms and Conditions ("Terms") governs the use of the services that
                            are
                            made available by Sawhney Bizline Solutions Pvt. Limited (the “Company”) on
                            the
                            website, www.pikmybox.com (“Website”). These terms represents the governing
                            agreement and understanding
                            between the company and the individual or any entity/ company who uses or
                            subscribes and avails the service provided by the said Website.
                        </p>
                        <h4>
                            1. INFORMATION ABOUT US

                        </h4>
                        <p>
                            www.pikmybox.com is a website owned and operated by M/s Sawhney Bizline
                            Solutions Pvt. Limited, being a company incorporated under the Indian
                            Companies
                            Act, 1956 (hereinafter referred to as "Website", “We”, or “Us”).

                        </p>
                        <p>
                            Please read and understand the Terms of Service and User Agreement
                            (hereinafter
                            referred to as the "Terms of Use") carefully before clicking on the I Agree
                            button. Upon the USER (also referred to as ‘You’) agreeing to the terms of
                            use,
                            the same will be
                            legally binding and will govern your use of our services provided through
                            the
                            website, www.pikmybox.com or any other website of the Company.
                        </p>
                        <h4>
                            2. TERMS OF USE OF WEBSITE

                        </h4>
                        <p>By accessing or using our website, availing the services provided on or
                            through
                            the website or any other website of the Company or any applications made
                            available by the Company, however accessed, you agree to be bound
                            by these Terms of Use. These Terms of Use constitutes a legally binding
                            agreement between the parties. Thereby, by accessing and using this website,
                            you
                            indicate that you have read and accepted these Terms and Conditions
                            and you oblige to be legally bound by the terms of this agreement.
                        </p>
                        <p>
                            We also encourage and advise you to read our privacy policy. You also agree
                            to
                            abide by the privacy policy (the "Privacy Policy") that applies to the
                            website
                            which forms a part and parcel of Terms and Use of our website. If you do not
                            agree to be bound
                            by all of these Terms of Use, please refrain from accessing or using our
                            website
                            and availing the Services provided therein.

                        </p>
                        <p>
                            Also, the Company reserves the right to update, modify and/ or change the
                            Terms
                            of Use from time to time by posting modification/ changes on the website
                            without
                            any notice. You shall, at all times, be responsible for regularly reviewing
                            the
                            Terms of Use
                            and the other policies and note the changes made on our website. Your
                            continued
                            usage and access of the website after any such modifications/ changes are
                            posted
                            shall constitute your consent to such changes.
                        </p>
                        <h4>
                            3. PLATFORM

                        </h4>
                        <p>
                            Our website also provides a software application for completing statutory
                            processes agreed prescribed by the Government of India, Central Board of
                            Indirect taxes principally by Notification 48/2018-Customs dated 4th June
                            2018
                            and circular 14/2018-Customs
                            dated 4th June 2018 and all other applicable provisions of the Customs Act
                            and
                            Rules, Regulations, Notifications, and Circulars issued thereunder for
                            filing
                            Customs documents.
                        </p>
                        <h4>
                            4. REGISTRATION
                        </h4>
                        <p>
                            To use our website's services, you are required to create an account on our
                            website. You are also required to give such information and documents that
                            may
                            be required by various government authorities and other service providers
                            engaged in carrying out
                            incidental and ancilliary to the services offered by the Website and
                            Company. If
                            you fail to provide the said information and/ or documents, your
                            registration
                            may be liable for cancellation.
                        </p>
                        <p>
                            You undertake to represent and warrant that: (a) all the information and
                            data
                            that you provide while registering and the documents you upload for that
                            purpose
                            are true, accurate, updated, and complete (apart from optional items) as
                            required by various
                            registration forms on the Website (the "Registration Data") and that (b) you
                            will maintain and promptly update the Registration Data and documents
                            uploaded
                            to keep it true, accurate, current and complete. If you
                            provide any information or documents that is false, untrue, inaccurate, not
                            current or incomplete, or if we have reasonable grounds to suspect that such
                            information or document is false, untrue, inaccurate, not
                            current or incomplete, we have the right to suspend or terminate your
                            account
                            with immediate effect.
                        </p>
                        <p>
                            In consideration of using our website, you represent that you are authorized
                            and
                            competent to use our site by the person, natural or legal, in whose name the
                            registration is done and to create binding legal obligations for any
                            liability
                            you may incur
                            as a result of the use of our site. You understand that you are legally and
                            financially responsible for all uses of our site by you and persons
                            authorized
                            by you.
                        </p>
                        <h4>
                            5. SOFTWARE
                        </h4>
                        <p> The website may make available for download certain software or
                            documentation.
                            The said software or downloads, and their installation (if any) or its use
                            may
                            be subject to the terms and conditions of use as mentioned
                            therein and you will be required to accept the same. By accepting the terms,
                            you
                            agree to become a party to and you will be bound by all the terms and
                            conditions
                            mentioned herein.
                        </p>
                        <h4>
                            6. CONTENT ON OUR SITES
                        </h4>
                        <p>
                            Our website includes a combination of content that we create, or create with
                            third parties, or with our partners or licensors or associates. All
                            materials
                            published on our sites, including, but not limited to written content,
                            photographs, graphics, images,
                            maps, illustrations, marks, logos, sound or video clips, design, texts,
                            artwork,
                            graphic material, databases, proprietary information and/ or otherwise
                            legally
                            protectable elements of the website are protected by
                            our copyrights and the trademarks, service marks and logos contained therein
                            and
                            are the property of the Company or those of our partners or licensors or
                            associates respectively.
                        </p>
                        <p>
                            You may not modify, publish, transmit, participate in the transfer or sale
                            of,
                            reproduce, create derivative works of, distribute, publicly perform,
                            publicly
                            display, or in any way exploit any of the materials or content on our
                            website in
                            whole or in part.
                            Any unauthorized or prohibited use of any material may subject you to civil
                            liability, criminal prosecution, or both, under applicable laws.
                        </p>
                        <p>
                            If you would like to request permission to use any of the content on our
                            website, please review our copyright notice, provided hereinbelow.
                        </p>
                        <p>
                            The website reserves the right to modify, reject or eliminate any
                            information
                            residing on or transmitted to any of its servers that it, in its sole
                            discretion, believes is unacceptable or in violation of these terms and
                            conditions and to suspend or end
                            your service for any reason or for violation of these terms and conditions.
                        </p>
                        <h4>
                            7. LIMITED LICENSE
                        </h4>
                        <p>
                            Except as expressly authorized by the Company in writing, you agree not to
                            sell,
                            license, rent, modify, distribute, copy, reproduce, transmit, publicly
                            display,
                            publish, adapt, edit the content and materials of the Web site, or create
                            derivative works
                            from such materials or content.
                        </p>
                        <h4>
                            8. CONSENT
                        </h4>
                        <blockquote>
                            Without the express prior written authorization of the Company, you may not:
                            <br>
                            <ul style="list-style-type:lower-roman;">
                                <li>
                                    use any data mining robots ("bots"), hardware or software modules
                                    that
                                    add a specific feature or service by plugging into an existing
                                    larger
                                    system ("plug-ins"), or other data gathering and extraction tools,
                                    scripts, applications, or methods on this site;
                                </li>
                                <li>
                                    use any device, software, or hardware to bypass any operational
                                    element
                                    or to interfere, or attempt to interfere, with the proper working of
                                    this site, server or activities conducted therein;
                                </li>
                                <li>
                                    take any action that imposes an unreasonable or disproportionately
                                    large
                                    load on this site or its network infrastructure or that adversely
                                    affects our network or other customers;
                                </li>
                                <li>
                                    decompile, reverse engineer, modify or disassemble any of the
                                    software
                                    in or associated with the network and/or server;
                                </li>
                                <li>
                                    se any meta tags or any other "hidden text" utilizing the name of
                                    any
                                    the Company or any trademark of the Company;
                                </li>
                                <li>
                                    frame or utilize framing techniques to enclose any trademark, logo,
                                    or
                                    other proprietary information (including images, text,page layout,
                                    or
                                    form) of the Company or use any trademark of the Company except as
                                    set
                                    forth in the foregoing limited license
                                    in these terms of use or in another written agreement between you
                                    and
                                    the Company that is currently in effect at the time of use; or
                                </li>
                                <li>
                                    otherwise exceed your limited access to the site as authorized by
                                    the
                                    Company.
                                </li>
                            </ul>
                        </blockquote>
                        <h4>
                            9. THIRD PARTY AUTHORIZATIONS
                        </h4>
                        <p>
                            If you authorize or grant access to any person to upload any information or
                            access any features of the services provided by the website based on your
                            Registration Data or User details or log in credentials, or avail the
                            services
                            provided by the website
                            and/or to use certain segments of the website, then you will be at all times
                            responsible and liable for access and the activities that are carried out
                            using
                            such authorization by the third party.
                        </p>
                        <h4>
                            10. UNAUTHORIZED USE
                        </h4>
                        <p>
                            In case of any unauthorized use of your account with the Website, you must
                            notify the website immediately on becoming aware of the same at intimate us
                            at
                            support@pikmybox.com and take all reasonable steps to protect your account
                            with
                            the website from
                            being misused in any manner including but not limited to changing the
                            password,
                            authorization code etc.
                        </p>
                        <p>
                            The website shall not be responsible or liable for any misuse or fraud which
                            is
                            caused as a result of your use of the account with the Website and/or if you
                            authorized any other person/ entity to use or avail the services from the
                            said
                            account with the
                            Website and/ or with third parties. Only you will be personally liable in
                            case
                            of loss or damage caused to website or to any third party resulting from
                            your
                            negligence or misuse in protecting account information
                            of the Website or of Third Parties, such as your login or password or
                            authorization code or any other authentication material provided to you.
                        </p>
                        <p>
                            The website reserves its rights to take legal action against the User or any
                            person or entity in case of any unauthorized use of this website or any
                            other
                            domain names owned by the Company and/or the content or data displayed by
                            the
                            said user or any other
                            person or entity that is unlawful. You shall be liable to indemnify and hold
                            the
                            Website harmless from any loss or damage that arises in case of any breach
                            or
                            violation of the terms of use as mentioned hereunder
                            and shall be liable to reimburse all costs and expenses incurred by the
                            Website
                            in defending any claim, demand, suit or proceeding arising from or in
                            connection
                            with such violation or breach.
                        </p>
                        <h4>
                            11. THIRD-PARTY SITES, PRODUCTS, AND SERVICES
                        </h4>
                        <p>
                            In case of links to other Internet sites owned by third parties, on the web
                            site
                            of www.pikmybox.com, your use of each of those sites is subject to the terms
                            and
                            conditions, if any, that each of those sites has posted. We have no control
                            over
                            sites that
                            are not ours, and we are not responsible for any changes to or content on
                            them.
                            The Company makes no warranties as to the accuracy and correctness of the
                            content of the links of third parties provided on the Website
                            at any time.
                        </p>
                        <p>
                            You agree that we will not be responsible or liable for any loss or damage
                            of
                            any sort incurred as the result of any of your transactions with third
                            parties.
                            Any questions, complaints, or claims related to any product or service
                            should be
                            directed to
                            the appropriate vendor.
                        </p>
                        <h4>
                            12. USE OF THIRD PARTY LINKS
                        </h4>
                        <p>
                            You may provide a link to the Website provided you link to the homepage of
                            our
                            website only and no other page and the said link must not bear any
                            intellectual
                            property rights of the Company including but not limited to trademarks,
                            logos,
                            devise marks
                            or graphics. Furthermore, the link should not in any way imply that the
                            Company
                            is making an endorsement of any product or service provided by you.
                        </p>
                        <p>
                            You cannot use the trademark, logo, name of the Company including but not
                            limited to the website in any manner which is unfair, deceptive or libellous
                            advertising or commentary or used in any way that will tend to injure or
                            compromise our professional
                            reputation and corporate identity and policies.
                        </p>
                        <h4>
                            13. INFORMATION UPLOADED BY THE USERS
                        </h4>
                        <p>
                            Our site does not control the information provided by you and it is your
                            duty to
                            ensure that all the information provided by you is true, complete and
                            accurate
                            and not offensive, incomplete, fraudulent, harmful, inaccurate or
                            misleading.
                            The Company is
                            under no obligation to monitor the Content and any other information
                            residing on
                            or transmitted to its servers. However, anyone using the Company’s server
                            agrees
                            that the Company may monitor the server contents
                            at any time to (a) comply with any necessary laws, regulations or other
                            governmental requests; (b) to operate the server properly or to protect
                            itself
                            and its users; or (c) perform any other function relating to
                            the provision of services through the Company’s website.
                        </p>
                        <h4>
                            14. PAYMENT
                        </h4>
                        <p>
                            The applicable charges for availing various services offered by us have been
                            duly mentioned in the website and you can accordingly subscribe to these
                            services offered by the Company.
                        </p>
                        <p>
                            You can choose from the any of the payment methods listed on our website for
                            payment of the fees and charges. We reserve the right to add other payment
                            methods in the future or to refrain from using any or some of the payment
                            methods, which we currently
                            offer. Please note that additional costs may be applicable, depending on the
                            payment method that has been chosen by the User.
                        </p>
                        <p>
                            You shall provide correct and accurate credit/ debit card or such other
                            information and details required by the approved payment gateway for
                            availing
                            Services on the Website. Further, you shall not use the credit/ debit card
                            which
                            is not lawfully owned
                            by you. The information provided by you will not be utilized or shared with
                            any
                            third party unless required in relation to fraud verifications or under
                            Customs
                            law, any other regulation or court order. You will
                            be solely responsible for the security and confidentiality of your credit/
                            debit
                            card details. We expressly disclaim all liabilities that may arise as a
                            consequence of any unauthorized use of your credit/ debit
                            card.
                        </p>
                        <p>
                            We reserve the right to cancel or temporarily stop any service being
                            provided by
                            us, at any time and without prior notice. In case of Service cancellation,
                            your
                            right to use the Service ceases right away. Refund of the fees, if any,
                            shall be
                            at our sole
                            discretion.
                        </p>
                        <h4>
                            15. TERMINATION
                        </h4>
                        <p>
                            The Company may, at its sole discretion or based on orders from a government
                            authority, terminate your access to all or any part of our Services at any
                            time,
                            effective immediately. You acknowledge that we may immediately suspend or
                            deactivate your Account
                            and all related information and Submissions made from your Account and bar
                            any
                            further access to such information or to the Site. If you wish to terminate
                            this
                            Agreement or your account (if applicable), you may
                            simply discontinue using our Services.
                        </p>
                        <p>
                            All provisions of this Agreement which by their nature should survive
                            termination shall survive termination, including, without limitation,
                            ownership
                            provisions, warranty disclaimers, indemnity and limitations of liability and
                            obligations towards government,
                            in respect of services availed by you from and through our website
                        </p>
                        <h4>
                            16. FRAUDULENT ACCOUNTS
                        </h4>
                        <p>
                            We reserve the right to monitor suspicious account activities on our
                            website.
                            Accordingly, any user who fraudulently misuses our services through his/ her
                            account shall be liable for legal action under the applicable laws. Further,
                            such user shall also
                            indemnify us for the lawyer's fees or other costs that may arise in
                            prosecuting
                            such actions. In such cases, we reserve the right to delete the account of
                            the
                            user . For the purpose of this clause, we shall owe
                            no liability for any refunds.
                        </p>
                        <p>
                            You shall not transfer your account to another person or party. You, must
                            also
                            notify us immediately of any unauthorized use of your account of which you
                            become aware and change any password or any other authentication material
                            which
                            you believe may have
                            been compromised.
                        </p>
                        <p>
                            The Company disclaims any responsibility for the results of your use, misuse
                            or
                            fraud with respect to the specific authorization given by you to any person
                            for
                            use of your account. You shall be personally liable in case any violation of
                            customs law or
                            loss or damage caused to the Company or to any third party resulting from
                            your
                            negligence in protecting your authorization details, such as your user name
                            or
                            password or authorization code or authentication material.
                        </p>
                        <h4>
                            17. FRAUD
                        </h4>
                        <p>
                            We reserve the right to suspend or terminate your account without notice if
                            you
                            have indulged in illegal, prohibited, fraudulent activity, or provided
                            untrue,
                            incomplete, or misrepresented information. We also reserve the right to
                            initiate
                            appropriate
                            legal proceedings against you as per applicable law, in addition to the
                            action
                            that may be taken by appropriate Government authorities against you.
                        </p>

                        <h4>
                            18. PRIVACY
                        </h4>
                        <p>
                            Information submitted to this website will be treated in accordance with our
                            current Privacy Policy. To learn more about how we protect the privacy of
                            the
                            personal information in your account, please visit our Privacy Policy . If
                            You
                            use the website and/or
                            our services, You are accepting the terms and conditions of our Privacy
                            Policy.
                            If You do not agree to have your information used in any of the ways
                            described
                            in the Privacy Policy, You must discontinue use of the
                            Site and/or the Services
                        </p>

                        <h4>
                            19. REVIEW OF INTEGRITY
                        </h4>

                        <p>
                            You are strictly prohibited from communicating on or through the Website any
                            unlawful, harmful, offensive or otherwise objectionable material of any
                            sort,
                            including, but not limited to, any material that encourages conduct that
                            would
                            constitute a criminal
                            offense, give rise to civil liability, or otherwise violate any applicable
                            local, state, national, or international law.
                        </p>
                        <p>
                            You may not upload any viruses, worms, Trojan horses, or other forms of
                            harmful
                            computer code, nor subject the Website's network or servers to unreasonable
                            traffic loads, or otherwise engage in conduct deemed disruptive to the
                            ordinary
                            operation of the
                            Website.
                        </p>
                        <p>If any of your actions undermine the integrity of our website or review
                            system,
                            then we shall have a right to temporarily suspend or permanently remove the
                            said
                            User from our Site.
                        </p>
                        <h4>
                            20. OUR LIABILITY
                        </h4>

                        <p>
                            You understand and agree that the Company will not be responsible or liable
                            for
                            any breach of security or violation of any laws with respect to your
                            declarations in any customs documents/forms or the goods exported by you or
                            under your authorisation by
                            any third party.
                        </p>
                        <p>
                            You understand and agree that the Company neither assures nor will be held
                            responsible or liable regarding the performance, time taken, handling or
                            clearance of cargo by the Customs or India Post or any other persons vested
                            with
                            control over your cargo
                            in course of its export from India.
                        </p>
                        <p>
                            You understand and agree that the Company will not be responsible or liable
                            for
                            any matter relating to the procedure, rules or regulations that you are
                            required
                            to follow under the any law for the time being in force.
                        </p>
                        <p>
                            You understand and agree that the website provides software application
                            developed and deployed by the Company for use by Exporters and Customs
                            Brokers
                            for export of goods through India Post or Express Courier Companies. While
                            the
                            Company undertakes to
                            do the best to ensure uninterrupted functioning and availability of the
                            website
                            and services, the nature of the Internet, wireless connectivity and of
                            electronic devices is such that uninterrupted services cannot
                            be guaranteed. You understand and agree that the Company will not
                            responsible
                            and liable for any loss or interruption in services or delays that may arise
                            due
                            to uploading of data to the web application or due to
                            issues related to wireless connectivity or the functioning of the interne
                        </p>
                        <p>We are also not responsible for indirect losses which happen as a side effect
                            of
                            the main loss or damage, including but not limited to:(a) loss of income or
                            revenue;(b) loss of business; (c) loss of profits or contracts;(d)
                            loss of anticipated savings; (e) loss of data; (f) waste of management or
                            office
                            time however arising and whether caused by tort (including negligence),
                            breach
                            of contract or otherwis
                        </p>
                        <h4>
                            21. LIMITATION OF LIABILITY
                        </h4>
                        <p>
                            YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO
                            EVENT
                            WILL THE COMPANY OR ITS OFFICERS, EMPLOYEES, DIRECTORS, SUBSIDIARIES,
                            AFFILIATES, AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                            SPECIAL,
                            CONSEQUENTIAL OR EXEMPLARY
                            DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES,
                            PROFITS,
                            GOODWILL, USE, DATA, INTANGIBLE LOSSES OR ANY OTHER DAMAGES (EVEN IF SUCH
                            PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE
                            POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL
                            PURPOSE OF ANY LIMITED REMEDY), ARISING OUT OF OR RELATED TO YOUR USE OF THE
                            SITE OR THE SERVICES, REGARDLESS OF WHETHER SUCH DAMAGES ARE
                            BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY),
                            WARRANTY,
                            STATUTE OR OTHERWISE. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THIS SITE
                            OR
                            THE SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                            USE OF THE SITE OR THE SERVICES.
                        </p>
                        <h4>
                            22. INDEMNITY
                        </h4>
                        <p>
                            You agree to indemnify, defend and hold harmless the Company, its parents,
                            subsidiaries, affiliates, officers, directors, co-branders and other
                            partners,
                            employees, consultants and agents, from and against any and all third party
                            claims, liabilities,
                            damages, losses, costs, expenses, fees (including reasonable attorneys’ fees
                            and
                            court costs) that such parties may incur as a result of or arising from (a)
                            any
                            information You submit, post or transmit through the
                            Site or Services, (b) Your use of the Site or Services, (c) Your violation
                            of
                            this Agreement, (d) Your violation of any rights of any other person or
                            entity
                            or (e) any viruses, trojan horses, worms, time bombs,
                            cancelbots or other similar harmful or deleterious programming routines
                            input by
                            You into the Services.
                        </p>
                        <h4>
                            23. COPYRIGHT NOTICE
                        </h4>
                        <p>
                            The copyright and other intellectual property right in all material and
                            content
                            on the website including but not limited to data, written material,
                            photographs,
                            graphics, videos and organization and layout of the website is owned by the
                            Company. In accessing
                            the website, its web pages, you agree that you may only access the content
                            for
                            your own non-commercial use. Any copies of these pages saved to your
                            computer or
                            any electronic device or disc or removable drives or
                            any other storage medium may be used only for subsequent viewing purposes
                            and
                            for non-commercial use only. Except where expressly stated otherwise, you
                            are
                            not permitted to copy, broadcast, download, store (in any
                            medium), transmit, show or play in public, adapt or change in any way the
                            content or any material of the website or its web pages for any other
                            purpose
                            whatsoever without the prior written permission of the Company.
                            Use for any other purpose is expressly prohibited by law, and may result in
                            civil and criminal penalties. Violators will be prosecuted. All rights
                            reserved.
                            Any rights not expressly granted herein are reserved.
                        </p>

                        <h4>
                            24. INTELLECTUAL PROPERTY RIGHTS
                        </h4>
                        <p>
                            Our Website include a combination of content that we create, our partners or
                            licensors or associates create, and that our users create. All materials
                            published on the Website, including, but not limited to written content,
                            data,
                            photographs, graphics,
                            images, illustrations, marks, logos, sound or video clips, design, texts,
                            artwork, graphic material, databases, proprietary information, flash
                            animation
                            and/ or otherwise legally protectable elements of the website,
                            are protected by our copyrights and the trademarks, service marks and logos
                            contained therein are the property of the Company or those of our partners
                            or
                            licensors or associates respectively.
                        </p>
                        <h4>
                            25. COMMUNICATION WITH USERS
                        </h4>
                        <p>
                            These terms and conditions apply to all communication which originates from
                            or
                            references of the Company. You consent to receive communications from the
                            website electronically. The website will communicate with you by email or by
                            posting notices on the
                            Website.
                        </p>
                        <p>
                            You agree that all agreements, notices, disclosures and other communications
                            that we provide to you electronically satisfy any legal requirement that
                            such
                            communications be in writing.
                        </p>
                        <h4>
                            26. NO AGENCY
                        </h4>
                        <p>
                            This agreement in whole or in any part thereof cannot be interpreted in such
                            a
                            manner so as to constitute the agency, or joint venture or partnership
                            relationship between the parties.
                        </p>
                        <h4>
                            27. COMPLAINTS
                        </h4>
                        <p>
                            All complaints regarding our services can be emailed at support@pikmybox.com
                        </p>
                        <h4>
                            28. NOTICE
                        </h4>
                        <p>
                            Any notice may be sent to the Company by sending the same to registered
                            office
                            of the Company or to email the same at support@pikmybox.com. Except as
                            explicitly stated otherwise, the Company will send a notice to you at the
                            email
                            address that you have
                            provided to us during the registration process. Notice shall be deemed given
                            24
                            hours after email is sent. Alternatively, we may give you notice by
                            certified
                            mail or post, to the address provided to us during the
                            registration process. In such case, notice shall be deemed given 3 days
                            after
                            the date of mailing.
                        </p>
                        <h4>
                            29. MODIFICATION/NOTIFICATION OF CHANGES
                        </h4>
                        <p>
                            We reserve the right to make changes to the Website, User Agreement, Privacy
                            Policy or related policies from time to time at any time by posting changes
                            on
                            this site. You shall, at all times, be responsible for regularly reviewing
                            the
                            Terms of Use and
                            the other policies and note the changes made on the Website. If you disagree
                            with our changes, then you should stop using our Services, and terminate
                            this
                            agreement. However, Your continued usage of the Service
                            after any such changes is posted shall constitute your consent to such
                            changes
                            and the same shall be legally enforceable and binding upon you. In the event
                            that any of the additional terms of use governing such
                            areas conflict with this Agreement, this Agreement will control.
                        </p>
                        <h4>
                            30. SEVERABILITY
                        </h4>
                        <p>
                            The Terms and Conditions shall be severable. If any part of this Agreement
                            is
                            held invalid, illegal, or unenforceable, that part will be construed to
                            reflect
                            the parties’ original determination, and the remaining portions will remain
                            in
                            full force and
                            effect. However, such determination shall not affect the enforceability,
                            legality, and validity of any other remaining provisions.
                        </p>
                        <p>
                            A waiver by either party of any term or condition of this agreement or any
                            breach thereof, in any one instance, will not waive such term or condition
                            or
                            any subsequent breach thereof.
                        </p>
                        <h4>
                            31. GOVERNING LAW & JURISDICTION
                        </h4>
                        <p>The Terms and Conditions, its performance and all disputes arising out of or
                            in
                            connection with the Terms and Conditions shall be governed by the laws of
                            India,
                            with exclusive jurisdiction conferred on the courts at
                            Delhi.
                        </p>
                        <h4>
                            32. HEADINGS
                        </h4>
                        <p>
                            Headings are used in this agreement for the mere sake of convenience only.
                            They
                            shall be construed or interpreted in such a manner that will not limit or
                            otherwise affect the terms and conditions herein.
                        </p>
                        <h4>
                            33. ENTIRE AGREEMENT
                        </h4>
                        <p>
                            This Agreement constitutes the entire agreement between the Company and you
                            concerning the subject matter hereof, and supersedes any prior
                            understandings or
                            agreements (whether oral or written) regarding the subject matter, and it
                            may
                            only be modified
                            by a written amendment signed by an authorized executive of the Company, or
                            by
                            the posting by the Company of a revised version on this site.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- end terms modal -->
    </div>
</div>