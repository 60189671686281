import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { PagerService } from 'src/app/services/pager.service';
import { WindowRefService } from 'src/app/services/window-ref.service';

import { NotificationService } from 'src/app/services/notification.service';
import { DateRangePickerService } from 'src/app/services/date-range-picker.service';
import { IDatePickerConfig } from 'ng2-date-picker';
declare var $: any;

@Component({
  selector: 'app-ship-abroad-user',
  templateUrl: './ship-abroad-user.component.html',
  styleUrls: ['./ship-abroad-user.component.css']
})
export class ShipAbroadUserComponent implements OnInit {
  datePickerConfig: IDatePickerConfig = {
    format: 'DD-MM-YYYY'
  }

  shipAbroadForm: FormGroup;
  shipAbroadUpdateForm: FormGroup;
  priceCheckForm: FormGroup;
  dateCheckForm: FormGroup;
  loading = false;
  submitted = false;
  shipAbroadData;
  country: any = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos Keeling Islands",
    "Colombia",
    "Comoros",
    "Congo Brazzaville",
    "Congo Kinshasa",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mcdonald Islands",
    "Holy See Vatican City State",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos PDR",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine Territory",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and Grenadines",
    "Saint-Barthelemy",
    "Saint-Martin",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timore Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "US Minor Outlying Islands",
    "USA",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Virgin Islands US",
    "Wallis and Futuna Islands",
    "West Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  product: any = [
    "Artificial or Imitation Jewellery",
    "Auto Spare Parts",
    "Ayurvedic, Herbal and Natural Products",
    "Books and Periodicals",
    "Coffee",
    "Garment",
    "Electronics and Accessories",
    "Footwear (except leather)",
    "Gold or Platimum Jewellery",
    "Handicraft",
    "Handloom",
    "Home Appliances",
    "Home Decor",
    "Jewellery with Precious Stones",
    "Leather Footwear",
    "Leather Goods (except footwear)",
    "Medical Equipment",
    "Musical Instruments",
    "Non-Perishable Food Items",
    "Personal Care Appliances",
    "Personal Care, Beauty and Grooming",
    "Pharmaceuticals",
    "Precious and Semi-Precious Stones",
    "Silver Jewellery",
    "Spices and Condiments",
    "Sports & Fitness",
    "Stationery and Office Supplies",
    "Tea",
    "Toys, Baby Products, Kids clothing",
    "Vitamins and Nutrition Supplements",
    "Watches",
    "Other",
  ];

  hsnCode: any = {
    "Artificial or Imitation Jewellery": "7117",
    "Auto Spare Parts": "8708",
    "Ayurvedic, Herbal and Natural Products": "3003",
    "Books and Periodicals": "4901",
    Coffee: "0901",
    "Garment": "6210",
    "Electronics and Accessories": "8415",
    "Footwear (except leather)": "6403",
    "Gold or Platimum Jewellery": "7113",
    Handicraft: "4421",
    Handloom: "5210",
    "Home Appliances": "8510",
    "Home Decor": "6912",
    "Jewellery with Precious Stones": "7113",
    "Leather Footwear": "7113",
    "Leather Goods (except footwear)": "6404",
    "Medical Equipment": "9402",
    "Musical Instruments": "9207",
    "Non-Perishable Food Items": "1905",
    "Personal Care Appliances": "8423",
    "Personal Care, Beauty and Grooming": "3304",
    Pharmaceuticals: "3004",
    "Precious and Semi-Precious Stones": "7103",
    "Silver Jewellery": "7113",
    "Spices and Condiments": "2103",
    "Sports & Fitness": "9506",
    "Stationery and Office Supplies": "4817",
    Tea: "0902",
    "Toys, Baby Products, Kids clothing": "9503",
    "Vitamins and Nutrition Supplements": "2936",
    Watches: "9102",
    Other: "9304",
  };
  payData = {};
  editData = {
    consigneeDetails: {
      name: "",
      businessName: "",
      businessImporterCode: "",
      address: "",
      city: "",
      state: "",
      country: "",
      countryCode: "",
      email: "",
      mobile: "",
      zipCode: "",
    },
    trackingNo: "",
    status: "",
    exporterId: "",
    packageDesc: "",
    height: "",
    width: "",
    grossWeight: "",
    length: "",
    cifValue: "",
    hsnCode: "",
    shippingMethod: "",
    pickupDate: "",
    shippingCharge: "",
    productCategory: "",
  };
  priceData = {
    costForEMSSpeedPost: "",
    costForInternationalAirParcel: "",
    costForAirservice: "",
    costForInternationalTrackedPackets: ""
  };
  refrenceNumber;
  userData = [];
  isLoading = false;
  lastdate;
  refId3;
  conname;
  conaddress;
  concity;
  concountry;
  conpincode;
  contrackingNo;
  conmobile;
  conemail;
  constate;
  conpackageDesc;
  constatus;
  conheight;
  conwidth;
  conweight;
  conlength;
  convalue;
  concustomerId;
  conhsn;
  conshippingMethod;
  conpickUpDate;
  paymentService;
  userDataLength = 0;
  sundayList = [];
  norecord = false;
  payAmount;
  checkStatus = false;
  checktracking = false;
  conproductCatogory;
  checkEMS = true;
  checkInternational = true
  checkAirService = true
  checkTrackedPackets = true

  spinner = false;
  showHeader = false;
  public myDatePickerOptions1;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private winRef: WindowRefService,
    public pagerService: PagerService,
    private nfs: NotificationService,
    private dp: DateRangePickerService
  ) { }

  ngOnInit() {
    //  this.getAllData(this.pager.currentPage, "view");

    this.setPage(1);
    this.shipAbroadForm = this.formBuilder.group({
      cutomerID: ["", [Validators.required, Validators.minLength(8)]],
      name: ["", Validators.required],
      phone: ["", [Validators.required]],
      email: [""],
      address: ["", Validators.required],
      city: ["", Validators.required],
      pincode: ["", Validators.required],
      state: ["", Validators.required],
      length: ["", [Validators.required, Validators.min(1)]],
      height: ["", [Validators.required, Validators.min(1)]],
      weight: ["", [Validators.required, Validators.min(1)]],
      width: ["", [Validators.required, Validators.min(1)]],
      itemDes: ["", Validators.required],
      hsn: [
        "",
        [Validators.required, Validators.min(100), Validators.max(100000000)],
      ],
      value: ["", Validators.required],
      country: ["", Validators.required],
      productCategory: ["", Validators.required],
    });


    setTimeout(() => {
      this.shipAbroadForm.controls['country'].setValue("")
      this.shipAbroadForm.controls['productCategory'].setValue("")
    }, 100);


    this.priceCheckForm = this.formBuilder.group({
      priceCheck: ["", Validators.required],
    });

    this.dateCheckForm = this.formBuilder.group({
      date: ["", Validators.required],
    });

    const currentDate = new Date();

    let currentDateCounter = currentDate.getUTCDate();
    while (currentDateCounter < 32) {
      let tempDate = new Date(
        currentDate.getUTCFullYear() +
        "-" +
        (currentDate.getUTCMonth() + 1) +
        "-" +
        currentDateCounter
      );

      if (!tempDate.getUTCDay()) {
        this.sundayList.push({
          year: tempDate.getUTCFullYear(),
          month: tempDate.getUTCMonth() + 1,
          day: tempDate.getUTCDate(),
        });
      }
      ++currentDateCounter;
    }

    this.myDatePickerOptions1 = {
      dateFormat: "yyyy/mm/dd",
      markCurrentDay: true,


      disableUntil:
        new Date().getHours() >= 12
          ? {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
          }
          : {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate() - 1,
          },
      disableSince:
        new Date().getHours() >= 12
          ? {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate() + 4,
          }
          : {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate() + 4,
          },

    };
  }

  allpickupsCount;
  pager: any = {};
  pagedItems;

  setPage(page) {
    this.spinner = true;
    this.authService
      .getOrderCount().subscribe((res) => {
        if (res.success) {
          this.spinner = false;

          this.allpickupsCount = res.length;
          this.pager = this.pagerService.getPager(this.allpickupsCount, page);
          this.pagedItems = this.userData.slice(
            this.pager.startIndex,
            this.pager.endIndex + 1
          );
          this.getAllData(page, "view");
        } else {
          this.allpickupsCount = 0;
          this.pager = {};
          this.userData = [];
        }
      });
  }
  get f() {
    return this.priceCheckForm.controls;
  }
  getArrayP2P(number) {
    let arr = new Array(number);
    arr.length = number;
    return arr;
  }

  get fval(): any {
    return this.shipAbroadForm.controls;
  }

  get fval1(): any {
    return this.dateCheckForm.controls;
  }

  get changeState() {
    return this.shipAbroadForm.get("state");
  }
  get changeCountry() {
    return this.shipAbroadForm.get("country");
  }
  get changeProductCategory() {
    return this.shipAbroadForm.get("productCategory");
  }

  HSN(event) {
    let hsnSplit = event.target.value.split(":")[1].trim();
    this.shipAbroadForm.controls['hsn'].setValue(parseInt(this.hsnCode[hsnSplit]));
  }

  onFormSubmit(data) {
    this.submitted = true;

    this.shipAbroadData = data;
    // return for here if form is invalid
    if (this.shipAbroadForm.invalid) {
      return;
    }
    this.authService
      .calculatePrice({
        height: data.height + "",
        length: data.length + "",
        weight: data.weight + "",
        width: data.width + "",
        Country: data.country,
      })
      .subscribe((res) => {
        if (res.success) {
          this.priceData = res.data;

          if (
            this.editData.height != this.shipAbroadData.height ||
            this.editData.grossWeight != this.shipAbroadData.weight ||
            this.editData.width != this.shipAbroadData.width ||
            this.editData.length != this.shipAbroadData.length
          ) {
            this.conshippingMethod = "";
          }

          $("#myCarousel1").carousel("next");
          $("#myCarousel").carousel("next");
          if (typeof res.data.costForEMSSpeedPost != "number") {
            this.checkEMS = false;
          }
          if (typeof res.data.costForInternationalAirParcel != "number") {
            this.checkInternational = false;
          }
          if (typeof res.data.costForAirservice != "number") {
            this.checkAirService = false;
          }
          if (typeof res.data.costForInternationalTrackedPackets != "number") {
            this.checkTrackedPackets = false;
          }

        }
      });
  }

  submitPrice() {
    if (this.shipAbroadData.productCategory == "Coffee" || this.shipAbroadData.productCategory == 'Tea') {
      this.shipAbroadData.hsn = "0" + this.shipAbroadData.hsn;
    }

    this.authService
      .addorderbySuperuser({
        customerId: this.shipAbroadData.cutomerID,
        consigneeAddress: this.shipAbroadData.address,
        consigneeCity: this.shipAbroadData.city,
        consigneeEmail: this.shipAbroadData.email,
        height: this.shipAbroadData.height + "",
        hsn: this.shipAbroadData.hsn + "",
        packageDesc: this.shipAbroadData.itemDes,
        length: this.shipAbroadData.length + "",
        consigneeName: this.shipAbroadData.name,
        consigneeMobile: this.shipAbroadData.phone,
        consigneeZipcode: this.shipAbroadData.pincode,
        consigneeState: this.shipAbroadData.state,
        consigneeValue: this.shipAbroadData.value,
        weight: this.shipAbroadData.weight + "",
        width: this.shipAbroadData.width + "",
        consigneeCountry: this.shipAbroadData.country,
        shippingMethod: this.priceCheckForm.value.priceCheck,
        productCategory: this.shipAbroadData.productCategory + "",
        shippingCharges:
          this.priceData[this.priceCheckForm.value.priceCheck] + "",
      })
      .subscribe(
        (res) => {
          if (res.success) {
            this.authService.showAlert("Data saved");
            //this.setPage(this.pager.currentPage)
            this.refrenceNumber = res.refno;
            this.getAllData(this.pager.currentPage, "view").then((value) => {
              $("#myCarousel").carousel("next");
              this.clearFields();
            }).catch((error) => {
              // console.log(error)
            });
          } else {
            this.authService.showAlert(res.message);
          }
        },

        (error) => {
          // console.log(error);
        }
      );
  }

  clearFields() {
    this.shipAbroadForm.reset()
    setTimeout(() => {
      this.shipAbroadForm.controls['country'].setValue("")
      this.shipAbroadForm.controls['productCategory'].setValue("")
    }, 1);
  }

  goBack() {
    $("#myCarousel").carousel("prev");
    this.checkEMS = true;
    this.checkInternational = true
    this.checkAirService = true
    this.checkTrackedPackets = true
  }
  goBack1() {
    $("#myCarousel1").carousel("prev");
    this.checkEMS = true;
    this.checkInternational = true
    this.checkAirService = true
    this.checkTrackedPackets = true
  }
  closeModal() {
    $(".modal").modal("hide");
    $("#myCarousel").carousel(0);
    $("#myCarousel1").carousel(0);
    this.shipAbroadForm.reset();
  }

  submitdate(data) {
    this.submitted = true;
    // return for here if form is invalid
    if (this.dateCheckForm.invalid) {
      return;
    }

    this.isLoading=true;
    this.authService
      .sendPersonalExportDate({
        refno: this.refrenceNumber,
        pickupDate: this.dp.GetFormatedDate(data.date),
      })
      .subscribe((res) => {
        $("form1").modal("hide");
        this.authService.showAlert("Data saved");
        this.setPage(this.pager.currentPage);
        this.isLoading=false;
      });
  }


  getAllData(page, type) {
    this.spinner = true
    return new Promise((resolve, reject) => {

      this.authService.getAllShipAbroadSuperUserData({
        page: page,

      }).subscribe((res) => {
        if (res.success) {
          this.spinner = false
          this.userData = res.users;
          this.showHeader = true
          // this.userData = this.userData.reverse();
          this.refrenceNumber = res.users[0].boxId;
          resolve("resolve")
        }
        reject("reject")
      });
    })


  }

  printCNForms(bId) {
    this.isLoading = true;
    var obj = {
      refno: bId,
    };

    this.authService.printPersonalExportCNForm(obj).subscribe((data) => {
      if (data.success) {
        this.isLoading = false;
        var myWindow = window.open("", "", "width=800,height=1000");
        myWindow.document.write(data.cn22);
        myWindow.focus();
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
        }, 1000);
      } else {
        this.authService.showAlert(data.message);
      }
    });
  }

  deleteShipment(refId) {
    this.authService
      .deleteShipAbroadEntry({ refno: refId })
      .subscribe((res) => {
        if (res.success) {
          this.nfs.showInfo("", res.message, "success");
          this.setPage(this.pager.currentPage);

        }
      });
  }

  updateShipment(refId, index) {
    this.editData = this.userData[index];
    this.contrackingNo = this.editData.trackingNo;
    this.constatus = this.editData.status;
    this.conname = this.editData.consigneeDetails.name;
    this.conaddress = this.editData.consigneeDetails.address;
    this.concity = this.editData.consigneeDetails.city;
    this.concountry = this.editData.consigneeDetails.country;
    this.conemail = this.editData.consigneeDetails.email;
    this.conmobile = this.editData.consigneeDetails.mobile;
    this.conpincode = this.editData.consigneeDetails.zipCode;
    this.constate = this.editData.consigneeDetails.state;
    this.conpackageDesc = this.editData.packageDesc;
    this.conheight = this.editData.height;
    this.conwidth = this.editData.width;
    this.conweight = this.editData.grossWeight;
    this.conlength = this.editData.length;
    this.convalue = this.editData.cifValue;
    this.conhsn = this.editData.hsnCode;
    this.conshippingMethod = this.editData.shippingMethod;
    this.conpickUpDate = this.editData.pickupDate;
    this.refrenceNumber = refId;
    this.concustomerId = this.editData.exporterId;
    this.conproductCatogory = this.editData.productCategory;

    if (this.constatus == "Booking Completed") {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
    $("#form3").modal("show");
  }

  schedulePickup(refId) {
    this.refId3 = refId;
    $("#form2").modal("show");
  }

  submitdate1(data) {
    this.submitted = true;
    // return for here if form is invalid
    if (this.dateCheckForm.invalid) {
      return;
    }

    this.authService
      .sendPersonalExportDate({
        refno: this.refId3,
        pickupDate: data.date,
      })
      .subscribe((res) => {
        this.authService.showAlert("Date saved");
        this.setPage(this.pager.currentPage);
        $("#form2").modal("hide");
      });
  }

  submitUpdatePrice() {
    if (this.shipAbroadData.productCategory == "Coffee" || this.shipAbroadData.productCategory == 'Tea') {
      this.shipAbroadData.hsn = "0" + this.shipAbroadData.hsn;
    }
    this.authService
      .updatePersonalExportShipAbroad({
        refno: this.refrenceNumber,
        consigneeAddress: this.shipAbroadData.address,
        consigneeCity: this.shipAbroadData.city,
        consigneeEmail: this.shipAbroadData.email,
        height: this.shipAbroadData.height + "",
        hsn: this.shipAbroadData.hsn + "",
        packageDesc: this.shipAbroadData.itemDes,
        length: this.shipAbroadData.length + "",
        consigneeName: this.shipAbroadData.name,
        consigneeMobile: this.shipAbroadData.phone,
        consigneeZipcode: this.shipAbroadData.pincode,
        consigneeState: this.shipAbroadData.state,
        consigneeValue: this.shipAbroadData.value,
        weight: this.shipAbroadData.weight + "",
        width: this.shipAbroadData.width + "",
        consigneeCountry: this.shipAbroadData.country,
        shippingMethod: this.priceCheckForm.value.priceCheck,
        productCategory: this.shipAbroadData.productCategory + "",
        shippingCharges:
          this.priceData[this.priceCheckForm.value.priceCheck] + "",
      })
      .subscribe((res) => {
        if (res.success) {
          this.nfs.showInfo("Shipment Updated", res.message, "success");
          this.setPage(this.pager.currentPage);

          $(".modal").modal("hide");
          $("#myCarousel1").carousel(0);
          $("#myCarousel").carousel(0);
        }
      });
  }

  updateTrackingId() {
    $("#form3").modal("hide");
    $("#trackingIdUpdate").modal("show");
    $("#myCarousel1").carousel(0);
    $("#myCarousel").carousel(0);
  }

  submitTrackingId(id) {
    if (this.contrackingNo == null) {
      this.checktracking = true;
      return;
    }
    this.authService
      .updatePersonalTrackingNo({ refno: id, trackingNo: this.contrackingNo })
      .subscribe((res) => {
        if (res.success) {
          this.authService.showAlert("Tracking ID updated");
          this.setPage(this.pager.currentPage);
          $("#trackingIdUpdate").modal("hide");
        }
      });
  }
}
