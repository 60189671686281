<div class="wrapper">
    <div class="container-fluid">

        <div class="col-md-12" style="box-shadow: 1px 1px 1px 1px #e0e0e0;margin-bottom: 2%">
            <h5 _ngcontent-c3="" class="red-text text-left" style="font-weight:500;">**Important Notice**</h5>
            <h5 class="grey-text text-left" style="font-weight: 500;">Please refer to the following documents for
                completing your MEIS form.</h5>
            <label class="grey-text text-left" style="font-weight: 500; font-size: 14px">
                1) For ITC (HS Code) and Product SI. No., <a class="link blue-text"
                    href="/assets/docs/MEISApp3BTable2.pdf" target="new"> refer here.</a>
                <br> 2) For SI. No. of Export Country, <a class="link blue-text"
                    href="/assets/docs/MEISApp3BTable1_for_country(1).pdf" target="new">refer here.</a>
                <br>3) For Sr. No. of Category of Export, <a class="link blue-text" href="/assets/docs/Appendix_3c.pdf"
                    target="new">refer here.</a>

            </label>

        </div>

        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 40%"></div>
        <table id="mainTable" class="table table-bordered m-b-0 text-center">
            <thead>
                <tr>
                    <th>
                        <input type="checkbox" [(ngModel)]="selectedAll" id="selectAll" (change)="selectAll();"
                            class="inp-cbx" style="display: none">
                        <label *ngIf="!authService.isAdmin()" for="selectAll" class="cbx">
                            <span style="    border-color: #fff;">
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        Sl. No.
                    </th>
                    <th>
                        Docket No.
                    </th>
                    <th>
                        PBE No.
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Invoice No.
                    </th>
                    <th>
                        Amount
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="(orders?.length && !isLoading)">
                <tr *ngFor='let o of orders; let i = index'>
                    <td>
                        <input [checked]="(o.selected)" *ngIf="(!authService.isAdmin())" value="{{o._id}}"
                            type="checkbox" id="cbx{{i}}" (change)="selectBoxIds($event);" class="inp-cbx"
                            style="display: none">
                        <label *ngIf="(!authService.isAdmin())" for="cbx{{i}}" class="cbx">
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                        {{i+1}}
                    </td>
                    <td>
                        {{o.tempSBill}}
                    </td>
                    <td>
                        {{o.shippingBillDetails.bill}} / {{o.shippingBillDetails.date | date:'dd-MM-yyyy'}}
                    </td>
                    <td>
                        {{o.description}}
                    </td>
                    <td>
                        {{o.invoiceNo}} / {{o.invoiceDate | date:'dd-MM-yyyy'}}
                    </td>
                    <td>
                        {{o.paymentDetails.fob | currency:'INR':'code':'1.2-2'}}
                    </td>
                </tr>
            </tbody>

        </table>
        <div class="row" *ngIf='(!isLoading)'>
            <div class="col-md-12 text-center">
                <button class="btn btn-outline-primary waves-effect" (click)='moreData()'
                    *ngIf="!noMoreData && orders.length>20">
                    <i class="fa fa-chevron-down"></i>&nbsp; More Orders
                </button>

                <button class="btn btn-outline-primary waves-effect" (click)='backData()' *ngIf="noMore">
                    <i class="fa fa-chevron-up"></i>&nbsp; Back
                </button>

                <!-- <button class="btn btn-outline-success waves-effect" (click)="generateMeis('view')" *ngIf="(selectedBoxIdsForPBE?.length>0)">
              <i class="fa fa-eye"></i>&nbsp;<i class="fa fa-ticket"></i>&nbsp; View Meis
            </button> -->
                <button class="btn btn-outline-info waves-effect" (click)="excelDownload()"
                    *ngIf="(selectedBoxIdsForPBE?.length>0)">
                    <i class="fa fa-table"></i>&nbsp; Download Excel
                </button>
                <!-- <button class="btn btn-outline-info waves-effect" *ngIf="(selectedBoxIdsForPBE?.length>0)" (click)="generateMeis('')">
              <i class="fa fa-download"></i>&nbsp;<i class="fa fa-ticket"></i>&nbsp; Download Meis
            </button> -->
            </div>
        </div>
        <div class="row" *ngIf="isLoading">
            <div class="card-body">
                <div class="lds-css ng-scope">
                    <div class="lds-blocks" style="margin: auto">
                        <div style="left:38px;top:38px;animation-delay:0s">
                        </div>
                        <div style="left:80px;top:38px;animation-delay:0.125s">
                        </div>
                        <div style="left:122px;top:38px;animation-delay:0.25s">
                        </div>
                        <div style="left:38px;top:80px;animation-delay:0.875s"></div>
                        <div style="left:122px;top:80px;animation-delay:0.375s"></div>
                        <div style="left:38px;top:122px;animation-delay:0.75s"></div>
                        <div style="left:80px;top:122px;animation-delay:0.625s"></div>
                        <div style="left:122px;top:122px;animation-delay:0.5s"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf='(!isLoading)'>
            <div class="col-md-12">
                <h4 class="text-center" style="margin-top: 30px;margin-bottom: 20px;">OR UPLOAD EXCEL</h4>
                <form [formGroup]="form" class="row" (ngSubmit)="onSubmit()">
                    <div class="form-group col-md-3">

                    </div>
                    <div class="form-group col-md-3">
                        <input type="file" style="margin-top:8px" class="form-control" id="avatar"
                            (change)="onFileChange($event)" #fileInput>
                    </div>
                    <div class="form-group col-md-1">
                        <button type="button" class="btn btn-sm btn-default" (click)="clearFile()">clear file</button>
                    </div>
                    <div class="form-group col-md-2">
                        <button type="submit" [disabled]="form.invalid || loading" class="btn btn-amber"> <i
                                class="fa fa-download" *ngIf="!loading"></i>&nbsp;DOWNLOAD PDF
                            <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>