import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PagerService } from 'src/app/services/pager.service';

declare var $: any;

@Component({
  selector: 'app-export-proof',
  templateUrl: './export-proof.component.html',
  styleUrls: ['./export-proof.component.css']
})
export class ExportProofComponent implements OnInit {
  pickupAddress = []
  selectedPickupAddress = "";
  agentSelected = false
  selectedBill = null
  pinCodeForPickup = ''
  agentCode = ''
  onFpoSubmit = true
  pickupCharges = 0
  selBnpl;
  agentCharges = []
  selectedFpoAgent = null
  services = []
  bnplUsed = "1"
  charges;
  serverDate: any;
  pickupStatus = 'Pending'
  onSBill = true
  totalIpPrice = 0
  isAllConsigneeSelected = true;
  onCutomClearenece = true;
  scanResult: any = [];
  isAll = false;
  record;
  tempsBill: any;
  currentData: any;
  selectedDocket = ''

  vl = 'filter';
  goodsLength;
  actualPbe1 = '';
  fpo = 'all'
  cnt = [];
  today;
  dd;
  mm;
  yyyy;
  actualPbe2 = '';
  searchInput = '';
  searchType = 'iec'
  search = 'all';
  isSearch = false;
  searchQuery = '';
  isLoading = true;
  parcelLength: '';
  trackingData;
  bnplDetails: any = {}
  pageSize = 20;
  allPbeCount
  pages = Array(50).fill(1).map((x, i) => i);
  pageNo = 1;
  docketNo;
  cn22 = ''
  isBnpl = false
  agents = []
  cn23 = ''
  isCn22: boolean = false;
  isCn23: boolean = false;
  private allItems: any[];
  selectedAll: any;
  selectedBoxIdsForPBE = [];
  selectedInvoices = [];
  selectedDocketNo: any;
  self: any;
  selectedDoc: any = [];
  loading: boolean = false;
  // pager object
  pager: any = {};
  pbeDate: any;
  consigneeInfo: any = [];

  // paged items
  pagedItems: any[];

  userData: any


  fpoList = [];
  newInvoiceArray;
  date = new Date().getDate() + "-" + new Date().getMonth() + 1 + "-" + new Date().getFullYear();
  selectedBoxId;
  a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  n: any;
  form: FormGroup;
  egmForm: FormGroup;
  leoForm: FormGroup;
  selectedInsCurrency = '0'
  selectedFreCurrency = '0'
  selectedComCurrency = '0'
  selectedPacCurrency = '0'
  selectedDedCurrency = '0'
  selectedDisCurrency = '0'
  selectedBoxValue;
  trackingNo;
  downloadForm;
  boxData;
  selectedButtonType = 'goodsDetails';
  selectedBoxToView;
  htm1;
  sdrValue = null;
  selectedBoxDataByExistingData;
  rawData;
  categoryOfExporter1 = 'Custom (DTA units)'
  modeOfRealisation1 = 'L/C'
  natureOfContract1 = 'FOB'
  exporterDeclaration1 = 'already realized by debit/credit card'
  htm;
  selectedInvoice;
  inv;
  boxes = [];
  selectedBox;
  selectedCard;
  selectedBoxData = [];
  actualPbe;
  tempRefPbe;
  boxCreated;
  notification = ''
  currentUrl: any;
  noticeId = ''
  currency = [];
  selectedDocStatus;

  constructor(public authService: AuthService, private nfs: NotificationService,
    private activatedRoute: ActivatedRoute, public fb: FormBuilder, public router: Router,
    public pagerService: PagerService) {
    this.createForm();
    this.createLeoForm();
    this.createDownloadForm();
    this.currentUrl = this.activatedRoute.snapshot.params;
    // this.refBy=this.currentUrl.page;
    this.getBoxes(Number(this.currentUrl.page));
    this.createEgmForm()
    this.getSdr();
    if (this.authService.isExporterAdmin()) {
      this.downloadForm.controls['searchType'].setValue('cha');
    }

  }



  ngOnInit() {

    this.getboxcount();
    this.getfpolist();

    //  this.getNotification()
  }



  closeToast(id) {
    $(".toast").removeClass('on');
    localStorage.setItem('isRead', "1")
  }


  getSdr() {
    this.authService.getSdr().subscribe(result => {
      if (result.success) {
        this.sdrValue = result.data.sdrValue
        // // console.log(this.sdrValue)
      }
      else {
        this.sdrValue = null
      }
    })
  }


  getfpolist() {
    this.authService.getfpolist().subscribe(result => {
      if (result.success) {
        this.fpoList = result.data
        this.services = result.services
      }
    })
  }


  addUpdateBnpl() {
    this.selBnpl = this.bnplDetails;
    this.bnplDetails.isNew = true
    this.authService.postReq('authentication/updateBnpl', this.bnplDetails).subscribe((res) => {
      if (res.success) {
        this.authService.showAlert(res.message);
        $('#bnplModal').modal('hide');
        this.userData.bnpl.push(this.bnplDetails)
        this.isBnpl = true
      }
      else {
        this.authService.showAlert(res.message)
      }
    })
  }


  getFpoNameByCode(c) {
    var fpo = this.fpoList.filter((f) => f.portCode == c);
    return fpo[0].fpoName;
  }




  JSONToCSVConvertor(url) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }


    // // console.log(this.today);
    a.download = 'PIKMYBOX_' + this.today + ".xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  }


  createForm() {
    this.getCurrencies();
    this.form = this.fb.group({
      rbiApprovalNo: [''],
      rbiApprovalDate: [''],
      categoryOfExporter: ['Custom (DTA units)'],
      modeOfRealisation: ['L/C'],
      natureOfContract: ['FOB'],
      exporterDeclaration: [''],
      formNo: [''],
      thirdPartyName: [""],
      thirdPartyAddress: [""],
      freightAmountForeign: ['0'],
      freightAmountInr: ['0'],
      freightCurrency: ['0'],
      freightExchangeRate: ['0'],

      insuranceAmountForeign: ['0'],
      insuranceAmountInr: ['0'],

      commissionAmountForeign: ['0'],
      commissionAmountInr: ['0'],

      discountAmountForeign: ['0'],
      discountAmountInr: ['0'],

      deductionAmountForeign: ['0'],
      deductionAmountInr: ['0'],

      packingAmountForeign: ['0'],
      packingAmountInr: ['0']
    })
  }

  createLeoForm() {
    this.leoForm = this.fb.group({
      leoDate: ['']
    })
  }

  createEgmForm() {
    this.egmForm = this.fb.group({
      egmDate: [''],
      egmNumber: ['']
    })
  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    // return !!/[\d\s]/.test(input);
    return !!/[\d\.\s]/.test(input);
  }


  changeExchangeRate(i, j) {

    if (this.form.value[i] == '0') {
      this.form.controls[j].setValue('0')
    }
    else {
      var s = this.form.value[i].price
      this.form.controls[j].setValue(s)
    }
  }

  deleteTmp(e) {
    // // console.log(e)
    this.nfs.showConfirmation(
      'Are you sure?',
      'Do you want to delete the Docket No. ' + e,
      'warning',
      'Yes, delete it!',
    ).then((result) => {
      if (result.value) {
        this.authService.deletebypbe({ tempSBill: e }).subscribe(data => {
          if (data.success) {
            // swal('Success', data.message, 'success');
            this.authService.showAlert(data.message);

            this.boxes = []
            this.getBoxes(this.pager.currentPage)
          }
          else {
            // swal('Error', data.message, 'error')
            this.authService.showAlert(data.message);

          }
        })
      }
    })

  }
  nextIn() {
    $(".inputs").keyup(function () {
      if (this.value.length == this.maxLength) {
        $(this).next('.inputs').focus();
      }
    });
  }
  undo(e) {
    // // console.log(e)
    this.nfs.showConfirmation(
      'Are you sure?',
      'Do you want to undo the "Generate PBE" ' + e + ' ?',
      'warning',
      'Yes, undo it!',
    ).then((result) => {
      if (result.value) {
        this.authService.undobypbe({ tempSBill: e }).subscribe(data => {
          if (data.success) {
            // swal('Success', data.message, 'success');
            this.authService.showAlert(data.message);

            this.boxes = []
            this.getBoxes(this.pager.currentPage)
          }
          else {
            // swal('Error', data.message, 'error')
            this.authService.showAlert(data.message);

          }
        })
      }
    })

  }

  isUpdateAll(e) {
    // // console.log(e.target.checked)
    this.isAll = e.target.checked
  }

  submitEdf(formValue) {
    var obj = formValue;
    if (obj.rbiApprovalDate == '' || obj.rbiApprovalDate == null || obj.rbiApprovalDate == undefined) {
      obj.rbiApprovalDate = '';
    }
    else {
      obj.rbiApprovalDate = new Date(obj.rbiApprovalDate.date.month.toString() + '-' + obj.rbiApprovalDate.date.day.toString() + '-' + obj.rbiApprovalDate.date.year.toString());
    }

    if (this.selectedInvoices.length > 1) {
      this.isAll = true;
    }
    else {
      this.isAll = false;
    }

    this.authService.saveEdfData({ edfDetails: obj, tempSBill: this.selectedBoxToView, invoiceNo: this.selectedInvoice, isAll: this.isAll, invoices: this.selectedInvoices }).subscribe(data => {
      if (data.success) {
        this.form.reset();
        // setTimeout(() => {

        // })
        this.categoryOfExporter1 = 'Custom (DTA units)'
        this.modeOfRealisation1 = 'L/C'
        this.natureOfContract1 = 'FOB'
        this.exporterDeclaration1 = 'already realized by debit/credit card'

        $('#detailModal').modal('show');
        this.selectedInvoices = [];
        // swal('Success', data.message, 'success')
        this.authService.showAlert(data.message);

      }
      else {
        // swal('Error', data.message, 'error')
        this.authService.showAlert(data.message);

      }
    });
  }

  setPage(page: number) {
    // get pager object from service
  }

  setPage1(page: number) {
    this.router.navigate(['/exportProof/' + page])
    this.pager = this.pagerService.getPager(this.allPbeCount, page);
    // get current page of items
    this.pagedItems = this.boxes.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.getBoxes(page)
  }

  resetDownloadFilter() {
    this.searchInput = '';
    this.searchQuery = ''
    this.isSearch = false
    this.getBoxes(1);
  }

  createDownloadForm() {
    this.downloadForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
      fpo: ['all'],
      searchInput: [''],
      searchType: ['iec'],
      toInr: [''],
      fromInr: ['']
    })
  }

  getboxcount() {
    this.authService.getboxcount().subscribe(data => {
      this.boxCreated = data.data;
      // // console.log(data)
    })
  }
  selectRefPbe(val) {
    this.tempRefPbe = val
  }
  getCurrencies() {
    this.authService.getCurrencies().subscribe((res) => {
      if (res.success) {
        if (res.data.length != 0) {
          this.currency = [];
          for (var i = 0; i < res.data.length; i++) {
            var obj = { label: res.data[i].name, value: res.data[i].short, price: res.data[i].value }
            this.currency.push(obj)
          }
        }
        else {
          // // console.log("No Currency Found");
        }
      }
      else {
        // // console.log("Some error occurred while fetching currencies");
      }
    })
  }




  getBoxes(p) {

    this.rawData = [];
    var rt = this.downloadForm.value

    if (this.authService.isCustomLoggedIn()) {
      rt.fpo = this.authService.getFpoCode()
    }


    rt.searchType = this.searchType;
    rt.searchQuery = this.searchQuery;
    rt.isSearch = this.isSearch;
    rt.page = p;

    if (this.vl == 'download') {
      this.isLoading = true;
      this.authService.downloadpbedatainexcel(rt).subscribe(data => {
        if (data.success) {
          if (data.count == 0) {
            this.authService.showAlert('No data found between selected date, Select other date & try again');

            // swal('Sorry!', , 'info')
          }
          else {

            const blob = new Blob([new Uint8Array(data.link.data)])

            var objectUrl = URL.createObjectURL(blob);
            this.JSONToCSVConvertor(objectUrl);
            this.isLoading = false
            this.authService.showAlert('Please wait, Download will start shortly');

            // swal('Data Found!', , 'success')
          }
        }
        else {
          // swal('Oops!', data.message, 'error')
          this.authService.showAlert(data.message);

        }
        this.isLoading = false
      })
    }

    else {
      this.isLoading = true;
      this.authService.getpbecount(rt).subscribe(d => {
        this.allPbeCount = d.data;
        this.pager = this.pagerService.getPager(this.allPbeCount, p);
      })
      this.authService.allordersbypbe(rt).subscribe(data => {
        this.isLoading = false
        if (data.success) {
          this.rawData = data.result;
          this.boxes = this.rawData;
          // this.serverDate = data.currentDate
          this.getboxcount();

        }
        else {
          this.isLoading = false
          // swal('Error!', data.message, 'error')
          this.authService.showAlert(data.message);

        }
      })
    }
  }


  selectFpoAgent(agent) {
    // alert(agent)
    this.selectedFpoAgent = this.agentCharges.filter((a) => a.userCode == agent)[0]
    this.bnplUsed = this.selectedFpoAgent.bnplUsed
    if (this.bnplUsed == '2' || this.bnplUsed == '3') {
      this.isBnpl = false;
      $('#bnplCheckbox').prop('checked', false)
    }
  }


  inputfield() {
    if (this.searchInput) {
      return true;
    }
    else
      return false;
  }

  proofOfExport(docketNumber) {
    // console.log(docketNumber)
    this.router.navigate(['/proofOfExport/' + docketNumber]);
  }

  navigateProofOfExport(docketNumber) {

    docketNumber = docketNumber + "";

    if (this.searchInput && /^PMB[0-9]{13}$/g.test(docketNumber)) {
      this.router.navigate(['/proofOfExport/' + docketNumber]);
      return true
    }

    else
      return false
  }

  filter(vll) {
    this.vl = vll
    this.searchType = this.downloadForm.controls['searchType'].value;
    this.searchInput = this.downloadForm.controls['searchInput'].value;
    // var inp = this.searchInput;
    // inp = inp.replace(/\s/g, '');
    if (this.searchInput != null) {
      this.searchInput = this.searchInput.replace(/(^\s+|\s+$)/g, '')
    }
    else {
      this.searchInput = ''
    }
    // this.getboxcount()
    this.isSearch = true;
    /****************************************** */
    /** query making moved on backend api end */
    /****************************************** */
    /****************************************** */

    // if (this.searchType == 'iec') {
    //   this.searchQuery = 'exporterDetails.ieCode';
    //   this.getBoxes(1)
    // }
    // else if (this.searchType == 'docket') {
    //   this.searchQuery = 'tempSBill';
    //   this.getBoxes(1)
    // }
    // else if (this.searchType == 'company') {
    //   this.searchQuery = 'exporterDetails.name'
    // }

    if (this.searchInput) {
      this.getBoxes(1)
    }
    else {
      // swal('Oops!',, 'error')
      this.authService.showAlert('Invalid Request');

    }
  }

}
