<div class="wrapper">
    <div class="container-fluid">
        <div class="row" *ngFor="let data of blogData" style="margin-bottom:2%">
            <div class="col-lg-5">

                <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
                    <img class="img-fluid"
                        src="https://s3.ap-south-1.amazonaws.com/picmybox-internal/blogs/{{data.images}}"
                        alt="Sample image">
                    <a>
                        <div class="mask rgba-white-slight waves-effect waves-light"></div>
                    </a>
                </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7">

                <!-- Category -->
                <a class="green-text">
                    <h6 class="font-weight-bold mb-3"><i class="fas fa-utensils pr-2"></i>{{data.type}}</h6>
                </a>
                <!-- Post title -->
                <h3 class="font-weight-bold mb-3"><strong>{{data.title}}</strong></h3>
                <!-- Excerpt -->
                <p>{{data.description}}</p>
                <!-- Post data -->
                <p>by <a><strong>{{data.createdBy.name}}</strong></a>, {{data.createdBy.date | timeAgo}}</p>
                <!-- Read more button -->
                <a class="btn btn-primary btn-md waves-effect waves-light">Read more</a>

            </div>
            <!-- Grid column -->
        </div>
    </div>
</div>