import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $: any

@Component({
  selector: 'app-ratecalculator',
  templateUrl: './ratecalculator.component.html',
  styleUrls: ['./ratecalculator.component.css']
})
export class RatecalculatorComponent implements OnInit {

  postType: string = "ems";
  ipErrMsg = ''
  dhlErrMsg = ''
  aramexRates = 0
  dataSuccess: boolean = true;
  dataError: boolean = true;
  dhlData = []
  ems: string = "";
  air: string = "";
  airServe: string = "";
  trackedPackets: string = "";
  weightNotSupported: string = "";
  isCompare = false;
  countries: any = [];
  maxWeight: any = null;
  maxLength: any = null;
  maxWidth: any = null;
  maxHeight: any = null;

  settings = {
    singleSelection: true,
    text: "Select Destination Country",
    selectAllText: 'Select All',
    enableFilterSelectAll: false,
    unSelectAllText: 'UnSelect All',
    classes: "myclass custom-class",
    idField: 'id',
    textField: 'itemName',
    allowSearchFilter: true,
    itemsShowLimit: 3,
  };

  // settings: IDropdownSettings = {
  //   singleSelection: true,
  //   idField: 'id',
  //   textField: 'itemName',
  //   selectAllText: 'Select All',
  //   unSelectAllText: 'UnSelect All',
  //   itemsShowLimit: 3,
  //   allowSearchFilter: true
  // };

  currentUrl: any;
  select: any = [];
  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute) {
    this.currentUrl = this.activatedRoute.snapshot.params;
    this.getCountries()
  }

  ngOnInit() {
  }

  changeCountry() {
    this.dataError = true;
    this.dataSuccess = true;
    this.air = ""
    this.ems = ""
    this.airServe = ""
    this.trackedPackets = ''
    this.dhlData = []
    if (!(this.select.length == 0 || !(this.maxWeight) || this.maxWeight == 0 || this.maxWeight == '0' || !(this.maxHeight) || this.maxHeight == 0 || this.maxHeight == '0' || !(this.maxWidth) || this.maxWidth == 0 || this.maxWidth == '0' || !(this.maxLength) || this.maxLength == 0 || this.maxLength == '0')) {
      this.calculate()
    }

  }

  comparePrice(country) {
    this.select.push({ itemName: country, id: country })
  }

  onItemSelect() {
    this.changeCountry();
  }

  OnItemDeSelect(item: any) {
  }

  onSelectAll(items: any) {
    // // console.log(items);
  }

  onDeSelectAll(items: any) {
    // // console.log(items);
  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\.\s]/.test(input);
  }

  getCountries() {
    this.authService.getCountries({}).subscribe((res) => {
      this.countries = [];
      if (res.success) {
        if (res.data.length != 0) {
          for (let index = 0; index < res.data.length; index++) {
            this.countries.push({ id: res.data[index].country, itemName: res.data[index].country })
          }
          let p = this.currentUrl.details
          if (p.toLowerCase() != 'new') {
            var d = p.split('_');
            this.maxHeight = Number(d[1])
            this.maxWidth = Number(d[2])
            this.maxLength = Number(d[3])
            this.maxWeight = Number(d[4])
            this.comparePrice(d[0]);
            this.changeCountry()
            // console.log(d)
          }
        }
        else {
          this.authService.showAlert('No Country Found')
        }
      }
      else {
        this.authService.showAlert('No Country Found');
      }
    })
  }

  public calculate() {
    this.ipErrMsg = '';
    this.air = ""
    this.ems = ""
    this.airServe = ""
    this.trackedPackets = ''
    var country = this.select[0].itemName;
    var weight = this.maxWeight;

    //1. India post rate 
    this.authService.rateCalculation(country, weight, this.maxLength, this.maxWidth, this.maxHeight).subscribe((res) => {
      this.dataError = true;
      this.dataSuccess = true;
      if (res.success) {
        if (res.msg == "Weight not supported by any available delivery type") {
          this.dataError = false;
          this.ipErrMsg = res.msg
          this.weightNotSupported = res.msg;
          this.ipErrMsg = '';
          this.air = ""
          this.ems = ""
          this.airServe = ""
          this.trackedPackets = ''
        }
        else {
          this.dataSuccess = false;
          this.air = res.data.costForInternationalAirParcel.toString()
          this.ems = res.data.costForEMSSpeedPost.toString()
          // console.log(this.ems)
          this.airServe = res.data.costForAirservice.toString()
          this.trackedPackets = res.data.costForInternationalTrackedPackets.toString();
          setTimeout(() => {
            $('#df').css('right', document.getElementById('454').clientWidth + 20)
            $('#df').css('display', 'block')
          }, 300)
        }
      }
      else {
        this.air = ""
        this.ems = ""
        this.airServe = ""
        this.trackedPackets = ''
        // swal('Oops!',res.message,'error')
      }
    })


    //2. DHL rate 
    this.dhlCalc()

    // 3. Aramex rate
    this.aramexCalc()

    //4. Fedex rate
    this.fedexRateCal()

    //5. Xpressbees rate
    this.xpressbeesRateCal()

    //4. Fedex rate
    this.delhveryRateCal()
  }

  dhlCalc() {
    this.dhlData = []
    this.authService.findDhlRates(
      {
        "countryName": this.select[0].itemName,
        "weights": this.maxWeight
      }).subscribe((res) => {
        if (res.success) {
          this.dhlData = res.ratesdata
          this.dhlData.sort(this.compareFunction)
        }
        else {
          this.dhlData = []
          // swal('Oops!',res.message,'error')
        }
      })
  }

  aramexCalc() {

    //The formula for calculating volumetric weight is length x width x height (cm) / 5000.  (In KG)
    //The formula for calculating volumetric weight is length x width x height (cm) / 5.  (In Grams)
    //
    var volumetricweight = (this.maxLength * this.maxWidth * this.maxHeight) / 5  // we need in grams


    this.authService.postReq('aramex/calculateAramexCharges', { countryCode: this.select[0].id, weight: volumetricweight > this.maxWeight ? volumetricweight : this.maxWeight }).subscribe((res) => {
      if (res.success) {
        this.aramexRates = res.data
      }
      else {
        this.aramexRates = 0
      }
    })
  }


  fedexRates
  fedexRateCal() {
    var obj = {
      consigneeCountry: this.select[0].itemName,
      grossWeight: this.maxWeight,
      netHeight: this.maxHeight,
      netLength: this.maxLength,
      netWidth: this.maxWidth,
      isCalculator: true
    }
    this.authService.postReq('fedex/getShipmentRatesFedex', obj).subscribe((res) => {
      if (res.success) {
        this.fedexRates = res.data
      }
      else {
        this.fedexRates = 0
      }
    })
  }

  delhiveryRates=[]
  delhveryRateCal(){
    var obj = {
        "consigneeCountry": this.select[0].itemName,
        "grossWeight": this.maxWeight,
        "isCalculator": true,
        "netHeight": this.maxHeight,
        "netLength": this.maxLength,
        "netWidth": this.maxWidth
    }
    this.authService.postReq('delhivery/getDelhiveryRate', obj).subscribe((res) => {
      if (res.success) {
        this.delhiveryRates = res.data
      }
      else {
        this.delhiveryRates = []
      }
    })
  }



  xpressbeesRates: any = {}
  xpressbeesRateCal() {

    this.xpressbeesRates = {}

    var obj = {
      consigneeCountry: this.select[0].itemName,
      grossWeight: this.maxWeight,
      netHeight: this.maxHeight,
      netLength: this.maxLength,
      netWidth: this.maxWidth,
      isCalculator: true
    }
    this.authService.postReq('xpressbees/getXpressBeesRate', obj).subscribe((res) => {
      if (res.success) {
        this.xpressbeesRates = res.data
        // console.log(this.xpressbeesRates)
      }
      else {
        this.xpressbeesRates = 0
      }
    })
  }

  compareFunction(a, b) {
    if (a.rate > b.rate)
      return 1;
    else
      return -1;
  }

}

