<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div class="container-fluid">
    <div class="row" style="margin-top:2%">

        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="padding-bottom:0px">
                    <div class="row">
                        <div class="col-sm-9">
                            <div class="dark-font">
                                <ol class="breadcrumb" style="background-color: #fff">
                                    <li class="breadcrumb-item">
                                        <a class="black-text" routerLink="/home">Home</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li *ngIf="authService.isSuperAdmin()" class="breadcrumb-item">
                                        <a class="black-text" routerLink="/exportProof/1">Export Proof Docs</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li *ngIf="authService.isSuperAdmin()" class="breadcrumb-item">
                                        <a class="black-text" routerLink="/exportProof/1">Manage Docs</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li *ngIf="!authService.isSuperAdmin()" class="breadcrumb-item">
                                        <a class="black-text" routerLink="/pbe">My PBE</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li *ngIf="!authService.isSuperAdmin()" class="breadcrumb-item">
                                        <a class="black-text" routerLink="/pbe">Export Proof Docs</a>
                                        <i class="fa fa-caret-right mx-2" aria-hidden="true" style="color:#000"></i>
                                    </li>
                                    <li class="breadcrumb-item active" style="color:#000">
                                        <span><strong>Docket No. : {{docketNo}}</strong></span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-3 text-right">
                            <button *ngIf="!authService.isSuperAdmin()" class="btn btn-outline-primary btn-sm"
                                (click)=closepbe()><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Back</button>
                            <button *ngIf="authService.isSuperAdmin()" class="btn btn-outline-primary btn-sm"
                                (click)=closeexport()><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Back</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-sm-12" style="margin-bottom:30px;">
            <ul class="nav nav-tabs nav-justified" role="tablist" style="margin-top:2%">
                <li class="nav-item waves-effect waves-light">
                    <a class="nav-link active" id="home-tab-md" data-toggle="tab" href="#home-md" role="tab"
                        aria-controls="home-md" aria-selected="true">Export Proof Documents</a>
                </li>
            </ul>

            <div class="tab-content card pt-5" id="myTabContentMD">

                <div class="tab-pane fade show active" id="home-md" role="tabpanel" aria-labelledby="home-tab-md">
                    <table id="mainTable" class="table table-bordered m-b-0 text-center">
                        <thead>
                            <tr>
                                <td>Document Name</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="lHeight">PBE</th>
                                <td>
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none">
                                                <input style="overflow: hidden;" type="file" multiple
                                                    class="form-control input-size" (change)="onFileChangePBE($event)"
                                                    accept="image/x-png,image/jpeg,.pdf" #fileUploader1>
                                            </td>
                                            <td style="border:none;">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="submitPBE();fileUploader1.value = null"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload</button>
                                            </td>
                                            <td style="border:none;">
                                                <button *ngIf="checkPBE || PBECountCheck" class="btn btn-sm btn-warning"
                                                    (click)="viewPBE()" data-toggle="modal"
                                                    data-target="#viewPBEData"><i
                                                        class="fa fa-eye"></i>&nbsp;View</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">RBI</th>
                                <td>
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none">
                                                <input style="overflow: hidden;" type="file" multiple
                                                    class="form-control input-size" (change)="onFileChangeRBI($event)"
                                                    accept="image/x-png,image/jpeg,.pdf" #fileUploader2>
                                            </td>
                                            <td style="border:none;">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="submitRBI();fileUploader2.value = null"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload</button>
                                            </td>
                                            <td style="border:none;">
                                                <button *ngIf="checkRBI || RBICountCheck" class="btn btn-sm btn-warning"
                                                    (click)="viewRBI()" data-toggle="modal"
                                                    data-target="#viewRBIData"><i
                                                        class="fa fa-eye"></i>&nbsp;View</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">Export Inovice</th>
                                <td>
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none">
                                                <input style="overflow: hidden;" type="file" multiple
                                                    class="form-control input-size"
                                                    (change)="onFileChangeExport($event)"
                                                    accept="image/x-png,image/jpeg,.pdf" #fileUploader3>
                                            </td>
                                            <td style="border:none;">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="submitExportInvoice();fileUploader3.value = null"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload</button>
                                            </td>
                                            <td style="border:none;">
                                                <button *ngIf="checkExport || exportInvoiceCheck || checkGeneratedView"
                                                    class="btn btn-sm btn-warning" (click)="viewExportInvoices();"
                                                    data-toggle="modal" data-target="#viewExportData"><i
                                                        class="fa fa-eye"></i>&nbsp;View</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">Postal Receipts</th>
                                <td>
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none">
                                                <input style="overflow: hidden;" type="file" multiple
                                                    class="form-control input-size"
                                                    (change)="onFileChangePostal($event)"
                                                    accept="image/x-png,image/jpeg,.pdf" #fileUploader4>
                                            </td>
                                            <td style="border:none;">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="submitPostalReciepts();fileUploader4.value = null"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload</button>
                                            </td>
                                            <td style="border:none;">
                                                <button *ngIf="checkPostal || postalCountCheck"
                                                    class="btn btn-sm btn-warning" (click)="viewPostalReciepts()"
                                                    data-toggle="modal" data-target="#viewPostalData"><i
                                                        class="fa fa-eye"></i>&nbsp;View</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th class="lHeight">Additional Documents</th>
                                <td>
                                    <table class="m-auto">
                                        <tr style="border: none">
                                            <td style="border:none">
                                                <input style="overflow: hidden;" type="file" multiple
                                                    class="form-control input-size"
                                                    (change)="onFileChangeAdditionalDocs($event)"
                                                    accept="image/x-png,image/jpeg,.pdf" #fileUploader5>
                                            </td>
                                            <td style="border:none;">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="submitAdditionalDocs();fileUploader5.value = null"><i
                                                        class="fa fa-upload"></i>&nbsp;Upload</button>
                                            </td>
                                            <td style="border:none;">
                                                <button *ngIf="checkAdditional || AdditionalDocCountCheck"
                                                    class="btn btn-sm btn-warning" (click)="viewAdditionalDocs()"
                                                    data-toggle="modal" data-target="#viewAdditionalData"><i
                                                        class="fa fa-eye"></i>&nbsp;View</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center">
                        <button *ngIf="!authService.isSuperAdmin()" class="btn btn-outline-primary btn-sm"
                            (click)=closepbe()>CLOSE</button>
                        <button *ngIf="authService.isSuperAdmin()" class="btn btn-outline-primary btn-sm"
                            (click)=closeexport()>CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- PBE Modal -->
<div class="modal fade" id="viewPBEData" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngFor="let r of pbeData; let j=index">
                    <!-- <a style="
      text-decoration: underline;
      color: blue;
      font-weight: 500;
      "
            href="{{pbeimagearray[j]}}">PBE&nbsp;-&nbsp;{{j+1}}<br>
          </a> -->

                    <a (click)="pbe_rbi_exportInvoiceView('pbe', r )"
                        style="text-decoration: underline; color: blue; font-weight: 500;">
                        PBE&nbsp;-&nbsp;{{j+1}}<br>
                    </a>


                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- RBI Modal -->
<div class="modal fade" id="viewRBIData" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngFor="let r of rbiData; let j=index">
                    <a (click)="pbe_rbi_exportInvoiceView('rbi', r )"
                        style="text-decoration: underline; color: blue; font-weight: 500;">RBI&nbsp;-&nbsp;{{j+1}}<br>
                    </a>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- Postal Receipt Modal -->
<div class="modal fade" id="viewPostalData" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngFor="let r of postalReceiptData; let j=index">

                    <a style="
        text-decoration: underline;
        color: blue;
        font-weight: 500;
      " target="_blank" href="https://s3.ap-south-1.amazonaws.com/picmybox-internal/receipts/{{r}}">Postal
                        Receipt&nbsp;-&nbsp;{{j+1}}<br>
                    </a>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- Export Invoices Modal -->
<div class="modal fade" id="viewExportData" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngIf="checkGeneratedView">&nbsp;<a style="
        text-decoration: underline;
        font-weight: 500;" href="{{generatedFile}}" class="text-default">Download Generated Invoice</a>
                </div>
                <div *ngFor="let r of exportInvoiceData; let j=index">
                    <a (click)="pbe_rbi_exportInvoiceView('exportInvoice', r )"
                        style="text-decoration: underline; color: blue; font-weight: 500;">Export
                        Invoice&nbsp;-&nbsp;{{j+1}}<br>
                    </a>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- Additional Doc Modal -->
<div class="modal fade" id="viewAdditionalData" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngFor="let r of additionalDocData; let j=index">
                    <a (click)="additionalView(r )"
                        style="text-decoration: underline; color: blue; font-weight: 500;">Additional
                        Doc&nbsp;-&nbsp;{{j+1}}<br>
                    </a>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>