import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;


  constructor(public formBuilder: FormBuilder, public authService: AuthService,
    public router: Router, private nfs: NotificationService) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        this.emailValidation
      ])],
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      cname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      mobile: ['', Validators.compose(
        [
          Validators.required, this.mobileValidation])
      ],
      message: ['', Validators.compose(
        [
          Validators.required, Validators.minLength(10)])
      ],
    });
  }


  mobileValidation(control) {
    if (control.value == null || control.value == "" || control.value == undefined) {
      return { 'mobileNull': false };
    }
    else {
      var mobile = control.value.toString();
      var regexMobile = /^\d{10}(,\d{10})*$/;
      if (mobile.match(regexMobile)) {
        return { 'mobile': true };
      }
      else {
        return { 'mobile': false };
      }
    }

  }

  emailValidation(control) {
    if (control.value == null || control.value == "") return null;
    var email = control.value;
    var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/i;
    if (email.match(regexEmail)) {
      return { 'email': true };
    }
    else {
      return { 'email': false };
    }
  }

  getQuote(type) {
    this.form.get('message').setValue(type)
  }

  submitForm(formVal) {

    this.authService.contactFormEmail(formVal).subscribe(data => {
      if (data.success) {
        this.nfs.showInfo('Success', data.message, 'success');
        this.form.reset()
      }
      else {
        this.nfs.showInfo('Error', data.message, 'error')
      }
    })
  }



}
