import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as crypto from "crypto-js";
import { Location } from '@angular/common';
import { SocketService } from './socket.service';
declare var snackbar: any;
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userId: any;
  domain = environment.apiUrl;
  socketUrl = environment.socketUrl;
  x: any = null;
  editType: any = null;
  pdf;
  messages: any;
  authToken;
  user;
  route;
  options;
  notification;
  noticeId;
  stateList: any;
  private helper: any = '';
  listenObs: any = { success: false, docketNo: "" };
  listenObs$: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    public router: Router,
    private socketService: SocketService,
    public activatedRoute: ActivatedRoute,
    public location: Location) {
    this.listenObs$ = new BehaviorSubject(this.listenObs);
    this.helper = new JwtHelperService();
  }

  setListenObs(ss, val) {
    this.listenObs = { success: ss, docketNo: val };
    this.listenObs$.next(this.listenObs);
  }

  getListenObs(): Observable<any> {
    return this.listenObs$ as Observable<any>;
  }

  loadToken() {
    this.authToken = localStorage.getItem("authToken");
  }

  loadUserToken() {
    this.authToken = localStorage.getItem("token");
  }

  register(user): Observable<any> {
    return this.http
      .post(this.domain + "authentication/register", user)

  }

  getCurrencies(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getCurrencies", this.options)

  }

  newRegistration(user): Observable<any> {

    return this.http.post(this.domain + "authentication/newregistrationUser", user)
  }

  allusers(obj): Observable<any> {

    if (obj.userType == 101 || obj.userType == '101') { // for p2p user
      return this.http.post(this.domain + "authentication/personalallusers", obj)
    } else {
      return this.http.post(this.domain + 'authentication/allusers', obj)
    }
  }

  getroles(): Observable<any> {
    return this.http
      .get(this.domain + "authentication/getroles")

  }

  getmyclients(): Observable<any> {
    return this.http
      .get(this.domain + "authentication/getmyclients")

  }

  updateroles(obj): Observable<any> {
    return this.http
      .post(this.domain + "authentication/updateroles", obj)

  }

  uploadImage(file): Observable<any> {
    return this.http
      .post(this.domain + "authentication/upload", file)

  }
  processdhlinvoice(fileData): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/processdhlinvoice",
        fileData,
        this.options
      )

  }
  gendhlbillfile(obj): Observable<any> {

    return this.http
      .post(this.domain + "excel/gendhlbillfile", obj)

  }
  updateDhlInvoices(obj): Observable<any> {

    return this.http
      .post(this.domain + "excel/updateDhlInvoices", obj)

  }
  processbillfile(obj): Observable<any> {

    return this.http
      .post(this.domain + "excel/processbillfile", obj)

  }

  uploadPdf(file): Observable<any> {
    return this.http
      .post(this.domain + "authentication/uploadFileforusers", file)

  }

  fetchDocsFromS3(details): Observable<any> {
    return this.http.post(this.domain + "authentication/fetchDocsFromS3", details);
  }

  uploadInvoice(file): Observable<any> {

    return this.http
      .post(this.domain + "authentication/uploadInvoice", file)

  }

  registerTruck(truckDetails): Observable<any> {
    return this.http
      .post(this.domain + "authentication/registertruck", truckDetails)

  }

  registerBankAccount(bankDetails): Observable<any> {
    return this.http
      .post(this.domain + "authentication/registerbankacc", bankDetails)

  }

  sendOtp(data): Observable<any> {
    return this.http
      .post(this.domain + "authentication/sendotp", data)

  }

  sendOtpMobileProtected(data): Observable<any> {
    return this.http
      .post(this.domain + "authentication/sendotpforpasswordreset", data)

  }

  verifyIec(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifyIec2023", obj)

  }

  verifyGST(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifyGst2023", obj)

  }

  verifyotp(otpdata): Observable<any> {
    return this.http
      .post(this.domain + "authentication/verifyotp", otpdata)

  }
  createFPOUser(otpdata): Observable<any> {

    return this.http
      .post(this.domain + "authentication/createFPOUser", otpdata)

  }

  createCreditNote(otpdata): Observable<any> {
    return this.http
      .post(
        this.domain + "subscription/createCreditNote",
        otpdata,
        this.options
      )

  }

  verifyAndSetPassword(otpdata): Observable<any> {
    return this.http
      .post(this.domain + "authentication/verifyAndSetPassword", otpdata)

  }

  verifyotpForgotPassword(otpdata): Observable<any> {
    return this.http
      .post(this.domain + "authentication/verifyotpforforgotpassword", otpdata)

  }

  getchaList(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getchaList")

  }

  getOrdersForEditByBoxID(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/getOrdersForEditByBoxID", arr)

  }
  deleteorders(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/deleteorders", arr)

  }

  meisFormGenerate(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/meisFormGenerate", arr)

  }

  deleteboxes(o): Observable<any> {

    return this.http
      .post(this.domain + "order/deleteboxes", o)

  }
  mergeParcels(o): Observable<any> {

    return this.http
      .post(this.domain + "order/mergeParcels", o)

  }

  getEdfData(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/getEdfData", arr)

  }

  deletebypbe(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/deletebypbe", arr)

  }

  undobypbe(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/undobypbe", arr)

  }

  sendpbeToCustoms(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/sendpbeToCustoms", arr)

  }
  submitToAramex(arr): Observable<any> {

    return this.http
      .post(this.domain + "aramex/bookingSubmitToAramex", arr)

  }

  createnotification(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/createnotification", arr)

  }

  contactFormEmail(val): Observable<any> {
    return this.http
      .post(this.domain + "authentication/contactFormEmail", val)

  }
  getAuthKey(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getAuthKey")

  }
  genAuthKey(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/genAuthKey")

  }

  checkforsubscription(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/checkforsubscription")

  }

  getCurrency(): Observable<any> {
    return this.http.get("../currency.json");
  }

  payment(): Observable<any> {

    return this.http
      .get(this.domain + "payment/payumoney")

  }

  getAllSubuser(): Observable<any> {

    return this.http
      .get(this.domain + "subuser/allsubusersDetails")

  }

  getAllOrders(): Observable<any> {

    return this.http
      .get(this.domain + "order/allorders")

  }
  getAdDetails(): Observable<any> {
    return this.http
      .get(this.domain + "order/getAdDetails")

  }
  markaspaidtoagent(body): Observable<any> {

    return this.http
      .post(this.domain + "order/markaspaidtoagent", body)

  }
  getuserWallet(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getuserWallet", obj)

  }
  getdhlBillSummary(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getdhlBillSummary", obj)

  }
  getmails(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getmails", obj)

  }
  composemail(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/composemail", obj)

  }
  subscribePlan(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/subscribePlan", obj)

  }

  getDhlstates(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getDhlstates", obj)

  }
  getZips(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getZips", obj)

  }

  submitFedexData(obj): Observable<any> {

    return this.http
      .post(this.domain + "fedex/submitFedexData", obj)

  }

  getParcelById(obj): Observable<any> {

    return this.http.post(this.domain + "fedex/getParcelById", obj)
  }

  getDHLexpressParcelById(obj): Observable<any> {

    return this.http.post(this.domain + "dhlexpress/getDHLExpressParcelById", obj)
  }

  updateFields(obj): Observable<any> {

    return this.http.post(this.domain + "fedex/updateFedexFields", obj)
  }

  getusertransaction(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getusertransaction")

  }
  getusertransactionpost(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getusertransaction",
        obj,
        this.options
      )

  }
  getusertransactioncount(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getusertransactioncount",
        obj,
        this.options
      )

  }
  issueRefund(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/issueRefund", obj)

  }

  getAllusertransaction(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getAllusertransaction",
        obj,
        this.options
      )

  }

  getalltransaction(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getalltransaction", obj)

  }
  getalltransactionCount(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getalltransactionCount",
        obj,
        this.options
      )

  }
  updateFund(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/updateFund", obj)

  }

  // printlabel(obj) {
  //  
  //   return this.http.post(this.domain + 'order/printlabel',obj)
  // }

  getnotice(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getnotice")

  }
  updateAdDetails(o): Observable<any> {

    return this.http
      .post(this.domain + "order/updateAdDetails", o)

  }
  updatedhlDetails(o): Observable<any> {

    return this.http
      .post(this.domain + "authentication/updatedhlDetails", o)

  }
  verifycsb(o): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifycsb", o)

  }

  deleteAdDetails(o): Observable<any> {

    return this.http
      .post(this.domain + "order/deleteAdDetails", o)

  }

  deleteFiscalDetails(o): Observable<any> {

    return this.http
      .post(this.domain + "authentication/deleteFiscalDetails", o)

  }

  sendMailDHLioss(o): Observable<any> {

    return this.http
      .post(this.domain + "authentication/sendMailDHLioss", o)

  }

  allordersbyiec(val): Observable<any> {

    return this.http
      .get(this.domain + "order/allordersbyiec/" + val)

  }

  updatesubuser(data): Observable<any> {

    return this.http
      .post(this.domain + "subuser/updatesubuser", data)

  }

  // registerStudent(student){
  //  
  //   return this.http.post(this.domain+'authentication/registerstudent', student)
  // }

  checkUser(username): Observable<any> {
    return this.http
      .get(this.domain + "authentication/checkUsername/" + username)

  }

  login(user): Observable<any> {
    return this.http
      .post(this.domain + "authentication/login", user)

  }

  resetPassword(details): Observable<any> {
    return this.http
      .post(this.domain + "authentication/resetpassword", details)

  }

  setNewpassword(details): Observable<any> {
    return this.http
      .post(this.domain + "authentication/setNewpassword", details)

  }
  setDefaultsignatory(details): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/setDefaultsignatory",
        details,
        this.options
      )

  }

  checkEncrptedString(details): Observable<any> {
    return this.http
      .get(this.domain + "authentication/checkEncrptedString/" + details)

  }

  uploadExcel(details): Observable<any> {
    return this.http
      .post(this.domain + "authentication/uploadsealfile", details)

  }

  uploadreceipts(details): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/uploadreceipts",
        details,
        this.options
      )

  }

  updateExhangerate(data): Observable<any> {

    return this.http
      .post(this.domain + "order/updateExhangerate", data)

  }
  updatesdrvalue(o): Observable<any> {

    return this.http
      .post(this.domain + "order/updatesdrvalue", o)

  }

  getSdrValue(): Observable<any> {

    return this.http
      .get(this.domain + "order/getSdrValue")

  }

  getMeisData(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getAllordersForMeis", obj)

  }

  getgeneratedmeis(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getgeneratedmeis", obj)

  }

  deleteobject(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/deleteobject", obj)

  }

  sendmeistodgft(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/sendmeistodgft", obj)

  }

  addremark(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/addremark", obj)

  }

  updatestatusbydgft(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updatestatusbydgft", obj)

  }



  sendMail(data): Observable<any> {
    return this.http
      .post(this.domain + "authentication/sendEmail", data)

  }

  snd(details): Observable<any> {

    return this.http
      .post(
        "http://api.pdflayer.com/api/convert?access_key=143995c23387be981f40f3da62007faf&document_url=https://pdflayer.com/documentation",
        details
      )

  }

  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.removeItem("token");
    localStorage.removeItem("authToken");
  }

  logoutFormServer(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/logout")

  }

  storeUserData(data) {
    let { token, redirectToken } = data;
    localStorage.setItem("token", token);
    localStorage.setItem("authToken", redirectToken);
    this.authToken = redirectToken;
  }

  getUserToken(): Observable<any> {
    return this.http.get(this.domain + "authentication/userToken")
  }

  getProfile(): Observable<any> {
    return this.http.get(this.domain + "authentication/profile")
  }

  GetDecodedUser() {
    const token = localStorage.getItem('token');

    if (token) {
      let decodedToken = this.helper.decodeToken(token);

      if (decodedToken) {
        return decodedToken;
      }

      return {};
    }
  }

  loggedIn() {
    try {
      const token = localStorage.getItem('token');
      // // console.log("token >> ", token);

      if (token) {
        // Check whether the token is expired 
        let decodedToken = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        // // console.log("decodedToken >> ", decodedToken)
        if (decodedToken && !isExpired) {
          return true;
        }
      }
      return false;
    } catch (error) {
      // console.log("catch loggedIn >>> ", error)
      return false;
    }
  }

  checkieCode(ieCode): Observable<any> {

    return this.http
      .get(this.domain + "authentication/checkIeCode/" + ieCode)

  }

  stateAndCityList(obj): Observable<any> {
    return this.http.post(this.domain + "authentication/getStateAndCity", obj)
  }

  generateInvoicesInBulk(docket, ie = ''): Observable<any> {

    return this.http.post(this.domain + "ses/generateInvoicesInBulk",
      { docketNo: docket, ieCode: ie })
  }

  downloadInvoicesInBulk(docket): Observable<any> {

    return this.http.get(this.domain + 'downloadZip/' + docket);
  }

  checkIeCodeForDataEntry(ieCode): Observable<any> {

    return this.http
      .get(
        this.domain + "authentication/checkIeCodeForDataEntry/" + ieCode,
        this.options
      )

  }

  checkieccodeForgotPassword(ieccode): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/checkieccodeForForgotPassword",
        ieccode,
        this.options
      )

  }

  saveForlater(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/saveForlater", arr)

  }

  downloadpbedatainexcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/downloadpbedatainexcel", obj)

  }

  downloadAsPdf(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/downloadAsPdf", obj)

  }
  downloadrazorpaydata(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getallcapturedpayment",
        obj,
        this.options
      )

  }

  downloadMeisExcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/downloadMeisExcel", obj)

  }

  downloadGstin(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/downloadgstr", obj)

  }

  downloadPbeData(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/downloadPbeData", obj)

  }

  getfpolist(): Observable<any> {

    return this.http
      .get(this.domain + "order/getfpolist")

  }

  editorders(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/editorders", arr)

  }

  processebay(arr): Observable<any> {

    return this.http
      .post(this.domain + "ebay/processebay", arr)

  }

  processamazon(arr): Observable<any> {

    return this.http
      .post(this.domain + "amazon/processamazon", arr)

  }

  sendOrdersToCha(arr): Observable<any> {

    return this.http
      .post(this.domain + "order/sendOrdersToCha", arr)

  }

  pboeprintRequest(): Observable<any> {

    return this.http
      .get(this.domain + "order/downloadAsPdf")

  }
  getclientsbyorders(): Observable<any> {

    return this.http
      .get(this.domain + "order/getclientsbyorders")

  }
  createBox(data): Observable<any> {

    return this.http
      .post(this.domain + "order/createBox", data)

  }
  updateFpo(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updateFpo", obj)

  }
  updatePbe(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updatePbe", obj)

  }
  undopbe(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/undopbe", obj)

  }
  alliecordersbypbe(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/alliecordersbypbe", obj)

  }
  updateTrackingNo(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updateTrackingNo", obj)

  }
  getCountries(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getCountries", obj)

  }

  getCategories(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getCategories")

  }

  changeArr2ArrofObjects(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/changeArr2ArrofObjects", obj)

  }

  uploadRowData(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/saveneworderbyexcel", obj)

  }

  allordersbybox(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/allordersbybox", obj)

  }

  aramexParcels(obj): Observable<any> {

    return this.http
      .post(this.domain + "aramex/getparcels", obj)

  }
  fedexParcels(obj): Observable<any> {

    return this.http
      .post(this.domain + "fedex/getFedexParcels", obj)

  }

  xpressbeesParcels(obj): Observable<any> {

    return this.http
      .post(this.domain + "xpressbees/parcelListing", obj)

  }

  dhlExpParcels(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhlexpress/getDHLExpParcels", obj)

  }

  createFedexShipment(obj): Observable<any> {

    return this.http
      .post(this.domain + "fedex/createFedexShipment", obj)

  }

  submit2xpressbeesMethod(obj): Observable<any> {

    return this.http
      .post(this.domain + "xpressbees/submit2xpressbeesRoute", obj)

  }

  createDHLexpressShipment(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhlexpress/createDHLexpressShipment", obj)

  }

  deletedhlexpPraecels(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhlexpress/deleteDHLeXPARCEL", obj)

  }

  allordersbypbe(o): Observable<any> {

    return this.http
      .post(this.domain + "order/allordersbypbe", o)

  }

  getdataforboxbypbe(o): Observable<any> {

    return this.http
      .post(this.domain + "order/getdataforboxbypbe", o)

  }

  getpbecount(o): Observable<any> {

    return this.http
      .post(this.domain + "order/getpbecount", o)

  }

  updateLeoDate(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updateLeoDate", obj)

  }

  // updateEgmData(obj) {
  //  
  //   return this.http .post(this.domain + "order/updateEgmData", obj) 
  // }

  updateEgmData(obj): Observable<any> {

    return this.http.post(this.domain + "order/update_egmDetails", obj)
  }

  trackshipment(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/trackshipment", obj)

  }

  printLabel(val): Observable<any> {

    return this.http
      .post(this.domain + "order/printLabel", val)

  }

  getboxcount(details = {}): Observable<any> {
    return this.http
      .post(this.domain + "order/getboxcount", details)

  }

  getDhlboxcount(): Observable<any> {
    return this.http.get(this.domain + "order/getDhlboxcount")
  }

  getSdr(): Observable<any> {
    return this.http.get(this.domain + "order/getsdrvalue")
  }

  createPbe(obj): Observable<any> {
    return this.http.post(this.domain + "order/createPbe", obj)
  }

  saveneworderbyexcel(obj): Observable<any> {
    return this.http.post(this.domain + "order/saveneworderbyexcel", obj)
  }

  InsertComboData(obj): Observable<any> {
    return this.http.post(this.domain + "domestic/InsertComboData", obj)
  }

  getIntegratedCourierList(obj): Observable<any> {
    return this.http.post(this.domain + "domestic/getIntegratedCourierList", obj)
  }

  sendToCha(obj): Observable<any> {
    return this.http.post(this.domain + "order/sendboxtocha", obj)
  }

  printpboe(val): Observable<any> {
    return this.http.post(this.domain + "order/printpboe", val)
  }

  dataforpboe(val): Observable<any> {
    return this.http.post(this.domain + "order/dataforpboe", val)
  }

  getdataagainstbox(obj): Observable<any> {
    return this.http.post(this.domain + "order/getdataagainstbox", obj)
  }

  saveEdfData(obj): Observable<any> {
    return this.http.post(this.domain + "order/saveEdfData", obj)
  }

  saveneworderbyui(arr): Observable<any> {
    return this.http.post(this.domain + "save/saveitems", arr)
  }

  submitParcel(arr): Observable<any> {
    return this.http.post(this.domain + "dhlexpress/submitParcel", arr)
  }

  savedhldata(arr): Observable<any> {
    return this.http.post(this.domain + "save/savedhldata", arr)
  }

  createAramexBooking(arr): Observable<any> {
    return this.http.post(this.domain + "aramex/createAramexBooking", arr)
  }

  editorderbyui(arr): Observable<any> {
    return this.http.post(this.domain + "order/editorderbyui", arr)
  }

  editdatadhl(arr): Observable<any> {
    return this.http.post(this.domain + "order/editdatadhl", arr)
  }

  getdatabybox(val): Observable<any> {
    return this.http.post(this.domain + "order/getdatabybox", val)
  }

  getdatabyboxforinvoice(val): Observable<any> {
    return this.http.post(this.domain + "order/getdatabyboxforinvoice", val)
  }

  getconsigneedatabybox(val): Observable<any> {
    return this.http.post(this.domain + "order/getconsigneedatabybox", val)
  }

  rateCalculation(country, weight, l, w, h): Observable<any> {
    var details = {
      country: country,
      weight: weight,
      length: l,
      width: w,
      height: h,
    };

    return this.http
      .post(
        this.domain + "authentication/rateCalculation",
        details,
        this.options
      )

  }
  findDhlRates(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhl/finddhlrates", obj)

  }
  closeOut(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhl/findcloseout", obj)

  }
  dhlLabelDownload(obj): Observable<any> {
    return this.http.post(this.domain + "dhl/dhlLabelDownload", obj)
  }

  pickup(obj): Observable<any> {
    return this.http.post(this.domain + "dhl/findpickup", obj)
  }

  requestPickup(obj): Observable<any> {
    return this.http.post(this.domain + "order/requestPickup", obj)
  }

  getallpickuprequests(obj): Observable<any> {
    return this.http.post(this.domain + "order/getallpickuprequests", obj)
  }

  getallpickuprequestscount(obj): Observable<any> {
    return this.http.post(this.domain + "order/getallpickuprequestscount", obj)
  }



  allUsersCount(obj): Observable<any> {
    return this.http.post(this.domain + "authentication/allUsersCount", obj)
  }

  trackParcel(obj): Observable<any> {
    return this.http.post(this.domain + "authentication/trackParcel", obj)
  }

  updateProfile(obj): Observable<any> {
    return this.http
      .post(this.domain + "authentication/updateProfile", obj)
  }

  saveInventoryItem(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/saveInventoryItem", obj)
  }

  fetchInventoryItems(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/fetchInventoryItems", obj)
  }

  updateItem(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/updateItem", obj)
  }

  updateAvailabelQty(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/updateAvailabelQty", obj)
  }

  fetchItemTransactions(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/fetchItemTransactions", obj)
  }

  deleteInventoryItem(obj): Observable<any> {

    return this.http.post(this.domain + "inventory/deleteInventoryItem", obj)
  }

  updateFiscalidDetails(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/updateFiscalidDetails",
        obj,
        this.options
      )

  }

  uploadMeisDoc(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/meisfileupload", obj)

  }

  adminlogin(admin): Observable<any> {
    return this.http
      .post(this.domain + "adminauth/loginadmin", admin)

  }

  getItemLookUp(): Observable<any> {

    return this.http
      .get(this.domain + "lookup/getMainCat")

  }

  getAgentsForPickup(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/getAgentsForPickup", obj)

  }
  getChargesForPickup(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/calculatePickupCharge", obj)

  }

  getMainCat(obj): Observable<any> {
    return this.http
      .post(this.domain + "lookup/getHsnData", obj)

  }

  downloadMeisExcelAfterUpload(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "order/downloadMeisExcelAfterUpload",
        obj,
        this.options
      )

  }

  scanParcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/scanParcel", obj)

  }

  gethelpfulresources(): Observable<any> {

    return this.http
      .get(this.domain + "lookup/gethelpfulresources")

  }
  viewDoc(obj): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/viewDoc", obj)

  }

  getallnews(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getallnews")

  }

  createhelpfulresource(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/createhelpfulresource", obj)

  }

  uploadAgentData(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/uploadAgentData", obj)

  }

  createNews(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/createnews", obj)

  }

  getInvoices(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/getInvoices", obj)

  }

  printallcnform(val): Observable<any> {

    return this.http
      .post(this.domain + "order/printallcnform", val)

  }

  generateHarmonizedLabel(val): Observable<any> {

    return this.http
      .post(this.domain + "order/generateHarmonizedLabel", val)
  }

  printallcnformWithShippingLabel(val): Observable<any> {

    return this.http.post(this.domain + 'order/printacnformWithShippinglabel', val)
  }

  deleteInvoice(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/deleteInvoice", obj)

  }

  printallshippinglabels(val): Observable<any> {

    return this.http
      .post(this.domain + "order/printallshippinglabels", val)

  }
  generateshippinglabelsForDHL(val): Observable<any> {

    return this.http
      .post(this.domain + "dhl/findshippingLabel", val)

  }

  trackDhlParcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "dhl/trackDhlParcel", obj)

  }

  printallcpform(val): Observable<any> {

    return this.http
      .post(this.domain + "order/printallcpform", val)

  }

  viewpbesummary(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/viewpbesummary", obj)

  }

  harmonizedCountries(val): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/harmonizedCountries", val)

  }

  getCurrentPBEdetails(val): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/getCurrentPBEdetails", val)

  }

  eVerifiedUpload(obj): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/eVerifiedUpload", obj)

  }

  inventoryItemImagesUpload(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/inventoryItemImagesUpload", obj)

  }

  trackingIdUpload(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/trackingIdUpload", obj)

  }

  removeTrackingId(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/removeTrackingId", obj)

  }

  getAllTrackingIds(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/getAllTrackingIds", obj)

  }

  addShopifyItems(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/shopifyFetch", obj)

  }

  viewInventoryItemImage(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/viewItemImage", obj)

  }

  deleteItemImage(obj): Observable<any> {

    return this.http
      .post(this.domain + "inventory/deleteItemImage", obj)

  }


  checkDocumentStatus(obj): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/checkDocumentStatus", obj)

  }

  deletedocument(obj): Observable<any> {

    return this.http
      .post(this.domain + "indiaPost/deletedocument", obj)

  }

  changeApistatus(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/changeApistatus", obj)

  }

  getApiStats(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getApiStats", obj)

  }

  orderDetails(): Observable<any> {

    return this.http
      .post(this.domain + "lookup/postAnalyticsPMB", "")

  }

  pbeChartData(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/monthlyfpopbedata", obj)

  }

  productCatPieChart(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/monthlycatwisedata", obj)

  }

  getProfileDetails(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getprofile")

  }

  getFiscalIdDetails(): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getFiscalIdDetails", {})

  }

  addremoveserviceProvider(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/addremoveserviceProvider",
        obj,
        this.options
      )

  }

  verifyPass(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifyPassword", obj)

  }
  getOtp(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/sendotpformobileupdate",
        obj,
        this.options
      )

  }
  verifyotpforupdate(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/verifyotpforupdate",
        obj,
        this.options
      )

  }
  createrazorpayorder(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/createrazorpayorder",
        obj,
        this.options
      )

  }
  generateCSI(obj): Observable<any> {

    return this.http
      .post(this.domain + "excel/gencsi", obj)

  }

  uploadExcelFiles(obj): Observable<any> {

    return this.http
      .post(this.domain + "excel/uploadExcelFiles", obj)

  }

  getExcelFiles(): Observable<any> {

    return this.http
      .get(this.domain + "excel/getExcelFiles")

  }

  getAllConsignees(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getAllConsignees", obj)

  }

  notesubmit(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/addSellerNote", obj)

  }

  getAddedNote(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getAddedNote", obj)

  }

  getTaxInvForSubs(id): Observable<any> {

    return this.http
      .get(
        this.domain + "subscription/generateTaxInvoiceForSub/" + id,
        this.options
      )

  }
  genCreditNote(body): Observable<any> {

    return this.http
      .post(this.domain + "subscription/generateCreditNote", body)

  }

  createTicket(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/createticket", obj)

  }
  updateSubscription(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/updateSubscription",
        obj,
        this.options
      )

  }

  gettickets(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/gettickets", obj)

  }

  getTicketsCount(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/getTicketsCount", obj)

  }

  getComments(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/getcomments", obj)

  }

  addComment(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/addcomment", obj)

  }
  acceptExporter(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/acceptExporter", obj)

  }

  searchParcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/searchParcel", obj)

  }
  cloneParcel(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/cloneParcel", obj)

  }
  deleteTicket(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/deleteTicket", obj)

  }
  getTaskDetails(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/getTaskDetails", obj)

  }
  getPmsUsers(): Observable<any> {

    return this.http
      .get(this.domain + "ticket/getPmsUsers")

  }
  itemsShipped(): Observable<any> {

    return this.http
      .get(this.domain + "stats/itemsShipped")

  }
  pbewiseData(): Observable<any> {

    return this.http
      .get(this.domain + "stats/pbewiseData")

  }
  parcelwiseData(): Observable<any> {

    return this.http
      .get(this.domain + "stats/parcelwiseData")

  }
  topTendest(): Observable<any> {

    return this.http
      .get(this.domain + "stats/parcelbydestinationforexporter")

  }
  pbevaluestatsforexporter(): Observable<any> {

    return this.http
      .get(this.domain + "stats/pbevaluestatsforexporter")

  }
  categoryWiseItemsCount(): Observable<any> {

    return this.http
      .get(this.domain + "stats/categoryWiseItemsCount")

  }
  updateTicket(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/updateTicket", obj)

  }

  getticketdetails(obj): Observable<any> {

    return this.http
      .post(this.domain + "ticket/getticketdetails", obj)

  }

  createBlog(obj): Observable<any> {

    return this.http
      .post(this.domain + "blog/createBlog", obj)

  }
  getBlog(obj): Observable<any> {

    return this.http
      .post(this.domain + "blog/getBlogs", obj)

  }

  getedfdataforpbe(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getedfdataforpbe", obj)

  }
  getallcsbs(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getallcsbs", obj)

  }
  getShipments(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getShipments", obj)

  }
  updateCloseout(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/updateCloseout", obj)

  }

  getallcsbscount(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getallcsbscount", obj)

  }

  getalldhlBillscount(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getalldhlBillscount",
        obj,
        this.options
      )

  }

  sendDocViaEmail(obj): Observable<any> {

    return this.http
      .post(this.domain + "docs/sendDocViaEmail", obj)

  }

  getCountriesthreshold(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getCountriesThreshold")

  }

  getCountriesthresholdData(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getThresholdBrCountry",
        obj,
        this.options
      )

  }

  verifyiec(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifyiec", obj)

  }

  getExporterDoc(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getExporterDoc", obj)

  }

  getLogoForInvoice(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getLogo")

  }

  getLogoForExporter(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/getLogoforexporter",
        obj,
        this.options
      )

  }

  getInvoiceCount(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/getInvoiceCount", obj)

  }

  getDataforinvoice(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/getDataforinvoice", obj)

  }

  getsignatoryimage(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/getsignatoryimage", obj)

  }

  updatesignatory(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/updatesignatory", obj)

  }

  deleteSignatory(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/deleteSignatory", obj)

  }

  updateLogo(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/updateLogo", obj)

  }

  checkRbiEdf(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getRbiEdfData", obj)

  }

  getreferrals(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getreferrals")

  }

  postReq(uri, obj): Observable<any> {

    return this.http
      .post(this.domain + uri, obj)

  }
  getReq(uri): Observable<any> {

    return this.http
      .get(this.domain + uri)

  }
  addreferrals(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/addreferrals", obj)

  }

  createRbiTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/createRbiTemplate", obj)

  }

  getRbiTemplates(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getRbiTemplates", obj)

  }

  changePassword(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/changePassword", obj)

  }

  postApi(uri, obj): Observable<any> {

    return this.http
      .post(this.domain + uri, obj)

  }
  addalternateAddress(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/addalternateAddress",
        obj,
        this.options
      )

  }

  getUnits(): Observable<any> {

    return this.http
      .get(this.domain + "authentication/getunits")

  }

  createitemTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/createitemTemplate", obj)

  }

  getItemTemplates(): Observable<any> {

    return this.http
      .get(this.domain + "template/getItemTemplates")

  }

  updateItemTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/updateItemTemplate", obj)

  }

  deleteItemTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/deleteItemTemplate", obj)

  }

  createConsigneeTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/createConsigneeTemplate", obj)

  }
  getConsigneeTemplates(): Observable<any> {

    return this.http
      .get(this.domain + "template/getConsigneeTemplates")

  }
  updateConsigneeTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/updateConsigneeTemplate", obj)

  }

  deleteConsigneeTemplate(obj): Observable<any> {

    return this.http
      .post(this.domain + "template/deleteConsigneeTemplate", obj)

  }

  digiLocker(): Observable<any> {

    return this.http
      .get(this.domain + "digi")

  }
  sendDoc(obj): Observable<any> {

    return this.http
      .post(this.domain + "getdoc", obj)

  }

  pbestatsforexporter(): Observable<any> {

    return this.http
      .get(this.domain + "stats/pbestatsforexporter")

  }

  parcelstatsforexporter(obj): Observable<any> {

    return this.http
      .post(this.domain + "stats/parcelstatsforexporter", obj)

  }

  getweekwisepbedata(): Observable<any> {

    return this.http
      .get(this.domain + "stats/getweekwisepbedata")

  }
  getParcelValueData(): Observable<any> {

    return this.http
      .get(this.domain + "stats/getParcelValueData")

  }
  getParcelcountData(): Observable<any> {

    return this.http
      .get(this.domain + "stats/getParcelcountData")

  }

  parcelvaluestatsforexporter(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "stats/parcelvaluestatsforexporter",
        obj,
        this.options
      )

  }

  regitserWithDetails(obj): Observable<any> {

    return this.http
      .post(
        this.domain + "authentication/registerWithexportToDomestic",
        obj,
        this.options
      )

  }

  verifyPayment(obj): Observable<any> {

    return this.http
      .post(this.domain + "authentication/verifyPayment", obj)

  }
  payforIndiaPost(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/payforIndiaPost", obj)

  }

  updatePickupStatus(obj): Observable<any> {

    return this.http
      .post(this.domain + "lookup/updatepickupstatus", obj)

  }

  getInitiateList(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/getInitiateList", obj)

  }

  addMonthlySubscription(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "order/addMonthlyPickupSubscription",
        obj,
        this.options
      )

  }
  addMonthlySubscriptionbysuperuser(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "order/addMonthlyPickupSubscriptionValues_bySuperuser",
        obj,
        this.options
      )

  }

  checkMonthlySubscription(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/monthlyPickupPlanActiveOrNot",
        obj,
        this.options
      )

  }

  editPickupSubscriptionValues(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "order/editPickupSubscriptionValues",
        obj,
        this.options
      )

  }

  monthlySubscriptionData(obj): Observable<any> {
    return this.http
      .post(this.domain + "order/getPickupSubscriptionInfo", obj)

  }
  sendpersonalExportOTP(obj): Observable<any> {
    return this.http
      .post(this.domain + "authentication/personal-sendotp", obj)

  }
  loginwithusername(obj): Observable<any> {
    return this.http
      .post(this.domain + "authentication/sendotpforlogin", obj)

  }
  verifypersonalExportOTP(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication//personal-verifyotp",
        obj,
        this.options
      )

  }

  sendPersonalExportRegisteration(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/registerPersonalUser",
        obj,
        this.options
      )

  }

  loginPersonalExport(obj): Observable<any> {
    return this.http
      .post(this.domain + "authentication/login", obj)

  }

  getPersonalExportProfile(): Observable<any> {


    return this.http
      .get(this.domain + "authentication/getprofile")

  }

  updateMobileSendOTP(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication//personalsendotpformobileupdate",
        obj,
        this.options
      )

  }
  verifyupdateMobileSendOTP(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication//personalverifyotpforupdate",
        obj,
        this.options
      )

  }
  verifyloginwithOTP(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/loginwithotp", obj)

  }
  personalExportForgotPassword(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/personal-forgotPassword",
        obj,
        this.options
      )

  }

  personalExportChangePassword(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/changePassword", obj)

  }

  personalExportShipAbroadForm(obj): Observable<any> {


    return this.http
      .post(this.domain + "save/personal-saveitems", obj)

  }

  sendPersonalExportPrice(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/selectShippingService",
        obj,
        this.options
      )

  }

  sendPersonalExportDate(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/pickupRequest", obj)

  }

  getAllShipAbroadData(obj): Observable<any> {



    return this.http
      .get(this.domain + "authentication/getAllOrderByUser")

  }

  calculatePrice(obj): Observable<any> {
    return this.http
      .post(
        this.domain + "authentication/calulateShippingRate",
        obj,
        this.options
      )

  }

  deleteShipAbroadEntry(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/deleteOrder", obj)

  }

  printPersonalExportCNForm(obj): Observable<any> {


    return this.http
      .post(this.domain + "order/cnform", obj)

  }

  updatePersonalExportShipAbroad(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/editOrder", obj)

  }

  createPayment(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/createrazorpayorder",
        obj,
        this.options
      )

  }
  verifyPaymentPersonalExport(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/verifyPayment", obj)

  }
  personalExportCustomerDetails(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/getCustomerDetails",
        obj,
        this.options
      )

  }

  disablePersonalExportAccount(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/disableUser", obj)

  }

  enablePersonalExportAccount(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/enableUser", obj)

  }

  creditDetailsUpdate(obj): Observable<any> {

    return this.http
      .post(this.domain + "domestic/creditDetailsUpdate", obj)

  }

  getDomesticSubUsers(obj): Observable<any> {

    return this.http
      .post(this.domain + "domestic/getDomesticSubUsers", obj)

  }

  updatePersonalExportSuperUser(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/editUser", obj)

  }

  getAllShipAbroadSuperUserData(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/getallOrder", obj)

  }

  addorderbySuperuser(obj): Observable<any> {


    return this.http
      .post(this.domain + "save/orderBySuperuser", obj)

  }

  verifyPersonalExportPayment(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/verifyPayment", obj)

  }

  getTransactions(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/personal-getalltransaction",
        obj,
        this.options
      )

  }

  getTransactionsCount(obj): Observable<any> {


    return this.http
      .post(
        this.domain + "authentication/personal-getalltransactionCount",
        obj,
        this.options
      )

  }

  changeStatus(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/pickupRequestStatus", obj)

  }

  getAgentList(): Observable<any> {
    return this.http
      .get(this.domain + "order/getPickupFboAgent")

  }

  selectAgent(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/pickupFboAgent", obj)

  }

  // generateInvoice(obj) {
  //  
  //   return this.http
  //     .post(this.domain + "order/personalinvoice", obj)
  //     
  // }

  generateInvoice(obj): Observable<any> {


    return this.http
      .post(this.domain + "order/personalinvoice", obj)

  }

  uploadPersonalReceipts(obj): Observable<any> {


    return this.http
      .post(this.domain + "authentication/uploadreceipts", obj)

  }

  getpersonalUserRemarks(obj): Observable<any> {


    return this.http
      .post(this.domain + "order/addfeedback", obj)

  }

  updatePersonalTrackingNo(obj): Observable<any> {


    return this.http
      .post(this.domain + "save/editTrackingNo", obj)

  }

  addPersonalRemark(obj): Observable<any> {


    return this.http
      .post(this.domain + "lookup/addRemarks", obj)

  }

  getMap(address): Observable<any> {


    const api =
      "https://maps.googleapis.com/maps/api/geocode/json?address={address}&key={key}";
    return this.http.get(
      api
        .replace("{address}", address)
        .replace("{key}", "AIzaSyAQG8hblTkoqXOB-Fpw-it1qZQORZ5Zu3U")
    );
  }

  getOrderCount(): Observable<any> {


    return this.http
      .get(this.domain + "order/getallOrderCount")

  }
  getP2PRrequests(obj): Observable<any> {

    return this.http
      .post(this.domain + "order/personal-getallpickuprequests", obj)

  }


  importShopifyOrder(obj): Observable<any> {

    return this.http.post(this.domain + "save/importShopifyOrder", obj)
  }

  kvFormatts(num) {
    // =================================== <Basics> ==============================
    // Indian number system – Thousands, lakhs, crores.
    // International number system – Billion, million, trillion
    // 10 Lakhs = 1 Million, 100 Lakhs = 10 Million, 1000 Lakhs = 100 Million,
    // 10000 Lakhs = 1 Billion(1000 Million), 10000000 Lakhs = 1000 Billion,
    // 1,00,000 Crores = 1 Trillion(1000 Billion),
    // =================================== </Basics> ==============================

    if (num >= 1000000000000) {
      return (num / 1000000000000).toFixed(1).replace(/\.0$/, "") + "T";
    }
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }

    if (num >= 10000000) {
      return (num / 10000000).toFixed(1).replace(/\.0$/, "") + "Cr";
    }
    // if (num >= 1000000) {
    //   return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    // }

    if (num >= 100000) {
      return (num / 100000).toFixed(1).replace(/\.0$/, "") + "L";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  decryptData(data) {
    try {
      const bytes = crypto.AES.decrypt(data, "secret key 123");
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(crypto.enc.Utf8));
      }
    } catch (e) {
      // // console.log(e);
    }
  }

  showIcon() {
    var d = localStorage.getItem("noticeReadCount");
    var t = localStorage.getItem("noticeCount");
    if (d == t) {
      return false;
    } else {
      return true;
    }
  }

  getNotification() {
    // // console.log(window.location);
    let condtn = window.location.pathname == "/";
    if (!condtn) {
      this.getnotice().subscribe((data) => {
        if (data.success) {
          if (data.data != null) {
            localStorage.setItem("noticeCount", data.updateCount);
            var n = localStorage.getItem("noticeId");
            if (
              n == null ||
              n == undefined ||
              n == "null" ||
              n == "undefined" ||
              n == ""
            ) {
              localStorage.setItem("noticeId", data.data._id);
              localStorage.setItem("isRead", "0");
              localStorage.setItem("notice", data.data.description);
              this.noticeId = data.data._id;
              // if(data.data.isBoth){
              //   this.notification=data.data.text;
              //   window.location.reload()
              // }
              if (data.data.isRefresh) {
                window.location.reload();
              } else {
                this.notification = data.data.description;
              }
            } else {
              if (n != data.data._id) {
                this.noticeId = data.data._id;
                localStorage.setItem("noticeId", data.data._id);
                localStorage.setItem("isRead", "0");
                localStorage.setItem("notice", data.data.description);
                // if(data.data.isBoth){
                //   this.notification=data.data.text;
                //   window.location.reload()
                //   $(".toast").addClass('on');
                //   $('#modalPush').modal();
                // }
                if (data.data.isRefresh) {
                  window.location.reload();
                } else {
                  this.notification = data.data.description;
                  $(".toast").addClass("on");
                  // $('#modalPush').modal();
                  setTimeout(() => {
                    $("#modalPush").modal();
                  }, 500);
                }
              } else {
                var t = localStorage.getItem("isRead");
                if (t == "0") {
                  this.notification = localStorage.getItem("notice");
                  $(".toast").addClass("on");
                  setTimeout(() => {
                    $("#modalPush").modal();
                  }, 500);
                }
              }
            }
          }
        }
      });
    }
  }

  closeToast() {
    $(".toast").removeClass("on");
    localStorage.setItem("isRead", "1");
  }

  isPermitted(val) {
    try {
      var token = localStorage.getItem("token");
      if (token) {
        // // console.log(token)
        var sj = this.helper.decodeToken(token);
        var p = sj.roles;
        // // console.log("sj >> ", sj);

        if (p.includes(val)) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  getSeriesCode() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "5") {
        return sj.seriesCode;
      } else {
        return "";
      }
    }
  }

  getFpoCode() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "5") {
        return sj.fpo;
      } else {
        return "";
      }
    }
  }

  getUrl() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.url;
      return p;
    } else {
      return "";
    }
  }

  isCha() {
    var token = localStorage.getItem("token");

    if (token) {

      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      // // console.log("token user >> ", sj);
      if (p == "2" || p == "4" || p == "11" || p == "12") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAgent() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "12") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isCustomAgent() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "2") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAdmin() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "10") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isSuperAdmin() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "10") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isCbic() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "6") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isExporterAdmin() {
    var token = localStorage.getItem("token");
    if (token) {
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "1") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getuserName() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      return sj.name;
    } else {
      return "";
    }
  }

  getuserProfile() {
    var token = localStorage.getItem("token");
    // // console.log("token >>> ", token);
    if (token) {
      var sj = this.helper.decodeToken(token);
      return sj;
    } else {
      return "";
    }
  }

  isExporterUser() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "3") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isUserSameAsLoggedIn(id) {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userId;
      if (p == id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isPBE1() {
    var token = localStorage.getItem("token");
    if (token) {
      var sj = this.helper.decodeToken(token);
      return sj.isWebUrl;
    } else {
      return false;
    }
  }

  decodeToken(tokenName = "token") {
    var token = localStorage.getItem(tokenName);
    if (token) {
      var sj = this.helper.decodeToken(token);
      return sj;
    }
    return {};
  }

  decodeUser() {
    try {
      var token = localStorage.getItem("token");
      // // console.log("if token >>  ", token);
      if (token) {
        var sj = this.helper.decodeToken(token);
        return sj;
      }
      return {};
    } catch (error) {
      // console.log("jwt error >> ", error);
      return {};
    }
  }



  isCustomLoggedIn() {
    var token = localStorage.getItem("token");
    if (token) {
      // // console.log(token)
      var sj = this.helper.decodeToken(token);
      var p = sj.userType;
      if (p == "5") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hideNav() {
    if (this.location.path() != "") {
      this.route = this.location.path();
      // // console.log(this.route)
      if (this.route == "/export-documents" || this.route == "") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async establishSocketConnection() {
    // // console.log("establish Socket Connection... ");
    try {
      this.userId = this.getuserProfile().userId;
      if (this.userId === "" || typeof this.userId === "undefined" || this.userId === null) {
        this.router.navigate(["/"]);
      } else {
        /* making socket connection by passing UserId. */
        await this.socketService.connectSocket(this.userId);

        /** start socket listener */
        this.listenForMessages();
      }
    } catch (error) {
      // console.log("establishSocketConnection error >>> ", error);
    }
  }

  listenForMessages(): void {
    this.socketService.listenSocketEvents('data').subscribe((socketResponse) => {
      // console.log("socket message received !!!!! >>> ", socketResponse);
      if (socketResponse.success) {
        /*** need to trigger an observable to notify documents page 
         * that this docket number is processed;
         * logic 
         * 1. add behavior subject
         * 2. subscribe on document component
         * 3. check for docket number is same that opend on the page
         * 4. if yes update button to download.
         * 5. unsubs behsubject on comp destroy.
         */
        this.setListenObs(true, socketResponse.docketNo || '');
        this.showSnackbar(socketResponse, 3000, this.domain);
      } else {
        this.showAlert(socketResponse.message, socketResponse.docketNo);
      }
    });
  }

  showSnackbar(message, time, domain) {
    // // console.log("download url >>>> ", domain + 'downloadZip/' + message.docketNo);
    snackbar.createSnackbar(message.message, {
      timeout: 5000,
      actions: [
        {
          text: "Download",
          style: { color: "pink" },
          callback(button, snackbar) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = domain + 'downloadZip/' + message.docketNo;
            a.target = "_blank";
            a.click();
          },
        },
      ],
    });
  }

  showAlert(message, docketNo = '') {
    // // console.log("tigger >> ", message);
    if (docketNo) {
      snackbar.createSnackbar(message + " for " + docketNo, {
        timeout: 3000,
      });
    } else {
      snackbar.createSnackbar(message, { timeout: 3000, });
    }
  }

  playSound() {
    var mp3Source =
      '<source src="assets/sound/sms-alert-3-daniel_simon.mp3" type="audio/mpeg">';
    document.getElementById("sound").innerHTML =
      '<audio autoplay="autoplay">' + mp3Source + "</audio>";
  }

  /*for all dopc information*/
  getAllExportDocRequest(obj): Observable<any> {
    return this.http
      .post(this.domain + "indiapost/POEdocsView", obj)

  }

  /*new upload fuctions*/

  uploadDoc(details): Observable<any> {
    return this.http
      .post(
        this.domain + "indiapost/proofOfExportDocsUpload",
        details,
        this.options
      )

  }

  /*passes only one docket number and fetches all the receipts and docket number added to it*/
  getDocketData(details): Observable<any> {
    return this.http.post(this.domain + "indiapost/getPOEdocsListing", details);
  }

  deleteXBparcels(obj): Observable<any> {
    return this.http.post(this.domain + "xpressbees/deleteParcels", obj)
  }

  /*** d2 apis */
  d2Login(details): Observable<any> {
    return this.http.post(environment.d2Url + "authentication/login", details);
  }

  getd2OrgList(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/list", details);
  }

  Addd2Org(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/add", details);
  }

  getPermissionList(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/getPermissionList", details);
  }

  getIntegratedAssignedVendors(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/getIntegratedAssignedVendors", details);
  }

  getIntegratedVendorsList(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/getIntegratedVendors", details);
  }

  updateOrganization(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/update", details);
  }

  updateOrganizationManageSubscription(details): Observable<any> {
    return this.http.post(environment.d2Url + "organization/manageSubscription", details);
  }

  getAddressBookD2(details): Observable<any> {
    return this.http.post(environment.d2Url + "addressbook/userList", details);
  }

  addXpressbeesOnboardForm(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/exporterOnboarding_xpressbees", details);
  }

  submitXpressbeesParcelData(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/saveXpressbeesData", details);
  }

  getXpressBeesRates(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/getXpressBeesRate", details);
  }

  getXpressBeesParcelData(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/parcelListingById", details);
  }

  getXpressBeesParcelList(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/parcelListing", details);
  }

  submitToXpress(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/submit2xpressbees", details);
  }

  XBtrackData(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/getXBTrackData", details);
  }

  getXpressbeesDocFile(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/getXBDocuments", details);
  }

  saveOnboardDataAsDraft(details): Observable<any> {
    return this.http.post(this.domain + "xpressbees/saveOnboardDataAsDraft", details);
  }


  getCommonTrackingData(details): Observable<any> {
    return this.http.post(this.domain + "commontracking/getCommonTrackData", details);
  }

  trackAramexParcel(details): Observable<any> {
    return this.http.post(this.domain + "aramex/trackAramexParcel", details);
  }

  trackFedexParcel(details): Observable<any> {
    return this.http.post(this.domain + "fedex/fedexTrackingData", details);
  }

  getDelhiveryRates(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/getDelhiveryRate", details);
  }

  saveDelhiveryData(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/saveDelhiveryData", details);
  }

  getDelhiveryParcelList(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/delhiveryParcelListing", details);
  }

  deleteDelhiveryParcel(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/delhiveryDeleteParcel", details);
  }

  getDelhiveryParcelById(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/getDelhiveryParcelById", details);
  }

  submitParcelTODelhivery(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/submit2delhivery", details);
  }

  getDelhiveryDocs(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/getDelhiveryDocs", details);
  }

  getDelhiveryTrackingData(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/getDelhiveryTrackData", details);
  }

  getPackageStatusDelhivery(details): Observable<any> {
    return this.http.post(this.domain + "delhivery/delhiveryPackageStatusCheck", details);
  }

  fetchDataFromDocs(details): Observable<any> {
    return this.http.post(this.domain + "dataFromDoc/fetchDataFromFIle", details);
  }

  /*******
   * sales reports api
   */
  getUserRegiratrationData(details): Observable<any> {
    return this.http.post(this.domain + "reports/userdata", details);
  }

  getParcelData(details): Observable<any> {
    return this.http.post(this.domain + "reports/parceldata", details);
  }

  getParcelsValueData(details): Observable<any> {
    return this.http.post(this.domain + "reports/parcelvalue", details);
  }

  getUserSubscribedData(details): Observable<any> {
    return this.http.post(this.domain + "reports/subscriptiondata", details);
  }

  updateIecCertificate(details): Observable<any> {
    return this.http.post(this.domain + "authentication/updateIecCetificate", details);
  }


}
