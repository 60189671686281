<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4" style="margin: auto;">
                <div class="card m-b-20">
                    <div class="card-body">
                        <div class="accountbg"></div>
                        <div class="wrapper-page">
                            <div class="p-3">
                                <h4 class="text-muted font-18 m-b-5 text-center">{{greeting}} Super User</h4>
                                <div class="card m-b-20">
                                    <div class="card-body">
                                        <div class="tab-content">
                                            <div class="tab-pane active p-3" id="home2" role="tabpanel">
                                                <form class="form-horizontal m-t-30">
                                                    <div class="form-group" *ngIf="showUser">
                                                        <label for="uname">Username</label>
                                                        <input type="text" class="form-control" id="uname"
                                                            placeholder="Enter Username">
                                                    </div>
                                                    <div class="form-group" *ngIf="showPass">
                                                        <label for="userpassword">Password</label>
                                                        <input type="password" class="form-control" id="userpassword"
                                                            placeholder="Enter password">
                                                    </div>
                                                    <div class="form-group row m-t-20">
                                                        <!-- <div class="col-sm-6">
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customControlInline">
                                                                  <label class="custom-control-label" for="customControlInline">Remember me</label>
                                                              </div>
                                                          </div> -->
                                                        <div class="col-sm-12 text-center">
                                                            <button
                                                                class="btn btn-outline-warning waves-effect waves-light"
                                                                type="submit" (click)="login()" *ngIf="showPass">Log
                                                                In</button>
                                                            <button
                                                                class="btn btn-outline-info waves-effect waves-light"
                                                                type="reset" *ngIf="showPass">Reset</button>
                                                            <button
                                                                class="btn btn-outline-info waves-effect waves-light"
                                                                type="reset" (click)="(showPass=true)(showUser=true)"
                                                                *ngIf="!showPass">Cancel</button>
                                                            <button
                                                                class="btn btn-outline-primary waves-effect waves-light"
                                                                type="reset" *ngIf="!showPass"
                                                                (click)="resetPass()">Reset</button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group m-t-10 mb-0 row">
                                                        <div class="col-12 m-t-20">
                                                            <a (click)="forget()" class="text-muted">
                                                                <i class="mdi mdi-lock"></i> Forgot your password?</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>