import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-customlogin',
  templateUrl: './customlogin.component.html',
  styleUrls: ['./customlogin.component.css']
})
export class CustomloginComponent implements OnInit {
  selectedUserType = '5';
  greeting;
  userName = '';
  password;
  inProcess = false
  newPassword = ''
  confirmPassword = ''
  isCustoms = true;
  isFirstLogin = false
  isForgotPassword = false

  constructor(public authService: AuthService, public router: Router,
    private activatedRoute: ActivatedRoute, private nfs: NotificationService) {
    if (this.activatedRoute.snapshot.params['id'] == 'customs') {
      this.isCustoms = true
    }
    else {
      this.isCustoms = false;
      this.selectedUserType = '7'
    }
  }

  ngOnInit() {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      this.greeting = "Good morning!";
    }
    if (time > 12 && time < 16) {
      this.greeting = "Good afternoon!"
    }
    if (time > 16) {
      this.greeting = "Good Evening!";
    }
    if (time == 12) {
      this.greeting = "Good Noon!";
    }
  }

  login() {
    // var iecode = '';
    this.inProcess = true
    // var pwd = (<HTMLInputElement>document.getElementById('userpassword')).value;
    if ((this.password.length >= 5)) {
      // iecode = (<HTMLInputElement>document.getElementById('uname')).value;
      var obj = {
        userType: this.selectedUserType,
        password: this.password,
        userName: this.userName
      }
      this.authService.login(obj).subscribe(data => {
        if (data.success) {
          // this.authService.storeUserData(data.token);
          this.authService.storeUserData(data);
          this.authService.establishSocketConnection();

          if (obj.userType != '7') {
            this.router.navigate(['/pbe'])
          }
          else {
            this.router.navigate(['/dgft'])
          }
        }
        else {
          this.inProcess = false
          this.isFirstLogin = data.isFirstLogin ? data.isFirstLogin : false
          // swal('Oops!!!', data.message, 'error')
          this.authService.showAlert(data.message)
        }
      })
    }
    else {
      this.inProcess = false
      this.nfs.showInfo('Oops!!!', 'Please provide username & password', 'error')
    }
  }

  verifyAndSetPassword() {
    // var iecode = '';
    this.inProcess = true
    // var pwd = (<HTMLInputElement>document.getElementById('userpassword')).value;
    if ((this.password.length >= 5)) {
      // iecode = (<HTMLInputElement>document.getElementById('uname')).value;
      var obj = {
        userType: this.selectedUserType,
        otp: this.password,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        userName: this.userName,

      }
      this.authService.verifyAndSetPassword(obj).subscribe(data => {
        if (data.success) {
          this.authService.storeUserData(data.token);
          if (obj.userType != '7') {
            this.router.navigate(['/pbe'])
          }
          else {
            this.router.navigate(['/dgft'])
          }
        }
        else {
          this.inProcess = false
          // this.isFirstLogin = data.isFirstLogin?data.isFirstLogin:false
          // swal('Oops!!!', data.message, 'error')
          this.authService.showAlert(data.message)
        }
      })
    }
    else {
      this.inProcess = false
      this.nfs.showInfo('Oops!!!', 'Please provide username & password', 'error')
    }
  }

  changeUserType(val) {
    this.selectedUserType = val
  }
  reset(val) {
    this.isForgotPassword = val
  }

  forgot() {
    var iecode = '';
    // iecode = (<HTMLInputElement>document.getElementById('uname')).value;
    var obj = {
      userType: this.selectedUserType,
      userName: this.userName
    }
    if (iecode.length > 5) {
      this.authService.resetPassword(obj).subscribe(result => {
        if (result.success) {
          this.isForgotPassword = false
          this.nfs.showInfo('Success!!!', result.message, 'success')
        }
        else {
          this.nfs.showInfo('Error!!!', result.message, 'error')
        }
      })
    }
    else {
      this.nfs.showInfo('Error!!!', 'Please enter username.', 'error')
    }
  }

}
