<header>

    <nav class="navbar fixed-top navbar-expand-lg navbar-light scrolling-navbar">
        <a routerLink="/" class="navbar-brand">
            <img src="assets/images/logo.png" alt="" style="transform: scale(1.3);height:30px;padding-left: 10px;">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="authService.loggedIn()?'navbarMargin':''"
            id="navbarTogglerDemo01" style="padding-left: 20px;">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                <li class="nav-item" *ngIf="!authService.loggedIn()">
                    <a class="nav-link" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Home
                        <span class="sr-only">(current)</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="authService.loggedIn() && !authService.isPermitted('dgft')">
                    <a class="nav-link waves-effect waves-light" style="color:343a40f0" routerLink="/home"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home
                    </a>
                </li>

                <li class="nav-item"
                    *ngIf="authService.loggedIn() && !authService.isPermitted('dgft') && !authService.isCustomLoggedIn()">
                    <a class="nav-link waves-effect waves-light" style="color:343a40f0" routerLink="/inventory"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Inventory </a>
                </li>


                <li class="nav-item" *ngIf="authService.loggedIn() && authService.isExporterAdmin()">
                    <a class="nav-link waves-effect waves-light" style="color:343a40f0" routerLink="/exporterdashboard"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Analytics
                    </a>
                </li>
                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() && authService.isSuperAdmin()">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Analytics
                    </a>
                    <div class="dropdown-menu dropDownMargin" aria-labelledby="navbarDropdown">

                        <a class="dropdown-item" *ngIf="(authService.isSuperAdmin())" routerLinkActive="active"
                            routerLink="/exporterdashboard" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"><i class="fa fa-bar-chart"></i>&nbsp;
                            &nbsp;Analytics 1</a>
                        <a class="dropdown-item" *ngIf="(authService.isSuperAdmin())" routerLinkActive="active"
                            routerLink="/analytics" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"><i class="fa fa-bar-chart"></i>&nbsp;
                            &nbsp;Analytics 2</a>
                    </div>

                </li>
                <li class="nav-item" *ngIf="!authService.loggedIn()">
                    <a class="nav-link waves-effect waves-light" routerLink="/login" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Login</a>
                </li>

                <li class="nav-item" *ngIf="!authService.loggedIn()">
                    <a class="nav-link waves-effect waves-light" routerLink="/register" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Register</a>
                </li>
                <!-- <li class="nav-item" *ngIf="!authService.loggedIn()">
                    <a class="nav-link waves-effect waves-light" routerLink="/trackmyparcel/track"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Track My Parcel</a>
                </li> -->
                <li class="nav-item" *ngIf="!authService.loggedIn()">
                    <a class="nav-link waves-effect waves-light" routerLink="/officers/customs"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Customs Login</a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() && (authService.isPermitted('isAdmin')||authService.isCha() || authService.isPermitted('isFpoAgent'))  && !authService.isPermitted('dgft')">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        My Parcels
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                        <a class="dropdown-item" routerLink="/orders">Enter Data</a>
                        <!-- <a class="dropdown-item" routerLink="/dhl_form">Enter Data (DHL)</a> -->
                        <!-- <a class="dropdown-item" routerLink="/pbetemplate">Create Templates</a> -->
                        <a class="dropdown-item" routerLink="/bulkupload">Bulk Upload Data Using Excel</a>
                        <a class="dropdown-item"
                            *ngIf="(authService.getuserProfile().defaultCarrier=='indiapost' && !authService.isSuperAdmin())"
                            routerLink="/boxes/indiapost">View Parcels</a>
                        <a class="dropdown-item"
                            *ngIf="(authService.getuserProfile().defaultCarrier=='dhl' && !authService.isSuperAdmin())"
                            routerLink="/dhlboxes/dhl">View Parcels</a>
                        <a class="dropdown-item"
                            *ngIf="(authService.getuserProfile().defaultCarrier=='aramex' && !authService.isSuperAdmin())"
                            routerLink="/aramexparcels">View Parcels</a>
                        <a class="dropdown-item"
                            *ngIf="(authService.getuserProfile().defaultCarrier=='fedex' && !authService.isSuperAdmin())"
                            routerLink="/fedexparcels">View Parcels</a>
                        <a class="dropdown-item"
                            *ngIf="(authService.getuserProfile().defaultCarrier=='DHLex' && !authService.isSuperAdmin())"
                            routerLink="/dhlexpressparcels">View Parcels</a>
                        <a class="dropdown-item" *ngIf="(authService.isSuperAdmin())" routerLink="/boxes/indiapost">View
                            Parcels</a>

                    </div>
                </li>


                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() &&  (authService.isExporterAdmin() || authService.isSuperAdmin() || authService.isCha())">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        My Docs
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="/pbe"
                            *ngIf="authService.loggedIn() && (authService.isPermitted('isAdmin') ||authService.isCha() || authService.isPermitted('isFpoAgent'))  && !authService.isPermitted('dgft')">My
                            PBE</a>
                        <a class="dropdown-item" routerLink="/csb"
                            *ngIf="authService.loggedIn() && (authService.isExporterAdmin() || authService.isSuperAdmin())">My
                            CSB</a>
                        <a class="dropdown-item" routerLink="/domesticshipments"
                            *ngIf="authService.isSuperAdmin()">Domestic Shipments
                        </a>
                        <a class="dropdown-item" routerLink="/meis"
                            *ngIf="authService.loggedIn() && authService.isPermitted('isAdmin')">My
                            MEIS</a>
                        <a class="dropdown-item" routerLink="/gstr"
                            *ngIf="authService.loggedIn() &&  authService.isExporterAdmin() || authService.isSuperAdmin()">My
                            GSTR</a>
                        <a class="dropdown-item" routerLink="/invoice"
                            *ngIf="authService.loggedIn() &&  (authService.isExporterAdmin() || authService.isSuperAdmin() || authService.isCha())">My
                            Invoices</a>
                    </div>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() &&  (authService.isExporterAdmin() || authService.isAgent() || authService.isSuperAdmin())">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        My Services
                    </a>
                    <div class="dropdown-menu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                            routerLink="/pickup/1">
                            Pickup Requests</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" routerLink="/manage-billing/1">
                            Manage Billing</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" routerLink="/manage-agents">Manage Agents</a>
                        <a class="dropdown-item" *ngIf="authService.isExporterAdmin()" (click)="openModal()">
                            AD Code Registration</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" routerLink="/adreg">
                            AD Code Registration</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" routerLink="/exportProof/1">
                            Export Proof Docs</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" routerLink="/pickupsubscription">
                            Pickup Subscription</a>
                    </div>
                </li>

                <li class="nav-item" *ngIf=" authService.isSuperAdmin()">
                    <a class="nav-link nav-link2" routerLink="/shipAbroad" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">P2P Parcels
                    </a>
                </li>
                <li class="nav-item" *ngIf="authService.loggedIn() && !authService.isCustomLoggedIn()">
                    <a class="nav-link nav-link2" routerLink="/updates" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Updates
                        <i *ngIf="authService.showIcon()" class="fa fa-circle"
                            style="position: relative;top:-8px;font-size:10px;right:4px;color:#1770e4"></i></a>
                </li>

                <li class="nav-item"
                    *ngIf="!authService.loggedIn() || (authService.isExporterAdmin() || authService.isSuperAdmin() || authService.isCha())">
                    <a class="nav-link nav-link2" routerLink="/plans" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Pricing</a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() && !authService.isPermitted('dgft')">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Tools
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="/api">REST API</a>
                        <a class="dropdown-item" routerLink="/hsnlookup" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">HS Look Up</a>
                        <a class="dropdown-item" routerLink="/hsnClassifier" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">HSN Classifier</a>
                        <a class="dropdown-item" routerLink="/dutyfreeimportallowance" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Duty-Free Import Allowance</a>
                        <a class="dropdown-item" routerLink="/ratecalculator/new" routerLinkActive=""
                            [routerLinkActiveOptions]="{ exact: true }">Calculate postage</a>
                        <a class="dropdown-item" routerLink="/domesticrateupload"
                            *ngIf="authService.loggedIn() && authService.isSuperAdmin()" routerLinkActive=""
                            [routerLinkActiveOptions]="{ exact: true }">Rate Upload</a>
                        <a class="dropdown-item" routerLink="/settings"
                            *ngIf="authService.loggedIn() && (authService.isExporterAdmin() || authService.isCha() || authService.isAgent())"
                            routerLinkActive="" [routerLinkActiveOptions]="{ exact: true }">Exchange Rate</a>

                        <a class="dropdown-item" routerLink="/trackparcels" *ngIf="authService.loggedIn()">
                            Track Parcels
                        </a>
                        <a class="dropdown-item" routerLink="/reports"
                            *ngIf="authService.loggedIn() && authService.isSuperAdmin()">
                            Sales Reports
                        </a>
                    </div>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Helpdesk
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                        [ngClass]="!authService.loggedIn()?'dropdownMargin':''">
                        <a *ngIf="authService.loggedIn() && authService.isSuperAdmin()" class="dropdown-item"
                            routerLink="/viewtickets" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Tickets</a>
                        <!-- <a class="dropdown-item" data-toggle="modal" data-target="#trackShipment"
                            *ngIf="authService.loggedIn()">Track
                            My Parcel</a> -->
                        <a class="dropdown-item" routerLink="/resources" *ngIf="authService.loggedIn()">Helpful
                            Resources</a>
                        <a class="dropdown-item" routerLink="/helpdesk/Issue">Report an
                            Issue</a>
                        <a class="dropdown-item" routerLink="/helpdesk/DemoRequest">Get
                            a Demo</a>
                        <a class="dropdown-item" routerLink="/helpdesk/FeatureRequest">Suggest
                            a Feature</a>
                        <!-- <a class="dropdown-item" routerLink="/serviceprovider">Service Providers</a> -->
                        <a class="dropdown-item" routerLink="/contact" *ngIf="authService.loggedIn()">Contact
                            us</a>
                    </div>
                </li>

                <li class="nav-item">
                    <a class="nav-link nav-link2" routerLink="/video-resources" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Resources</a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn() && authService.isSuperAdmin()">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Accounts
                    </a>
                    <div class="dropdown-menu dropDownMargin" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            routerLink="/billing/1" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">
                            DHL Billing</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            routerLink="/initiatelist" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">
                            Approvals</a>
                        <a class="dropdown-item" *ngIf="authService.isSuperAdmin()" routerLinkActive="active"
                            routerLink="/aramex-billing/1" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">
                            Aramex Billing</a>
                        <a class="dropdown-item" routerLink="/all_invoices"
                            *ngIf="authService.loggedIn() && authService.isSuperAdmin()">
                            Tax Invoices</a>
                    </div>

                </li>

                <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="authService.loggedIn()">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-user"
                            style="height:25px;width:25px;border-radius:50%;line-height:25px;background-color: rgb(255,0,102);text-align: center;color:#fff"></i>
                    </a>
                    <div class="dropdown-menu dropDownMargin" aria-labelledby="navbarDropdown">

                        <a class="dropdown-item" *ngIf="!(authService.isAdmin() || authService.isCustomLoggedIn())"
                            routerLinkActive="active" routerLink="/myaccount" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"><i class="fa fa-bars"></i>&nbsp;
                            &nbsp;My Account</a>
                        <a class="dropdown-item" routerLinkActive="active" routerLink="/profile"
                            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><i
                                class="fa fa-user"></i>&nbsp; &nbsp;Profile</a>

                        <a class="dropdown-item" *ngIf="(authService.loggedIn() && authService.isSuperAdmin())"
                            routerLink="/settings" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"><i class="fa fa-cog"></i>&nbsp;
                            &nbsp;Settings</a>


                        <!-- <a class="dropdown-item" routerLinkActive="active" routerLink="/inbox" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"><i class="fa fa-user"></i>&nbsp; &nbsp;Inbox</a> -->
                        <a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                            (click)="onLogoutClick()"><i class="fa fa-sign-out"></i>&nbsp; &nbsp;Logout</a>

                    </div>
                </li>

                <li class="nav-item"
                    *ngIf="authService.loggedIn() && (authService.isPermitted('isAdmin') || authService.isCha() || authService.isPermitted('isFpoAgent'))">
                </li>

            </ul>
        </div>
    </nav>

</header>
<div class="modal bottom" id="trackShipment" tabindex="-1" role="dialog" aria-labelledby="trackShipment">
    <div class="modal-dialog modal-lg" role="document">

        <div class="modal-content">

            <div class="modal-header">
                <h6 class="modal-title text-center">Parcel Tracking</h6>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)='trackMore()'>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-md-6" style="margin: auto">
                        <input type="text" [readonly]='isTracking' [(ngModel)]="trackingId"
                            placeholder="Enter tracking Id" class="form-control">
                    </div>
                </div>
                <div class="row" style="margin-top: 10px">
                    <div class="col-md-6 text-center" style="margin: auto">
                        <button class="btn btn-outline-info" *ngIf='!isTracking' (click)="trackShipment()">Track
                            Now</button>
                        <button class="btn btn-outline-danger" *ngIf='isTracking' (click)='trackMore()'>Track
                            more</button>
                    </div>
                </div>
                <div class="col-md-12" innerHTML="{{trackingData}}"
                    style="max-height: 368px;overflow-y: auto;margin-top: 20px;">
                </div>

                <div class="modal-footer" style="padding-bottom: 0">
                    <div style="margin: auto">
                        <a href="https://play.google.com/store/apps/details?id=com.pikmybox.pmb" target="_blank">
                            <img src="assets/images/play_store.png" alt="pikmybox" style="height: 45px;">
                        </a>
                        <a href="https://apps.apple.com/us/app/pikmybox/id1473778437?ls=1" target="_blank">
                            <img alt="pikmybox" src="assets/images/appstore.png" style="height: 45px;">
                        </a>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>



<div class="modal" id="adCodeReg" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="modalcloseoutLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style=" min-width: 900px;">
        <div class="modal-content" style="background: #596173;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100"
                    style="color: #e6a91A;font-weight: 500;font-family: 'Bree Serif', serif !important;">AD Code
                    Registration</h4>
            </div>
            <div class="modal-body p-0 m-0">
                <!-- {{authService.domain}}adCoderegiframe -->
                <!-- <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(authService.domain+'rate/56565')" frameborder="0"></iframe> -->
                <iframe id="adIframe" *ngIf="isAdModal" [src]="authService.domain+'adCoderegiframe'" frameborder="0"
                    style="width: 100%;min-height:500px ;"></iframe>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="btn btn-warning" id="payBtn" (click)="payForAdCodeReg()">Pay</button>
                <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>