<div class="container">
    <div class="row justify-content-sm-center" style="margin-top:5%">

        <!-- <div class="col-sm-4">
        <select class="form-control" style="height:45px;" (change)="getCountry($event)">
          <option value="0"  selected >Select Country</option>
          <option *ngFor="let c of countries" value="{{c.group}}">{{c.country}}</option>
        </select>
      </div>-->
        <div class="col-sm-6">
            <input type="text" [(ngModel)]="searchItem" class="form-control"
                placeholder="Keyword  or HS Code (min 3 char)" style="height:45px;">
            <i *ngIf="searchItem?.length>2" (click)="(searchItem='');reset()" class="fa fa-close text-danger"
                style="position: absolute;right: 24px;top: 10px;font-size: 25px;cursor: pointer;"></i>
        </div>

        <div class="col-sm-2 text-right">
            <button class="btn btn-outline-primary" style="margin:0px" (click)="getHSNRecord()">
                <i class="fa fa-search"></i>
                Search
            </button>
        </div>
    </div>
</div>

<div class="container" style="margin-top:2%;width:100%">
    <table class="table table-bordered m-b-0 text-center" id="mainTable">
        <thead>
            <tr>

                <th style="width:10%">HS Code</th>
                <!-- <th>S.No.<br/>(MEIS)</th> -->
                <th>Description of Goods</th>
                <th>Duty</th>
                <!-- <th style="width:20%">MEIS Reward Rate (in %)</th> -->

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hsn of hsnData;let i=index">

                <td>{{(hsn.hsnCode==""?hsn.itcCode:hsn.hsnCode)}}</td>
                <!--  <td>{{hsn.slNo}}</td> -->
                <td class="text-left" *ngIf="(hsn.hsnCode=='')"><span>{{hsn.description}}</span><span
                        *ngIf="hsn.isHandicraft">&nbsp;&nbsp;&nbsp;<img src="assets/images/verified.png"></span></td>
                <td class="text-left" *ngIf="(hsn.hsnCode!='')"><a (click)="getMainCat(hsn.hsnCode)">{{hsn.description}}
                        <span *ngIf="hsn.isHandicraft">&nbsp;&nbsp;&nbsp;<img
                                src="assets/images/verified.png"></span></a></td>

                <!-- <td *ngIf="isSearch">
                    <span *ngIf="groupA || hsn.itcCode?.length>7">Group A - {{hsn.groupA}}</span><br/>
                    <span *ngIf="groupB">Group B - {{hsn.groupB}}</span><br/>
                    <span *ngIf="groupC">Group C - {{hsn.groupC}}</span>
                  </td>
  
                 <td *ngIf="!isSearch">
                    <span *ngIf="(hsn.itcCode!=undefined && hsn.itcCode.length>7)">Group A - {{hsn.groupA}}</span><br/>
                    <span *ngIf="(hsn.itcCode!=undefined && hsn.itcCode.length>7)">Group B - {{hsn.groupB}}</span><br/>
                    <span *ngIf="(hsn.itcCode!=undefined && hsn.itcCode.length>7)">Group C - {{hsn.groupC}}</span>
                  </td>-->
                <td>
                    <span *ngIf="hsn.hsnCode?.length>7 || hsn.itcCode?.length>7 ">
                        30%
                    </span>

                </td>
            </tr>
        </tbody>

    </table>

    <div class="row" *ngIf="isLoading">
        <div class="card-body">
            <div class="lds-css ng-scope">
                <div class="lds-blocks" style="margin: auto">
                    <div style="left:38px;top:38px;animation-delay:0s">
                    </div>
                    <div style="left:80px;top:38px;animation-delay:0.125s">
                    </div>
                    <div style="left:122px;top:38px;animation-delay:0.25s">
                    </div>
                    <div style="left:38px;top:80px;animation-delay:0.875s"></div>
                    <div style="left:122px;top:80px;animation-delay:0.375s"></div>
                    <div style="left:38px;top:122px;animation-delay:0.75s"></div>
                    <div style="left:80px;top:122px;animation-delay:0.625s"></div>
                    <div style="left:122px;top:122px;animation-delay:0.5s"></div>
                </div>
            </div>
        </div>
    </div>


    <div class="text-center" style="margin-bottom:5%">
        <button class="btn btn-outline-primary" (click)="reset()">Reset</button>
        <button class="btn btn-outline-primary" (click)="goback()" *ngIf="previous?.length>1">Back</button>
    </div>

</div>