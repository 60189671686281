<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4" style="margin: auto;" *ngIf="isVerifiedLink">
                <div class="card m-b-20">
                    <div class="card-body">
                        <div class="accountbg"></div>
                        <div class="wrapper-page">
                            <div class="p-3">
                                <h4 class="text-muted font-18 m-b-5 text-center">Set New Password !</h4>
                                <div class=" m-b-20">
                                    <div class="">
                                        <!-- Tab panes -->
                                        <div class="tab-content" style="padding-top: 16px;">
                                            <div class="tab-pane active p-3" id="home2" role="tabpanel">
                                                <form [formGroup]="form" class="form-horizontal m-t-30">
                                                    <div class="form-group">
                                                        <label for="password">New Password</label>
                                                        <input (keypress)="removeWhiteSpace($event)"
                                                            [ngClass]="{'is-invalid': (!form.controls.password.valid || form.controls.password.errors?.required)  && form.controls.password.dirty,
                                                             'is-valid':(form.controls.password.valid  && form.controls.password.dirty)}" type="password"
                                                            class="form-control" formControlName="password"
                                                            id="password" placeholder="Enter password">
                                                        <div class="invalid-feedback">Password must be of min 8
                                                            charecters.</div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="cpassword">Confirm Password</label>
                                                        <input type="password" (keypress)="removeWhiteSpace($event)"
                                                            [ngClass]="{'is-invalid': !form.controls.cpassword.valid  && form.controls.cpassword.dirty, 
                                                            'is-valid':((form.controls.cpassword.valid  && form.controls.cpassword.dirty) )}"
                                                            formControlName="cpassword" class="form-control"
                                                            id="cpassword" placeholder="Enter Password Again">
                                                        <div class="invalid-feedback">Password & confirm password must
                                                            be same.</div>
                                                    </div>
                                                    <div class="form-group row m-t-20">
                                                        <div class="col-sm-12 text-center">
                                                            <a class="btn btn-info w-md waves-effect waves-light"
                                                                routerLink="/">
                                                                <i class="fa fa-chevron-left"></i>&nbsp;Back</a>
                                                            <button [disabled]="!form.valid"
                                                                class="btn btn-primary w-md waves-effect waves-light"
                                                                type="button" (click)="resetPassword()">
                                                                <i class="fa fa-send"></i>&nbsp;Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8" style="margin: auto;" *ngIf="!isVerifiedLink">
                <div class="tab-pane active p-3" id="home2" role="tabpanel" *ngIf="!isVerifiedLink">
                    <div class="card btn-outline-danger text-center z-depth-2">
                        <div class="card-body">
                            <p class="red-text mb-0">{{message}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>