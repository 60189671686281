<div class="container" style="padding: 3.5rem 3rem 3rem 3rem;">

    <div class="row">
        <div class="col-12 col-lg-12 col-xl-12 d-flex">
            <div class="card flex-fill w-100">
                <div class="card-header text-center">
                    <h5 class="card-title mb-0" style="font-weight: 600">Updates</h5>
                </div>

                <div class="card-body">
                    <ul class="timeline">
                        <li class="timeline-item" *ngFor="let d of newsData;let i=index"
                            style="padding:10px;border-bottom:1px solid #e0e0e0">

                            <strong><i class="fa fa-calendar" aria-hidden="true"
                                    style="color:#ff3547;"></i>&nbsp;{{d.date |date:'dd-MM-yyyy'}}</strong>

                            <span>
                                <img src="assets/Icon/new.gif" *ngIf="d.isNewIcon"
                                    style="height:30px;width:70px;float:right">
                            </span>

                            <div [innerHtml]="d.description"></div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="answer-card card mb-4" style="width:100%">
        <div class="card-header forum-card-img-30 d-flex justify-content-between">
           
            <div style="float:rigth">
              <p class="mb-0" style="float:left">
                <strong><i class="fa fa-calendar" aria-hidden="true" style="color:#ff3547;line-height: 34px;"></i>&nbsp;&nbsp;{{d.date |date:'dd-MM-yyyy'}}</strong>
              </p>
            </div>
  
            <div style="float:left">
              <img src="assets/Icon/new.gif" *ngIf="d.isNewIcon" style="height:35px;width:85px;float:right">
            </div>
          
        </div>
          
        <div class="card-body" [innerHtml]="d.description"></div>
      </div>    -->
    </div>
</div>