<div class="loading" *ngIf="loading">Loading&#8230;</div>

<app-navbar [attr.id]="(!authService.loggedIn() && currentRoute != '/export') ? '': 'thisNavbarId'"
    [ngStyle]="{'display':(!authService.loggedIn() && currentRoute != '/export' )?'block':'none' }"
    *ngIf=" (currentRoute!='/') && !authService.hideNav()"></app-navbar>

<div class="container-fluid" style="padding:0px;min-height: 100% !important;"
    [ngStyle]="{'padding-top':(!authService.loggedIn() && currentRoute != '/export' && currentRoute != '/'  )?'3.5rem':'' }">


    <app-navbar *ngIf="authService.loggedIn() && currentRoute!='/'"></app-navbar>
    <div *ngIf="authService.loggedIn()" class="container-fluid" style=" padding:0px;"
        [ngStyle]="{'padding-top':currentRoute!='/'?'4rem':'' }"></div>

    <router-outlet></router-outlet>

    <div class="snackbars snackbars-center">

    </div>
    <div id="sound"></div>
    <div class="modal" id="modalPush" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-notify modal-danger" role="document">
            <!--Content-->
            <div class="modal-content text-center">
                <!--Header-->
                <div class="modal-header d-flex justify-content-center">
                    <p class="heading">Be always up to date</p>
                </div>

                <!--Body-->
                <div class="modal-body">
                    <i class="fa fa-bell fa-4x animated rotateIn mb-4"></i>
                    <br />
                    <div [innerHtml]="authService.notification" class="text-left">

                    </div>
                    <!-- {{authService.notification}} -->
                </div>

                <!--Footer-->
                <div class="modal-footer flex-center">
                    <button type="button" class="btn btn-outline-danger btn-sm" data-dismiss="modal"
                        (click)="authService.closeToast()">Okay</button>
                </div>
            </div>
            <!--/.Content-->
        </div>
    </div>


    <div style="padding-bottom: 50px;"></div>
    <!-- background-color: #25263A; -->

    <footer *ngIf="!authService.hideNav()" class="page-footer font-small unique-color-dark mt-4"
        style="position:  fixed;width:  100%;bottom: 0px;z-index: 999; padding: 5px 0px; ">

        <div class="row" style="display:none" id="contactForm">
            <div class="col-md-12 text-center">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <h3 style="font-weight:600;font-size:20px;">Write To Us</h3>
                    </div>
                    <div class="col-md-3" (click)="hideShowForm('close')">
                        <i class="fa fa-fw fa-chevron-down" style="cursor:pointer"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-12">

                <form class="frm transform" [formGroup]="form" (ngSubmit)="submitForm(form.value)">

                    <div class="md-form">
                        <input type="text" id="form1" class="form-control" formControlName="name" required>
                        <label for="form1">Your Name</label>
                        <div class="invalid-feedback">Name is Required.</div>
                    </div>
                    <div class="md-form">
                        <input type="text" id="form2" class="form-control" formControlName="cname" required>
                        <label for="form2">Company Name</label>
                        <div class="invalid-feedback">Company Name is Required.</div>
                    </div>
                    <div class="md-form">
                        <input type="email" id="form3" class="form-control" formControlName="email" required>
                        <label for="form3">Email</label>
                    </div>
                    <div class="md-form">
                        <input type="text" id="form4" class="form-control" formControlName="mobile" required>
                        <label for="form4">Mobile</label>
                    </div>
                    <div class="md-form">
                        <input type="text" id="form5" class="form-control" formControlName="message" required>
                        <label for="form5">Message</label>
                    </div>
                    <div class="md-form">
                        <button type="submit" class="btn btn-sm btn-block btn-primary">Send</button>
                    </div>
                </form>
            </div>
        </div>

        <a class="launcher" *ngIf="(currentRoute!='/')" (click)="hideShowForm('open')" id="launcher">
            <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px"
                height="24px" viewBox="0 0 60.002 60.002" enable-background="new 0 0 60.002 60.002"
                xml:space="preserve">
                <path fill="white" d="M48.855,16.159c0,1.362-0.128,2.628-0.385,3.798c-0.257,1.169-0.708,2.327-1.349,3.471
        c-0.643,1.143-1.518,2.313-2.623,3.508c-1.105,1.196-2.481,2.51-4.125,3.95c-1.672,1.415-2.982,2.593-3.934,3.531
        c-0.951,0.937-1.664,1.889-2.139,2.853c-0.476,0.964-0.771,2.061-0.887,3.294c-0.116,1.234-0.173,2.841-0.173,4.82h-7.018
        c0-2.468,0.116-4.499,0.347-6.093c0.231-1.593,0.629-3.02,1.195-4.28c0.566-1.26,1.363-2.472,2.391-3.642
        c1.028-1.169,2.352-2.553,3.972-4.147c1.131-1.105,2.146-2.043,3.046-2.815c0.874-0.771,1.606-1.521,2.198-2.253
        c0.59-0.734,1.034-1.524,1.33-2.373c0.295-0.848,0.443-1.889,0.443-3.123c0-1.517-0.276-2.917-0.829-4.203
        s-1.317-2.397-2.294-3.336c-0.977-0.938-2.134-1.67-3.471-2.197c-1.336-0.527-2.801-0.792-4.395-0.792
        c-6.349,0-10.115,3.677-11.298,11.028h-7.711c0.18-2.545,0.764-4.858,1.753-6.941c0.99-2.082,2.314-3.862,3.972-5.34
        c1.658-1.478,3.606-2.622,5.842-3.432c2.237-0.81,4.679-1.214,7.327-1.214c2.879,0,5.475,0.405,7.789,1.214
        s4.286,1.921,5.918,3.335c1.632,1.415,2.892,3.091,3.778,5.032C48.412,11.757,48.855,13.871,48.855,16.159z M34.088,59.767h-8.175
        v-8.174h8.175V59.767z"></path>
            </svg>
        </a>
        <!-- {{currentRoute}} -->
        <div class="footer-copyright text-center py-2 row"
            [ngStyle]="{'padding-top':(currentRoute=='/')?'0.2rem !important':'0.5rem !important', 'padding-bottom':(currentRoute=='/')?'0.2rem !important':'0.6rem !important'}">

            <div class="col-md-12" style="padding: 0 30px">


                <div class="row uniqFooterL">
                    <div class="col-sm-3" style="font-size: 12px;text-align: left;vertical-align: middle;
          align-self: center;">
                        <!-- <span style="color:#fff">Your data is secure with us. We do not share data with any third party</span> -->

                        Follow Us &nbsp;: &nbsp;
                        <a href="https://www.facebook.com/pikmybox/" target="new"><i class="fa fa-facebook-official"
                                style="color:#ddd;font-size: 15px"></i></a>
                        &nbsp;
                        <a href="https://www.instagram.com/pikmybox/" target="new"><i class="fa fa-instagram"
                                aria-hidden="true" style="color:#ddd;font-size:15px"></i></a>
                        &nbsp;

                        <a href="https://www.youtube.com/channel/UCL4kJ7j87def-F1RUN-Iidw/featured" target="new"><i
                                class="fa fa-youtube" aria-hidden="true" style="color:#ddd;font-size:15px"></i></a>
                        <a href="https://twitter.com/PikMyBox" target="new" class="ml-2"><i class="fa fa-twitter"
                                aria-hidden="true" style="color:#ddd;font-size:15px"></i></a>
                    </div>
                    <div class="col-sm-6 p-0" style="text-align:center;vertical-align: middle;
          align-self: center;">
                        <span style="font-size: 10px;">
                            <a href="javascript:void(0)" routerLink="/privacypolicy"><span
                                    style="text-decoration: underline">Privacy
                                    Policy</span> &nbsp;&nbsp;Your data is secure with us. We do not share data with any
                                third party.</a>
                        </span>

                    </div>
                    <div class="col-sm-3" style="text-align:right">

                        <span style="float:right" style="font-size: 12px;">
                            <!-- © PikMyBox 2018 -->
                            <a routerLink="/" class="navbar-brand mr-0 p-0">
                                <img src="assets/images/logo.png" alt="" style="height:27px;padding-left: 10px;">
                            </a>
                            <!-- <p style="color:#fff">Your data is secure with us. We do not share data with any third party</p> -->
                        </span>
                    </div>
                </div>

                <div class="row uniqFooterS">
                    <div class="col-6" style="font-size: 12px;text-align: center;vertical-align: middle;
            align-self: center;">
                        <!-- <span style="color:#fff">Your data is secure with us. We do not share data with any third party</span> -->

                        Follow Us &nbsp;: &nbsp;
                        <a href="https://www.facebook.com/pikmybox/" target="new"><i class="fa fa-facebook-official"
                                style="color:#ddd;font-size: 15px"></i></a>
                        &nbsp;
                        <a href="https://www.instagram.com/pikmybox/" target="new"><i class="fa fa-instagram"
                                aria-hidden="true" style="color:#ddd;font-size:15px"></i></a>
                        &nbsp;

                        <a href="https://www.youtube.com/channel/UCL4kJ7j87def-F1RUN-Iidw/featured" target="new"><i
                                class="fa fa-youtube" aria-hidden="true" style="color:#ddd;font-size:15px"></i></a>
                        <a href="https://twitter.com/PikMyBox" target="new"><i class="fa fa-twitter" aria-hidden="true"
                                style="color:#ddd;font-size:15px"></i></a>
                    </div>
                    <div class="col-6" style="text-align:center">

                        <span style="float:right" style="font-size: 12px;">
                            <!-- © PikMyBox 2018 -->
                            <a routerLink="/" class="navbar-brand mr-0 p-0">
                                <img src="assets/images/logo.png" alt="" style="height:27px;padding-left: 10px;">
                            </a>
                            <!-- <p style="color:#fff">Your data is secure with us. We do not share data with any third party</p> -->
                        </span>
                    </div>
                    <div class="col-12 p-0" style="text-align:center;vertical-align: middle;
            align-self: center;">
                        <span style="font-size: 10px;">
                            <a href="javascript:void(0)" routerLink="/privacypolicy"><span
                                    style="text-decoration: underline">Privacy
                                    Policy</span> &nbsp;&nbsp;Your data is secure with us. We do not share data with any
                                third party.</a>
                        </span>
                    </div>

                </div>

            </div>

        </div>

        <!-- Copyright -->

    </footer>
</div>