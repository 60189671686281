<div class="container">

    <div class="row mt-4">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button [ngStyle]="{'background':(serviceProvider =='indiapost') ? '#53698d' : '#969696' }" class="btn"
                    style="margin: 0px;text-align: center;width: 8rem;padding: 8px 16px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;font-family: 'Bree Serif', serif!important;font-size: 15px;height:38px"
                    (click)="serviceProvider='indiapost'">
                    INDIA POST
                </button>

                <button [ngStyle]="{'background': (serviceProvider=='fedex') ? '#53698d' : '#969696' }" class="btn"
                    style="margin: 0px;text-align: center;width: 8rem;border-top-right-radius: 0px;border-bottom-right-radius: 0px;padding: 8px 16px;font-size: 15px;font-family: 'Bree Serif', serif !important;height: 38px;"
                    (click)="serviceProvider='fedex'; status='Pickup Done'">Fedex
                </button>

                <button [ngStyle]="{'background': (serviceProvider =='aramex') ? '#53698d' : '#969696' }" class="btn"
                    style="margin: 0px;text-align: center;width: 8rem;border-top-right-radius: 10px;border-bottom-right-radius: 10px;padding: 8px 16px;font-size: 15px;font-family: 'Bree Serif', serif !important;height: 38px;"
                    (click)="serviceProvider='aramex'; status='Pickup Scheduled'">Aramex
                </button>

            </div>
        </div>
        <div class="col-md-4"></div>
    </div>

    <div class="card" style="margin-top:5%">
        <div class="card-body">
            <div class="row">

                <div class="col-md-3">
                    <select class="form-control" (change)="getYear($event)" style="height:45px;">
                        <option value="" disabled selected>Select Year</option>
                        <option *ngFor="let y of year" value="{{y.name}}">{{y.name}}</option>
                    </select>
                </div>

                <div class="col-md-3">
                    <select class="form-control" (change)="getMonth($event)" style="height:45px;">
                        <option value="0" disabled selected>Select Month</option>
                        <option *ngFor="let m of month" value="{{m.id}}">{{m.name}}</option>
                    </select>
                </div>

                <div class="col-md-3" *ngIf="serviceProvider == 'indiapost'">
                    <select class="form-control" (change)="noteChange($event)" style="height:45px;">
                        <option value="0" disabled selected>Select Note</option>
                        <option value="All"> All </option>
                        <option value="PBE not submitted in FPO"> PBE not submitted in FPO </option>
                        <option value="PBE submitted"> PBE submitted </option>
                        <option value="PBE Cancelled"> PBE Cancelled </option>
                    </select>
                </div>

                <div class="col-md-3" *ngIf="serviceProvider != 'indiapost'">
                    <select class="form-control" [(ngModel)]="status" style="height:45px;">
                        <option value="0" [selected]="serviceProvider != 'fedex' || serviceProvider != 'aramex'"
                            disabled>Select
                            Status</option>
                        <option *ngIf="serviceProvider == 'fedex'" [selected]="serviceProvider == 'fedex'"
                            value="Pickup Done">
                            Pickup Done </option>
                        <option *ngIf="serviceProvider == 'aramex'" [selected]="serviceProvider == 'aramex'"
                            value="Pickup Scheduled"> Pickup Scheduled </option>
                    </select>
                </div>


                <div class="col-md-2">
                    <button class="btn btn-outline-primary" [disabled]="loading" (click)="downloadGstin()"
                        style="margin:0px">DOWNLOAD</button>
                </div>

            </div>
        </div>
    </div>
</div>